import { Grid, GridItem } from '@chakra-ui/react';
import { SalesOrdersDetailSpec } from 'features/salesOrdersV2/models';
import { Fragment, memo } from 'react';

export const SalesOrdersPreviewDetailSpec = memo(
  ({ specs }: { specs: SalesOrdersDetailSpec[] }) => {
    // 表示すべき仕様がない場合はブロック自体を表示しない
    if (!Object.values(specs).some((spec) => spec.node)) {
      return <></>;
    }

    return (
      <Grid
        mt={2}
        ml={4}
        pl={3}
        borderLeft="2px"
        borderColor="gray.200"
        gap="4px 12px"
        gridTemplateColumns="max-content 1fr"
      >
        {specs.map(
          (spec, i) =>
            spec.node && (
              <Fragment key={`spec-${i}`}>
                <GridItem textAlign="right">{spec.label}</GridItem>
                <GridItem whiteSpace="pre-wrap">{spec.node}</GridItem>
              </Fragment>
            ),
        )}
      </Grid>
    );
  },
);
