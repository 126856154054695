import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { Caution } from '@icon-park/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { DemandItemConfirm } from 'features/demandItem/components/DemandItemConfirm/DemandItemConfirm';
import { DemandItemFormType } from 'features/demandItem/form';
import { parseDemandItemCodeForUrl } from 'features/demandItem/models';
import { confirm_DemandItemEditConfirmPageMutation } from 'gql/__generated__/confirm_DemandItemEditConfirmPageMutation.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { useNavigationBlocker } from 'hooks/useNavigationBlocker';
import { toast } from 'lib/toast';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingCategoryError, MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';
import { DemandItemContext, KeepVersionContext } from '../context';

export const DemandItemEditConfirmPage = () => {
  const navigate = useNavigate();
  const form = useFormContext<DemandItemFormType>();

  const { demandCode, itemCode } = useParams();
  if (!demandCode) {
    throw new MissingRequiredParamError('demandCode');
  }
  if (!itemCode) {
    throw new MissingRequiredParamError('itemCode');
  }

  const { category, itemNumber } = parseDemandItemCodeForUrl(itemCode);
  if (!category) {
    throw new MissingCategoryError();
  }

  const { commitMutation, isMutationInFlight } =
    useMutationWrapper<confirm_DemandItemEditConfirmPageMutation>(
      graphql`
      mutation confirm_DemandItemEditConfirmPageMutation(
        $id: ID!
        $keepVersion: Boolean!
        $input: DemandItemUpdateInput!
      ) {
        updateDemandItem(id: $id, keepVersion: $keepVersion, input: $input) {
          id
        }
      }
    `,
    );

  const { getValues } = form;
  const { name } = getValues();

  const keepVersionContext = useContext(KeepVersionContext);
  if (!keepVersionContext) {
    throw new Error('KeepVersionContext is null');
  }
  const { keepVersion } = keepVersionContext;

  const demandItemContext = useContext(DemandItemContext);
  if (!demandItemContext) {
    throw new Error('demandItemContext is null');
  }
  const { itemId, isCurrentVersion } = demandItemContext;

  const { allowNavigation } = useNavigationBlocker(true, false);

  if (!name)
    return (
      <Navigate
        to={paths.demands
          ._demandCode(demandCode)
          .items._itemCode({ itemNumber, category })
          .edit.confirm.url()}
      />
    );

  const handleSubmit = (data: DemandItemFormType) => {
    allowNavigation();

    const uploadables = data.attachments.map((attachment, index) => {
      return {
        [`variables.input.attachments.${[index]}.file.file`]: attachment.file,
      };
    });

    commitMutation({
      variables: {
        id: itemId,
        keepVersion,
        input: {
          category: data.category,
          name: data.name,
          prices: data.prices.map((price) => ({
            quantity: Number(price.quantity) || 0,
            unitPrice: Number(price.unitPrice) || 0,
            unitSellingPrice: Number(price.unitSellingPrice) || 0,
          })),
          spec: {
            cardboard: data.cardboard,
            flexiblePackage: data.flexiblePackage,
            giftBox: data.giftBox,
            paperBag: data.paperBag,
            other: data.other,
          },
          supplierID: data.supplier.id,
          taxCategoryID: data.tax.id,
          internalMemo: data.memo,
          images: data.images.map((image, index) => ({
            id: image.registeredImageId,
            // displayOrder が歯抜けになっている可能性があるため、採番し直す
            displayOrder: index + 1,
            objectName: image.objectName,
          })),
          attachments: data.attachments.map((attachment) => ({
            id: attachment.id,
            file: {
              file: null,
            },
          })),
        },
      },
      uploadables: Object.assign({}, ...uploadables),
      onError() {
        toast({
          title: '商品の更新に失敗しました',
          status: 'error',
        });
      },
      onCompleted: () => {
        toast({
          title: '商品を更新しました',
          status: 'success',
        });
        navigate(paths.demands._demandCode(demandCode).url());
      },
    });
  };

  const handleBack = () => {
    allowNavigation();
    navigate(
      paths.demands._demandCode(demandCode).items._itemCode({ itemNumber, category }).edit.url(),
    );
  };

  return (
    <>
      <Flex justify="space-between" width="768px">
        <Box onClick={handleBack}>
          <PageBack />
        </Box>
      </Flex>
      <SubHeading label="内容の確認" />
      {!keepVersion && (
        <VStack
          px={4}
          py={3}
          alignItems="flex-start"
          borderWidth={2}
          borderColor="yellow.500"
          borderRadius="6px"
          spacing={1}
        >
          <HStack spacing={1}>
            <Caution theme="outline" size="20" fill="#D69E2E" />
            <Text fontWeight="bold" fontSize="sm">
              バージョンアップにより、コード内のバージョン番号が更新されます
            </Text>
          </HStack>
          <Text color="gray.600" fontSize="xs">
            必要に応じて取引先にコードが更新されたことを通知してください
          </Text>
        </VStack>
      )}
      <DemandItemConfirm
        onSubmit={handleSubmit}
        isSubmitting={isMutationInFlight}
        isDisabledSubmit={!isCurrentVersion}
      />
    </>
  );
};
