import { useCreateInvoiceBulkSendJobMutation } from 'gql/__generated__/useCreateInvoiceBulkSendJobMutation.graphql';
import { CreateInvoiceBulkSendJobInput } from 'gql/graphql.types';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { toast } from 'lib/toast';
import { useNavigate } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';

export const useCreateInvoiceBulkSendJob = () => {
  const navigate = useNavigate();
  const { commitMutation, isMutationInFlight } =
    useMutationWrapper<useCreateInvoiceBulkSendJobMutation>(
      graphql`
      mutation useCreateInvoiceBulkSendJobMutation($input: CreateInvoiceBulkSendJobInput!) {
        createInvoiceBulkSendJob(input: $input) {
          id
        }
      }
    `,
    );

  const createInvoiceBulkSendJob = (
    input: CreateInvoiceBulkSendJobInput,
    handleError?: () => void,
  ) => {
    commitMutation({
      variables: {
        input,
      },
      onCompleted: () => {
        toast({
          title: '一括送付を開始しました',
          description: '送付が完了した請求書はステータスが\n送付済みに更新されます',
          containerStyle: {
            whiteSpace: 'pre-wrap',
          },
          status: 'success',
        });
        navigate(paths.invoice.url());
      },
      onError: () => {
        handleError && handleError();
      },
    });
  };

  return { createInvoiceBulkSendJob, isMutationInFlight };
};
