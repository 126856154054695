import { ItemAmountPreview } from 'components/ItemAmountPreview';
import { NewSalesOrderFormType } from 'features/salesOrdersV2/form';
import { useFormContext, useWatch } from 'react-hook-form';
import { calculateDetailAmount } from 'utils/priceCalculation';

type Props = {
  index: number;
};

export const NewSalesOrderFormDetailsItemAmountPreview = ({ index }: Props) => {
  const form = useFormContext<NewSalesOrderFormType>();
  const detail = useWatch({ name: `details.${index}`, control: form.control });

  return (
    <ItemAmountPreview
      amount={calculateDetailAmount({
        quantity: detail.quantity || 0,
        price: detail.unitSellingPrice || 0,
      })}
      height="32px"
      px={3}
      py={1}
      minW="auto"
      fontSize="sm"
    />
  );
};
