import {
  Alert,
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { AddOne, Info } from '@icon-park/react';
import { CompanyInvoiceRecipientRecipientType } from 'gql/graphql.types';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import {
  InvoiceRecipientEditFormDataType,
  invoiceRecipientEditFormSchema,
} from '../InvoiceRecipientEditForm';
import { RecipientRow } from './RecipientRow';

type InvoiceRecipientEditModalProps = {
  isOpen: boolean;
  onClose: () => void;
  companyName: string;
  recipients: InvoiceRecipientEditFormDataType['recipients'];
  onSave: (recipients: InvoiceRecipientEditFormDataType['recipients']) => void;
  isLoading?: boolean;
};

export const InvoiceRecipientEditModal = ({
  isOpen,
  onClose,
  companyName,
  recipients,
  onSave,
  isLoading = false,
}: InvoiceRecipientEditModalProps) => {
  const methods = useForm<InvoiceRecipientEditFormDataType>({
    resolver: zodResolver(invoiceRecipientEditFormSchema),
    defaultValues: {
      recipients,
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'recipients',
  });

  const onSubmit = (data: InvoiceRecipientEditFormDataType) => {
    onSave(data.recipients);
    onClose();
  };

  const handleAddRecipient = () => {
    append({
      email: '',
      recipientType: CompanyInvoiceRecipientRecipientType.To,
    });
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  const handleUpdate = (
    index: number,
    value: InvoiceRecipientEditFormDataType['recipients'][number],
  ) => {
    update(index, value);
  };

  const isLastToRecipient = (index: number) => {
    return (
      fields[index].recipientType === CompanyInvoiceRecipientRecipientType.To &&
      fields.filter((field) => field.recipientType === CompanyInvoiceRecipientRecipientType.To)
        .length === 1
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pt={6} px={4} pb={3}>
          請求書送付先：{companyName}
        </ModalHeader>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody pt={3} px={4} pb={6}>
              {errors.recipients?.message && (
                <Alert
                  status="error"
                  borderRadius="md"
                  border="2px solid"
                  borderColor="red.500"
                  bg="white"
                  width="fit-content"
                  mb={2}
                >
                  <Box mr={1}>
                    <Info theme="outline" size="20" fill="#E53E3E" />
                  </Box>
                  <Text fontSize="sm" color="gray.900" fontWeight="bold" size="sm">
                    {errors.recipients.message}
                  </Text>
                </Alert>
              )}
              <VStack align="stretch" spacing={1}>
                <Flex justifyContent="space-between">
                  <Text fontSize="sm">送信方法</Text>
                  <Text fontSize="sm" flex="1" ml={10}>
                    メールアドレス
                  </Text>
                </Flex>
                <VStack align="stretch" spacing={2}>
                  {fields.map((field, index) => (
                    <RecipientRow
                      key={field.id}
                      index={index}
                      field={field}
                      remove={handleRemove}
                      update={handleUpdate}
                      isLastToRecipient={isLastToRecipient}
                    />
                  ))}
                  <Button
                    size="sm"
                    leftIcon={<AddOne size={14} />}
                    width="100%"
                    onClick={handleAddRecipient}
                    borderRadius="md"
                    bg="gray.100"
                    color="gray.700"
                  >
                    連絡先追加
                  </Button>
                </VStack>
              </VStack>
            </ModalBody>
            <ModalFooter p={4} borderTop="1px solid" borderColor="gray.200">
              <Button colorScheme="blue" type="submit" isLoading={isLoading}>
                更新
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};
