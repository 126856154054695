import {
  DemandItemAttachmentsInput,
  useDemandItemAttachmentsQuery$data,
  useDemandItemAttachmentsQuery as useDemandItemAttachmentsQueryType,
} from 'gql/__generated__/useDemandItemAttachmentsQuery.graphql';
import RelayEnvironment from 'lib/relay/RelayEnvironment';
import { useState } from 'react';
import { fetchQuery, graphql } from 'relay-runtime';

const useDemandItemAttachmentsQuery = graphql`
  query useDemandItemAttachmentsQuery($input: DemandItemAttachmentsInput!) {
    demandItemAttachments(input: $input) {
      attachmentID
      fileName
      byteSize
      url
    }
  }
`;

export type DemandItemAttachmentsType = useDemandItemAttachmentsQuery$data['demandItemAttachments'];

export const useDemandItemAttachments = (input: DemandItemAttachmentsInput) => {
  const [data, setData] = useState<DemandItemAttachmentsType>();

  const fetch = async () => {
    const res = await fetchQuery<useDemandItemAttachmentsQueryType>(
      RelayEnvironment,
      useDemandItemAttachmentsQuery,
      { input },
      { fetchPolicy: 'network-only' },
    ).toPromise();

    setData(res?.demandItemAttachments);
  };

  return { data, fetch };
};
