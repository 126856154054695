import {
  Box,
  Flex,
  FormControl,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Text,
} from '@chakra-ui/react';
import { CloseSmall, DeleteKey } from '@icon-park/react';
import { DemandFormDataType } from 'features/demand/form';
import { CompanyInvoiceRecipientRecipientType } from 'gql/graphql.types';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

type Props = {
  index: number;
  field: NonNullable<DemandFormDataType['recipients']>[number];
  remove: (index: number) => void;
  update: (index: number, value: NonNullable<DemandFormDataType['recipients']>[number]) => void;
};

export const DemandFormRecipientRow = ({ index, field, remove, update }: Props) => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<DemandFormDataType>();

  const handleClearRecipientEmail = () => {
    const currentRecipient = {
      id: field.id,
      email: '',
      recipientType: field.recipientType,
    };
    update(index, currentRecipient);
  };

  const recipient = useWatch({
    control,
    name: `recipients.${index}`,
  });

  return (
    <Box key={field.id} w="full">
      <Flex alignItems="flex-start">
        <Box minWidth="60px" mt="0.4rem">
          <Controller
            control={control}
            name={`recipients.${index}.recipientType`}
            render={({ field: controllerField }) => (
              <RadioGroup
                value={recipient?.recipientType}
                onChange={(value) => {
                  const newValue = value as CompanyInvoiceRecipientRecipientType;
                  controllerField.onChange(newValue);

                  const currentRecipient = {
                    id: recipient.id,
                    email: recipient.email,
                    recipientType: newValue,
                  };
                  update(index, currentRecipient);
                }}
              >
                <HStack spacing={2}>
                  <Radio
                    value={CompanyInvoiceRecipientRecipientType.To}
                    colorScheme="blue"
                    size="sm"
                  >
                    <Text fontSize="sm">To</Text>
                  </Radio>
                  <Radio
                    value={CompanyInvoiceRecipientRecipientType.Cc}
                    colorScheme="blue"
                    size="sm"
                  >
                    <Text fontSize="sm">Cc</Text>
                  </Radio>
                </HStack>
              </RadioGroup>
            )}
          />
        </Box>
        <Box flex={1} ml="0.75rem">
          <FormControl isInvalid={!!errors.recipients?.[index]?.email}>
            <InputGroup size="sm">
              <Input
                type="email"
                borderRadius="md"
                border="1px solid"
                borderColor={errors.recipients?.[index]?.email ? 'red.500' : 'gray.200'}
                placeholder="メールアドレスを入力"
                pr="2.5rem"
                height="32px"
                {...register(`recipients.${index}.email`)}
              />
              <InputRightElement width="2.5rem" height="100%" justifyContent="center">
                <IconButton
                  aria-label="clear-recipient-email"
                  icon={<DeleteKey size="1rem" />}
                  size="sm"
                  variant="text"
                  onClick={handleClearRecipientEmail}
                  color="gray.500"
                />
              </InputRightElement>
            </InputGroup>
            {errors.recipients?.[index]?.email && (
              <Text color="red.500" fontSize="xs" mt="1">
                {errors.recipients[index].email.message}
              </Text>
            )}
          </FormControl>
        </Box>
        <Box ml="0.5rem">
          <IconButton
            aria-label="clear-recipient"
            icon={<CloseSmall size="1rem" />}
            size="sm"
            variant="text"
            onClick={() => remove(index)}
            color="gray.500"
          />
        </Box>
      </Flex>
    </Box>
  );
};
