import { Box, Text, VStack } from '@chakra-ui/react';
import { AdProduct } from '@icon-park/react';

export const DemandItemDetailBlockNoItem = () => {
  return (
    <VStack width="100%" height="100%" justifyContent="center" alignItems="center">
      <Box>
        <AdProduct theme="outline" size={24} />
      </Box>
      <Text>該当する商品がありません</Text>
    </VStack>
  );
};
