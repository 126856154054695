/**
 * @generated SignedSource<<3bc5b4ae30e5f5978190c05b4f54c1a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useEstimationDeleteV2Mutation$variables = {
  id: string;
};
export type useEstimationDeleteV2Mutation$data = {
  readonly deleteEstimationV2: boolean;
};
export type useEstimationDeleteV2Mutation = {
  response: useEstimationDeleteV2Mutation$data;
  variables: useEstimationDeleteV2Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteEstimationV2",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useEstimationDeleteV2Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useEstimationDeleteV2Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3dfa0d23dcc9dcffb187bb716a39a476",
    "id": null,
    "metadata": {},
    "name": "useEstimationDeleteV2Mutation",
    "operationKind": "mutation",
    "text": "mutation useEstimationDeleteV2Mutation(\n  $id: ID!\n) {\n  deleteEstimationV2(id: $id)\n}\n"
  }
};
})();

(node as any).hash = "81f646629176aa56a2c0c529ddca3f60";

export default node;
