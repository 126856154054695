import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { CustomFormHelperText } from 'components/CustomFormHelperText';
import { ErrorMessage } from 'components/ErrorMessage';
import { DemandRecurringCostFormType } from 'features/demandRecurringCost/form';
import { SupplierComboBox } from 'features/supplier/components/SupplierComboBox';
import { TaxSelectBox } from 'features/tax/components/TaxSelectBox';
import { DemandRecurringCostFormFragment$key } from 'gql/__generated__/DemandRecurringCostFormFragment.graphql';
import { Controller, useFormContext } from 'react-hook-form';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { DemandRecurringCostPriceForm } from './DemandRecurringCostPriceForm';

type Props = {
  queryRef: DemandRecurringCostFormFragment$key;
  navigateToConfirm: () => void;
};

const demandRecurringCostFormFragment = graphql`
  fragment DemandRecurringCostFormFragment on Query {
    ...TaxSelectBoxFragment
  }
`;

export const DemandRecurringCostForm = ({ queryRef, navigateToConfirm }: Props) => {
  const query = useFragment(demandRecurringCostFormFragment, queryRef);

  const form = useFormContext<DemandRecurringCostFormType>();

  const {
    control,
    setValue,
    getValues,
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = form;

  const onClearSupplierInput = () => setValue('supplier', { id: '', name: '' });

  return (
    <form onSubmit={handleSubmit(navigateToConfirm)}>
      <VStack spacing={10} alignItems="flex-start" width="100%">
        <VStack spacing={4} alignItems="flex-start" width="512px">
          <FormControl isRequired isInvalid={!!errors.name}>
            <FormLabel>費用名</FormLabel>
            <Input type="string" {...register('name')} size="sm" borderRadius="4px" />
            <ErrorMessage name="name" errors={errors} />
          </FormControl>
          <FormControl isInvalid={!!errors.supplier?.id}>
            <FormLabel>サプライヤー</FormLabel>
            <Controller
              name="supplier"
              control={control}
              render={({ field: { onChange } }) => (
                <SupplierComboBox
                  inputProps={{ size: 'sm', borderRadius: '4px' }}
                  onChangeSelected={onChange}
                  defaultSelectedItem={
                    getValues('supplier') && getValues('supplier').id && getValues('supplier').name
                      ? {
                          id: getValues('supplier').id as string,
                          name: getValues('supplier').name as string,
                        }
                      : { id: '', name: '' }
                  }
                  onClearInput={onClearSupplierInput}
                />
              )}
            />
            <ErrorMessage name="supplier.id" errors={errors} />
          </FormControl>
          <FormControl isInvalid={!!errors.memo}>
            <FormLabel>社内メモ</FormLabel>
            <Textarea {...register('memo')} size="sm" borderRadius="4px" />
            <ErrorMessage name="memo" errors={errors} />
            <CustomFormHelperText>リピート時の注意点などを記載しましょう</CustomFormHelperText>
          </FormControl>
          <FormControl isInvalid={!!errors.note}>
            <FormLabel>補足</FormLabel>
            <Input type="string" {...register('note')} size="sm" borderRadius="4px" />
            <ErrorMessage name="note" errors={errors} />
            <CustomFormHelperText>
              書類に記載されるため、デマンドに伝えたい内容に限定しましょう
            </CustomFormHelperText>
          </FormControl>
        </VStack>

        <VStack spacing={4} alignItems="flex-start" width="512px">
          <Heading as="h3" fontSize="xl">
            単価表
          </Heading>
          <Box w="168px">
            <FormControl isRequired isInvalid={!!errors?.tax?.id}>
              <FormLabel>税区分</FormLabel>
              <Controller
                name={`tax`}
                control={control}
                render={() => (
                  <TaxSelectBox
                    size="sm"
                    borderRadius="4px"
                    defaultValue={getValues('tax.id')}
                    queryRef={query}
                    onChange={(e) => {
                      setValue(`tax`, {
                        ...{
                          id: e.target.value,
                          rate: e.target[e.target.selectedIndex].getAttribute(
                            'data-rate',
                          ) as string,
                          name: e.target[e.target.selectedIndex].getAttribute(
                            'data-name',
                          ) as string,
                        },
                      });
                    }}
                  />
                )}
              />
            </FormControl>
          </Box>
          <DemandRecurringCostPriceForm />
        </VStack>

        <Button colorScheme="blue" w="full" type="submit" isLoading={isSubmitting}>
          確認画面へ
        </Button>
      </VStack>
    </form>
  );
};
