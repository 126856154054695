import { Flex } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';
import { NewSalesOrderForm } from './components/NewSalesOrderForm';
import { useNavigationBlocker } from 'hooks/useNavigationBlocker';

export const NewSalesOrder = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const { allowNavigation } = useNavigationBlocker();

  const handleFormConfirm = () => {
    allowNavigation();
    navigate(paths.estimations._estimationId(id).newSalesOrder.masterRegistrationSetting.url());
  };

  return (
    <>
      <Flex justify="space-between" width="768px">
        <RouterLink to={paths.estimations._estimationId(id).url()}>
          <PageBack />
        </RouterLink>
      </Flex>
      <SubHeading label="発注請書の作成" />
      <NewSalesOrderForm navigateToConfirm={handleFormConfirm} />
    </>
  );
};
