/**
 * @generated SignedSource<<01f2473966e43271c42e841cbad4e7b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SalesOrderDetailType = "item" | "onetime_cost" | "recurring_cost" | "%future added value";
export type ReorderSalesOrderInput = {
  demandID: string;
  detailMessage: string;
  details: ReadonlyArray<ReorderSalesOrderDetailInput>;
  internalAssignees?: ReadonlyArray<SalesOrderInternalAssigneeInput> | null;
  internalMemo?: string | null;
  receivedDate: any;
  supplierID: string;
  title: string;
};
export type ReorderSalesOrderDetailInput = {
  item?: ReorderSalesOrderDetailItemInput | null;
  onetimeCost?: ReorderSalesOrderDetailOnetimeCostInput | null;
  recurringCost?: ReorderSalesOrderDetailRecurringCostInput | null;
};
export type ReorderSalesOrderDetailItemInput = {
  fixedDeliveryDate?: any | null;
  itemID: string;
  memo: string;
  orderNumber: number;
  quantity: number;
  taxCategoryID: string;
  type: SalesOrderDetailType;
  unitPrice: number;
  unitSellingPrice: number;
};
export type ReorderSalesOrderDetailOnetimeCostInput = {
  fixedDeliveryDate?: any | null;
  memo: string;
  name: string;
  note: string;
  orderNumber: number;
  quantity: number;
  taxCategoryID: string;
  type: SalesOrderDetailType;
  unitPrice: number;
  unitSellingPrice: number;
};
export type ReorderSalesOrderDetailRecurringCostInput = {
  costID: string;
  fixedDeliveryDate?: any | null;
  memo: string;
  orderNumber: number;
  quantity: number;
  taxCategoryID: string;
  type: SalesOrderDetailType;
  unitPrice: number;
  unitSellingPrice: number;
};
export type SalesOrderInternalAssigneeInput = {
  userID: string;
};
export type confirm_SalesOrderCreateConfirmPageMutation$variables = {
  input: ReorderSalesOrderInput;
};
export type confirm_SalesOrderCreateConfirmPageMutation$data = {
  readonly reorderSalesOrder: {
    readonly id: string;
  };
};
export type confirm_SalesOrderCreateConfirmPageMutation = {
  response: confirm_SalesOrderCreateConfirmPageMutation$data;
  variables: confirm_SalesOrderCreateConfirmPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SalesOrder",
    "kind": "LinkedField",
    "name": "reorderSalesOrder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "confirm_SalesOrderCreateConfirmPageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "confirm_SalesOrderCreateConfirmPageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ace787cf24a8c218843010dcb398de63",
    "id": null,
    "metadata": {},
    "name": "confirm_SalesOrderCreateConfirmPageMutation",
    "operationKind": "mutation",
    "text": "mutation confirm_SalesOrderCreateConfirmPageMutation(\n  $input: ReorderSalesOrderInput!\n) {\n  reorderSalesOrder(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "efbeb789a734ab9ad79cc3d0da0d5aaa";

export default node;
