import { Img, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import {
  SALES_ORDER_DETAIL_TYPE,
  SalesOrderDetailType,
  SalesOrderSpec,
  SalesOrdersDetailSpec,
  getItemSpecs,
  getOnetimeCostSpecs,
  getRecurringCostSpecs,
} from 'features/salesOrdersV2/models';
import NotionLogo from 'public/images/notion_logo.svg';
import { useState } from 'react';
import { dateFormat } from 'utils/date';
import { calculateDetailAmount } from 'utils/priceCalculation';
import { SalesOrdersPreviewDetailSpec } from '../SalesOrdersPreview/SalesOrdersPreviewDetailSpec';

export type SalesOrdersDetailPagePreviewDetail = {
  id: string;
  detailNumber: string;
  notionPageUrl: string;
  name: string;
  memo: string | undefined;
  fixedDeliveryDate: string | undefined;
  type: SalesOrderDetailType;
  category?: string;
  spec: SalesOrderSpec;
  quantity: string | number | undefined;
  unitPrice: string | number | undefined;
  unitSellingPrice: string | number | undefined;
  tax: {
    id: string;
    rate: string | number;
  };
};

type Props = {
  details: SalesOrdersDetailPagePreviewDetail[];
  detailsForCalculation: {
    unitPrice: string | number;
    unitSellingPrice: string | number;
    quantity: string | number;
    tax: {
      rate: string | number;
    };
  }[];
};

export const SalesOrdersDetailPagePreviewDetails = ({ details, detailsForCalculation }: Props) => {
  const [isDragging, setIsDragging] = useState(false);

  return (
    <VStack mt={6} w="full" align="start" justify="space-between">
      <Table>
        <Thead>
          <Tr>
            <Th w="24px" p={0}>
              {/* Notionアイコン用の空ヘッダー */}
            </Th>
            <Th pl={0} pr={2} py={3} color="gray.900">
              品名
            </Th>
            <Th w="120px" px={2} py={3} whiteSpace="nowrap" color="gray.900">
              メモ
            </Th>
            <Th w="120px" px={2} py={3} whiteSpace="nowrap" color="gray.900">
              確定納品日
            </Th>
            <Th isNumeric w="92px" px={2} py={3} whiteSpace="nowrap" color="gray.900">
              数量
            </Th>
            <Th isNumeric w="92px" px={2} py={3} whiteSpace="nowrap" color="gray.900">
              単価
            </Th>
            <Th isNumeric w="92px" pl={2} pr={0} py={3} whiteSpace="nowrap" color="gray.900">
              金額
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {details.map((detail, i) => (
            <Tr
              key={`detail-${detail.name}-${i}`}
              fontSize="xs"
              verticalAlign="top"
              _hover={
                detail.notionPageUrl
                  ? {
                      bgColor: 'gray.50',
                      cursor: 'pointer',
                    }
                  : {}
              }
              onPointerDown={() => setIsDragging(false)}
              onPointerMove={() => setIsDragging(true)}
              onPointerUp={() => {
                if (!detail.notionPageUrl) return;
                if (!isDragging) {
                  window.open(detail.notionPageUrl, '_blank', 'noopener, noreferrer');
                }
                setIsDragging(false);
              }}
            >
              <Td w="24px" px={0} py={3}>
                {detail.notionPageUrl && <Img src={NotionLogo} alt="notion logo" />}
              </Td>
              <Td pl={0} pr={2} py={3} whiteSpace="pre-wrap">
                <Text>{detail.name}</Text>
                <SalesOrdersPreviewDetailSpec specs={getSpecs(detail)} />
              </Td>
              <Td w="120px" px={2} py={3} whiteSpace="pre-wrap">
                {detail.memo ?? '-'}
              </Td>
              <Td w="120px" px={2} py={3} whiteSpace="pre-wrap">
                {detail.fixedDeliveryDate ? dateFormat(detail.fixedDeliveryDate) : '-'}
              </Td>
              <Td isNumeric w="92px" px={2} py={3}>
                {detailsForCalculation[i].quantity.toLocaleString()}
              </Td>
              <Td isNumeric w="92px" px={2} py={3}>
                {detailsForCalculation[i].unitSellingPrice.toLocaleString()}
              </Td>
              <Td isNumeric w="92px" pl={2} pr={0} py={3}>
                {calculateDetailAmount({
                  quantity: detailsForCalculation[i].quantity,
                  price: detailsForCalculation[i].unitSellingPrice,
                }).toLocaleString()}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </VStack>
  );
};

const getSpecs = (detail: SalesOrdersDetailPagePreviewDetail) => {
  const specs: SalesOrdersDetailSpec[] = [
    {
      label: '受注NO',
      node: detail.detailNumber,
    },
  ];

  switch (detail.type) {
    case SALES_ORDER_DETAIL_TYPE.Item:
      return specs.concat(getItemSpecs(detail.category ?? '', detail.spec));
    case SALES_ORDER_DETAIL_TYPE.RecurringCost:
      return specs.concat(getRecurringCostSpecs(detail.spec.cost));
    case SALES_ORDER_DETAIL_TYPE.OnetimeCost:
      return specs.concat(getOnetimeCostSpecs(detail.spec.cost));
    default:
      return specs;
  }
};
