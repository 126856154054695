import { useDisclosure } from '@chakra-ui/react';

export const useSalesOrdersItemModal = () => {
  const {
    isOpen: isOpenItemModal,
    onOpen: onOpenItemModal,
    onClose: onCloseItemModal,
  } = useDisclosure();

  return { isOpenItemModal, onOpenItemModal, onCloseItemModal };
};
