import { Empty, PartialMessage } from '@bufbuild/protobuf';
import {
  CreateEstimationResponseDetail,
  EstimationResponseDetailTypeItem,
} from 'proto/model/estimation_response/v1/create_estimation_response_pb';
import {
  CardboardSpec,
  FlexiblePackageSpec,
  GiftBoxSpec,
  OtherSpec,
  PaperBagSpec,
} from 'proto/model/item/v1/category_pb';
import {
  CategoryItemsSummary,
  ItemCategory,
  ItemsBySpec,
} from 'proto/model/item/v1/summarize_item_pb';

export const createSubmitDetails = (
  items: PartialMessage<CategoryItemsSummary>[],
): CreateEstimationResponseDetail[] => {
  const sortedItems = items.flatMap(
    (summarisedItem) => summarisedItem.items?.map((item) => sortQuantityItems(item)) || [],
  );

  return sortedItems
    .flatMap((item) => getItemSpecRecursive(item, new Map<string, unknown>()))
    .map(
      (item) =>
        new CreateEstimationResponseDetail({
          value: {
            case: 'item',
            value: item,
          },
        }),
    );
};

export const sortQuantityItems = (
  item: PartialMessage<ItemsBySpec>,
): PartialMessage<ItemsBySpec> => {
  if (!item.items?.length) return item;

  const isAllQuantity = item.items.every((subItem) => subItem.name === 'quantity');
  if (isAllQuantity) {
    const clonedItems = [...item.items];

    // 子仕様が全てquantityの場合は、quantityの昇順でソート
    clonedItems.sort((a, b) => {
      const valueA = Number(a.value?.kind?.value || 0);
      const valueB = Number(b.value?.kind?.value || 0);
      return valueA - valueB;
    });

    return { ...item, items: clonedItems };
  }

  item.items = item.items.map((subItem) => sortQuantityItems(subItem));
  return item;
};

export const getItemSpecRecursive = (
  item: PartialMessage<ItemsBySpec>,
  spec: Map<string, unknown>,
): EstimationResponseDetailTypeItem[] => {
  if (item.name) {
    spec.set(item.name || '', item.value?.kind?.value);
  }

  const hasItems = (item.items?.length || 0) > 0;

  if (hasItems) {
    return item.items?.flatMap((i) => getItemSpecRecursive(i, spec)) || [];
  }

  const detailItem = new EstimationResponseDetailTypeItem();
  if (spec.has('name')) detailItem.name = spec.get('name')?.toString();
  if (spec.has('quantity')) detailItem.quantity = BigInt(spec.get('quantity') as number);
  if (spec.has('unitPrice')) detailItem.unitPrice = spec.get('unitPrice') as number;
  if (spec.has('annualQuantity'))
    detailItem.annualQuantity = spec.get('annualQuantity')?.toString();
  if (spec.has('askingUnitPrice'))
    detailItem.askingUnitPrice = spec.get('askingUnitPrice')?.toString();
  detailItem.sourceDetailId = item.sourceItemId;

  const defaultSpec = item.spec;
  switch (defaultSpec?.case) {
    case 'cardboardSpec': {
      const itemSpec = new CardboardSpec(defaultSpec.value);

      if (spec.has('size')) itemSpec.size = spec.get('size')?.toString();
      if (spec.has('type')) itemSpec.type = spec.get('type')?.toString();
      if (spec.has('material')) itemSpec.material = spec.get('material')?.toString();
      if (spec.has('thickness')) itemSpec.thickness = spec.get('thickness')?.toString();
      if (spec.has('printingColor')) itemSpec.printingColor = spec.get('printingColor')?.toString();
      if (spec.has('processings')) itemSpec.processing = spec.get('processings')?.toString();
      if (spec.has('other')) itemSpec.other = spec.get('other')?.toString();

      detailItem.spec = {
        case: 'cardboardSpec',
        value: itemSpec,
      };
      break;
    }
    case 'flexiblePackageSpec': {
      const itemSpec = new FlexiblePackageSpec(defaultSpec.value);

      if (spec.has('size')) itemSpec.size = spec.get('size')?.toString();
      if (spec.has('type')) itemSpec.type = spec.get('type')?.toString();
      if (spec.has('material')) itemSpec.material = spec.get('material')?.toString();
      if (spec.has('printingColor')) itemSpec.printingColor = spec.get('printingColor')?.toString();
      if (spec.has('processings')) itemSpec.processing = spec.get('processings')?.toString();
      if (spec.has('other')) itemSpec.other = spec.get('other')?.toString();

      detailItem.spec = {
        case: 'flexiblePackageSpec',
        value: itemSpec,
      };
      break;
    }
    case 'giftBoxSpec': {
      const itemSpec = new GiftBoxSpec(defaultSpec.value);

      if (spec.has('size')) itemSpec.size = spec.get('size')?.toString();
      if (spec.has('type')) itemSpec.type = spec.get('type')?.toString();
      if (spec.has('paperType')) itemSpec.paperType = spec.get('paperType')?.toString();
      if (spec.has('printingColor')) itemSpec.printingColor = spec.get('printingColor')?.toString();
      if (spec.has('processings')) itemSpec.processing = spec.get('processings')?.toString();
      if (spec.has('other')) itemSpec.other = spec.get('other')?.toString();

      detailItem.spec = {
        case: 'giftBoxSpec',
        value: itemSpec,
      };
      break;
    }
    case 'paperBagSpec': {
      const itemSpec = new PaperBagSpec(defaultSpec.value);

      if (spec.has('size')) itemSpec.size = spec.get('size')?.toString();
      if (spec.has('paperType')) itemSpec.paperType = spec.get('paperType')?.toString();
      if (spec.has('printingColor')) itemSpec.printingColor = spec.get('printingColor')?.toString();
      if (spec.has('processings')) itemSpec.processing = spec.get('processings')?.toString();
      if (spec.has('handle')) itemSpec.handle = spec.get('handle')?.toString();
      if (spec.has('other')) itemSpec.other = spec.get('other')?.toString();

      detailItem.spec = {
        case: 'paperBagSpec',
        value: itemSpec,
      };
      break;
    }
    case 'otherSpec': {
      const itemSpec = new OtherSpec(defaultSpec.value);

      if (spec.has('other')) itemSpec.other = spec.get('other')?.toString();

      detailItem.spec = {
        case: 'otherSpec',
        value: itemSpec,
      };
      break;
    }
    case 'unknownSpec': {
      detailItem.spec = {
        case: 'unknownSpec',
        value: new Empty(),
      };
      break;
    }
  }

  return [detailItem];
};

export const getRootItemByCategory = (
  items: PartialMessage<CategoryItemsSummary>[],
  category: ItemCategory,
) => items.filter((item) => item.category === category)[0];

export const removeRootItemByCategory = (
  items: PartialMessage<CategoryItemsSummary>[],
  category: ItemCategory,
) => items.filter((item) => item.category !== category);

export const convertExtraItemFormat = (data: PartialMessage<CategoryItemsSummary>) => {
  const output: { name: string; quantity: number; unitPrice: number }[] = [];

  if (!data.items) return output;

  for (let index = 0; index < data.items?.length; index++) {
    const item = data.items[index];

    if (item.name === 'name') {
      const newItem = { name: '', quantity: 0, unitPrice: 0 };
      switch (item.value?.kind?.case) {
        case 'stringValue':
          newItem.name = item.value.kind.value;
          break;
      }

      const quantities = item.items;
      if (!quantities || quantities.length === 0) continue;

      for (let quantityIndex = 0; quantityIndex < quantities.length; quantityIndex++) {
        const quantity = quantities[quantityIndex];

        if (quantity.name === 'quantity') {
          switch (quantity.value?.kind?.case) {
            case 'numberValue':
              newItem.quantity = quantity.value.kind.value;
              break;
            case 'stringValue':
              newItem.quantity = Number(quantity.value.kind.value) || 0;
          }

          const unitPrice = quantity.items?.[0];
          // データ上、quantityが存在していればunitPricesも存在しているが
          // itemsがoptionalなので念の為分岐を挟んでいる
          if (!unitPrice) continue;

          switch (unitPrice.value?.kind?.case) {
            case 'numberValue':
              newItem.unitPrice = unitPrice.value.kind.value;
              break;
            case 'stringValue':
              newItem.unitPrice = Number(unitPrice.value.kind.value) || 0;
          }
        }
      }

      output.push(newItem);
    }
  }

  return output;
};
