import { Box, VStack } from '@chakra-ui/react';
import { DemandRecipientsFragment$key } from 'gql/__generated__/DemandRecipientsFragment.graphql';
import { CompanyInvoiceRecipientRecipientType } from 'gql/graphql.types';
import { graphql, useFragment } from 'react-relay';

const demandRecipientsFragment = graphql`
  fragment DemandRecipientsFragment on Company {
    invoiceRecipients {
        id
        email
        recipientType
    }
  }
`;

type Props = {
  queryRef: DemandRecipientsFragment$key;
};

export const DemandRecipients = ({ queryRef }: Props) => {
  const { invoiceRecipients: recipients } = useFragment(demandRecipientsFragment, queryRef);

  const toRecipients =
    recipients
      ?.filter((recipient) => recipient.recipientType === CompanyInvoiceRecipientRecipientType.To)
      .map((recipient) => recipient.email) || [];
  const ccRecipients =
    recipients
      ?.filter((recipient) => recipient.recipientType === CompanyInvoiceRecipientRecipientType.Cc)
      .map((recipient) => recipient.email) || [];

  return (
    <VStack spacing={1} alignItems="flex-start">
      <Box color="gray.500">請求書送付先</Box>
      {toRecipients.length === 0 && ccRecipients.length === 0 && <Box whiteSpace="pre-wrap">-</Box>}
      {toRecipients.length > 0 && <Box whiteSpace="pre-wrap">To: {toRecipients.join(' / ')}</Box>}
      {ccRecipients.length > 0 && <Box whiteSpace="pre-wrap">Cc: {ccRecipients.join(' / ')}</Box>}
    </VStack>
  );
};
