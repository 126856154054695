import { Button, HStack } from '@chakra-ui/react';
import { Copy, Delete, Edit, FilePdf } from '@icon-park/react';
import { ConfirmModalButton } from 'components/ConfirmModalButton';
import { useDownloadEstimationPdfV2 } from 'features/estimation/hooks/useDownloadEstimationPdfV2';
import { useEstimationDeleteV2 } from 'features/estimation/hooks/useEstimationDeleteV2';
import { EstimationDetailHeaderFragment$key } from 'gql/__generated__/EstimationDetailHeaderFragment.graphql';
import { useFragment } from 'react-relay';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';

const EstimationDetailHeaderFragment = graphql`
  fragment EstimationDetailHeaderFragment on Estimation
  {
    id
    orders {
      id
    }
  }
`;

type Props = {
  queryRef: EstimationDetailHeaderFragment$key;
};

export const EstimationDetailHeader = ({ queryRef }: Props) => {
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const { downloadEstimationPdf } = useDownloadEstimationPdfV2();
  const { onSubmit } = useEstimationDeleteV2();
  const { orders } = useFragment(EstimationDetailHeaderFragment, queryRef);

  const order = orders ? orders[0] : null;

  return (
    <HStack spacing={4}>
      <ConfirmModalButton
        header="削除しますか？"
        button={({ onOpen }) => (
          <Button leftIcon={<Delete />} onClick={onOpen}>
            削除
          </Button>
        )}
        footer={({ onClose }) => (
          <HStack spacing={3}>
            <Button onClick={onClose}>キャンセル</Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => {
                onClose();
                onSubmit(id);
              }}
            >
              削除
            </Button>
          </HStack>
        )}
      />
      <RouterLink to={paths.estimations.new.url({ originalEstimateId: id })}>
        <Button leftIcon={<Copy />}>複製</Button>
      </RouterLink>
      {!order && (
        <RouterLink to={paths.estimations.edit._estimationId(id).url()}>
          <Button colorScheme="blue" leftIcon={<Edit />}>
            編集
          </Button>
        </RouterLink>
      )}
      <Button colorScheme="blue" leftIcon={<FilePdf />} onClick={() => downloadEstimationPdf(id)}>
        PDFダウンロード
      </Button>
    </HStack>
  );
};
