import { HStack, Text, VStack } from '@chakra-ui/react';
import { memo } from 'react';

export const SalesOrdersPreviewPrices = memo(
  ({
    subtotalAmount,
    totalTax,
    totalAmount,
  }: { subtotalAmount: number; totalTax: number; totalAmount: number }) => {
    return (
      <VStack w="full" spacing={2}>
        <HStack w="full" justify="space-between" fontSize="sm">
          <Text>小計</Text>
          <Text>{subtotalAmount.toLocaleString()}</Text>
        </HStack>
        <HStack w="full" justify="space-between" fontSize="sm">
          <Text>消費税</Text>
          <Text>{totalTax.toLocaleString()}</Text>
        </HStack>
        <HStack w="full" justify="space-between" fontWeight="bold">
          <Text>合計金額</Text>
          <Text>{totalAmount.toLocaleString()}</Text>
        </HStack>
      </VStack>
    );
  },
);
