/**
 * @generated SignedSource<<9de01fddd28cb359fd2545ddebce6fc9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DemandItemFormFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TaxSelectBoxFragment">;
  readonly " $fragmentType": "DemandItemFormFragment";
};
export type DemandItemFormFragment$key = {
  readonly " $data"?: DemandItemFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DemandItemFormFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DemandItemFormFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaxSelectBoxFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "1ec7fcaa4f44da15dd066b4b23ffa078";

export default node;
