import { HStack, IconButton, Text, VStack } from '@chakra-ui/react';
import { AddOne, Delete } from '@icon-park/react';
import { FileUploadButton } from 'components/FileUploadButton';
import { DemandItemFormType } from 'features/demandItem/form';
import { ChangeEvent } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { formatBytes } from 'utils/number';

export const DemandItemAttachmentForm = () => {
  const { register, getValues, setValue, control } = useFormContext<DemandItemFormType>();

  const attachments = useWatch({ name: 'attachments', control });

  const onChangeAttachment = (event: ChangeEvent<HTMLInputElement>) => {
    const newFiles = () => {
      const existingFiles = getValues('attachments');
      const existingFileNames = existingFiles.map((file) => file.fileName);

      const newFiles = Array.from(event.target.files ?? []).filter(
        (file) => !existingFileNames.includes(file.name),
      );

      return [
        ...existingFiles,
        ...newFiles.map((file) => ({
          id: undefined,
          file,
          fileName: file.name,
          fileSize: file.size,
        })),
      ];
    };
    setValue('attachments', newFiles());
  };

  const onDeleteAttachment = (index: number) => {
    const attachments = getValues('attachments');
    const newAttachments = attachments.filter((_, i) => i !== index);
    setValue('attachments', newAttachments);
  };

  return (
    <>
      {attachments?.length > 0 && (
        <VStack spacing={2} alignItems="flex-start">
          {Array.from(attachments).map((attachment, index) => (
            <HStack key={index} alignItems="center">
              <HStack
                w="fit-content"
                spacing={1}
                px={3}
                py={2.5}
                bg="gray.100"
                borderRadius="6px"
                alignItems="baseline"
              >
                <Text fontSize="sm" lineHeight="1">
                  {attachment.fileName}
                </Text>
                <Text fontSize="xs" lineHeight="1" color="gray.500">
                  ({formatBytes(attachment.fileSize)})
                </Text>
              </HStack>
              <IconButton
                aria-label="添付ファイルを削除する"
                icon={<Delete />}
                size="sm"
                variant="ghost"
                rounded="full"
                onClick={() => onDeleteAttachment(index)}
              />
            </HStack>
          ))}
        </VStack>
      )}
      <FileUploadButton
        name="attachments"
        label="追加"
        multiple={true}
        accept="*"
        register={register}
        errors={undefined}
        leftIcon={<AddOne />}
        buttonProps={{ size: 'sm' }}
        onChange={onChangeAttachment}
      />
    </>
  );
};
