import { SalesOrdersFormDetailSpec } from 'features/salesOrdersV2/components/SalesOrdersFormDetailSpec';
import {
  NewSalesOrderDetailFormType,
  SALES_ORDER_DETAIL_CATEGORY_COST,
} from 'features/salesOrdersV2/form';
import { SalesOrdersDetailSpec, getItemSpecs } from 'features/salesOrdersV2/models';
import { memo } from 'react';

type Props = {
  detail: NewSalesOrderDetailFormType;
  emptyView?: React.ReactNode;
};

export const NewSalesOrderConfirmSpecPreview = memo(({ detail, emptyView }: Props) => {
  const specs = getSpecs(detail);

  // 表示すべき仕様がない場合はブロック自体を表示しない
  if (!Object.values(specs).some((spec) => spec.node)) {
    return emptyView ?? <></>;
  }

  return <SalesOrdersFormDetailSpec specs={specs} />;
});

const getSpecs = (detail: NewSalesOrderDetailFormType): SalesOrdersDetailSpec[] => {
  if (detail.category === SALES_ORDER_DETAIL_CATEGORY_COST) {
    return [
      {
        label: '補足',
        node: detail.spec?.cost?.note,
      },
    ];
  }

  return getItemSpecs(detail.category, detail.spec);
};
