/**
 * @generated SignedSource<<ea4a44ca17d5ccab04e33a491cd9a983>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SalesOrdersDetailPageHeaderFragment$data = {
  readonly id: string;
  readonly invoices: ReadonlyArray<{
    readonly id: string;
  }> | null;
  readonly orders: ReadonlyArray<{
    readonly id: string;
  }> | null;
  readonly " $fragmentType": "SalesOrdersDetailPageHeaderFragment";
};
export type SalesOrdersDetailPageHeaderFragment$key = {
  readonly " $data"?: SalesOrdersDetailPageHeaderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SalesOrdersDetailPageHeaderFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SalesOrdersDetailPageHeaderFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Order",
      "kind": "LinkedField",
      "name": "orders",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Invoice",
      "kind": "LinkedField",
      "name": "invoices",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "SalesOrder",
  "abstractKey": null
};
})();

(node as any).hash = "6ad90c202236485e1bb84062993aab30";

export default node;
