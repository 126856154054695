import { Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import {
  SalesOrdersEditFormDetailCostType,
  SalesOrdersEditFormDetailItemType,
  SalesOrdersEditFormDetailType,
  SalesOrdersReorderFormDetailCostType,
  SalesOrdersReorderFormDetailItemType,
  SalesOrdersReorderFormDetailType,
} from 'features/salesOrdersV2/form';
import {
  SALES_ORDER_DETAIL_TYPE,
  SalesOrdersDetailSpec,
  getItemSpecs,
  getOnetimeCostSpecs,
  getRecurringCostSpecs,
} from 'features/salesOrdersV2/models';
import { memo } from 'react';
import { dateFormat } from 'utils/date';
import { calculateDetailAmount } from 'utils/priceCalculation';
import { SalesOrdersPreviewDetailSpec } from './SalesOrdersPreviewDetailSpec';

type SalesOrdersPreviewDetailsType =
  | SalesOrdersEditFormDetailType[]
  | SalesOrdersReorderFormDetailType[];
type SalesOrdersPreviewDetailType =
  | SalesOrdersEditFormDetailType
  | SalesOrdersReorderFormDetailType;
type SalesOrdersPreviewDetailItemType =
  | SalesOrdersEditFormDetailItemType
  | SalesOrdersReorderFormDetailItemType;
type SalesOrdersPreviewDetailCostType =
  | SalesOrdersEditFormDetailCostType
  | SalesOrdersReorderFormDetailCostType;

export const SalesOrdersPreviewDetails = memo(
  ({
    details,
  }: {
    details: SalesOrdersPreviewDetailsType;
  }) => {
    const detailsForCalculation = details.map((detail: SalesOrdersPreviewDetailType) => ({
      unitPrice: detail.unitPrice || 0,
      unitSellingPrice: detail.unitSellingPrice || 0,
      quantity: detail.quantity || 0,
      tax: detail.tax || 0,
    }));

    return (
      <VStack mt={6} w="full" align="start" justify="space-between">
        <Table>
          <Thead>
            <Tr>
              <Th pl={0} pr={2} py={3} color="gray.900">
                品名
              </Th>
              <Th w="120px" px={2} py={3} whiteSpace="nowrap" color="gray.900">
                メモ
              </Th>
              <Th w="120px" px={2} py={3} whiteSpace="nowrap" color="gray.900">
                確定納品日
              </Th>
              <Th isNumeric w="92px" px={2} py={3} whiteSpace="nowrap" color="gray.900">
                数量
              </Th>
              <Th isNumeric w="92px" px={2} py={3} whiteSpace="nowrap" color="gray.900">
                単価
              </Th>
              <Th isNumeric w="92px" pl={2} pr={0} py={3} whiteSpace="nowrap" color="gray.900">
                金額
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {details.map((detail, i) => (
              <Tr key={`detail-${detail.name}-${i}`} verticalAlign="top" fontSize="xs">
                <Td pl={0} pr={2} py={3} whiteSpace="pre-wrap">
                  <Text>{detail.name}</Text>
                  <SalesOrdersPreviewDetailSpec specs={getSpecs(detail)} />
                </Td>
                <Td w="120px" px={2} py={3} whiteSpace="pre-wrap">
                  {detail.memo || '-'}
                </Td>
                <Td w="120px" px={2} py={3} whiteSpace="pre-wrap">
                  {detail.fixedDeliveryDate ? dateFormat(detail.fixedDeliveryDate) : '-'}
                </Td>
                <Td isNumeric w="92px" px={2} py={3}>
                  {detailsForCalculation[i].quantity.toLocaleString()}
                </Td>
                <Td isNumeric w="92px" px={2} py={3}>
                  {detailsForCalculation[i].unitSellingPrice.toLocaleString()}
                </Td>
                <Td isNumeric w="92px" pl={2} pr={0} py={3}>
                  {calculateDetailAmount({
                    quantity: detailsForCalculation[i].quantity,
                    price: detailsForCalculation[i].unitSellingPrice,
                  }).toLocaleString()}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </VStack>
    );
  },
);

const getSpecs = (detail: SalesOrdersPreviewDetailType): SalesOrdersDetailSpec[] => {
  if (detail.type === SALES_ORDER_DETAIL_TYPE.Item) {
    const itemDetail = detail as SalesOrdersPreviewDetailItemType;
    return getItemSpecs(itemDetail.category, itemDetail.spec);
  }

  const costDetail = detail as SalesOrdersPreviewDetailCostType;

  if (detail.type === SALES_ORDER_DETAIL_TYPE.RecurringCost) {
    return getRecurringCostSpecs({
      note: costDetail?.note,
    });
  }

  return getOnetimeCostSpecs({
    note: costDetail?.note,
  });
};
