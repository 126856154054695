import { FileZip } from '@icon-park/react';
import { FileSelectButton } from 'components/FileSelectButton';
import { useInvoiceBulkSendJobUploadURL } from 'features/invoice/hooks/useInvoiceBulkSendJobUploadURL';
import { captureException } from 'utils/error';

type Props = {
  onUploaded: (objectName: string) => void;
  onError?: (message: string) => void;
  isUploading: boolean;
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ZipFileUploadButton = ({
  onUploaded,
  onError,
  isUploading,
  setIsUploading,
}: Props) => {
  const { fetchUrl } = useInvoiceBulkSendJobUploadURL();

  const handleFileSelected = async (files: File[]) => {
    if (files.length === 0) return;

    // NOTE: 複数ファイル選択は未対応
    const file = files[0];

    setIsUploading(true);

    try {
      const data = await fetchUrl();
      if (!data) {
        throw new Error('Failed to get upload URL.');
      }

      const response = await fetch(data.url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/zip',
        },
        body: file,
      });

      if (!response.ok) {
        throw new Error(`Failed to upload, statusCode: ${response.status}`);
      }

      onUploaded(data.objectName);
    } catch (error) {
      if (!(error instanceof Error)) return;

      const errorMessage = error.message;
      onError?.(errorMessage);
      captureException(error, {
        level: 'warning',
      });
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <FileSelectButton
      onFileSelected={handleFileSelected}
      label="ZIPファイルを選択"
      leftIcon={<FileZip />}
      accept=".zip"
      isLoading={isUploading}
      buttonProps={{
        colorScheme: 'blue',
        loadingText: '読み込み中',
        spinnerPlacement: 'end',
        width: '13rem',
      }}
    />
  );
};
