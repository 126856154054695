import { Box, Button, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { Notes } from '@icon-park/react';
import { DemandItemFormSpec, DemandItemFormType } from 'features/demandItem/form';
import {
  DEMAND_ITEM_CATEGORY,
  DEMAND_ITEM_CATEGORY_LABEL,
  DemandItemCategory,
} from 'features/demandItem/models';
import { useFormContext } from 'react-hook-form';
import { DemandItemDetailBlockInfo } from '../DemandItemDetailBlock';
import { DemandItemPriceTable } from '../DemandItemPriceTable';
import { DemandItemConfirmImagePreview } from './DemandItemConfirmImagePreview';
import { DemandItemConfirmSpec } from './DemandItemConfirmSpec';

type Props = {
  onSubmit: (data: DemandItemFormType) => void;
  isSubmitting: boolean;
  isDisabledSubmit?: boolean;
};

export const DemandItemConfirm = ({ onSubmit, isDisabledSubmit = false, isSubmitting }: Props) => {
  const form = useFormContext<DemandItemFormType>();
  const { getValues, handleSubmit } = form;

  const {
    name,
    category,
    supplier,
    memo,
    prices,
    cardboard,
    flexiblePackage,
    giftBox,
    paperBag,
    other,
    images,
    attachments,
    tax,
  } = getValues();

  const specValueMap: Record<DemandItemCategory, DemandItemFormSpec> = {
    [DEMAND_ITEM_CATEGORY.Cardboard]: cardboard,
    [DEMAND_ITEM_CATEGORY.FlexiblePackage]: flexiblePackage,
    [DEMAND_ITEM_CATEGORY.GiftBox]: giftBox,
    [DEMAND_ITEM_CATEGORY.PaperBag]: paperBag,
    [DEMAND_ITEM_CATEGORY.Other]: other,
  } as const;

  const getDetailBlockInfoValues = () => {
    const baseValues: { label: string; node: React.ReactNode }[] = [
      { label: 'サプライヤー', node: supplier.name },
      { label: 'カテゴリー', node: DEMAND_ITEM_CATEGORY_LABEL[category].categoryName },
      { label: '社内メモ', node: memo },
    ];

    if (attachments.length > 0) {
      baseValues.push({
        label: 'その他のデータ',
        node: (
          <VStack spacing={1} alignItems="flex-start">
            {Array.from(attachments).map((attachment, index) => (
              <HStack
                key={index}
                w="fit-content"
                maxW="full"
                minH="24px"
                spacing={1}
                alignItems="flex-start"
              >
                <Icon mt="4.5px" w={4} h={4} size={16} as={Notes} />
                <Text fontSize="sm">{attachment.fileName}</Text>
              </HStack>
            ))}
          </VStack>
        ),
      });
    }

    return baseValues;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box w="1024px" pb={8}>
        <VStack spacing={4} alignItems="flex-start" justifyContent="space-between">
          <DemandItemConfirmSpec
            category={category}
            itemName={name}
            values={specValueMap[category]}
          />
          <HStack px={4} spacing={4} alignItems="flex-start" justify="space-between" w="100%">
            <VStack alignItems="flex-start" spacing={4} maxW="578px">
              {images.length > 0 && <DemandItemConfirmImagePreview images={images} />}

              <DemandItemDetailBlockInfo values={getDetailBlockInfoValues()} />
            </VStack>

            <Box w="382px">
              <DemandItemPriceTable
                values={prices.map((price, index) => ({
                  id: index.toString(),
                  quantity: price.quantity || 0,
                  unitPrice: price.unitPrice || 0,
                  unitSellingPrice: price.unitSellingPrice || 0,
                }))}
                tax={tax.name}
              />
            </Box>
          </HStack>
          <Button
            colorScheme="blue"
            w="full"
            type="submit"
            isLoading={isSubmitting}
            isDisabled={isDisabledSubmit}
          >
            登録
          </Button>
        </VStack>
      </Box>
    </form>
  );
};
