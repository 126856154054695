import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { AddOne, Loading, Send } from '@icon-park/react';
import { DateRangePicker } from 'components/DateRangePicker';
import { LoadMoreButton } from 'components/LoadMoreButton';
import { CompanyComboBox } from 'features/company/components/CompanyComboBox';
import { InvoiceIsSentFilterButton } from 'features/invoice/components/InvoiceFilter/InvoiceIsSentFilterButton';
import { InvoiceTableRow } from 'features/invoice/components/InvoiceTableRow';
import { getInvoiceWhere, useInvoiceFilter } from 'features/invoice/hooks/useInvoiceFilter';
import { useQueryParams } from 'features/invoice/hooks/useInvoiceQueryPrams';
import { UserSelectBox } from 'features/user/components/UserSelectBox';
import { invoicePageFragment$key } from 'gql/__generated__/invoicePageFragment.graphql';
import { invoicePageQuery } from 'gql/__generated__/invoicePageQuery.graphql';
import { useEffect, useState } from 'react';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { Link, useSearchParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { dateFormatHyphenDate } from 'utils/date';
import { paths } from 'utils/paths';

const InvoicePageQuery = graphql`
  query invoicePageQuery($where: InvoiceWhereInput, $demandID: ID!, $isSkip: Boolean!, $sentWhere: InvoiceWhereInput, $unsentWhere: InvoiceWhereInput) {
    ...invoicePageFragment @arguments(where: $where)
    companies(where: {id: $demandID}) @skip(if: $isSkip) {
      edges {
        node {
          id
          name
        }
      }
    }
    sentInvoicesCount: invoices(where: $sentWhere) {
      totalCount
    }
    unsentInvoicesCount: invoices(where: $unsentWhere) {
      totalCount
    }
  }
`;

const InvoicePageFragment = graphql`
  fragment invoicePageFragment on Query
    @refetchable(queryName: "invoiceFragmentRefetchQuery")
    @argumentDefinitions (
      count: { type: "Int", defaultValue: 100 }
      after: { type: "Cursor" }
      direction: { type: "OrderDirection", defaultValue: DESC }
      field: { type: "InvoiceOrderField", defaultValue: CREATED_AT }
      where: {type: "InvoiceWhereInput", defaultValue: null}
    )
  {
    invoices(after: $after, first: $count, orderBy: {direction: $direction, field: $field}, where: $where) @connection(key: "invoiceFragment_invoices") {
      edges {
        cursor
        node {
          ...InvoiceTableRowFragment
        }
      }
      totalCount
    }
    userInfo {
      connectedServices {
        freee
      }
    }
  }
`;

export const InvoicePage = () => {
  const { updateQueryParams } = useQueryParams();
  const [searchParams] = useSearchParams();
  const demandId = searchParams.get('demand');
  const creatorId = searchParams.get('creator');
  const isSent = searchParams.get('isSent');
  const startBookedAt = searchParams.get('startBookedAt');
  const endBookedAt = searchParams.get('endBookedAt');

  const [bookedAtDateRange, setBookedAtDateRange] = useState<[Date | null, Date | null]>([
    startBookedAt ? new Date(startBookedAt) : null,
    endBookedAt ? new Date(endBookedAt) : null,
  ]);
  const [startBookedAtDate, endBookedAtDate] = bookedAtDateRange;

  const query = useLazyLoadQuery<invoicePageQuery>(
    InvoicePageQuery,
    {
      ...getInvoiceWhere(
        creatorId || '',
        demandId || '',
        isSent || '',
        startBookedAt || '',
        endBookedAt || '',
      ),
      demandID: demandId || '',
      isSkip: !demandId,
      sentWhere: getInvoiceWhere(
        creatorId || '',
        demandId || '',
        'true',
        startBookedAt || '',
        endBookedAt || '',
      ).where,
      unsentWhere: getInvoiceWhere(
        creatorId || '',
        demandId || '',
        'false',
        startBookedAt || '',
        endBookedAt || '',
      ).where,
    },
    { fetchPolicy: 'network-only' },
  );
  const { data, hasNext, loadNext, refetch, isLoadingNext } = usePaginationFragment(
    InvoicePageFragment,
    query as invoicePageFragment$key,
  );

  const handleClickMore = () => loadNext(100);

  const {
    onClearDemand,
    onChangeUser,
    onChangeCompany,
    onChangeIsSent,
    onChangeBookedAt,
    isPending,
  } = useInvoiceFilter(refetch);

  const demand = (query.companies?.edges || [])[0]?.node;

  const handleChangeDates = (dates: [Date | null, Date | null]) => {
    setBookedAtDateRange(dates);

    const [startDate, endDate] = dates;
    if (!startDate || !endDate) {
      return;
    }

    onChangeBookedAt(dateFormatHyphenDate(startDate), dateFormatHyphenDate(endDate));
  };

  const handleClearDates = () => {
    setBookedAtDateRange([null, null]);
    onChangeBookedAt('', '');
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    updateQueryParams({
      demand: demandId || '',
      creator: creatorId || '',
      isSent: isSent || '',
      startBookedAt: startBookedAt || '',
      endBookedAt: endBookedAt || '',
    });
  }, [demandId, creatorId, isSent, startBookedAt, endBookedAt]);

  return (
    <>
      <HStack gap={4}>
        <Button
          colorScheme="blue"
          leftIcon={<AddOne />}
          width="13rem"
          borderRadius="0.5rem"
          as={Link}
          to={paths.invoice.new.url()}
        >
          請求書作成
        </Button>
        <Button
          colorScheme="blue"
          leftIcon={<Send />}
          width="13rem"
          borderRadius="0.5rem"
          as={Link}
          to={paths.invoice.bulkSend.url()}
        >
          請求書一括送付
        </Button>
      </HStack>
      <HStack mt="1.5rem">
        <FormControl w="220px">
          <FormLabel mb={1}>デマンド</FormLabel>
          <CompanyComboBox
            onChangeSelected={onChangeCompany}
            defaultSelectedItem={demand || { id: '', name: '' }}
            onClearInput={onClearDemand}
          />
        </FormControl>
        <FormControl w="220px">
          <FormLabel mb={1}>作成者</FormLabel>
          <UserSelectBox
            value={creatorId || ''}
            enableValue={true}
            onChange={(e) => onChangeUser(e.target.value)}
          />
        </FormControl>
        <FormControl w="248px">
          <FormLabel mb={1}>請求日</FormLabel>
          <DateRangePicker
            startDate={startBookedAtDate}
            endDate={endBookedAtDate}
            onChange={handleChangeDates}
            onClear={handleClearDates}
          />
        </FormControl>
      </HStack>
      <HStack mt="1rem">
        <InvoiceIsSentFilterButton
          label="すべて"
          isActive={isSent === null}
          onClick={() => onChangeIsSent(null)}
        />
        <InvoiceIsSentFilterButton
          label="未送付"
          isActive={isSent === 'false'}
          onClick={() => onChangeIsSent('false')}
          count={query.unsentInvoicesCount?.totalCount}
          countColor="red.500"
        />
        <InvoiceIsSentFilterButton
          label="送付済み"
          isActive={isSent === 'true'}
          onClick={() => onChangeIsSent('true')}
          count={query.sentInvoicesCount?.totalCount}
        />
      </HStack>
      {isPending ? (
        <Loading />
      ) : (
        <Box mt="1.5rem">
          <Table size="md">
            <Thead>
              <Tr whiteSpace="nowrap">
                <Th w="14%">請求書番号</Th>
                <Th w="8%">ステータス</Th>
                <Th w="22%">件名</Th>
                <Th w="23%">デマンド</Th>
                <Th w="12%">請求日</Th>
                <Th w="10%">作成者</Th>
                <Th w="6%" />
                <Th w="5%" />
              </Tr>
            </Thead>
            <Tbody>
              {data.invoices.edges?.map(
                (edge, i) =>
                  edge?.node && (
                    <InvoiceTableRow key={`invoice-${edge?.cursor}-${i}`} queryRef={edge?.node} />
                  ),
              )}
            </Tbody>
          </Table>
          <Box my={3} mx={4}>
            <LoadMoreButton
              hasNext={hasNext}
              onClickMore={handleClickMore}
              totalCount={data.invoices.totalCount}
              currentLength={data.invoices.edges?.length}
              loading={isLoadingNext}
            />
          </Box>
        </Box>
      )}
    </>
  );
};
