import { Badge, Box, Button, Td, Text, Tr } from '@chakra-ui/react';
import { Order, SendEmail, TriangleRuler } from '@icon-park/react';
import { Link } from 'components/Link';
import { SalesOrdersTableRowFragment$key } from 'gql/__generated__/SalesOrdersTableRowFragment.graphql';
import { useFragment } from 'react-relay';
import { Link as RouterLink } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { dateFormat } from 'utils/date';
import { concatFullName } from 'utils/label';
import { paths } from 'utils/paths';

const SalesOrdersTableRowFragment = graphql`
  fragment SalesOrdersTableRowFragment on SalesOrder {
    id
    transactionID
    title
    demand {
      id
      name
    }
    supplier {
      id
      name
    }
    receivedDate
    details {
      edges {
        node {
          deliveryEvents(where: { isValid: true }) {
            edges {
              node {
                fixedDeliveryDate
              }
            }
          }
        }
      }
    }
    estimations {
        id
        estimationResponse {
            id
            assignee {
                id
                estimationRequest {
                    id
                }
            }
        }
    }
    orders {
      id
    }
    internalAssignees {
      user {
        id
        profile {
          lastName
          firstName
        }
      }
    }
  }
`;

export const SalesOrdersTableRow = ({
  queryRef,
}: { queryRef: SalesOrdersTableRowFragment$key }) => {
  const {
    id,
    demand,
    estimations,
    supplier,
    transactionID,
    title,
    details,
    orders,
    receivedDate,
    internalAssignees,
  } = useFragment(SalesOrdersTableRowFragment, queryRef);
  const order = orders?.[0];
  const estimation = estimations?.[0];
  const estimationRequestId = estimation?.estimationResponse?.assignee.estimationRequest.id;

  const SalesOrderTypeLabel = () => {
    const regex = /リピート/i;
    const hasRepeatTextInTitle = regex.test(title);

    // 初回発注として扱う条件
    //  → 見積書を引用している && 発注請書のタイトルに「リピート」を含まない
    const isFirstTimeOrder = estimation && !hasRepeatTextInTitle;

    // リピート発注として扱う条件
    //  → 見積書を引用していない || 発注請書のタイトルに「リピート」を含む
    const isReorder = !estimation || hasRepeatTextInTitle;

    // OR条件なので先に判定する
    if (isReorder) {
      return (
        <Badge
          variant="outline"
          colorScheme="orange"
          width="56px"
          textAlign="center"
          rounded="sm"
          fontWeight="bold"
        >
          リピート
        </Badge>
      );
    }

    if (isFirstTimeOrder) {
      return (
        <Badge
          variant="outline"
          colorScheme="green"
          width="56px"
          textAlign="center"
          rounded="sm"
          fontWeight="bold"
        >
          新規
        </Badge>
      );
    }

    // ここに来ることはない
    return <></>;
  };

  const fixedDeliveryDateText = () => {
    const deliveryDates: string[] = [];

    details?.edges?.forEach((detail) => {
      const fixedDeliveryDate = detail?.node?.deliveryEvents?.edges?.[0]?.node?.fixedDeliveryDate;
      if (!fixedDeliveryDate) {
        return;
      }

      deliveryDates.push(fixedDeliveryDate);
    });

    deliveryDates.sort((a, b) => {
      return new Date(a).getTime() - new Date(b).getTime();
    });

    if (deliveryDates.length === 0) {
      return '-';
    }

    return Array.from(new Set(deliveryDates))
      .map((date) => dateFormat(date))
      .join('\n');
  };

  return (
    <Tr fontSize="xs">
      <Td>{transactionID}</Td>
      <Td>
        {/* 受注形式ラベル(新規 or リピート) */}
        <SalesOrderTypeLabel />
      </Td>
      <Td>{title || '-'}</Td>
      <Td>
        <Link to={paths.company._id(demand.id).url}>{demand.name}</Link>
      </Td>
      <Td>
        <Link to={paths.supplier._id(supplier.id).url()}>{supplier.name}</Link>
      </Td>
      <Td whiteSpace="nowrap">{dateFormat(receivedDate)}</Td>
      <Td whiteSpace="pre-wrap">{fixedDeliveryDateText()}</Td>
      <Td>
        {internalAssignees?.map((assignee) =>
          assignee.user.profile?.[0]?.lastName && assignee.user.profile?.[0]?.firstName ? (
            <Box key={assignee.user.id}>
              {concatFullName({
                lastName: assignee.user.profile[0].lastName,
                firstName: assignee.user.profile[0].firstName,
              })}
            </Box>
          ) : (
            <Box key={assignee.user.id}>-</Box>
          ),
        ) || '-'}
      </Td>
      <Td align="center" textAlign="center">
        {estimationRequestId ? (
          <Button
            as={RouterLink}
            w={6}
            minW={0}
            h={6}
            borderRadius={50}
            to={paths.estimationRequest._id(estimationRequestId)}
            px="auto"
            mx="auto"
          >
            <SendEmail size={12} />
          </Button>
        ) : (
          <Text>-</Text>
        )}
      </Td>
      <Td align="center" textAlign="center">
        {estimation ? (
          <Button
            as={RouterLink}
            w={6}
            minW={0}
            h={6}
            borderRadius={50}
            to={paths.estimation._id(estimation.id).url}
            px="auto"
            mx="auto"
          >
            <TriangleRuler size={12} />
          </Button>
        ) : (
          <Text>-</Text>
        )}
      </Td>
      <Td align="center" textAlign="center">
        {order ? (
          <Button
            as={RouterLink}
            w={6}
            minW={0}
            h={6}
            borderRadius={50}
            to={paths.order._id(order.id)}
            px="auto"
            mx="auto"
          >
            <Order size={12} />
          </Button>
        ) : (
          <Text>-</Text>
        )}
      </Td>
      <Td w="40px">
        <Button as={RouterLink} colorScheme="blue" size="xs" to={paths.salesOrders.id(id).url()}>
          詳細
        </Button>
      </Td>
    </Tr>
  );
};
