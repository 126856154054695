/**
 * @generated SignedSource<<cda3528a89c5160302f9a12fac6a48f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SalesOrdersFormItemModalFilterQuery$variables = {
  demandId: string;
};
export type SalesOrdersFormItemModalFilterQuery$data = {
  readonly demandItems: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly supplier: {
          readonly id: string;
          readonly name: string;
        };
      } | null;
    } | null> | null;
  };
};
export type SalesOrdersFormItemModalFilterQuery = {
  response: SalesOrdersFormItemModalFilterQuery$data;
  variables: SalesOrdersFormItemModalFilterQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "demandId"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "demandID",
        "variableName": "demandId"
      },
      {
        "kind": "Literal",
        "name": "isCurrent",
        "value": true
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Supplier",
  "kind": "LinkedField",
  "name": "supplier",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SalesOrdersFormItemModalFilterQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DemandItemConnection",
        "kind": "LinkedField",
        "name": "demandItems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DemandItemEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DemandItem",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SalesOrdersFormItemModalFilterQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DemandItemConnection",
        "kind": "LinkedField",
        "name": "demandItems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DemandItemEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DemandItem",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f0eb8ca4054101c6e3ffed0f2b7ea9d8",
    "id": null,
    "metadata": {},
    "name": "SalesOrdersFormItemModalFilterQuery",
    "operationKind": "query",
    "text": "query SalesOrdersFormItemModalFilterQuery(\n  $demandId: ID!\n) {\n  demandItems(where: {isCurrent: true, demandID: $demandId}) {\n    edges {\n      node {\n        supplier {\n          id\n          name\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d29ed0b001fe2650190ffe900154a4cb";

export default node;
