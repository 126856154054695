/**
 * @generated SignedSource<<bfba44bcdf4dd718719f2ddca7a03091>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SalesOrderDetailType = "item" | "onetime_cost" | "recurring_cost" | "%future added value";
export type UpdateSalesOrderInputV2 = {
  demandID: string;
  detailMessage: string;
  details: ReadonlyArray<UpdateSalesOrderDetailInputV2>;
  id: string;
  internalAssignees?: ReadonlyArray<SalesOrderInternalAssigneeInput> | null;
  internalMemo?: string | null;
  receivedDate: any;
  supplierID: string;
  title: string;
};
export type UpdateSalesOrderDetailInputV2 = {
  fixedDeliveryDate?: any | null;
  id?: string | null;
  memo: string;
  specByType: UpdateSalesOrderDetailSpecByTypeInputV2;
  type: SalesOrderDetailType;
};
export type UpdateSalesOrderDetailSpecByTypeInputV2 = {
  item?: UpdateSalesOrderDetailItemInputV2 | null;
  onetimeCost?: UpdateSalesOrderDetailOnetimeCostInputV2 | null;
  recurringCost?: UpdateSalesOrderDetailRecurringCostInputV2 | null;
};
export type UpdateSalesOrderDetailItemInputV2 = {
  demandItemID?: string | null;
  name: string;
  quantity: number;
  taxCategoryID: string;
  unitPrice: number;
  unitSellingPrice: number;
};
export type UpdateSalesOrderDetailOnetimeCostInputV2 = {
  name: string;
  note: string;
  quantity: number;
  taxCategoryID: string;
  unitPrice: number;
  unitSellingPrice: number;
};
export type UpdateSalesOrderDetailRecurringCostInputV2 = {
  demandRecurringCostID: string;
  quantity: number;
  taxCategoryID: string;
  unitPrice: number;
  unitSellingPrice: number;
};
export type SalesOrderInternalAssigneeInput = {
  userID: string;
};
export type confirm_SalesOrderEditConfirmPageMutation$variables = {
  input: UpdateSalesOrderInputV2;
};
export type confirm_SalesOrderEditConfirmPageMutation$data = {
  readonly updateSalesOrderV2: {
    readonly id: string;
  };
};
export type confirm_SalesOrderEditConfirmPageMutation = {
  response: confirm_SalesOrderEditConfirmPageMutation$data;
  variables: confirm_SalesOrderEditConfirmPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SalesOrder",
    "kind": "LinkedField",
    "name": "updateSalesOrderV2",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "confirm_SalesOrderEditConfirmPageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "confirm_SalesOrderEditConfirmPageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "47911a80065d3b42cfee0d46345bbe0e",
    "id": null,
    "metadata": {},
    "name": "confirm_SalesOrderEditConfirmPageMutation",
    "operationKind": "mutation",
    "text": "mutation confirm_SalesOrderEditConfirmPageMutation(\n  $input: UpdateSalesOrderInputV2!\n) {\n  updateSalesOrderV2(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b5d3a23a32e31166b95f5104466165be";

export default node;
