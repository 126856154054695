import { Grid, GridItem } from '@chakra-ui/react';
import { Fragment } from 'react';

type Props = {
  values: {
    label: string;
    node?: React.ReactNode;
  }[];
};

export const DemandItemDetailBlockInfo = ({ values }: Props) => {
  return (
    <Grid gridTemplateColumns="max-content 1fr" gap="8px 12px">
      {values.map(({ label, node }) => (
        <Fragment key={label}>
          <GridItem
            fontSize="sm"
            color="gray.500"
            whiteSpace="nowrap"
            textAlign="right"
            lineHeight="1.7"
          >
            {label}
          </GridItem>
          <GridItem fontSize="sm" lineHeight="1.7" whiteSpace="pre-wrap">
            {node}
          </GridItem>
        </Fragment>
      ))}
    </Grid>
  );
};
