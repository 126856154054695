/**
 * @generated SignedSource<<cb2305934225eb600c76ebe053349ef3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ItemCategory = "cardboard" | "flexiblePackage" | "giftBox" | "other" | "paperBag" | "unknown" | "%future added value";
export type CreateSalesOrderInputV2 = {
  demandID: string;
  detailMessage: string;
  details: ReadonlyArray<CreateSalesOrderDetailInputV2>;
  estimationID: string;
  internalAssignees?: ReadonlyArray<SalesOrderInternalAssigneeInput> | null;
  internalMemo?: string | null;
  masterRegistrationItems?: ReadonlyArray<CreateSalesOrderMasterRegistrationItemInput> | null;
  masterRegistrationRecurringCosts?: ReadonlyArray<CreateSalesOrderMasterRegistrationRecurringCostInput> | null;
  receivedDate: any;
  supplierID: string;
  title: string;
};
export type CreateSalesOrderDetailInputV2 = {
  cost?: CreateSalesOrderDetailCostInput | null;
  item?: CreateSalesOrderDetailItemInput | null;
};
export type CreateSalesOrderDetailCostInput = {
  estimationDetailID: string;
  fixedDeliveryDate?: any | null;
  memo?: string | null;
  quantity: number;
  taxCategoryID: string;
  unitPrice: number;
  unitSellingPrice: number;
};
export type CreateSalesOrderDetailItemInput = {
  estimationDetailID: string;
  fixedDeliveryDate?: any | null;
  memo?: string | null;
  quantity: number;
  taxCategoryID: string;
  unitPrice: number;
  unitSellingPrice: number;
};
export type SalesOrderInternalAssigneeInput = {
  userID: string;
};
export type CreateSalesOrderMasterRegistrationItemInput = {
  category: ItemCategory;
  demandItemID?: string | null;
  estimationDetailIDs: ReadonlyArray<string>;
  name: string;
  prices: ReadonlyArray<CreateSalesOrderMasterRegistrationPriceInput>;
  spec: CreateSalesOrderMasterRegistrationSpecInput;
  taxCategoryID: string;
};
export type CreateSalesOrderMasterRegistrationPriceInput = {
  quantity: number;
  unitPrice: number;
  unitSellingPrice: number;
};
export type CreateSalesOrderMasterRegistrationSpecInput = {
  cardboard?: CreateSalesOrderMasterRegistrationCardboardInput | null;
  flexiblePackage?: CreateSalesOrderMasterRegistrationFlexiblePackageInput | null;
  giftBox?: CreateSalesOrderMasterRegistrationGiftBoxInput | null;
  other?: CreateSalesOrderMasterRegistrationOtherInput | null;
  paperBag?: CreateSalesOrderMasterRegistrationPaperBagInput | null;
};
export type CreateSalesOrderMasterRegistrationCardboardInput = {
  material: string;
  other: string;
  printingColor: string;
  processing: string;
  size: string;
  thickness: string;
  type: string;
};
export type CreateSalesOrderMasterRegistrationFlexiblePackageInput = {
  material: string;
  other: string;
  printingColor: string;
  processing: string;
  size: string;
  type: string;
};
export type CreateSalesOrderMasterRegistrationGiftBoxInput = {
  other: string;
  paperType: string;
  printingColor: string;
  processing: string;
  size: string;
  type: string;
};
export type CreateSalesOrderMasterRegistrationOtherInput = {
  specText: string;
};
export type CreateSalesOrderMasterRegistrationPaperBagInput = {
  handle: string;
  other: string;
  paperType: string;
  printingColor: string;
  processing: string;
  size: string;
};
export type CreateSalesOrderMasterRegistrationRecurringCostInput = {
  estimationDetailIDs: ReadonlyArray<string>;
  internalMemo?: string | null;
  name: string;
  note: string;
  prices: ReadonlyArray<CreateSalesOrderMasterRegistrationPriceInput>;
  taxCategoryID: string;
};
export type confirm_NewSalesOrderConfirmMutation$variables = {
  input: CreateSalesOrderInputV2;
};
export type confirm_NewSalesOrderConfirmMutation$data = {
  readonly createSalesOrderV2: {
    readonly id: string;
  };
};
export type confirm_NewSalesOrderConfirmMutation = {
  response: confirm_NewSalesOrderConfirmMutation$data;
  variables: confirm_NewSalesOrderConfirmMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SalesOrder",
    "kind": "LinkedField",
    "name": "createSalesOrderV2",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "confirm_NewSalesOrderConfirmMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "confirm_NewSalesOrderConfirmMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ed29a2d709c17d586eeba529fd654860",
    "id": null,
    "metadata": {},
    "name": "confirm_NewSalesOrderConfirmMutation",
    "operationKind": "mutation",
    "text": "mutation confirm_NewSalesOrderConfirmMutation(\n  $input: CreateSalesOrderInputV2!\n) {\n  createSalesOrderV2(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d0f79f2e6051975017e6dd39ea4a2296";

export default node;
