import { useDisclosure } from '@chakra-ui/react';

export const useSalesOrdersRecurringCostModal = () => {
  const {
    isOpen: isOpenRecurringCostModal,
    onOpen: onOpenRecurringCostModal,
    onClose: onCloseRecurringCostModal,
  } = useDisclosure();

  return { isOpenRecurringCostModal, onOpenRecurringCostModal, onCloseRecurringCostModal };
};
