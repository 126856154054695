/**
 * @generated SignedSource<<fece1cea80aff2502cbf347e5f0bfb2e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type create_DemandItemCreatePageQuery$variables = {};
export type create_DemandItemCreatePageQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DemandItemFormFragment">;
};
export type create_DemandItemCreatePageQuery = {
  response: create_DemandItemCreatePageQuery$data;
  variables: create_DemandItemCreatePageQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "create_DemandItemCreatePageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "DemandItemFormFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "create_DemandItemCreatePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TaxCategoryConnection",
        "kind": "LinkedField",
        "name": "taxCategories",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TaxCategoryEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TaxCategory",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "afb1d402e89a48ebee268a99df5e91b6",
    "id": null,
    "metadata": {},
    "name": "create_DemandItemCreatePageQuery",
    "operationKind": "query",
    "text": "query create_DemandItemCreatePageQuery {\n  ...DemandItemFormFragment\n}\n\nfragment DemandItemFormFragment on Query {\n  ...TaxSelectBoxFragment\n}\n\nfragment TaxSelectBoxFragment on Query {\n  taxCategories {\n    edges {\n      node {\n        id\n        name\n        rate\n      }\n    }\n  }\n}\n"
  }
};

(node as any).hash = "d8d97c325e2ece3e90680dfd35bf186a";

export default node;
