/**
 * @generated SignedSource<<f881b8e76e9c65abf9e2b58da216a3ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SalesOrdersDetailPageInternalMemoFragment$data = {
  readonly internalMemos: ReadonlyArray<{
    readonly body: string;
  }> | null;
  readonly " $fragmentType": "SalesOrdersDetailPageInternalMemoFragment";
};
export type SalesOrdersDetailPageInternalMemoFragment$key = {
  readonly " $data"?: SalesOrdersDetailPageInternalMemoFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SalesOrdersDetailPageInternalMemoFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SalesOrdersDetailPageInternalMemoFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SalesOrderInternalMemo",
      "kind": "LinkedField",
      "name": "internalMemos",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "body",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SalesOrder",
  "abstractKey": null
};

(node as any).hash = "ea8d868fa6afb280b6476be388e18957";

export default node;
