import { Box, Button, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import { GroupedInvoiceType } from 'features/invoice/type';
import { CompanyInvoiceRecipientRecipientType } from 'gql/graphql.types';

type InvoiceBulkSendTableProps = {
  groupedInvoiceData: GroupedInvoiceType[];
  isUploading: boolean;
  onEditRecipients: (index: number) => void;
};

export const InvoiceBulkSendTable = ({
  groupedInvoiceData,
  isUploading,
  onEditRecipients,
}: InvoiceBulkSendTableProps) => {
  return (
    <Table w="100%">
      <Thead>
        <Tr>
          <Th color="gray.700" w="240px">
            デマンド
          </Th>
          <Th color="gray.700" w="406px">
            ファイル名
          </Th>
          <Th color="gray.700" w="406px">
            請求書送付先
          </Th>
          <Th color="gray.700" w="108px"></Th>
        </Tr>
      </Thead>
      <Tbody>
        {groupedInvoiceData.map((group, groupIndex) => (
          <Tr key={`company-${groupIndex}`}>
            <Td verticalAlign="center">{group.company.name}</Td>
            <Td verticalAlign="center">
              <VStack align="start" spacing={1}>
                {group.documents.map((document, docIndex) => (
                  <Text key={`doc-${docIndex}`}>{document.fileName}</Text>
                ))}
              </VStack>
            </Td>
            <Td verticalAlign="center">
              <Box>
                {RecipientsByType(group.recipients, CompanyInvoiceRecipientRecipientType.To, 'To')}
                {RecipientsByType(group.recipients, CompanyInvoiceRecipientRecipientType.Cc, 'Cc')}
              </Box>
            </Td>
            <Td>
              <Button
                size="sm"
                colorScheme="blue"
                onClick={() => onEditRecipients(groupIndex)}
                isDisabled={isUploading}
              >
                送付先修正
              </Button>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

// 請求書送付先表示用の共通関数
const RecipientsByType = (
  recipients: GroupedInvoiceType['recipients'],
  recipientType: CompanyInvoiceRecipientRecipientType,
  prefix: 'To' | 'Cc',
) => {
  return recipients
    .filter((recipient) => recipient.recipientType === recipientType)
    .map((recipient, idx: number) => (
      <Text key={`${prefix}-${idx}`}>
        {prefix} : {recipient.email}
      </Text>
    ));
};
