/**
 * @generated SignedSource<<22341ba008b6c92889664fe2cd314255>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InvoiceIsSentUpdateInput = {
  isSent: boolean;
};
export type useUpdateInvoiceIsSentMutation$variables = {
  id: string;
  input: InvoiceIsSentUpdateInput;
};
export type useUpdateInvoiceIsSentMutation$data = {
  readonly updateInvoiceIsSent: {
    readonly id: string;
    readonly isSent: boolean;
  };
};
export type useUpdateInvoiceIsSentMutation = {
  response: useUpdateInvoiceIsSentMutation$data;
  variables: useUpdateInvoiceIsSentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Invoice",
    "kind": "LinkedField",
    "name": "updateInvoiceIsSent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isSent",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateInvoiceIsSentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUpdateInvoiceIsSentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4fa09d919ae5e63f09659df0e36646bb",
    "id": null,
    "metadata": {},
    "name": "useUpdateInvoiceIsSentMutation",
    "operationKind": "mutation",
    "text": "mutation useUpdateInvoiceIsSentMutation(\n  $id: ID!\n  $input: InvoiceIsSentUpdateInput!\n) {\n  updateInvoiceIsSent(id: $id, input: $input) {\n    id\n    isSent\n  }\n}\n"
  }
};
})();

(node as any).hash = "1975dafaee10a75ee838e868081047d8";

export default node;
