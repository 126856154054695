import { Th, Thead, Tr } from '@chakra-ui/react';

type Props = {
  enableCheckbox?: boolean;
  checkboxLabel?: string;
};

export const DemandItemPriceTableHeader = ({ enableCheckbox, checkboxLabel }: Props) => {
  return (
    <Thead>
      <Tr>
        {enableCheckbox && (
          <Th p={2} w="24px" whiteSpace="nowrap">
            {checkboxLabel}
          </Th>
        )}
        <Th p={2} isNumeric>
          数量
        </Th>
        <Th p={2} isNumeric>
          仕入単価
        </Th>
        <Th p={2} isNumeric>
          顧客単価
        </Th>
      </Tr>
    </Thead>
  );
};
