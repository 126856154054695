import { Button, Grid, GridItem, VStack } from '@chakra-ui/react';
import { EstimationFormType } from 'features/estimations/form';
import { useFormContext } from 'react-hook-form';
import { EstimationConfirmItemPriceList } from './EstimationConfirmItemPriceList';
import { EstimationConfirmPreview } from './EstimationConfirmPreview';
type Props = {
  confirmButtonLabel: string;
  onSubmit: (data: EstimationFormType) => void;
  isSubmitting?: boolean;
};

export const EstimationConfirmV2 = ({
  confirmButtonLabel,
  onSubmit,
  isSubmitting = false,
}: Props) => {
  const form = useFormContext<EstimationFormType>();
  const { getValues, handleSubmit } = form;

  const { title, internalAssignees } = getValues();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack w="768px" spacing={6} alignItems="flex-start">
        <Grid gridTemplateColumns="max-content 1fr" gap="12px 8px">
          <GridItem color="gray.500" textAlign="right" fontSize="sm">
            件名
          </GridItem>
          <GridItem fontSize="sm">{title}</GridItem>
          <GridItem color="gray.500" textAlign="right" fontSize="sm">
            担当者
          </GridItem>
          <GridItem fontSize="sm">
            {internalAssignees.map((assignee) => assignee.label).join(' / ')}
          </GridItem>
        </Grid>

        {/* 見積書部分 */}
        <EstimationConfirmPreview />

        <Button
          colorScheme="blue"
          w="full"
          type="submit"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          {confirmButtonLabel}
        </Button>

        <EstimationConfirmItemPriceList />
      </VStack>
    </form>
  );
};
