import { HStack, Text } from '@chakra-ui/react';

export const MasterRegistrationSettingRequiredFieldLabel = ({
  label,
}: { label: '商品区分' | '費用区分' }) => {
  return (
    <HStack align="center" justify="flex-end" spacing={0} mt="5px">
      <Text>{label}</Text>
      <Text
        as="span"
        role="presentation"
        aria-hidden="true"
        // FYI: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/form-control/src/form-label.tsx
        className="chakra-form__required-indicator css-1tfjd1n"
        color="red.500"
      >
        *
      </Text>
    </HStack>
  );
};
