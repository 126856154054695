export type SalesOrderSpecCardboard = {
  size: string;
  type: string;
  material: string;
  thickness: string;
  printingColor: string;
  processing: string;
  other: string;
};

export type SalesOrderSpecFlexiblePackage = {
  size: string;
  type: string;
  material: string;
  printingColor: string;
  processing: string;
  other: string;
};

export type SalesOrderSpecGiftBox = {
  size: string;
  type: string;
  paperType: string;
  printingColor: string;
  processing: string;
  other: string;
};

export type SalesOrderSpecPaperBag = {
  size: string;
  paperType: string;
  printingColor: string;
  processing: string;
  handle: string;
  other: string;
};

export type SalesOrderSpecOther = {
  specText: string;
};

export type SalesOrderSpecCost = {
  note: string;
};

export type SalesOrderSpec = {
  cardboard?: SalesOrderSpecCardboard | undefined;
  flexiblePackage?: SalesOrderSpecFlexiblePackage | undefined;
  giftBox?: SalesOrderSpecGiftBox | undefined;
  paperBag?: SalesOrderSpecPaperBag | undefined;
  other?: SalesOrderSpecOther | undefined;
  cost?: SalesOrderSpecCost | undefined;
};

export type SalesOrderDetail = {
  name: string;
  tax: {
    id: string;
    rate: string | number;
  };
  fixedDeliveryDate?: string | undefined;
  category: string;
  spec: SalesOrderSpec;
  unitPrice?: string | number | undefined;
  quantity?: string | number | undefined;
  unitSellingPrice?: string | number | undefined;
};

export const deduplicateAdjacentNames = <T extends SalesOrderDetail>(details: T[]): T[] => {
  if (details.length === 0) return [];

  const result: T[] = [{ ...details[0] }];

  for (let i = 1; i < details.length; i++) {
    const prev = details[i - 1];
    const curr = details[i];
    const newElement: T = { ...curr };

    if (
      prev.name === curr.name &&
      prev.category === curr.category &&
      JSON.stringify(prev.spec) === JSON.stringify(curr.spec)
    ) {
      newElement.name = '同上';
    }

    result.push(newElement);
  }

  return result;
};

export const detailNumber = (transactionId: number, branchNumber: number): string => {
  // transactionId-branchNumberの形式, branchNumberは2桁で0埋めする
  return `${transactionId}-${branchNumber.toString().padStart(2, '0')}`;
};
