import { HStack, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import { deduplicateAdjacentNames } from 'features/estimations/models';
import { EstimationDetailItemPriceListFragment$key } from 'gql/__generated__/EstimationDetailItemPriceListFragment.graphql';
import { graphql, useFragment } from 'react-relay';
import { calculateDetailAmount } from 'utils/priceCalculation';
import { mapPriceListDetailsToEstimationDetails } from '../utils/mapper';
import { EstimationDetailSpecPreview } from './EstimationDetailSpecPreview';

const EstimationDetailItemPriceListFragment = graphql`
  fragment EstimationDetailItemPriceListFragment on Estimation
  {
    priceListDetails: details(orderBy: {direction: ASC, field: ORDER_NUMBER}) {
      edges {
        node {
          type
          orderNumber
          documentDisplayStatus
          cost {
            name
            note
            unitPrice
            unitSellingPrice
            quantity
            taxCategory {
              id
              rate
            }
          }
          item {
            name
            category
            taxCategory {
              id
              rate
            }
            unitPrice
            unitSellingPrice
            quantity
            cardboard {
              id
              size
              type
              material
              thickness
              printingColor
              processing
              other
            }
            flexiblePackage {
              id
              size
              type
              material
              printingColor
              processing
              other
            }
            giftBox {
              id
              size
              type
              paperType
              printingColor
              processing
              other
            }
            paperBag {
              id
              size
              paperType
              printingColor
              processing
              handle
              other
            }
            other {
              id
              specText
            }
          }
        }
      }
    }
  }
`;

type Props = {
  queryRef: EstimationDetailItemPriceListFragment$key;
};

export const EstimationDetailItemPriceList = ({ queryRef }: Props) => {
  const { priceListDetails } = useFragment(EstimationDetailItemPriceListFragment, queryRef);

  const mappedDetails = mapPriceListDetailsToEstimationDetails(priceListDetails);

  const items = deduplicateAdjacentNames(mappedDetails);

  return (
    <VStack align="stretch" p={10} spacing={6} borderWidth="1px" borderRadius="6px" w="full" mb={6}>
      <HStack justify="space-between" align="center">
        <Text fontSize="md" fontWeight="bold">
          単価表
        </Text>
        <Text color="gray.500" fontSize="sm">
          ※見積書には記載されません
        </Text>
      </HStack>
      <Table>
        <Thead>
          <Tr>
            <Th color="gray.700" w="100%" pl="0">
              品名
            </Th>
            <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap">
              数量
            </Th>
            <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap">
              仕入単価
            </Th>
            <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap">
              顧客単価
            </Th>
            <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap" pr="0">
              金額 (税抜)
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.map((item, i) => (
            <Tr key={`${item.name}-${i}`} verticalAlign="top">
              <Td pl="0" whiteSpace="pre-wrap" fontSize="xs">
                {item.name}
                {item.name !== '同上' && <EstimationDetailSpecPreview detail={item} />}
              </Td>
              <Td isNumeric color="gray.700" fontSize="xs">
                {item.quantity?.toLocaleString() ?? 0}
              </Td>
              <Td isNumeric color="gray.700" fontSize="xs">
                {item.unitPrice?.toLocaleString() ?? 0}
              </Td>
              <Td isNumeric color="gray.700" fontSize="xs">
                {item.unitSellingPrice?.toLocaleString() ?? 0}
              </Td>
              <Td isNumeric color="gray.700" pr="0" fontSize="xs">
                {calculateDetailAmount({
                  quantity: item.quantity ?? 0,
                  price: item.unitSellingPrice ?? 0,
                }).toLocaleString()}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </VStack>
  );
};
