import { useUpdateInvoiceIsSentMutation } from 'gql/__generated__/useUpdateInvoiceIsSentMutation.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { toast } from 'lib/toast';
import { graphql } from 'relay-runtime';
export const useUpdateInvoiceIsSent = () => {
  const { commitMutation, isMutationInFlight } = useMutationWrapper<useUpdateInvoiceIsSentMutation>(
    graphql`
      mutation useUpdateInvoiceIsSentMutation($id: ID!, $input: InvoiceIsSentUpdateInput!) {
        updateInvoiceIsSent(id: $id, input: $input) {
          id
          isSent
        }
      }
    `,
  );

  const onSubmit = (id: string, isSent: boolean) => {
    commitMutation({
      variables: { id, input: { isSent } },
      onCompleted: () => {
        toast({
          title: '送付ステータスを更新しました',
          status: 'success',
        });
      },
    });
  };

  return { onSubmit, isMutationInFlight };
};
