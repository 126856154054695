import { CompanyInvoiceRecipientRecipientType } from 'gql/graphql.types';
import { errorMessages } from 'utils/validation';
import { z } from 'zod';

export const invoiceRecipientEditFormSchema = z.object({
  recipients: z
    .array(
      z.object({
        id: z.string().optional(),
        email: z
          .string()
          .min(1, { message: errorMessages.REQUIRED })
          .email({ message: errorMessages.INVALID_EMAIL_FORMAT }),
        recipientType: z.nativeEnum(CompanyInvoiceRecipientRecipientType),
      }),
    )
    .min(1, { message: '請求書送付先は最低1件必要です' })
    .refine((recipients) => {
      const emails = recipients.map((r) => r.email);
      // 空文字が含まれている場合はチェックしない（email の必須チェックを優先させるため）
      if (emails.some((e) => e === '')) {
        return true;
      }

      return new Set(emails).size === emails.length;
    }, 'メールアドレスが重複しています')
    .refine((recipients) => {
      return recipients.some((r) => r.recipientType === CompanyInvoiceRecipientRecipientType.To);
    }, 'TOの請求書送付先は最低1件必要です'),
});

export type InvoiceRecipientEditFormDataType = z.infer<typeof invoiceRecipientEditFormSchema>;
