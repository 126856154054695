import { Divider, HStack, Text, VStack } from '@chakra-ui/react';
import { SubHeading } from 'components/SubHeading';
import { memo } from 'react';

export const SalesOrdersPreviewHeader = memo(
  ({ publishedAt, orderedAt }: { publishedAt: string; orderedAt: string }) => {
    return (
      <VStack spacing={4} alignItems="flex-start" w="full">
        <HStack justifyContent="space-between" alignItems="baseline" w="full">
          <SubHeading label="発注請書" />
          <HStack spacing={2}>
            <Text fontSize="sm">発行日：{publishedAt}</Text>
            <Text fontSize="sm">受注日：{orderedAt}</Text>
          </HStack>
        </HStack>
        <Divider />
      </VStack>
    );
  },
);
