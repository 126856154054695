import { Box } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { DemandForm } from 'features/company/components/DemandForm';
import { useUpdateDemand } from 'features/company/hooks/useUpdateDemand';
import { edit_CompanyEditPageQuery } from 'gql/__generated__/edit_CompanyEditPageQuery.graphql';
import { useLazyLoadQuery } from 'react-relay';
import { Link, Navigate, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { isItemManagementEnabled } from 'utils/environment';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';

const CompanyEditPageQuery = graphql`
  query edit_CompanyEditPageQuery  ($id: ID!) {
    companies(where: {id: $id}) {
      edges {
        node {
          id
          name
          code
          internalMemos {
            body
          }
        }
      }
    }
  }
`;

export function CompanyEditPage() {
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const { onSubmit, isMutationInFlight } = useUpdateDemand({ id });

  const { companies } = useLazyLoadQuery<edit_CompanyEditPageQuery>(
    CompanyEditPageQuery,
    {
      id,
    },
    { fetchPolicy: 'network-only' },
  );

  const demandData = companies?.edges?.[0]?.node;
  if (!demandData) return null;

  const demand = {
    name: demandData.name,
    memo: demandData.internalMemos?.[0]?.body || '',
  };

  // 商品管理のリダイレクト対応
  if (isItemManagementEnabled()) {
    const demandCode = companies.edges[0]?.node?.code;

    if (!demandCode) {
      // デマンドコードがない場合は、デマンド一覧にリダイレクト
      return <Navigate to={paths.demands.url()} replace />;
    }

    return <Navigate to={paths.demands._demandCode(demandCode).edit.url()} replace />;
  }

  return (
    <>
      <Box mb={6}>
        <Link to={paths.company._id(id).url}>
          <PageBack />
        </Link>
      </Box>
      <Box mb={6}>
        <SubHeading label="デマンドの編集" />
      </Box>
      <Box w="480px">
        <DemandForm
          type="edit"
          value={demand}
          onSubmit={onSubmit}
          isMutationInFlight={isMutationInFlight}
        />
      </Box>
    </>
  );
}
