import { Box, Button, Flex, HStack } from '@chakra-ui/react';
import { Edit, EfferentFour } from '@icon-park/react';
import { Link } from 'components/Link';
import { RouteLinkWithReferrer } from 'components/RouteLinkWithReferrer';
import {
  DemandRecurringCostBlock,
  DemandRecurringCostBlockBody,
  DemandRecurringCostBlockHeader,
  DemandRecurringCostBlockInfo,
} from 'features/demandRecurringCost/components/DemandRecurringCostBlock';
import { DemandRecurringCostPriceTable } from 'features/demandRecurringCost/components/DemandRecurringCostPriceTable';
import { paths } from 'utils/paths';
import { SalesOrdersDetailMasterDataRecurringCostType } from '.';

type Props = {
  cost: SalesOrdersDetailMasterDataRecurringCostType;
};

export const SalesOrdersDetailPageMasterDataRecurringCost = ({ cost }: Props) => {
  const latestOrder =
    cost.salesOrderRecurringCosts.edges?.[0]?.node?.salesOrderDetail?.salesOrder?.orders?.[0];
  const editPath = paths.demands
    ._demandCode(cost.demand.code)
    .recurringCosts._costNumber(cost.costNumber)
    .edit.url();

  return (
    <DemandRecurringCostBlock key={cost.id}>
      <DemandRecurringCostBlockHeader itemName={cost.name} value={cost.note || '-'} />
      <DemandRecurringCostBlockBody>
        <DemandRecurringCostBlockInfo
          values={[
            {
              label: 'サプライヤー',
              node: cost.supplier?.name || '-',
            },
            {
              label: 'カテゴリー',
              node: '費用',
            },
            {
              label: '最終発注',
              node: latestOrder ? (
                <Link to={paths.order._id(latestOrder.id)} target="_blank">
                  <HStack gap={1}>
                    <Box>{latestOrder.title}</Box>
                    <EfferentFour theme="outline" />
                  </HStack>
                </Link>
              ) : (
                '-'
              ),
            },
            {
              label: '社内メモ',
              node: cost.internalMemos?.[0]?.body || '-',
            },
          ]}
        />
        <Box w="382px">
          <DemandRecurringCostPriceTable
            values={
              cost.prices?.map((price) => ({
                id: price.id,
                quantity: price.quantity,
                unitPrice: price.unitPrice,
                unitSellingPrice: price.unitSellingPrice,
              })) || []
            }
            tax={cost.tax?.name}
          />
        </Box>
      </DemandRecurringCostBlockBody>
      <Flex w="full" pr={4} justifyContent="flex-end">
        <Button colorScheme="blue" rightIcon={<Edit />} as={RouteLinkWithReferrer} to={editPath}>
          編集
        </Button>
      </Flex>
    </DemandRecurringCostBlock>
  );
};
