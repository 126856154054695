import { FormControl, HStack, Icon, IconButton, Text, useRadioGroup } from '@chakra-ui/react';
import { Edit } from '@icon-park/react';
import { ErrorMessage } from 'components/ErrorMessage';
import {
  MASTER_REGISTRATION_SETTING_ITEM_TYPE,
  MASTER_REGISTRATION_SETTING_ITEM_TYPE_UPDATE,
  NewSalesOrderFormType,
} from 'features/salesOrdersV2/form';
import { Fragment } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { MasterRegistrationSettingRadioButton } from './MasterRegistrationSettingRadioButton';

export const MasterRegistrationSettingItemRadioGroup = ({
  index,
  onOpenModal,
}: {
  index: number;
  onOpenModal: () => void;
}) => {
  const form = useFormContext<NewSalesOrderFormType>();
  const {
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = form;

  const checkedType = useWatch({
    control: form.control,
    name: `masterRegistrationItems.${index}.type`,
  });

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: `itemRegisterType-${index}`,
    value: checkedType,
    onChange: (value) => {
      if (value === MASTER_REGISTRATION_SETTING_ITEM_TYPE_UPDATE) {
        onOpenModal();
        return;
      }

      setValue(`masterRegistrationItems.${index}.type`, value);
      setValue(`masterRegistrationItems.${index}.demandItem`, {
        id: '',
        name: '',
        version: '',
        itemNumber: '',
        category: undefined,
        demandCode: '',
      });
      clearErrors(`masterRegistrationItems.${index}.type`);
    },
  });

  const group = getRootProps();

  return (
    <FormControl isInvalid={!!(errors?.masterRegistrationItems || [])[index]?.type}>
      <HStack {...group}>
        {Object.entries(MASTER_REGISTRATION_SETTING_ITEM_TYPE).map(([key, value]) => {
          const radio = getRadioProps({ value: key });
          const isChecked = checkedType === key;

          return (
            <Fragment key={key}>
              <MasterRegistrationSettingRadioButton
                {...radio}
                isChecked={isChecked}
                isInvalid={!!(errors?.masterRegistrationItems || [])[index]?.type}
              >
                {value}
              </MasterRegistrationSettingRadioButton>
              {key === MASTER_REGISTRATION_SETTING_ITEM_TYPE_UPDATE && isChecked && (
                <>
                  <Text isTruncated maxW="248px">
                    {getValues(`masterRegistrationItems.${index}.demandItem.name`)}
                  </Text>
                  <IconButton
                    icon={<Icon as={Edit} color="blue.500" />}
                    aria-label="変更"
                    variant="ghost"
                    size="sm"
                    onClick={onOpenModal}
                  />
                </>
              )}
            </Fragment>
          );
        })}
      </HStack>
      <ErrorMessage name={`masterRegistrationItems.${index}.type`} errors={errors} />
    </FormControl>
  );
};
