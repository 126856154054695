import {
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loading } from '@icon-park/react';
import { SalesOrdersFormRecurringCostModalDetails } from 'features/salesOrdersV2/components/SalesOrdersFormRecurringCostModal/SalesOrdersFormRecurringCostModalDetails';
import {
  SalesOrdersFormRecurringCostModalAcceptableFormType,
  SalesOrdersFormRecurringCostModalType,
  salesOrdersFormRecurringCostModalSchema,
} from 'features/salesOrdersV2/form';
import { Suspense } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

export const SalesOrdersFormRecurringCostModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  // 大元のフォーム
  const rootForm = useFormContext<SalesOrdersFormRecurringCostModalAcceptableFormType>();

  // モーダル上での選択を保持するフォーム
  const modalForm = useForm<SalesOrdersFormRecurringCostModalType>({
    resolver: zodResolver(salesOrdersFormRecurringCostModalSchema),
    defaultValues: {
      demand: rootForm.getValues('demand'),
      supplier: rootForm.getValues('supplier'),
      details: [],
    },
  });

  const handleAddDetails = () => {
    const details = rootForm.getValues('details');
    const selectedDetails = modalForm.getValues('details');

    const newDetails = [...details, ...selectedDetails];
    rootForm.setValue('details', newDetails);

    const selectedSupplier = modalForm.getValues('supplier');
    if (selectedSupplier) {
      rootForm.setValue('supplier', selectedSupplier);
    }

    onClose();
  };

  return (
    <FormProvider {...modalForm}>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="6xl" closeOnEsc={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pt={6} px={4} pb={3} borderBottom="1px solid" borderColor="gray.200">
            <HStack justifyContent="space-between" alignItems="baseline">
              <Text>継続費用を選択</Text>
            </HStack>
          </ModalHeader>

          <ModalBody p={0}>
            <HStack spacing={0} h="70vh">
              <Suspense
                fallback={
                  <Flex w="100%" h="100%" justifyContent="center" alignItems="center">
                    <Loading />
                  </Flex>
                }
              >
                <SalesOrdersFormRecurringCostModalDetails />
              </Suspense>
            </HStack>
          </ModalBody>

          <ModalFooter p={4} borderTop="1px solid" borderColor="gray.200">
            <Button colorScheme="blue" onClick={handleAddDetails}>
              明細に追加
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
};
