import { Divider, HStack, Text, VStack } from '@chakra-ui/react';
import { memo } from 'react';

export const SalesOrdersPreviewTotalPrice = memo(
  ({ demandName, totalPrice }: { demandName: string; totalPrice: number }) => {
    return (
      <VStack w="full" spacing={2} align="start">
        <Text fontSize="xl" fontWeight="bold">
          {demandName} 御中
        </Text>
        <Text color="gray.500" fontSize="sm">
          下記のとおりご発注を承りました
        </Text>
        <VStack spacing={1} w="full" h={41}>
          <HStack align="end" justify="space-between" w="full">
            <Text>合計金額</Text>
            <HStack spacing={1} align="end">
              <Text fontSize="2xl" fontWeight="bold">
                {totalPrice.toLocaleString()}
              </Text>
              <Text>円</Text>
            </HStack>
          </HStack>
          <Divider borderColor="gray.700" />
        </VStack>
      </VStack>
    );
  },
);
