import { Box, Button, Grid, GridItem, HStack, Text, VStack } from '@chakra-ui/react';
import { Loading } from '@icon-park/react';
import { InformationShizai } from 'components/InformationShizai';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import {
  SalesOrdersPreviewDetails,
  SalesOrdersPreviewHeader,
  SalesOrdersPreviewPrices,
  SalesOrdersPreviewTotalPrice,
} from 'features/salesOrdersV2/components/SalesOrdersPreview';
import {
  SalesOrdersEditFormDetailCostType,
  SalesOrdersEditFormDetailItemType,
  SalesOrdersEditFormDetailType,
  SalesOrdersEditFormType,
} from 'features/salesOrdersV2/form';
import { confirm_SalesOrderEditConfirmPageMutation } from 'gql/__generated__/confirm_SalesOrderEditConfirmPageMutation.graphql';
import { SalesOrderDetailType, UpdateSalesOrderDetailInputV2 } from 'gql/graphql.types';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { useNavigationBlocker } from 'hooks/useNavigationBlocker';
import { toast } from 'lib/toast';
import { Suspense } from 'react';
import { useFormContext } from 'react-hook-form';
import { ScrollRestoration, useNavigate } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { cdateJST, dateFormat, getToday } from 'utils/date';
import { paths } from 'utils/paths';
import {
  calculateSubtotalAmount,
  calculateTotalAmount,
  calculateTotalTaxAmount,
} from 'utils/priceCalculation';

export const SalesOrdersEditConfirmPage = () => {
  const navigate = useNavigate();

  const { getValues, handleSubmit } = useFormContext<SalesOrdersEditFormType>();
  const {
    salesOrderId,
    title,
    receivedDate,
    demand,
    supplier,
    details,
    internalAssignees,
    detailMessage,
    memo,
  } = getValues();

  const detailsForCalculation = details.map((detail: SalesOrdersEditFormDetailType) => ({
    unitPrice: detail.unitPrice || 0,
    unitSellingPrice: detail.unitSellingPrice || 0,
    quantity: detail.quantity || 0,
    tax: detail.tax || 0,
  }));

  const { commitMutation, isMutationInFlight } =
    useMutationWrapper<confirm_SalesOrderEditConfirmPageMutation>(
      graphql`
      mutation confirm_SalesOrderEditConfirmPageMutation(
        $input: UpdateSalesOrderInputV2!
      ) {
        updateSalesOrderV2(input: $input) {
          id
        }
      }
    `,
    );

  const { allowNavigation } = useNavigationBlocker(true, false);

  const onSubmitForm = (data: SalesOrdersEditFormType) => {
    allowNavigation();
    commitMutation({
      variables: {
        input: {
          id: data.salesOrderId,
          demandID: data.demand.id,
          supplierID: data.supplier.id,
          title: data.title,
          receivedDate: cdateJST(data.receivedDate).toDate(),
          details: getDetails(data.details),
          detailMessage: data.detailMessage,
          internalMemo: data.memo,
          internalAssignees: [
            ...(data.internalAssignees?.map((assignee) => ({ userID: assignee.value })) || []),
          ],
        },
      },
      onError() {
        toast({
          title: '発注請書の更新に失敗しました',
          status: 'error',
        });
      },
      onCompleted: (res) => {
        toast({
          title: '発注請書を更新しました',
          description: '受注DBと案件DBの紐付けをお願いします',
          status: 'success',
        });
        navigate(paths.salesOrders.id(res.updateSalesOrderV2.id).url());
      },
    });
  };

  const handleBack = () => {
    allowNavigation();
    navigate(paths.salesOrders.id(salesOrderId).edit.url());
  };

  return (
    <Box w="1024px">
      <ScrollRestoration />

      <Suspense fallback={<Loading />}>
        <Box>
          <Box onClick={handleBack}>
            <PageBack />
          </Box>
        </Box>

        <Box mt={6}>
          <SubHeading label="内容の確認" />
        </Box>

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid mt={6} gridTemplateColumns="max-content 1fr" gap="12px 8px">
            <GridItem color="gray.500" textAlign="right" fontSize="sm">
              件名
            </GridItem>
            <GridItem fontSize="sm">{title}</GridItem>
            <GridItem color="gray.500" textAlign="right" fontSize="sm">
              担当者
            </GridItem>
            <GridItem fontSize="sm">
              {internalAssignees.map((assignee) => assignee.label).join(' / ')}
            </GridItem>
            <GridItem color="gray.500" textAlign="right" fontSize="sm">
              サプライヤー
            </GridItem>
            <GridItem fontSize="sm">{supplier.name}</GridItem>
            <GridItem color="gray.500" textAlign="right" fontSize="sm">
              社内メモ
            </GridItem>
            <GridItem fontSize="sm" whiteSpace="pre-wrap">
              {memo}
            </GridItem>
          </Grid>

          {/* 書類形式のプレビュー */}
          <VStack
            mt={6}
            spacing={0}
            alignItems="flex-start"
            p={8}
            border="1px solid"
            borderColor="gray.200"
            borderRadius="8px"
          >
            {/* 書類名、発行日、受注日 */}
            <SalesOrdersPreviewHeader
              publishedAt={getToday()}
              orderedAt={dateFormat(receivedDate)}
            />

            {/* 合計金額、会社情報 */}
            <HStack mt={6} w="full" justify="space-between" align="flex-start">
              <Box w="320px">
                <SalesOrdersPreviewTotalPrice
                  demandName={demand.name}
                  totalPrice={calculateTotalAmount(detailsForCalculation)}
                />
              </Box>
              <Box w="320px">
                <InformationShizai />
              </Box>
            </HStack>

            {/* 明細 */}
            <SalesOrdersPreviewDetails details={details} />

            {/* 備考、合計金額 */}
            <HStack mt={4} w="full" justify="space-between" align="flex-start" spacing={8}>
              <VStack fontSize="sm" spacing={1} alignItems="flex-start">
                <Text>備考</Text>
                <Text whiteSpace="pre-wrap">{detailMessage}</Text>
              </VStack>
              <Box w="240px">
                <SalesOrdersPreviewPrices
                  subtotalAmount={calculateSubtotalAmount(detailsForCalculation)}
                  totalTax={calculateTotalTaxAmount(detailsForCalculation)}
                  totalAmount={calculateTotalAmount(detailsForCalculation)}
                />
              </Box>
            </HStack>
          </VStack>

          <Box mt={6} w="full">
            <Button colorScheme="blue" w="full" type="submit" isLoading={isMutationInFlight}>
              更新
            </Button>
          </Box>
        </form>
      </Suspense>
    </Box>
  );
};

const getDetails = (details: SalesOrdersEditFormDetailType[]) => {
  return details
    .map((detail): UpdateSalesOrderDetailInputV2 | undefined => {
      if (detail.type === SalesOrderDetailType.Item) {
        const detailItem = detail as SalesOrdersEditFormDetailItemType;
        return {
          id: detailItem.detailId,
          memo: detailItem.memo ?? '',
          type: SalesOrderDetailType.Item,
          fixedDeliveryDate: detailItem.fixedDeliveryDate
            ? cdateJST(detailItem.fixedDeliveryDate).toDate()
            : null,
          specByType: {
            item: {
              demandItemID: detailItem.itemId || undefined,
              name: detailItem.name,
              quantity: Number(detailItem.quantity) || 0,
              unitPrice: Number(detailItem.unitPrice) || 0,
              unitSellingPrice: Number(detailItem.unitSellingPrice) || 0,
              taxCategoryID: detailItem.tax.id,
            },
          },
        };
      }

      if (detail.type === SalesOrderDetailType.RecurringCost) {
        const detailCost = detail as SalesOrdersEditFormDetailCostType;
        return {
          id: detailCost.detailId,
          memo: detailCost.memo ?? '',
          type: SalesOrderDetailType.RecurringCost,
          fixedDeliveryDate: detailCost.fixedDeliveryDate
            ? cdateJST(detailCost.fixedDeliveryDate).toDate()
            : null,
          specByType: {
            recurringCost: {
              demandRecurringCostID: detailCost.costId ?? '',
              quantity: Number(detailCost.quantity) || 0,
              unitPrice: Number(detailCost.unitPrice) || 0,
              unitSellingPrice: Number(detailCost.unitSellingPrice) || 0,
              taxCategoryID: detailCost.tax.id,
            },
          },
        };
      }

      if (detail.type === SalesOrderDetailType.OnetimeCost) {
        const detailCost = detail as SalesOrdersEditFormDetailCostType;
        return {
          id: detailCost.detailId,
          memo: detailCost.memo ?? '',
          type: SalesOrderDetailType.OnetimeCost,
          fixedDeliveryDate: detailCost.fixedDeliveryDate
            ? cdateJST(detailCost.fixedDeliveryDate).toDate()
            : null,
          specByType: {
            onetimeCost: {
              name: detailCost.name,
              note: detailCost.note ?? '',
              quantity: Number(detailCost.quantity) || 0,
              unitPrice: Number(detailCost.unitPrice) || 0,
              unitSellingPrice: Number(detailCost.unitSellingPrice) || 0,
              taxCategoryID: detailCost.tax.id,
            },
          },
        };
      }
    })
    .filter((detail) => detail !== undefined);
};
