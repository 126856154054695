/**
 * @generated SignedSource<<372cb7317807e7df4203722ab9026b0e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EstimationDetailDocumentDisplayStatus = "hidden" | "shown" | "%future added value";
export type EstimationDetailType = "cost" | "item" | "text" | "%future added value";
export type ItemCategory = "cardboard" | "flexiblePackage" | "giftBox" | "other" | "paperBag" | "unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EstimationDetailPreviewFragment$data = {
  readonly createdAt: any;
  readonly demand: {
    readonly name: string;
  };
  readonly detailMessage: string | null;
  readonly expirationPeriod: string;
  readonly id: string;
  readonly previewDetails: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly cost: {
          readonly name: string;
          readonly note: string;
          readonly quantity: any;
          readonly taxCategory: {
            readonly id: string;
            readonly rate: number;
          };
          readonly unitPrice: number;
          readonly unitSellingPrice: number;
        } | null;
        readonly documentDisplayStatus: EstimationDetailDocumentDisplayStatus;
        readonly item: {
          readonly cardboard: {
            readonly id: string;
            readonly material: string;
            readonly other: string;
            readonly printingColor: string;
            readonly processing: string;
            readonly size: string;
            readonly thickness: string;
            readonly type: string;
          } | null;
          readonly category: ItemCategory | null;
          readonly flexiblePackage: {
            readonly id: string;
            readonly material: string;
            readonly other: string;
            readonly printingColor: string;
            readonly processing: string;
            readonly size: string;
            readonly type: string;
          } | null;
          readonly giftBox: {
            readonly id: string;
            readonly other: string;
            readonly paperType: string;
            readonly printingColor: string;
            readonly processing: string;
            readonly size: string;
            readonly type: string;
          } | null;
          readonly name: string;
          readonly other: {
            readonly id: string;
            readonly specText: string;
          } | null;
          readonly paperBag: {
            readonly handle: string;
            readonly id: string;
            readonly other: string;
            readonly paperType: string;
            readonly printingColor: string;
            readonly processing: string;
            readonly size: string;
          } | null;
          readonly quantity: any;
          readonly taxCategory: {
            readonly id: string;
            readonly rate: number;
          };
          readonly unitPrice: number;
          readonly unitSellingPrice: number;
        } | null;
        readonly orderNumber: any;
        readonly type: EstimationDetailType;
      } | null;
    } | null> | null;
  };
  readonly showAmountSummary: boolean;
  readonly " $fragmentType": "EstimationDetailPreviewFragment";
};
export type EstimationDetailPreviewFragment$key = {
  readonly " $data"?: EstimationDetailPreviewFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EstimationDetailPreviewFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitPrice",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitSellingPrice",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "TaxCategory",
  "kind": "LinkedField",
  "name": "taxCategory",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rate",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "material",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "printingColor",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "processing",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "other",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paperType",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EstimationDetailPreviewFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "demand",
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "detailMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expirationPeriod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showAmountSummary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": "previewDetails",
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "direction": "ASC",
            "field": "ORDER_NUMBER"
          }
        },
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "documentDisplayStatus": "shown"
          }
        }
      ],
      "concreteType": "EstimationDetailConnection",
      "kind": "LinkedField",
      "name": "details",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EstimationDetailEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EstimationDetail",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "orderNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "documentDisplayStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EstimationCost",
                  "kind": "LinkedField",
                  "name": "cost",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "note",
                      "storageKey": null
                    },
                    (v3/*: any*/),
                    (v4/*: any*/),
                    (v5/*: any*/),
                    (v6/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EstimationItem",
                  "kind": "LinkedField",
                  "name": "item",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "category",
                      "storageKey": null
                    },
                    (v6/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/),
                    (v5/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "EstimationItemCardboard",
                      "kind": "LinkedField",
                      "name": "cardboard",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v7/*: any*/),
                        (v2/*: any*/),
                        (v8/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "thickness",
                          "storageKey": null
                        },
                        (v9/*: any*/),
                        (v10/*: any*/),
                        (v11/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "EstimationItemFlexiblePackage",
                      "kind": "LinkedField",
                      "name": "flexiblePackage",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v7/*: any*/),
                        (v2/*: any*/),
                        (v8/*: any*/),
                        (v9/*: any*/),
                        (v10/*: any*/),
                        (v11/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "EstimationItemGiftBox",
                      "kind": "LinkedField",
                      "name": "giftBox",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v7/*: any*/),
                        (v2/*: any*/),
                        (v12/*: any*/),
                        (v9/*: any*/),
                        (v10/*: any*/),
                        (v11/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "EstimationItemPaperBag",
                      "kind": "LinkedField",
                      "name": "paperBag",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v7/*: any*/),
                        (v12/*: any*/),
                        (v9/*: any*/),
                        (v10/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "handle",
                          "storageKey": null
                        },
                        (v11/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "EstimationItemOther",
                      "kind": "LinkedField",
                      "name": "other",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "specText",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "details(orderBy:{\"direction\":\"ASC\",\"field\":\"ORDER_NUMBER\"},where:{\"documentDisplayStatus\":\"shown\"})"
    }
  ],
  "type": "Estimation",
  "abstractKey": null
};
})();

(node as any).hash = "0396a0c2f20ae4af35be026d5dc719c8";

export default node;
