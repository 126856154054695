import { DEMAND_ITEM_CATEGORY, DEMAND_ITEM_CATEGORY_LABEL } from 'features/demandItem/models';
import {
  errorMessages,
  quantitySchema,
  unitPriceSchema,
  unitSellingPriceSchema,
} from 'utils/validation';
import { z } from 'zod';
import { salesOrdersFormSpecSchema } from './common-schema';

export const SALES_ORDER_DETAIL_CATEGORY_COST = 'cost';

export const SALES_ORDER_DETAIL_CATEGORIES = {
  [DEMAND_ITEM_CATEGORY.Cardboard]:
    DEMAND_ITEM_CATEGORY_LABEL[DEMAND_ITEM_CATEGORY.Cardboard].categoryName,
  [DEMAND_ITEM_CATEGORY.FlexiblePackage]:
    DEMAND_ITEM_CATEGORY_LABEL[DEMAND_ITEM_CATEGORY.FlexiblePackage].categoryName,
  [DEMAND_ITEM_CATEGORY.GiftBox]:
    DEMAND_ITEM_CATEGORY_LABEL[DEMAND_ITEM_CATEGORY.GiftBox].categoryName,
  [DEMAND_ITEM_CATEGORY.PaperBag]:
    DEMAND_ITEM_CATEGORY_LABEL[DEMAND_ITEM_CATEGORY.PaperBag].categoryName,
  [DEMAND_ITEM_CATEGORY.Other]: DEMAND_ITEM_CATEGORY_LABEL[DEMAND_ITEM_CATEGORY.Other].categoryName,
  [SALES_ORDER_DETAIL_CATEGORY_COST]: '費用',
} as const;

export const MASTER_REGISTRATION_SETTING_ITEM_TYPE_NEW = 'new';
export const MASTER_REGISTRATION_SETTING_ITEM_TYPE_UPDATE = 'update';

export const MASTER_REGISTRATION_SETTING_ITEM_TYPE = {
  [MASTER_REGISTRATION_SETTING_ITEM_TYPE_NEW]: '新規',
  [MASTER_REGISTRATION_SETTING_ITEM_TYPE_UPDATE]: '仕様変更',
} as const;

export const MASTER_REGISTRATION_SETTING_COST_TYPE_RECURRING = 'recurring';
export const MASTER_REGISTRATION_SETTING_COST_TYPE_ONE_TIME = 'oneTime';

export const MASTER_REGISTRATION_SETTING_COST_TYPE = {
  [MASTER_REGISTRATION_SETTING_COST_TYPE_ONE_TIME]: '単発費用',
  [MASTER_REGISTRATION_SETTING_COST_TYPE_RECURRING]: '継続費用',
} as const;

const salesOrderDetailSpecCostSchema = z.object({
  cost: z
    .object({
      note: z.string(),
    })
    .optional(),
});

const salesOrderDetailSchema = z.object({
  /** NOTE: 行更新時はIDを指定する */
  id: z.string().optional(),
  estimationDetailID: z.string(),
  name: z
    .string()
    .min(1, { message: errorMessages.REQUIRED })
    .max(1000, { message: errorMessages.MAX_LENGTH(1000) }),
  unitPrice: unitPriceSchema(),
  unitSellingPrice: unitSellingPriceSchema,
  quantity: quantitySchema(),
  tax: z.object({
    id: z.string(),
    rate: z.union([z.string(), z.number()]),
    name: z.string(),
  }),
  fixedDeliveryDate: z.string().optional(),
  category: z.enum(Object.keys(SALES_ORDER_DETAIL_CATEGORIES) as [string, ...string[]], {
    errorMap: () => ({ message: '選択してください' }),
  }),
  spec: salesOrdersFormSpecSchema.merge(salesOrderDetailSpecCostSchema),
  memo: z
    .string()
    .max(500, { message: errorMessages.MAX_LENGTH(500) })
    .optional(),
});

const masterRegistrationItemSchema = z.object({
  demandItem: z
    .object({
      id: z.string(),
      name: z.string(),
      version: z.string(),
      itemNumber: z.string(),
      category: z
        .enum(Object.keys(SALES_ORDER_DETAIL_CATEGORIES) as [string, ...string[]])
        .optional(),
      demandCode: z.string(),
    })
    .optional(),
  estimationDetailIds: z.array(z.string()),
  name: z
    .string()
    .min(1, { message: errorMessages.REQUIRED })
    .max(1000, { message: errorMessages.MAX_LENGTH(1000) }),
  category: z.enum(Object.keys(SALES_ORDER_DETAIL_CATEGORIES) as [string, ...string[]], {
    errorMap: () => ({ message: '選択してください' }),
  }),
  spec: salesOrdersFormSpecSchema,
  prices: z.array(
    z.object({
      unitPrice: unitPriceSchema(),
      unitSellingPrice: unitSellingPriceSchema,
      quantity: quantitySchema(),
    }),
  ),
  tax: z.object({
    id: z.string(),
    rate: z.union([z.string(), z.number()]),
    name: z.string(),
  }),
  type: z
    .enum(['', ...(Object.keys(MASTER_REGISTRATION_SETTING_ITEM_TYPE) as [string, ...string[]])], {
      errorMap: () => ({ message: '選択しましょう' }),
    })
    .superRefine((val, ctx) => {
      // マスタ登録画面でのみバリデーションを行う
      const isOnMasterRegistrationSettingPage =
        typeof window !== 'undefined' &&
        window.location.pathname.includes('/new-sales-order/master-registration-setting');

      if (!isOnMasterRegistrationSettingPage) return;

      if (val === undefined || val === '') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: '選択しましょう',
        });
      }
    }),
});

const masterRegistrationCostSchema = z.object({
  estimationDetailIds: z.array(z.string()),
  name: z
    .string()
    .min(1, { message: errorMessages.REQUIRED })
    .max(1000, { message: errorMessages.MAX_LENGTH(1000) }),
  note: z.string(),
  memo: z.string().optional(),
  prices: z.array(
    z.object({
      unitPrice: unitPriceSchema(),
      unitSellingPrice: unitSellingPriceSchema,
      quantity: quantitySchema(),
    }),
  ),
  tax: z.object({
    id: z.string(),
    rate: z.union([z.string(), z.number()]),
    name: z.string(),
  }),
  type: z
    .enum(['', ...(Object.keys(MASTER_REGISTRATION_SETTING_COST_TYPE) as [string, ...string[]])], {
      errorMap: () => ({ message: '選択しましょう' }),
    })
    .superRefine((val, ctx) => {
      // マスタ登録画面でのみバリデーションを行う
      const isOnMasterRegistrationSettingPage =
        typeof window !== 'undefined' &&
        window.location.pathname.includes('/new-sales-order/master-registration-setting');

      if (!isOnMasterRegistrationSettingPage) return;

      if (val === undefined || val === '') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: '選択しましょう',
        });
      }
    }),
});

export const newSalesOrderFormSchema = z.object({
  demand: z.object({
    id: z.string(),
    name: z.string().min(1, { message: errorMessages.REQUIRED }),
  }),
  supplier: z.object({
    id: z.string(),
    name: z.string().min(1, { message: errorMessages.REQUIRED }),
  }),
  title: z
    .string()
    .min(1, { message: errorMessages.REQUIRED })
    .max(70, { message: errorMessages.MAX_LENGTH(70) }),
  detailMessage: z.string().max(2000, { message: errorMessages.MAX_LENGTH(2000) }),
  estimationID: z.string(),
  receivedDate: z.string(),
  details: z.array(salesOrderDetailSchema),
  internalAssignees: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      }),
    )
    .min(1, { message: '担当者を選択しましょう' }),
  memo: z
    .string()
    .max(500, { message: errorMessages.MAX_LENGTH(500) })
    .optional(),
  masterRegistrationItems: z.array(masterRegistrationItemSchema).optional(),
  masterRegistrationCosts: z.array(masterRegistrationCostSchema).optional(),
});

export type NewSalesOrderFormType = z.infer<typeof newSalesOrderFormSchema>;

export type NewSalesOrderDetailFormType = z.infer<typeof salesOrderDetailSchema>;

export type NewSalesOrderDetailCostFormType = z.infer<typeof salesOrderDetailSpecCostSchema>;

export type NewSalesOrderMasterRegistrationItemFormType = z.infer<
  typeof masterRegistrationItemSchema
>;

export type NewSalesOrderMasterRegistrationCostFormType = z.infer<
  typeof masterRegistrationCostSchema
>;
