/**
 * @generated SignedSource<<09369f1700c2ecbdd8af5b3bb2c2463f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ItemCategory = "cardboard" | "flexiblePackage" | "giftBox" | "other" | "paperBag" | "unknown" | "%future added value";
export type DemandItemUpdateInput = {
  attachments?: ReadonlyArray<DemandItemAttachmentUpdateInput> | null;
  category: ItemCategory;
  endedAt?: any | null;
  images?: ReadonlyArray<DemandItemImageUpdateInput> | null;
  internalMemo?: string | null;
  name: string;
  prices: ReadonlyArray<DemandItemPriceInput>;
  spec: DemandItemSpecInput;
  startedAt?: any | null;
  supplierID: string;
  taxCategoryID: string;
};
export type DemandItemAttachmentUpdateInput = {
  file?: UploadFile | null;
  id?: string | null;
};
export type UploadFile = {
  file: any;
};
export type DemandItemImageUpdateInput = {
  displayOrder: number;
  id?: string | null;
  objectName: string;
};
export type DemandItemPriceInput = {
  quantity: number;
  unitPrice: number;
  unitSellingPrice: number;
};
export type DemandItemSpecInput = {
  cardboard?: DemandItemCardboardSpecInput | null;
  flexiblePackage?: DemandItemFlexiblePackageSpecInput | null;
  giftBox?: DemandItemGiftBoxSpecInput | null;
  other?: DemandItemOtherSpecInput | null;
  paperBag?: DemandItemPaperBagSpecInput | null;
};
export type DemandItemCardboardSpecInput = {
  material: string;
  other: string;
  printingColor: string;
  processing: string;
  size: string;
  thickness: string;
  type: string;
};
export type DemandItemFlexiblePackageSpecInput = {
  material: string;
  other: string;
  printingColor: string;
  processing: string;
  size: string;
  type: string;
};
export type DemandItemGiftBoxSpecInput = {
  other: string;
  paperType: string;
  printingColor: string;
  processing: string;
  size: string;
  type: string;
};
export type DemandItemOtherSpecInput = {
  specText: string;
};
export type DemandItemPaperBagSpecInput = {
  handle: string;
  other: string;
  paperType: string;
  printingColor: string;
  processing: string;
  size: string;
};
export type confirm_DemandItemEditConfirmPageMutation$variables = {
  id: string;
  input: DemandItemUpdateInput;
  keepVersion: boolean;
};
export type confirm_DemandItemEditConfirmPageMutation$data = {
  readonly updateDemandItem: {
    readonly id: string;
  };
};
export type confirm_DemandItemEditConfirmPageMutation = {
  response: confirm_DemandItemEditConfirmPageMutation$data;
  variables: confirm_DemandItemEditConfirmPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "keepVersion"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      },
      {
        "kind": "Variable",
        "name": "keepVersion",
        "variableName": "keepVersion"
      }
    ],
    "concreteType": "DemandItem",
    "kind": "LinkedField",
    "name": "updateDemandItem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "confirm_DemandItemEditConfirmPageMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "confirm_DemandItemEditConfirmPageMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "63054b230151cb77189241703285b102",
    "id": null,
    "metadata": {},
    "name": "confirm_DemandItemEditConfirmPageMutation",
    "operationKind": "mutation",
    "text": "mutation confirm_DemandItemEditConfirmPageMutation(\n  $id: ID!\n  $keepVersion: Boolean!\n  $input: DemandItemUpdateInput!\n) {\n  updateDemandItem(id: $id, keepVersion: $keepVersion, input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1e988df2241438bd3711d98ddf3d7e6f";

export default node;
