import { Box, HStack, Image } from '@chakra-ui/react';
import { DemandItemFormImage } from 'features/demandItem/form';

type DemandItemConfirmImagePreviewProps = {
  images: DemandItemFormImage[];
};

export const DemandItemConfirmImagePreview = ({ images }: DemandItemConfirmImagePreviewProps) => {
  return (
    <HStack flexWrap="wrap" gap={2} maxWidth="572px" justifyContent="flex-start">
      {images
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map((image) => (
          <Box
            key={image.objectName}
            w="108px"
            h="108px"
            position="relative"
            bgColor="gray.50"
            rounded="lg"
          >
            <Image
              src={image.url}
              alt="商品写真"
              objectFit="contain"
              w="100%"
              h="100%"
              rounded="lg"
            />
          </Box>
        ))}
    </HStack>
  );
};
