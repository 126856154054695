import { zodResolver } from '@hookform/resolvers/zod';
import {} from 'features/demandItem/models';
import {
  DETAIL_MESSAGE_TEMPLATE,
  SalesOrdersEditFormType,
  salesOrdersEditFormSchema,
} from 'features/salesOrdersV2/form';
import {
  layout_SalesOrdersEditLayoutQuery,
  layout_SalesOrdersEditLayoutQuery$data,
} from 'gql/__generated__/layout_SalesOrdersEditLayoutQuery.graphql';
import { FormProvider, useForm } from 'react-hook-form';
import { useLazyLoadQuery } from 'react-relay';
import { Outlet, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { dateFormatHyphenDate } from 'utils/date';
import { MissingRequiredParamError } from 'utils/error';
import { concatFullName } from 'utils/label';
import { buildSalesOrdersEditFormDefaultDetails } from './helper';

const salesOrdersEditLayoutQuery = graphql`
  query layout_SalesOrdersEditLayoutQuery($id: ID!) {
    salesOrders(where: {id: $id}) {
      edges {
        node {
          id
          transactionID
          demand {
            id
            name
            code
          }
          supplier {
            id
            name
          }
          title
          receivedDate
          details(orderBy: {direction: ASC, field: ORDER_NUMBER}) {
            edges {
              node {
                id
                type
                branchNumber
                memos {
                  body
                }
                deliveryEvents(where: {isValid: true}) {
                  edges {
                    node {
                      fixedDeliveryDate
                    }
                  }
                }
                item {
                  id
                  name
                  category
                  quantity
                  unitPrice
                  unitSellingPrice
                  tax: taxCategory {
                    id
                    rate
                  }
                  demandItem {
                    id
                    itemNumber
                    version
                    category
                    supplier {
                      id
                      name
                    }
                  }
                  cardboard {
                    size
                    type
                    material
                    thickness
                    printingColor
                    processing
                    other
                  }
                  flexiblePackage {
                    size
                    type
                    material
                    printingColor
                    processing
                    other
                  }
                  giftBox {
                    size
                    type
                    paperType
                    printingColor
                    processing
                    other
                  }
                  paperBag {
                    size
                    paperType
                    printingColor
                    processing
                    handle
                    other
                  }
                  other {
                    specText
                  }
                }
                recurringCost {
                  id
                  name
                  note
                  quantity
                  unitPrice
                  unitSellingPrice
                  tax: taxCategory {
                    id
                    rate
                  }
                  demandRecurringCost {
                    id
                    costNumber
                    version
                    supplier {
                      id
                      name
                    }
                  }
                }
                onetimeCost {
                  id
                  name
                  note
                  quantity
                  unitPrice
                  unitSellingPrice
                  tax: taxCategory {
                    id
                    rate
                  }
                }
              }
            }
          }
          detailMessage
          internalMemos {
            body
          }
          internalAssignees {
            user {
              id
              profile {
                lastName
                firstName
              }
            }
          }
        }
      }
    }
    currentUser {
      userID
      firstName
      lastName
    }
  }
`;

export type SalesOrdersEditLayoutQueryEdgesType = NonNullable<
  NonNullable<layout_SalesOrdersEditLayoutQuery$data['salesOrders']>['edges']
>[0];
export type SalesOrdersEditLayoutQueryNodeType =
  NonNullable<SalesOrdersEditLayoutQueryEdgesType>['node'];
export type SalesOrdersEditLayoutQueryDetailEdgesType = NonNullable<
  NonNullable<NonNullable<SalesOrdersEditLayoutQueryNodeType>['details']>['edges']
>[0];
export type SalesOrdersEditLayoutQueryDetailNodeType =
  NonNullable<SalesOrdersEditLayoutQueryDetailEdgesType>['node'];
export type SalesOrdersEditLayoutQueryDetailItemType =
  NonNullable<SalesOrdersEditLayoutQueryDetailNodeType>['item'];

export const SalesOrdersEditLayout = () => {
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const { salesOrders } = useLazyLoadQuery<layout_SalesOrdersEditLayoutQuery>(
    salesOrdersEditLayoutQuery,
    { id },
    { fetchPolicy: 'network-only' },
  );

  const salesOrder = salesOrders?.edges?.[0]?.node;
  const demand = salesOrder?.demand;
  const supplier = salesOrder?.supplier;

  const form = useForm<SalesOrdersEditFormType>({
    resolver: zodResolver(salesOrdersEditFormSchema),
    defaultValues: {
      salesOrderId: salesOrder?.id,
      demand: { id: demand?.id, name: demand?.name, code: demand?.code },
      supplier: { id: supplier?.id, name: supplier?.name },
      title: salesOrder?.title,
      receivedDate: dateFormatHyphenDate(salesOrder?.receivedDate),
      details: buildSalesOrdersEditFormDefaultDetails(salesOrder),
      detailMessage: salesOrder?.detailMessage ?? DETAIL_MESSAGE_TEMPLATE,
      memo: salesOrder?.internalMemos?.map((memo) => memo?.body)?.[0],
      internalAssignees:
        salesOrder?.internalAssignees?.map((assignee) => ({
          value: assignee?.user?.id,
          label: concatFullName({
            lastName: assignee?.user?.profile?.[0]?.lastName ?? '',
            firstName: assignee?.user?.profile?.[0]?.firstName ?? '',
          }),
        })) ?? [],
    },
  });

  return (
    <FormProvider {...form}>
      <Outlet />
    </FormProvider>
  );
};
