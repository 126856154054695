import { Box, Button, Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { ArrowRight, EfferentFour, Notes } from '@icon-park/react';
import { Link } from 'components/Link';
import { RouteLinkWithReferrer } from 'components/RouteLinkWithReferrer';
import {
  DemandItemDetailBlock,
  DemandItemDetailBlockBody,
  DemandItemDetailBlockInfo,
  DemandItemDetailBlockSpecTableContainer,
} from 'features/demandItem/components/DemandItemDetailBlock';
import { DemandItemDetailBlockNoImageThumbnail } from 'features/demandItem/components/DemandItemDetailBlock/DemandItemDetailBlockNoImageThumbnail';
import { DemandItemDetailBlockThumbnail } from 'features/demandItem/components/DemandItemDetailBlock/DemandItemDetailBlockThumbnail';
import { DemandItemPriceTable } from 'features/demandItem/components/DemandItemPriceTable';
import {
  DEMAND_ITEM_CATEGORY_LABEL,
  DemandItemCategory,
  buildDemandItemCode,
} from 'features/demandItem/models';
import { paths } from 'utils/paths';
import { SalesOrdersDetailMasterDataDemandItemType } from '.';

type Props = {
  item: SalesOrdersDetailMasterDataDemandItemType;
  thumbnailUrl: string | undefined;
  onClickThumbnail: React.MouseEventHandler<HTMLDivElement>;
};

export const SalesOrdersDetailPageMasterDataItem = ({
  item,
  thumbnailUrl,
  onClickThumbnail,
}: Props) => {
  const imageCount = item.images?.length ?? 0;
  const latestOrder =
    item.salesOrderItems.edges?.[0]?.node?.salesOrderDetail?.salesOrder?.orders?.[0];

  const detailPath = paths.demands
    ._demandCode(item.demand.code)
    .items._itemCode({
      category: item.category as DemandItemCategory,
      itemNumber: item.itemNumber,
    })
    .url();

  const getDetailBlockInfoValues = () => {
    const values: { label: string; node: React.ReactNode }[] = [
      {
        label: 'コード',
        node:
          buildDemandItemCode({
            demandCode: item.demand?.code || '',
            category: item.category as DemandItemCategory,
            itemNumber: item.itemNumber,
            version: item.version,
          }) || '-',
      },
      {
        label: 'サプライヤー',
        node: item.supplier?.name || '-',
      },
      {
        label: 'カテゴリー',
        node: DEMAND_ITEM_CATEGORY_LABEL[item.category as DemandItemCategory].categoryName || '-',
      },
      {
        label: '最終発注',
        node: latestOrder ? (
          <Link to={paths.order._id(latestOrder.id)} target="_blank">
            <HStack gap={1}>
              <Box>{latestOrder.title}</Box>
              <EfferentFour theme="outline" />
            </HStack>
          </Link>
        ) : (
          '-'
        ),
      },
      {
        label: '社内メモ',
        node: item.internalMemos?.[0]?.body || '-',
      },
    ];

    if (item.attachments && item.attachments.length > 0) {
      values.push({
        label: 'その他のデータ',
        node: (
          <VStack spacing={1} alignItems="flex-start">
            {Array.from(item.attachments).map((attachment, index) => (
              <HStack
                key={index}
                w="fit-content"
                maxW="full"
                minH="24px"
                spacing={1}
                alignItems="flex-start"
              >
                <Icon mt="4.5px" w={4} h={4} size={16} as={Notes} />
                <Text fontSize="sm">{attachment.fileName}</Text>
              </HStack>
            ))}
          </VStack>
        ),
      });
    }

    return values;
  };

  return (
    <DemandItemDetailBlock key={item.id}>
      <DemandItemDetailBlockSpecTableContainer queryRef={item} />
      <DemandItemDetailBlockBody>
        <HStack spacing={4} alignItems="flex-start">
          {imageCount > 0 ? (
            <Box onClick={onClickThumbnail} cursor="pointer">
              <DemandItemDetailBlockThumbnail thumbnailUrl={thumbnailUrl} imageCount={imageCount} />
            </Box>
          ) : (
            <DemandItemDetailBlockNoImageThumbnail />
          )}
          <DemandItemDetailBlockInfo values={getDetailBlockInfoValues()} />
        </HStack>
        <Box w="382px">
          <DemandItemPriceTable
            values={
              item.prices?.map((price) => ({
                id: price.id,
                quantity: price.quantity,
                unitPrice: price.unitPrice,
                unitSellingPrice: price.unitSellingPrice,
              })) || []
            }
            tax={item.tax.name}
          />
        </Box>
      </DemandItemDetailBlockBody>
      <Flex w="full" pr={4} justifyContent="flex-end">
        <Button
          colorScheme="blue"
          rightIcon={<ArrowRight />}
          as={RouteLinkWithReferrer}
          to={detailPath}
        >
          詳細
        </Button>
      </Flex>
    </DemandItemDetailBlock>
  );
};
