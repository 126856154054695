/**
 * @generated SignedSource<<5d18cdae62aa0ecb81d74bff07699bd7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CompanyInvoiceRecipientRecipientType = "cc" | "to" | "%future added value";
export type UpdateDemandInput = {
  invoiceSendRecipients?: ReadonlyArray<InvoiceSendRecipientInput> | null;
  memo?: string | null;
  name: string;
};
export type InvoiceSendRecipientInput = {
  email: string;
  recipientType: CompanyInvoiceRecipientRecipientType;
};
export type edit_DemandsDetailEditPageMutation$variables = {
  id: string;
  input: UpdateDemandInput;
};
export type edit_DemandsDetailEditPageMutation$data = {
  readonly updateDemand: {
    readonly id: string;
  };
};
export type edit_DemandsDetailEditPageMutation = {
  response: edit_DemandsDetailEditPageMutation$data;
  variables: edit_DemandsDetailEditPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Company",
    "kind": "LinkedField",
    "name": "updateDemand",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "edit_DemandsDetailEditPageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "edit_DemandsDetailEditPageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e33984f0da1801e501e95fc02e0a2239",
    "id": null,
    "metadata": {},
    "name": "edit_DemandsDetailEditPageMutation",
    "operationKind": "mutation",
    "text": "mutation edit_DemandsDetailEditPageMutation(\n  $id: ID!\n  $input: UpdateDemandInput!\n) {\n  updateDemand(id: $id, input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "40b458b2ddc35355ffb16b8acc354229";

export default node;
