/**
 * @generated SignedSource<<e8b01b7c7b4dfaa46052e994343a3586>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ItemCategory = "cardboard" | "flexiblePackage" | "giftBox" | "other" | "paperBag" | "unknown" | "%future added value";
export type MasterRegistrationSettingModalQuery$variables = {
  category: ItemCategory;
  demandId: string;
};
export type MasterRegistrationSettingModalQuery$data = {
  readonly demandItems: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly category: ItemCategory;
        readonly demand: {
          readonly code: string;
        };
        readonly id: string;
        readonly itemNumber: any;
        readonly name: string;
        readonly salesOrderItems: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly salesOrderDetail: {
                readonly salesOrder: {
                  readonly orders: ReadonlyArray<{
                    readonly createdAt: any;
                    readonly id: string;
                  }> | null;
                };
              };
            } | null;
          } | null> | null;
        };
        readonly version: any;
      } | null;
    } | null> | null;
  };
};
export type MasterRegistrationSettingModalQuery = {
  response: MasterRegistrationSettingModalQuery$data;
  variables: MasterRegistrationSettingModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "category"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "demandId"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "category",
        "variableName": "category"
      },
      {
        "kind": "Variable",
        "name": "demandID",
        "variableName": "demandId"
      },
      {
        "kind": "Literal",
        "name": "isCurrent",
        "value": true
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemNumber",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "version",
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "direction": "DESC",
      "field": "CREATED_AT"
    }
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "hasSalesOrderDetailWith": {
        "deletedAtIsNil": true,
        "hasSalesOrderWith": {
          "deletedAtIsNil": true,
          "hasOrdersWith": {
            "deletedAtIsNil": true
          }
        }
      }
    }
  }
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Order",
  "kind": "LinkedField",
  "name": "orders",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MasterRegistrationSettingModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DemandItemConnection",
        "kind": "LinkedField",
        "name": "demandItems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DemandItemEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DemandItem",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "demand",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": (v9/*: any*/),
                    "concreteType": "SalesOrderItemConnection",
                    "kind": "LinkedField",
                    "name": "salesOrderItems",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SalesOrderItemEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SalesOrderItem",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SalesOrderDetail",
                                "kind": "LinkedField",
                                "name": "salesOrderDetail",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SalesOrder",
                                    "kind": "LinkedField",
                                    "name": "salesOrder",
                                    "plural": false,
                                    "selections": [
                                      (v10/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "salesOrderItems(first:1,orderBy:{\"direction\":\"DESC\",\"field\":\"CREATED_AT\"},where:{\"hasSalesOrderDetailWith\":{\"deletedAtIsNil\":true,\"hasSalesOrderWith\":{\"deletedAtIsNil\":true,\"hasOrdersWith\":{\"deletedAtIsNil\":true}}}})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MasterRegistrationSettingModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DemandItemConnection",
        "kind": "LinkedField",
        "name": "demandItems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DemandItemEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DemandItem",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "demand",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": (v9/*: any*/),
                    "concreteType": "SalesOrderItemConnection",
                    "kind": "LinkedField",
                    "name": "salesOrderItems",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SalesOrderItemEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SalesOrderItem",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SalesOrderDetail",
                                "kind": "LinkedField",
                                "name": "salesOrderDetail",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SalesOrder",
                                    "kind": "LinkedField",
                                    "name": "salesOrder",
                                    "plural": false,
                                    "selections": [
                                      (v10/*: any*/),
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "salesOrderItems(first:1,orderBy:{\"direction\":\"DESC\",\"field\":\"CREATED_AT\"},where:{\"hasSalesOrderDetailWith\":{\"deletedAtIsNil\":true,\"hasSalesOrderWith\":{\"deletedAtIsNil\":true,\"hasOrdersWith\":{\"deletedAtIsNil\":true}}}})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3cf529e5c8c719f59cd561d10f6dfd4c",
    "id": null,
    "metadata": {},
    "name": "MasterRegistrationSettingModalQuery",
    "operationKind": "query",
    "text": "query MasterRegistrationSettingModalQuery(\n  $demandId: ID!\n  $category: ItemCategory!\n) {\n  demandItems(where: {demandID: $demandId, category: $category, isCurrent: true}) {\n    edges {\n      node {\n        id\n        name\n        demand {\n          code\n          id\n        }\n        category\n        itemNumber\n        version\n        salesOrderItems(where: {hasSalesOrderDetailWith: {deletedAtIsNil: true, hasSalesOrderWith: {deletedAtIsNil: true, hasOrdersWith: {deletedAtIsNil: true}}}}, orderBy: {direction: DESC, field: CREATED_AT}, first: 1) {\n          edges {\n            node {\n              salesOrderDetail {\n                salesOrder {\n                  orders {\n                    id\n                    createdAt\n                  }\n                  id\n                }\n                id\n              }\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3bc84da615e8f3800bd2c8978029befb";

export default node;
