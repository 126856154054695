import { InputProps } from '@chakra-ui/react';
import { ComboBox, ComboBoxItem } from 'components/ComboBox';
import {
  SupplierComboBoxQuery$data,
  SupplierComboBoxQuery as SupplierComboBoxQueryType,
} from 'gql/__generated__/SupplierComboBoxQuery.graphql';
import { useEffect } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';

const filterByCompanyName = (
  suppliers: SupplierComboBoxQuery$data['suppliers'],
  input: string,
  excludeIds: string[],
) => {
  return (
    suppliers.edges
      ?.filter(
        (edge) =>
          (!input || edge?.node?.name.includes(input)) &&
          !excludeIds.includes(edge?.node?.id || ''),
      )
      .map((e) => ({ id: e?.node?.id || '', name: e?.node?.name || '' })) || []
  );
};

const SupplierComboBoxQuery = graphql`
  query SupplierComboBoxQuery {
    suppliers {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const SupplierComboBox = ({
  onChangeSelected,
  isDisabled = false,
  onClearInput,
  excludeIds = () => [],
  onFetchSuppliers,
  defaultSelectedItem,
  inputProps,
}: {
  onChangeSelected: (value?: ComboBoxItem | null) => void;
  isDisabled?: boolean;
  onClearInput: () => void;
  excludeIds?: () => string[];
  onFetchSuppliers?: (companies: ComboBoxItem[]) => void;
  defaultSelectedItem?: ComboBoxItem;
  inputProps?: Omit<InputProps, 'value'>;
}) => {
  const { suppliers } = useLazyLoadQuery<SupplierComboBoxQueryType>(
    SupplierComboBoxQuery,
    {},
    { fetchPolicy: 'network-only' },
  );

  const items = filterByCompanyName(suppliers, '', excludeIds());

  const handleReset = () => onClearInput();

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    onFetchSuppliers?.(items);
  }, [items]);

  return (
    <ComboBox
      {...inputProps}
      items={items}
      isDisabled={isDisabled}
      onReset={handleReset}
      onChangeSelected={onChangeSelected}
      defaultSelectedItem={defaultSelectedItem}
    />
  );
};
