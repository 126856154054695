/**
 * @generated SignedSource<<c7898a9e8d6252aa74d432a878b2e662>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type edit_DemandItemEditPageQuery$variables = {};
export type edit_DemandItemEditPageQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DemandItemFormFragment">;
};
export type edit_DemandItemEditPageQuery = {
  response: edit_DemandItemEditPageQuery$data;
  variables: edit_DemandItemEditPageQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "edit_DemandItemEditPageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "DemandItemFormFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "edit_DemandItemEditPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TaxCategoryConnection",
        "kind": "LinkedField",
        "name": "taxCategories",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TaxCategoryEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TaxCategory",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a827889603bf8a3ef4aa37478381ef8b",
    "id": null,
    "metadata": {},
    "name": "edit_DemandItemEditPageQuery",
    "operationKind": "query",
    "text": "query edit_DemandItemEditPageQuery {\n  ...DemandItemFormFragment\n}\n\nfragment DemandItemFormFragment on Query {\n  ...TaxSelectBoxFragment\n}\n\nfragment TaxSelectBoxFragment on Query {\n  taxCategories {\n    edges {\n      node {\n        id\n        name\n        rate\n      }\n    }\n  }\n}\n"
  }
};

(node as any).hash = "9f533c554c8a3ac58f90a7ae6b210827";

export default node;
