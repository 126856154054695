import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { ErrorMessage } from 'components/ErrorMessage';
import { CompanyComboBox } from 'features/company/components/CompanyComboBox';
import { NewSalesOrderFormType } from 'features/salesOrdersV2/form';
import { SupplierComboBox } from 'features/supplier/components/SupplierComboBox';
import { UserMultipleSelectBox } from 'features/user/components/UserMultipleSelectBox';
import { NewSalesOrderFormQuery } from 'gql/__generated__/NewSalesOrderFormQuery.graphql';
import { toast } from 'lib/toast';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';
import { NewSalesOrderFormDemandMemo } from './NewSalesOrderFormDemandMemo';
import { NewSalesOrderFormDetailPrices } from './NewSalesOrderFormDetailPrices';
import { NewSalesOrderFormLineItems } from './NewSalesOrderFormLineItems';

type Props = {
  navigateToConfirm: () => void;
  hasRelatedOrder?: boolean;
  hasRelatedInvoice?: boolean;
};

const newSalesOrderFormQuery = graphql`
  query NewSalesOrderFormQuery   {
    ...NewSalesOrderFormLineItemsFragment
  }
`;

export const NewSalesOrderForm = ({
  navigateToConfirm,
  hasRelatedOrder = false,
  hasRelatedInvoice = false,
}: Props) => {
  const query = useLazyLoadQuery<NewSalesOrderFormQuery>(
    newSalesOrderFormQuery,
    {},
    { fetchPolicy: 'network-only' },
  );

  const form = useFormContext<NewSalesOrderFormType>();
  const {
    control,
    getValues,
    register,
    formState: { errors, isSubmitting },
    handleSubmit: onSubmit,
    setValue,
  } = form;

  const handleSubmit = () => navigateToConfirm();
  const handleClearDemandInput = () => setValue('demand', { id: '', name: '' });
  const handleClearSupplierInput = () => setValue('supplier', { id: '', name: '' });

  useEffect(() => {
    const toastId = toast({
      title: '見積書の内容を引用しました',
      status: 'success',
    });

    return () => {
      toast.close(toastId);
    };
  }, []);

  return (
    <>
      <form onSubmit={onSubmit(handleSubmit)}>
        <VStack spacing={6} alignItems="flex-start">
          <HStack gap={4} align="start" w="3xl">
            <FormControl
              isInvalid={!!errors.demand}
              isRequired
              isDisabled={hasRelatedOrder || hasRelatedInvoice}
            >
              <VStack gap={2} align="stretch">
                <Box>
                  <FormLabel>デマンド</FormLabel>
                  <Controller
                    name="demand"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <CompanyComboBox
                        onChangeSelected={(value) => {
                          onChange(value);
                        }}
                        defaultSelectedItem={getValues('demand')}
                        onClearInput={handleClearDemandInput}
                        isDisabled={hasRelatedOrder || hasRelatedInvoice}
                        inputProps={{ size: 'sm', borderRadius: '4px' }}
                      />
                    )}
                  />
                  <ErrorMessage name="company" errors={errors} />
                </Box>

                <NewSalesOrderFormDemandMemo />
              </VStack>
            </FormControl>
            <FormControl isInvalid={!!errors.supplier} isRequired isDisabled={hasRelatedOrder}>
              <FormLabel>サプライヤー</FormLabel>
              <Controller
                name="supplier"
                control={control}
                render={({ field: { onChange } }) => (
                  <SupplierComboBox
                    onChangeSelected={onChange}
                    defaultSelectedItem={getValues('supplier')}
                    onClearInput={handleClearSupplierInput}
                    isDisabled={hasRelatedOrder}
                    inputProps={{ size: 'sm', borderRadius: '4px' }}
                  />
                )}
              />
              <ErrorMessage name="supplier" errors={errors} />
            </FormControl>
          </HStack>

          <HStack spacing={5} align="top" w="3xl">
            <FormControl isInvalid={!!errors.title} isRequired>
              <FormLabel>件名</FormLabel>
              <Input type="string" {...register('title')} size="sm" borderRadius={4} />
              <ErrorMessage name="title" errors={errors} />
            </FormControl>
            <FormControl isInvalid={!!errors.receivedDate} w="10rem" isRequired>
              <FormLabel>受注日</FormLabel>
              <Input type="date" {...register('receivedDate')} size="sm" borderRadius={4} />
              <ErrorMessage name="receivedDate" errors={errors} />
            </FormControl>
          </HStack>

          <NewSalesOrderFormLineItems queryRef={query} />

          <HStack spacing={6} align="flex-start" justifyContent="space-between" w="3xl">
            <VStack w="70%" spacing={4}>
              <FormControl isInvalid={!!errors.detailMessage}>
                <FormLabel>備考</FormLabel>
                <Textarea
                  {...register('detailMessage')}
                  minH="80px"
                  size="sm"
                  borderRadius="4px"
                  rows={10}
                />
                <ErrorMessage name="detailMessage" errors={errors} />
              </FormControl>
              <FormControl isInvalid={!!errors.memo}>
                <FormLabel>社内メモ</FormLabel>
                <Textarea {...register('memo')} minH="80px" size="sm" borderRadius="4px" />
                <ErrorMessage name="memo" errors={errors} />
                <Text color="gray.500" fontSize="sm" mt={1}>
                  初期費用の減額や値引きを行った場合は必ず理由を記載してください
                </Text>
              </FormControl>
              <FormControl isInvalid={!!errors.internalAssignees} isRequired>
                <FormLabel>担当者</FormLabel>
                <Controller
                  name="internalAssignees"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <UserMultipleSelectBox
                      onChange={onChange}
                      defaultValue={getValues('internalAssignees')}
                      menuPlacement="top"
                      size="sm"
                    />
                  )}
                />
                <ErrorMessage name="internalAssignees" errors={errors} />
              </FormControl>
            </VStack>

            <NewSalesOrderFormDetailPrices />
          </HStack>
          <Box w="3xl">
            <Button colorScheme="blue" w="full" type="submit" isLoading={isSubmitting}>
              確認画面へ進む
            </Button>
          </Box>
        </VStack>
      </form>
    </>
  );
};
