import { VStack } from '@chakra-ui/react';
import { NewSalesOrderFormLineItemsFragment$key } from 'gql/__generated__/NewSalesOrderFormLineItemsFragment.graphql';
import { graphql, useFragment } from 'react-relay';
import { NewSalesOrderFormDetails } from './NewSalesOrderFormDetails';

const newSalesOrderFormLineItemsFragment = graphql`
  fragment NewSalesOrderFormLineItemsFragment on Query {
    ...NewSalesOrderFormDetailsFragment
  }
`;

type Props = {
  queryRef: NewSalesOrderFormLineItemsFragment$key;
};

export const NewSalesOrderFormLineItems = ({ queryRef }: Props) => {
  const query = useFragment(newSalesOrderFormLineItemsFragment, queryRef);

  return (
    <>
      <VStack align="start">
        <VStack align="stretch" spacing={0}>
          <NewSalesOrderFormDetails queryRef={query} />
        </VStack>
      </VStack>
    </>
  );
};
