import { InvoiceSalesOrdersSelectFormFragment$data } from 'gql/__generated__/InvoiceSalesOrdersSelectFormFragment.graphql';

export type InvoiceSalesOrdersSelectFormFragmentDetailNode = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<
          NonNullable<
            NonNullable<InvoiceSalesOrdersSelectFormFragment$data['salesOrders']>['edges']
          >[number]
        >['node']
      >['details']
    >['edges']
  >[number]
>['node'];

export type MapToSalesOrderSelectFormType = {
  id: string;
  name: string;
  quantity: number;
  unitSellingPrice: number;
  tax: number;
};

export const mapToSalesOrderSelectForm = (
  salesOrderDetail: InvoiceSalesOrdersSelectFormFragmentDetailNode,
): MapToSalesOrderSelectFormType => {
  if (!salesOrderDetail) {
    throw new Error('Invalid sales order detail');
  }

  if (salesOrderDetail.item) {
    return {
      id: salesOrderDetail.id,
      name: salesOrderDetail.item.name,
      quantity: salesOrderDetail.item.quantity,
      unitSellingPrice: salesOrderDetail.item.unitSellingPrice,
      tax: salesOrderDetail.item.tax.rate,
    };
  }

  if (salesOrderDetail.onetimeCost) {
    return {
      id: salesOrderDetail.id,
      name: salesOrderDetail.onetimeCost.name,
      quantity: salesOrderDetail.onetimeCost.quantity,
      unitSellingPrice: salesOrderDetail.onetimeCost.unitSellingPrice,
      tax: salesOrderDetail.onetimeCost.tax.rate,
    };
  }

  if (salesOrderDetail.recurringCost) {
    return {
      id: salesOrderDetail.id,
      name: salesOrderDetail.recurringCost.name,
      quantity: salesOrderDetail.recurringCost.quantity,
      unitSellingPrice: salesOrderDetail.recurringCost.unitSellingPrice,
      tax: salesOrderDetail.recurringCost.tax.rate,
    };
  }

  throw new Error('Invalid sales order detail');
};
