import { Text, VStack } from '@chakra-ui/react';
import { SubHeading } from 'components/SubHeading';
import { useDemandItemPrimaryImageThumbnails } from 'features/demandItem/api/useDemandItemPrimaryImageThumbnails';
import {} from 'features/demandItem/components/DemandItemDetailBlock';
import { DemandItemImageSlider } from 'features/demandItem/components/DemandItemImageSlider';
import { useDemandItemImageSlider } from 'features/demandItem/hooks/useDemandItemImageSlider';
import {} from 'features/demandItem/models';
import {} from 'features/demandRecurringCost/components/DemandRecurringCostBlock';
import { SALES_ORDER_DETAIL_TYPE } from 'features/salesOrdersV2/models';
import {
  SalesOrdersDetailPageMasterDataFragment$data,
  SalesOrdersDetailPageMasterDataFragment$key,
} from 'gql/__generated__/SalesOrdersDetailPageMasterDataFragment.graphql';
import { useEffect } from 'react';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { SalesOrdersDetailPageMasterDataItem } from './SalesOrdersDetailPageMasterDataItem';
import { SalesOrdersDetailPageMasterDataRecurringCost } from './SalesOrdersDetailPageMasterDataRecurringCost';

const SalesOrdersDetailPageMasterDataFragment = graphql`
  fragment SalesOrdersDetailPageMasterDataFragment on SalesOrder {
    details(orderBy: { direction: ASC, field: ORDER_NUMBER }) {
      edges {
        node {
          type
          item {
            demandItem {
              id
              name
              category
              itemNumber
              version
              demand {
                code
              }
              supplier {
                name
              }
              internalMemos {
                body
              }
              prices {
                id
                quantity
                unitPrice
                unitSellingPrice
              }
              images {
                id
                displayOrder
              }
              attachments {
                fileName
              }
              tax:taxCategory {
                id
                name
                rate
              }
              salesOrderItems(
                where: {
                  hasSalesOrderDetailWith: {
                    deletedAtIsNil: true,
                    hasSalesOrderWith: {
                      deletedAtIsNil: true,
                      hasOrdersWith: {
                        deletedAtIsNil: true,
                      },
                    }
                  }
                },
                orderBy: {direction:DESC, field:CREATED_AT},
                first: 1
              ) {
                edges {
                  node {
                    salesOrderDetail {
                      salesOrder {
                        orders {
                          id
                          title
                        }
                      }
                    }
                  }
                }
              }
              ...DemandItemDetailBlockSpecTableContainerFragment
            }
          }
          recurringCost {
            demandRecurringCost {
              id
              name
              note
              costNumber
              demand {
                code
              }
              supplier {
                name
              }
              internalMemos {
                body
              }
              prices {
                id
                quantity
                unitPrice
                unitSellingPrice
              }
              tax: taxCategory {
                id
                name
                rate
              }
              salesOrderRecurringCosts(
                where: {
                  hasSalesOrderDetailWith: {
                    deletedAtIsNil: true,
                    hasSalesOrderWith: {
                      deletedAtIsNil: true,
                      hasOrdersWith: {
                        deletedAtIsNil: true,
                      },
                    }
                  }
                },
                orderBy: {direction:DESC, field:CREATED_AT},
                first: 1
              ) {
                edges {
                  node {
                    salesOrderDetail {
                      salesOrder {
                        orders {
                          id
                          title
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

type Props = {
  queryRef: SalesOrdersDetailPageMasterDataFragment$key;
};

type SalesOrdersDetailMasterDataDetailsType = NonNullable<
  SalesOrdersDetailPageMasterDataFragment$data['details']
>;
type SalesOrdersDetailMasterDataDetailType = NonNullable<
  NonNullable<SalesOrdersDetailMasterDataDetailsType['edges']>[number]
>;
type SalesOrdersDetailMasterDataNodeType = NonNullable<
  SalesOrdersDetailMasterDataDetailType['node']
>;
export type SalesOrdersDetailMasterDataDemandItemType = NonNullable<
  NonNullable<SalesOrdersDetailMasterDataNodeType['item']>['demandItem']
>;
export type SalesOrdersDetailMasterDataRecurringCostType = NonNullable<
  NonNullable<SalesOrdersDetailMasterDataNodeType['recurringCost']>['demandRecurringCost']
>;

export const SalesOrdersDetailPageMasterData = ({ queryRef }: Props) => {
  const { details } = useFragment(SalesOrdersDetailPageMasterDataFragment, queryRef);

  const itemMap = new Map<string, SalesOrdersDetailMasterDataDemandItemType>();
  const recurringCostMap = new Map<string, SalesOrdersDetailMasterDataRecurringCostType>();

  details?.edges?.forEach((detail) => {
    const detailType = detail?.node?.type;

    if (detailType === SALES_ORDER_DETAIL_TYPE.Item) {
      const item = detail?.node?.item?.demandItem;
      if (item) {
        itemMap.set(item.id, item);
      }
    } else if (detailType === SALES_ORDER_DETAIL_TYPE.RecurringCost) {
      const recurringCost = detail?.node?.recurringCost?.demandRecurringCost;
      if (recurringCost) {
        recurringCostMap.set(recurringCost.id, recurringCost);
      }
    }
  });

  const items = Array.from(itemMap.values());
  const recurringCosts = Array.from(recurringCostMap.values());

  const itemsHasImages =
    items.filter((item) => item.images?.length !== undefined && item.images?.length > 0) || [];

  const { data: thumbnails, fetch: fetchThumbnails } = useDemandItemPrimaryImageThumbnails({
    items: itemsHasImages.map((item) => ({
      id: item.id || '',
    })),
  });

  const { selectedItemId, imageCount, isOpenSlider, handleOpenSlider, handleCloseSlider } =
    useDemandItemImageSlider();

  useEffect(() => {
    if (thumbnails) {
      return;
    }
    fetchThumbnails();
  }, [details]);

  // 紐づくマスタデータが無い場合は表示しない
  if (items.length === 0 && recurringCosts.length === 0) {
    return <></>;
  }

  return (
    <VStack w="full" spacing={4} alignItems="flex-start">
      <SubHeading label="マスタ" />

      {items.length > 0 && (
        <>
          {isOpenSlider && (
            <DemandItemImageSlider
              itemId={selectedItemId}
              imageCount={imageCount}
              isOpen={isOpenSlider}
              onClose={handleCloseSlider}
            />
          )}

          <VStack w="full" spacing={4} alignItems="flex-start">
            <Text fontSize="xl" fontWeight="bold">
              商品
            </Text>

            {items.map((item) => {
              const thumbnailUrl = thumbnails?.find((data) => data?.itemID === item.id)?.url;
              return (
                <SalesOrdersDetailPageMasterDataItem
                  key={item.id}
                  item={item}
                  thumbnailUrl={thumbnailUrl}
                  onClickThumbnail={() => handleOpenSlider(item.id, item.images?.length ?? 0)}
                />
              );
            })}
          </VStack>
        </>
      )}

      {recurringCosts.length > 0 && (
        <VStack w="full" spacing={4} alignItems="flex-start">
          <Text fontSize="xl" fontWeight="bold">
            継続費用
          </Text>

          {recurringCosts.map((recurringCost) => {
            return (
              <SalesOrdersDetailPageMasterDataRecurringCost
                key={recurringCost.id}
                cost={recurringCost}
              />
            );
          })}
        </VStack>
      )}
    </VStack>
  );
};
