import { Box, Flex, HStack, VStack } from '@chakra-ui/react';
import { Loading } from 'components/Loading';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { SalesOrdersDetailPageHeader } from 'features/salesOrdersV2/components/SalesOrdersDetailPageHeader';
import { SalesOrdersDetailPageInternalMemo } from 'features/salesOrdersV2/components/SalesOrdersDetailPageInternalMemo';
import { SalesOrdersDetailPageMasterData } from 'features/salesOrdersV2/components/SalesOrdersDetailPageMasterData';
import { SalesOrdersDetailPagePreview } from 'features/salesOrdersV2/components/SalesOrdersDetailPagePreview';
import { SalesOrdersDetailPageSidebar } from 'features/salesOrdersV2/components/SalesOrdersDetailPageSidebar';
import { useQueryParams } from 'features/salesOrdersV2/hooks/useSalesOrdersQueryParams';
import { detail_SalesOrdersDetailPageQuery } from 'gql/__generated__/detail_SalesOrdersDetailPageQuery.graphql';
import { Suspense } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';

const SalesOrdersDetailPageQuery = graphql`
  query detail_SalesOrdersDetailPageQuery($id: ID!) {
    salesOrders(where: {id: $id}) {
      edges {
        node {
          ...SalesOrdersDetailPageHeaderFragment
          ...SalesOrdersDetailPageSidebarFragment
          ...SalesOrdersDetailPageInternalMemoFragment
          ...SalesOrdersDetailPagePreviewFragment
          ...SalesOrdersDetailPageMasterDataFragment
        }
      }
    }
  }
`;

export const SalesOrdersDetailPage = () => {
  const { queryParams } = useQueryParams();
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const { salesOrders } = useLazyLoadQuery<detail_SalesOrdersDetailPageQuery>(
    SalesOrdersDetailPageQuery,
    {
      id,
    },
    { fetchPolicy: 'network-only' },
  );

  const salesOrder = (salesOrders?.edges || [])[0]?.node;

  if (!salesOrder) return null;

  return (
    <VStack maxW="1160px" spacing={6} alignItems="flex-start">
      <Suspense fallback={<Loading />}>
        <Flex w="100%" justify="space-between">
          <RouterLink
            to={paths.salesOrders.url({
              assignee: queryParams.assignee,
              demand: queryParams.demand,
              transactionId: queryParams.transactionId,
              supplier: queryParams.supplier,
            })}
          >
            <PageBack />
          </RouterLink>
          <SalesOrdersDetailPageHeader queryRef={salesOrder} />
        </Flex>

        <Box>
          <SubHeading label="発注請書の詳細" />
        </Box>

        <HStack spacing={4} alignItems="flex-start">
          <Box w="208px">
            <SalesOrdersDetailPageSidebar queryRef={salesOrder} />
          </Box>
          <VStack w="936px" spacing={6}>
            <SalesOrdersDetailPageInternalMemo queryRef={salesOrder} />
            <SalesOrdersDetailPagePreview queryRef={salesOrder} />
            <SalesOrdersDetailPageMasterData queryRef={salesOrder} />
          </VStack>
        </HStack>
      </Suspense>
    </VStack>
  );
};
