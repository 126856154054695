import { Box, HStack, Table, Tbody, Text, VStack } from '@chakra-ui/react';
import { EfferentFour } from '@icon-park/react';
import { Link } from 'components/Link';
import {
  DemandRecurringCostBlock,
  DemandRecurringCostBlockBody,
  DemandRecurringCostBlockHeader,
  DemandRecurringCostBlockInfo,
} from 'features/demandRecurringCost/components/DemandRecurringCostBlock';
import { DemandRecurringCostPriceTableHeader } from 'features/demandRecurringCost/components/DemandRecurringCostPriceTable/DemandRecurringCostPriceTableHeader';
import { DemandRecurringCostPriceTableRow } from 'features/demandRecurringCost/components/DemandRecurringCostPriceTable/DemandRecurringCostPriceTableRow';
import {
  SalesOrdersEditFormDetailCostType,
  SalesOrdersFormRecurringCostModalType,
  SalesOrdersReorderFormDetailCostType,
} from 'features/salesOrdersV2/form';
import { SalesOrdersFormRecurringCostModalDetailsQuery as SalesOrdersFormRecurringCostModalDetailsQueryType } from 'gql/__generated__/SalesOrdersFormRecurringCostModalDetailsQuery.graphql';
import { SalesOrderDetailType } from 'gql/graphql.types';
import { useFormContext, useWatch } from 'react-hook-form';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';

const salesOrdersFormRecurringCostModalDetailsQuery = graphql`
  query SalesOrdersFormRecurringCostModalDetailsQuery($demandId: ID!) {
    demandRecurringCosts(where: {isCurrent: true, demandID: $demandId}, orderBy: {field: UPDATED_AT, direction: DESC}) {
      edges {
        node {
          id
          name
          note
          costNumber
          supplier {
            id
            name
          }
          internalMemos {
            body
          }
          prices {
            id
            quantity
            unitPrice
            unitSellingPrice
          }
          demand {
            code
          }
          taxCategory {
            id
            rate
          }
          salesOrderRecurringCosts(
            where: {
              hasSalesOrderDetailWith: {
                deletedAtIsNil: true,
                hasSalesOrderWith: {
                  deletedAtIsNil: true,
                  hasOrdersWith: {
                    deletedAtIsNil: true,
                  },
                }
              }
            },
            orderBy: {direction:DESC, field:CREATED_AT},
            first: 1
          ) {
            edges {
              node {
                salesOrderDetail {
                  salesOrder {
                    orders {
                      id
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

type RecurringCostType = NonNullable<
  NonNullable<
    NonNullable<
      SalesOrdersFormRecurringCostModalDetailsQueryType['response']['demandRecurringCosts']['edges']
    >[number]
  >['node']
>;
type RecurringCostPriceType = NonNullable<NonNullable<RecurringCostType['prices']>[number]>;

export const SalesOrdersFormRecurringCostModalDetails = () => {
  const { getValues, setValue } = useFormContext<SalesOrdersFormRecurringCostModalType>();

  const { demandRecurringCosts } =
    useLazyLoadQuery<SalesOrdersFormRecurringCostModalDetailsQueryType>(
      salesOrdersFormRecurringCostModalDetailsQuery,
      { demandId: getValues('demand').id },
      { fetchPolicy: 'network-only' },
    );

  if (
    !demandRecurringCosts ||
    !demandRecurringCosts.edges ||
    demandRecurringCosts.edges.length === 0 ||
    !demandRecurringCosts.edges[0]?.node ||
    demandRecurringCosts.edges[0]?.node === null
  ) {
    return (
      <VStack w="full" h="full" justifyContent="center" alignItems="center">
        <Text>継続費用が見つかりません</Text>
      </VStack>
    );
  }

  const costs = demandRecurringCosts.edges
    .map((edge) => {
      if (!edge?.node) return null;
      return edge.node;
    })
    .filter((value) => value != null);

  const handleChangePriceTableCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    cost: RecurringCostType,
    price: RecurringCostPriceType,
  ) => {
    const details = getValues('details');

    if (e.target.checked) {
      setValue('details', [...details, buildFormDetail(cost, price)]);
      // 選択した継続費用がサプライヤーに紐づいている場合のみ制限をする
      const costSupplier = cost.supplier;
      if (costSupplier) {
        setValue('supplier', costSupplier);
      }
    } else {
      setValue(
        'details',
        details.filter((detail) => detail.priceId !== price.id),
      );
      // チェックを外した後のdetailsにサプライヤーが1件もいなければ制約を外す
      const detailsWithSupplier = getValues('details').filter((detail) => detail.supplier);
      if (detailsWithSupplier.length === 0) {
        setValue('supplier', { id: '', name: '' });
      }
    }
  };

  return (
    <VStack w="100%" h="100%" spacing={0} overflowY="scroll" overscrollBehavior="auto">
      {costs.map((cost) => {
        const latestOrder =
          cost.salesOrderRecurringCosts.edges?.[0]?.node?.salesOrderDetail?.salesOrder?.orders?.[0];

        return (
          <DemandRecurringCostBlock key={cost.id}>
            <DemandRecurringCostBlockHeader itemName={cost.name} value={cost.note || '-'} />
            <DemandRecurringCostBlockBody>
              <DemandRecurringCostBlockInfo
                values={[
                  {
                    label: 'サプライヤー',
                    node: cost.supplier?.name || '-',
                  },
                  {
                    label: 'カテゴリー',
                    node: '費用',
                  },
                  {
                    label: '最終発注',
                    node: latestOrder ? (
                      <Link to={paths.order._id(latestOrder.id)} target="_blank">
                        <HStack gap={1}>
                          <Box>{latestOrder.title}</Box>
                          <EfferentFour theme="outline" />
                        </HStack>
                      </Link>
                    ) : (
                      '-'
                    ),
                  },
                  {
                    label: '社内メモ',
                    node: cost.internalMemos?.[0]?.body || '-',
                  },
                ]}
              />
              <DemandRecurringCostPriceTable
                cost={cost}
                handleChangePriceTableCheckbox={handleChangePriceTableCheckbox}
              />
            </DemandRecurringCostBlockBody>
          </DemandRecurringCostBlock>
        );
      })}
    </VStack>
  );
};

const DemandRecurringCostPriceTable = ({
  cost,
  handleChangePriceTableCheckbox,
}: {
  cost: RecurringCostType;
  handleChangePriceTableCheckbox: (
    e: React.ChangeEvent<HTMLInputElement>,
    cost: RecurringCostType,
    price: RecurringCostPriceType,
  ) => void;
}) => {
  const { control } = useFormContext<SalesOrdersFormRecurringCostModalType>();
  const supplier = useWatch({ control, name: 'supplier' });
  const details = useWatch({ control, name: 'details' });

  const sortedPrices = cost.prices?.toSorted((a, b) => Number(a.quantity) - Number(b.quantity));

  return (
    <Box w="422px">
      <HStack spacing={4} alignItems="stretch" w="100%">
        <Text
          border="1px solid"
          borderColor="gray.200"
          lineHeight={1.2}
          p={2}
          fontSize="sm"
          fontWeight="bold"
          textAlign="center"
          sx={{ writingMode: 'vertical-rl' }}
        >
          単価表
        </Text>
        <Table w="100%">
          <DemandRecurringCostPriceTableHeader enableCheckbox={true} />
          <Tbody>
            {sortedPrices?.map((price) => {
              const isChecked = details.some((detail) => detail.priceId === price.id);
              // 継続費用はサプライヤーが任意選択なので、 cost自体のsupplier.idも存在チェックする
              const isDisabled =
                !!supplier.id && !!cost.supplier?.id && supplier.id !== cost.supplier?.id;

              return (
                <DemandRecurringCostPriceTableRow
                  key={price.id}
                  value={price}
                  enableCheckbox={true}
                  checked={isChecked}
                  disabled={isDisabled}
                  showTooltip={isDisabled}
                  tooltipText={`選択中の明細と別のサプライヤーの継続費用は\n選択できません`}
                  handleChangeValue={(e) => {
                    handleChangePriceTableCheckbox(e, cost, price);
                  }}
                />
              );
            })}
          </Tbody>
        </Table>
      </HStack>
    </Box>
  );
};

const buildFormDetail = (
  cost: RecurringCostType,
  price: RecurringCostPriceType,
): SalesOrdersEditFormDetailCostType | SalesOrdersReorderFormDetailCostType => {
  return {
    type: SalesOrderDetailType.RecurringCost,
    supplier: cost.supplier
      ? {
          id: cost.supplier.id,
          name: cost.supplier.name,
        }
      : undefined,
    name: cost.name,
    note: cost.note,
    costId: cost.id,
    priceId: price.id,
    quantity: price.quantity,
    unitPrice: price.unitPrice,
    unitSellingPrice: price.unitSellingPrice,
    tax: {
      id: cost.taxCategory?.id,
      rate: cost.taxCategory?.rate,
    },
  };
};
