import { VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { NewSalesOrderFormType, newSalesOrderFormSchema } from 'features/salesOrdersV2/form';
import { layout_NewSalesOrderLayoutQuery } from 'gql/__generated__/layout_NewSalesOrderLayoutQuery.graphql';
import { FormProvider, useForm } from 'react-hook-form';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Outlet, useParams } from 'react-router-dom';
import { MissingRequiredParamError } from 'utils/error';
import { mapEstimationToNewSalesOrderForm } from './utils';

const newSalesOrderLayoutQuery = graphql`
  query layout_NewSalesOrderLayoutQuery($id: ID!) {
    estimations(where: {id: $id}) {
      edges {
        node {
          id
          company {
            id
            name
          }
          supplier {
            id
            name
          }
          title
          detailMessage
          details(orderBy: {direction: ASC, field: ORDER_NUMBER}, where: {documentDisplayStatus: shown}) {
            edges {
              node {
                id
                type
                orderNumber
                cost {
                  id
                  name
                  note
                  unitPrice
                  unitSellingPrice
                  quantity
                  tax: taxCategory {
                    id
                    rate
                    name
                  }
                }
                item {
                  id
                  name
                  category
                  tax: taxCategory {
                    id
                    rate
                    name
                  }
                  unitPrice
                  unitSellingPrice
                  quantity
                  cardboard {
                    size
                    type
                    material
                    thickness
                    printingColor
                    processing
                    other
                  }
                  flexiblePackage {
                    size
                    type
                    material
                    printingColor
                    processing
                    other
                  }
                  giftBox {
                    size
                    type
                    paperType
                    printingColor
                    processing
                    other
                  }
                  paperBag {
                    size
                    paperType
                    printingColor
                    processing
                    handle
                    other
                  }
                  other {
                    specText
                  }
                }
              }
            }
          }
          internalAssignees {
            user {
              id
              profile {
                lastName
                firstName
              }
            }
          }
        }
      }
    }
  }
`;

export const NewSalesOrderLayout = () => {
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const { estimations } = useLazyLoadQuery<layout_NewSalesOrderLayoutQuery>(
    newSalesOrderLayoutQuery,
    { id: id },
    { fetchPolicy: 'network-only' },
  );

  if (!estimations?.edges?.[0]?.node) {
    throw new Error('Estimations not found in NewSalesOrderLayout');
  }

  const form = useForm<NewSalesOrderFormType>({
    resolver: zodResolver(newSalesOrderFormSchema),
    defaultValues: mapEstimationToNewSalesOrderForm(estimations.edges[0]?.node),
  });

  return (
    <VStack spacing={6} alignItems="flex-start">
      <FormProvider {...form}>
        <Outlet />
      </FormProvider>
    </VStack>
  );
};
