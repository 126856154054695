/**
 * @generated SignedSource<<411113ea05abbddc38aacabd980e62bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ItemCategory = "cardboard" | "flexiblePackage" | "giftBox" | "other" | "paperBag" | "unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DemandItemDetailInfoFragment$data = {
  readonly attachments: ReadonlyArray<{
    readonly id: string;
  }> | null;
  readonly category: ItemCategory;
  readonly demand: {
    readonly code: string;
  };
  readonly endedAt: any | null;
  readonly id: string;
  readonly images: ReadonlyArray<{
    readonly displayOrder: any;
    readonly id: string;
  }> | null;
  readonly internalMemos: ReadonlyArray<{
    readonly body: string;
  }> | null;
  readonly itemNumber: any;
  readonly latestOrderInfo: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly salesOrderDetail: {
          readonly salesOrder: {
            readonly orders: ReadonlyArray<{
              readonly id: string;
              readonly title: string;
            }> | null;
          };
        };
      } | null;
    } | null> | null;
  };
  readonly name: string;
  readonly prices: ReadonlyArray<{
    readonly id: string;
    readonly quantity: any;
    readonly unitPrice: number;
    readonly unitSellingPrice: number;
  }> | null;
  readonly startedAt: any | null;
  readonly supplier: {
    readonly id: string;
    readonly name: string;
  };
  readonly tax: {
    readonly name: string;
  };
  readonly taxCategory: {
    readonly id: string;
    readonly rate: number;
  };
  readonly version: any;
  readonly " $fragmentSpreads": FragmentRefs<"DemandItemSpecInfoFragment">;
  readonly " $fragmentType": "DemandItemDetailInfoFragment";
};
export type DemandItemDetailInfoFragment$key = {
  readonly " $data"?: DemandItemDetailInfoFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DemandItemDetailInfoFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DemandItemDetailInfoFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Supplier",
      "kind": "LinkedField",
      "name": "supplier",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DemandItemInternalMemo",
      "kind": "LinkedField",
      "name": "internalMemos",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "body",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "demand",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "itemNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "version",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "category",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaxCategory",
      "kind": "LinkedField",
      "name": "taxCategory",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DemandItemPrice",
      "kind": "LinkedField",
      "name": "prices",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quantity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unitPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unitSellingPrice",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DemandItemImage",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayOrder",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DemandItemAttachment",
      "kind": "LinkedField",
      "name": "attachments",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "tax",
      "args": null,
      "concreteType": "TaxCategory",
      "kind": "LinkedField",
      "name": "taxCategory",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "latestOrderInfo",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "direction": "DESC",
            "field": "CREATED_AT"
          }
        },
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "hasSalesOrderDetailWith": {
              "deletedAtIsNil": true,
              "hasSalesOrderWith": {
                "deletedAtIsNil": true,
                "hasOrdersWith": {
                  "deletedAtIsNil": true
                }
              }
            }
          }
        }
      ],
      "concreteType": "SalesOrderItemConnection",
      "kind": "LinkedField",
      "name": "salesOrderItems",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SalesOrderItemEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SalesOrderItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SalesOrderDetail",
                  "kind": "LinkedField",
                  "name": "salesOrderDetail",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesOrder",
                      "kind": "LinkedField",
                      "name": "salesOrder",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Order",
                          "kind": "LinkedField",
                          "name": "orders",
                          "plural": true,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "title",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "salesOrderItems(first:1,orderBy:{\"direction\":\"DESC\",\"field\":\"CREATED_AT\"},where:{\"hasSalesOrderDetailWith\":{\"deletedAtIsNil\":true,\"hasSalesOrderWith\":{\"deletedAtIsNil\":true,\"hasOrdersWith\":{\"deletedAtIsNil\":true}}}})"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DemandItemSpecInfoFragment"
    }
  ],
  "type": "DemandItem",
  "abstractKey": null
};
})();

(node as any).hash = "134d76136f27fc3348ba0dd6ae7e7465";

export default node;
