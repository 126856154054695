import {
  useInvoiceDocumentsQuery$data,
  useInvoiceDocumentsQuery as useInvoiceDocumentsQueryType,
} from 'gql/__generated__/useInvoiceDocumentsQuery.graphql';
import RelayEnvironment from 'lib/relay/RelayEnvironment';
import { useState } from 'react';
import { fetchQuery, graphql } from 'relay-runtime';

const useInvoiceDocumentsQuery = graphql`
  query useInvoiceDocumentsQuery($ids: [ID!]!) {
    invoiceDocuments(where: {
      idIn: $ids
    }) {
      edges {
        node {
          id
          fileName
          invoice {
            id
            isSent
            company {
              id
              name
              invoiceRecipients {
                id
                email
                recipientType
              }
            }
          }
        }
      }
    }
  }
`;

export const useInvoiceDocuments = () => {
  const [isFetching, setIsFetching] = useState(false);

  const fetchDocuments = async (ids: string[]) => {
    let data: useInvoiceDocumentsQuery$data | undefined;
    setIsFetching(true);
    try {
      data = await fetchQuery<useInvoiceDocumentsQueryType>(
        RelayEnvironment,
        useInvoiceDocumentsQuery,
        { ids },
      ).toPromise();
    } catch (_error) {
      // nop: useErrorHandlableRelayEnvironmentでエラー処理している
      setIsFetching(false);
      return;
    }
    setIsFetching(false);
    return data?.invoiceDocuments?.edges?.map((edge) => edge?.node);
  };

  return { fetchDocuments, isFetching };
};
