import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Icon,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { DownloadFour, EfferentFour } from '@icon-park/react';
import { Link } from 'components/Link';
import {
  DemandItemAttachmentsType,
  useDemandItemAttachments,
} from 'features/demandItem/api/useDemandItemAttachments';
import { DemandItemPriceTable } from 'features/demandItem/components/DemandItemPriceTable';
import {
  DEMAND_ITEM_CATEGORY_LABEL,
  DemandItemCategory,
  buildDemandItemCode,
} from 'features/demandItem/models';
import { DemandItemDetailInfoFragment$key } from 'gql/__generated__/DemandItemDetailInfoFragment.graphql';
import { useEffect } from 'react';
import { graphql, useFragment } from 'react-relay';
import { dateFormatWithTimeWithoutDayOfWeek } from 'utils/date';
import { formatBytes } from 'utils/number';
import { paths } from 'utils/paths';
import { DemandItemDetailNoImageThumbnail } from './DemandItemDetailNoImageThumbnail';
import { DemandItemDetailThumbnails } from './DemandItemDetailThumbnails';
import { DemandItemSpecInfo } from './DemandItemSpecInfo';

const DemandItemDetailInfoFragment = graphql`
  fragment DemandItemDetailInfoFragment on DemandItem {
    id
    name
    supplier {
      id
      name
    }
    internalMemos {
      body
    }
    demand {
      code
    }
    itemNumber
    version
    category
    startedAt
    endedAt
    taxCategory {
      id
      rate
    }
    prices {
      id
      quantity
      unitPrice
      unitSellingPrice
    }
    images {
      id
      displayOrder
    }
    attachments {
      id
    }
    tax: taxCategory {
      name
    }
    latestOrderInfo: salesOrderItems(
      where: {
        hasSalesOrderDetailWith: {
          deletedAtIsNil: true,
          hasSalesOrderWith: {
            deletedAtIsNil: true,
            hasOrdersWith: {
              deletedAtIsNil: true,
            },
          }
        }
      },
      orderBy: {direction:DESC, field:CREATED_AT},
      first: 1
    ) {
      edges {
        node {
          salesOrderDetail {
            salesOrder {
              orders {
                id
                title
              }
            }
          }
        }
      }
    }
    ...DemandItemSpecInfoFragment
  }
`;

type Props = {
  queryRef: DemandItemDetailInfoFragment$key;
};

const getValidityPeriod = (startedAt: string, endedAt: string) => {
  const startedAtDate = dateFormatWithTimeWithoutDayOfWeek(startedAt);

  if (!endedAt) {
    return `${startedAtDate} -`;
  }

  const endedAtDate = dateFormatWithTimeWithoutDayOfWeek(endedAt);
  return `${startedAtDate} - ${endedAtDate}`;
};

export const DemandItemDetailInfo = ({ queryRef }: Props) => {
  const item = useFragment(DemandItemDetailInfoFragment, queryRef);
  const memo = item.internalMemos?.[0]?.body || '-';
  const demandItemCode = buildDemandItemCode({
    demandCode: item.demand.code,
    category: item.category as DemandItemCategory,
    itemNumber: item.itemNumber,
    version: item.version,
  });

  const latestOrder =
    item.latestOrderInfo.edges?.[0]?.node?.salesOrderDetail?.salesOrder?.orders?.[0];

  const { data, fetch: fetchAttachments } = useDemandItemAttachments({ itemID: item.id });

  useEffect(() => {
    if (!data) {
      fetchAttachments();
    }
  }, [data]);

  return (
    <HStack spacing={10} width="1024px" justifyContent="space-between" alignItems="flex-start">
      {item.images && item.images.length > 0 ? (
        <DemandItemDetailThumbnails item={item} />
      ) : (
        <DemandItemDetailNoImageThumbnail />
      )}

      <VStack spacing={6} width="536px">
        <Grid alignSelf="flex-start" gridTemplateColumns="max-content 1fr" gap="8px 12px">
          <GridItemLabel label="コード" />
          <GridItemValue value={demandItemCode} />

          <GridItemLabel label="有効期間" />
          <GridItemValue value={getValidityPeriod(item.startedAt, item.endedAt)} />

          <GridItemLabel label="サプライヤー" />
          <GridItemValue value={item.supplier.name} />

          <GridItemLabel label="カテゴリー" />
          <GridItemValue
            value={DEMAND_ITEM_CATEGORY_LABEL[item.category as DemandItemCategory].categoryName}
          />

          <GridItemLabel label="最終発注" />
          <GridItemValue
            value={
              latestOrder?.title ? (
                <Link to={paths.order._id(latestOrder?.id)} target="_blank">
                  <HStack gap={1}>
                    <Box>{latestOrder?.title}</Box>
                    <EfferentFour theme="outline" />
                  </HStack>
                </Link>
              ) : (
                '-'
              )
            }
          />

          <DemandItemSpecInfo queryRef={item} />

          <GridItemLabel label="社内メモ" />
          <GridItemValue value={memo || '-'} />

          <DemandItemDetailInfoAttachments
            attachmentCount={item.attachments?.length}
            attachments={data || []}
          />
        </Grid>

        <VStack spacing={1} width="100%">
          <DemandItemPriceTable
            values={(item.prices || []).map((price) => ({
              id: price.id,
              quantity: price.quantity,
              unitPrice: price.unitPrice,
              unitSellingPrice: price.unitSellingPrice,
            }))}
            tax={item.tax.name}
          />
        </VStack>
      </VStack>
    </HStack>
  );
};

const GridItemLabel = ({ label }: { label: string }) => (
  <GridItem
    display="flex"
    alignItems="center"
    justifyContent="flex-end"
    minHeight="24px"
    fontSize="sm"
    color="gray.500"
    whiteSpace="nowrap"
  >
    {label}
  </GridItem>
);

const GridItemValue = ({ value }: { value: React.ReactNode }) => (
  <GridItem
    display="flex"
    alignItems="center"
    justifyContent="flex-start"
    minHeight="24px"
    fontSize="sm"
  >
    {value}
  </GridItem>
);

const DemandItemDetailInfoAttachments = ({
  attachmentCount,
  attachments,
}: { attachmentCount: number | undefined; attachments: DemandItemAttachmentsType }) => {
  if (!attachmentCount || attachmentCount === 0) {
    return null;
  }

  const showSkeletons = attachmentCount > 0 && attachments.length === 0;
  const Skeletons = () => {
    return Array.from({ length: attachmentCount }).map((_, index) => (
      <Skeleton key={index} height="32px" width="300px" maxW="100%" bgColor="gray.500" />
    ));
  };

  const AttachmentButtons = () => {
    return attachments?.map((attachment) => {
      return (
        <Button
          key={attachment.attachmentID}
          colorScheme="gray"
          size="sm"
          px={3}
          py={2.5}
          justifyContent="space-between"
          onClick={() => {
            window.open(attachment.url, '_blank', 'noopener, noreferrer');
          }}
        >
          <HStack spacing={1}>
            <Icon as={DownloadFour} />
            <Text fontSize="sm" fontWeight="normal">
              {attachment.fileName}
            </Text>
            <Text fontSize="xs" fontWeight="normal" color="gray.500">
              ({formatBytes(attachment.byteSize)})
            </Text>
          </HStack>
        </Button>
      );
    });
  };

  return (
    <>
      <GridItem
        display="flex"
        pt={0.5}
        alignItems="flex-start"
        justifyContent="flex-end"
        minHeight="24px"
        fontSize="sm"
        color="gray.500"
        whiteSpace="nowrap"
      >
        その他のデータ
      </GridItem>
      <GridItemValue
        value={
          <VStack spacing={2} alignItems="flex-start">
            {showSkeletons ? <Skeletons /> : <AttachmentButtons />}
          </VStack>
        }
      />
    </>
  );
};
