import {
  Box,
  Flex,
  FormControl,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Text,
  useToken,
} from '@chakra-ui/react';
import { CloseSmall, DeleteKey } from '@icon-park/react';
import { CompanyInvoiceRecipientRecipientType } from 'gql/graphql.types';
import { useFormContext, useWatch } from 'react-hook-form';
import { InvoiceRecipientEditFormDataType } from '../InvoiceRecipientEditForm';

export type RecipientRowProps = {
  index: number;
  field: InvoiceRecipientEditFormDataType['recipients'][number];
  remove: (index: number) => void;
  update: (index: number, value: InvoiceRecipientEditFormDataType['recipients'][number]) => void;
  isLastToRecipient: (index: number) => boolean;
};

export const RecipientRow = ({
  index,
  field,
  remove,
  update,
  isLastToRecipient,
}: RecipientRowProps) => {
  const gray500 = useToken('colors', 'gray.500');
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<InvoiceRecipientEditFormDataType>();
  // 必要なデータのみをuseWatchで監視
  const recipient = useWatch({
    control,
    name: `recipients.${index}`,
  });

  const handleClearRecipientEmail = () => {
    update(index, {
      ...recipient,
      email: '',
    });
  };

  return (
    <Box key={field.id}>
      <Flex alignItems="center">
        <Box>
          <RadioGroup
            name={`recipients.${index}.recipientType`}
            value={recipient?.recipientType}
            onChange={(value) => {
              const newValue = value as CompanyInvoiceRecipientRecipientType;
              update(index, {
                ...recipient,
                recipientType: newValue,
              });
            }}
          >
            <HStack spacing={2}>
              <Radio
                value={CompanyInvoiceRecipientRecipientType.To}
                colorScheme="blue"
                {...register(`recipients.${index}.recipientType`)}
              >
                <Text fontSize="sm">To</Text>
              </Radio>
              <Radio
                value={CompanyInvoiceRecipientRecipientType.Cc}
                colorScheme="blue"
                {...register(`recipients.${index}.recipientType`)}
                isDisabled={isLastToRecipient(index)}
              >
                <Text fontSize="sm">Cc</Text>
              </Radio>
            </HStack>
          </RadioGroup>
        </Box>
        <Box flex={1} ml="0.75rem">
          <FormControl isInvalid={!!errors.recipients?.[index]?.email}>
            <InputGroup size="sm">
              <Input
                type="string"
                {...register(`recipients.${index}.email`)}
                borderRadius="md"
                border="1px solid"
                borderColor={errors.recipients?.[index]?.email ? 'red.500' : 'gray.200'}
                name={`recipients.${index}.email`}
                defaultValue={field.email}
                placeholder="メールアドレスを入力"
                pr="2.5rem"
              />
              <InputRightElement width="2.5rem" height="100%" justifyContent="center">
                <IconButton
                  aria-label="clear-recipient-email"
                  icon={<DeleteKey size="1rem" fill={gray500} />}
                  size="sm"
                  variant="text"
                  onClick={handleClearRecipientEmail}
                  isDisabled={!recipient?.email}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </Box>
        <Box ml="0.5rem">
          <IconButton
            aria-label="clear-recipient"
            icon={<CloseSmall size="1rem" />}
            size="sm"
            variant="text"
            onClick={() => remove(index)}
            isDisabled={isLastToRecipient(index)}
          />
        </Box>
      </Flex>
      {errors.recipients?.[index]?.email && (
        <Text color="red.500" fontSize="xs" mt="1" ml="6.3rem">
          {errors.recipients[index].email.message}
        </Text>
      )}
    </Box>
  );
};
