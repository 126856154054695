import {
  errorMessages,
  quantitySchema,
  unitPriceSchema,
  unitSellingPriceSchema,
} from 'utils/validation';
import { z } from 'zod';

export type DemandRecurringCostFormType = z.infer<typeof demandRecurringCostFormSchema>;

export const demandRecurringCostFormSchema = z.object({
  supplier: z.object({
    id: z.string().optional(),
    name: z.string().optional(),
  }),
  name: z.string().min(1, { message: errorMessages.REQUIRED }),
  note: z.string().max(500, { message: errorMessages.MAX_LENGTH(500) }),
  memo: z
    .string()
    .max(500, { message: errorMessages.MAX_LENGTH(500) })
    .optional(),
  tax: z.object({
    id: z.string(),
    rate: z.union([z.string(), z.number()]),
    name: z.string(),
  }),
  prices: z
    .array(
      z.object({
        id: z.string(),
        unitPrice: unitPriceSchema(),
        unitSellingPrice: unitSellingPriceSchema,
        quantity: quantitySchema(),
      }),
    )
    .superRefine((prices, ctx) => {
      const quantities = new Set<number>();

      prices.forEach((price, index) => {
        if (quantities.has(Number(price.quantity))) {
          ctx.addIssue({
            code: 'custom',
            path: [index, 'quantity'],
            message: `数量は重複しないようにしましょう`,
          });
        } else {
          quantities.add(Number(price.quantity));
        }
      });
    }),
});
