import { DEMAND_ITEM_CATEGORY } from 'features/demandItem/models';
import { SalesOrderSpec } from './SalesOrderDetail';

export * from './SalesOrderDetail';
export * from './SalesOrderDetailType';

export type SalesOrdersDetailSpec = {
  label: string;
  node: React.ReactNode | undefined;
};

export const getItemSpecs = (category: string, spec: SalesOrderSpec): SalesOrdersDetailSpec[] => {
  switch (category) {
    case DEMAND_ITEM_CATEGORY.Cardboard:
      return getCardboardSpec(spec?.cardboard);
    case DEMAND_ITEM_CATEGORY.FlexiblePackage:
      return getFlexiblePackageSpec(spec?.flexiblePackage);
    case DEMAND_ITEM_CATEGORY.GiftBox:
      return getGiftBoxSpec(spec?.giftBox);
    case DEMAND_ITEM_CATEGORY.PaperBag:
      return getPaperBagSpec(spec?.paperBag);
    case DEMAND_ITEM_CATEGORY.Other:
      return getOtherSpec(spec?.other);
    default:
      return [];
  }
};

const getCardboardSpec = (spec: SalesOrderSpec['cardboard']): SalesOrdersDetailSpec[] => {
  return [
    {
      label: 'サイズ',
      node: spec?.size,
    },
    {
      label: '形状',
      node: spec?.type,
    },
    {
      label: '構成',
      node: spec?.material,
    },
    {
      label: '段厚',
      node: spec?.thickness,
    },
    {
      label: '印刷',
      node: spec?.printingColor,
    },
    {
      label: '加工',
      node: spec?.processing,
    },
    {
      label: 'その他',
      node: spec?.other,
    },
  ];
};

const getFlexiblePackageSpec = (
  spec: SalesOrderSpec['flexiblePackage'],
): SalesOrdersDetailSpec[] => {
  return [
    {
      label: 'サイズ',
      node: spec?.size,
    },
    {
      label: '形状',
      node: spec?.type,
    },
    {
      label: '構成',
      node: spec?.material,
    },
    {
      label: '印刷',
      node: spec?.printingColor,
    },
    {
      label: '加工',
      node: spec?.processing,
    },
    {
      label: 'その他',
      node: spec?.other,
    },
  ];
};

const getGiftBoxSpec = (spec: SalesOrderSpec['giftBox']): SalesOrdersDetailSpec[] => {
  return [
    {
      label: 'サイズ',
      node: spec?.size,
    },
    {
      label: '形状',
      node: spec?.type,
    },
    {
      label: '用紙',
      node: spec?.paperType,
    },
    {
      label: '印刷',
      node: spec?.printingColor,
    },
    {
      label: '加工',
      node: spec?.processing,
    },
    {
      label: 'その他',
      node: spec?.other,
    },
  ];
};

const getPaperBagSpec = (spec: SalesOrderSpec['paperBag']): SalesOrdersDetailSpec[] => {
  return [
    {
      label: 'サイズ',
      node: spec?.size,
    },
    {
      label: '用紙',
      node: spec?.paperType,
    },
    {
      label: '印刷',
      node: spec?.printingColor,
    },
    {
      label: '加工',
      node: spec?.processing,
    },
    {
      label: '持ち手',
      node: spec?.handle,
    },
    {
      label: 'その他',
      node: spec?.other,
    },
  ];
};

const getOtherSpec = (spec: SalesOrderSpec['other']): SalesOrdersDetailSpec[] => {
  return [
    {
      label: 'その他',
      node: spec?.specText,
    },
  ];
};

export const getRecurringCostSpecs = (spec: SalesOrderSpec['cost']): SalesOrdersDetailSpec[] => {
  return [
    {
      label: '補足',
      node: spec?.note,
    },
  ];
};

export const getOnetimeCostSpecs = (spec: SalesOrderSpec['cost']): SalesOrdersDetailSpec[] => {
  return [
    {
      label: '補足',
      node: spec?.note,
    },
  ];
};
