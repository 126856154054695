import { DEMAND_ITEM_CATEGORY, DEMAND_ITEM_CATEGORY_LABEL } from 'features/demandItem/models';
import {
  errorMessages,
  quantitySchema,
  unitPriceSchema,
  unitSellingPriceSchema,
} from 'utils/validation';
import { z } from 'zod';
import { ESTIMATION_DETAIL_DOCUMENT_DISPLAY_STATUS } from '../models';

export const ESTIMATION_DETAIL_CATEGORY_COST = 'cost';

export const ESTIMATION_DETAIL_CATEGORIES = {
  [DEMAND_ITEM_CATEGORY.Cardboard]:
    DEMAND_ITEM_CATEGORY_LABEL[DEMAND_ITEM_CATEGORY.Cardboard].categoryName,
  [DEMAND_ITEM_CATEGORY.FlexiblePackage]:
    DEMAND_ITEM_CATEGORY_LABEL[DEMAND_ITEM_CATEGORY.FlexiblePackage].categoryName,
  [DEMAND_ITEM_CATEGORY.GiftBox]:
    DEMAND_ITEM_CATEGORY_LABEL[DEMAND_ITEM_CATEGORY.GiftBox].categoryName,
  [DEMAND_ITEM_CATEGORY.PaperBag]:
    DEMAND_ITEM_CATEGORY_LABEL[DEMAND_ITEM_CATEGORY.PaperBag].categoryName,
  [DEMAND_ITEM_CATEGORY.Other]: DEMAND_ITEM_CATEGORY_LABEL[DEMAND_ITEM_CATEGORY.Other].categoryName,
  [ESTIMATION_DETAIL_CATEGORY_COST]: '費用',
} as const;

export const ESTIMATION_DETAIL_CATEGORY_KEYS = Object.keys(ESTIMATION_DETAIL_CATEGORIES);

export type EstimationDetailType = z.infer<typeof estimationDetailFormSchema>;
export type EstimationFormType = z.infer<typeof estimationFormSchema>;
export type EstimationDetailCategory = keyof typeof ESTIMATION_DETAIL_CATEGORIES;

const estimationDetailSpecCardboardSchema = z.object({
  size: z.string(),
  type: z.string(),
  material: z.string(),
  thickness: z.string(),
  printingColor: z.string(),
  processing: z.string(),
  other: z.string(),
});

const estimationDetailSpecFlexiblePackageSchema = z.object({
  size: z.string(),
  type: z.string(),
  material: z.string(),
  printingColor: z.string(),
  processing: z.string(),
  other: z.string(),
});

const estimationDetailSpecGiftBoxSchema = z.object({
  size: z.string(),
  type: z.string(),
  paperType: z.string(),
  printingColor: z.string(),
  processing: z.string(),
  other: z.string(),
});

const estimationDetailSpecPaperBagSchema = z.object({
  size: z.string(),
  paperType: z.string(),
  printingColor: z.string(),
  processing: z.string(),
  handle: z.string(),
  other: z.string(),
});

const estimationDetailSpecOtherSchema = z.object({
  specText: z.string(),
});

const estimationDetailSpecCostSchema = z.object({
  note: z.string(),
});

const estimationDetailFormSchema = z.object({
  responseItemId: z.string().optional(),
  name: z
    .string()
    .min(1, { message: errorMessages.REQUIRED })
    .max(1000, { message: errorMessages.MAX_LENGTH(1000) }),
  unitPrice: unitPriceSchema(),
  quantity: quantitySchema(),
  unitSellingPrice: unitSellingPriceSchema,
  tax: z.object({
    id: z.string(),
    rate: z.union([z.string(), z.number()]),
  }),
  documentDisplayStatus: z.enum([
    ESTIMATION_DETAIL_DOCUMENT_DISPLAY_STATUS.Shown,
    ESTIMATION_DETAIL_DOCUMENT_DISPLAY_STATUS.Hidden,
  ]),
  category: z.enum(Object.keys(ESTIMATION_DETAIL_CATEGORIES) as [string, ...string[]], {
    errorMap: () => ({ message: '選択してください' }),
  }),
  spec: z.object({
    cardboard: estimationDetailSpecCardboardSchema.optional(),
    flexiblePackage: estimationDetailSpecFlexiblePackageSchema.optional(),
    giftBox: estimationDetailSpecGiftBoxSchema.optional(),
    paperBag: estimationDetailSpecPaperBagSchema.optional(),
    other: estimationDetailSpecOtherSchema.optional(),
    cost: estimationDetailSpecCostSchema.optional(),
  }),
});

export const estimationFormSchema = z.object({
  demand: z.object({
    id: z.string(),
    name: z.string(),
  }),
  supplier: z.object({
    id: z.string().min(1, { message: errorMessages.REQUIRED }),
    name: z.string(),
  }),
  title: z.string().max(70, { message: errorMessages.MAX_LENGTH(70) }),
  // 見積書の有効期限
  expirationPeriod: z.string().max(30, { message: errorMessages.MAX_LENGTH(30) }),
  detailMessage: z.string().max(2000, { message: errorMessages.MAX_LENGTH(2000) }),
  internalAssignees: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      }),
    )
    .min(1, { message: '担当者を選択しましょう' }),
  // 「合計金額を見積書に表示」のチェックボックス
  showAmountSummary: z.boolean(),
  details: z.array(estimationDetailFormSchema),
});
