import {
  Box,
  Divider,
  Flex,
  HStack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { InformationShizai } from 'components/InformationShizai';
import { SubHeading } from 'components/SubHeading';
import { EstimationDetail, deduplicateAdjacentNames } from 'features/estimations/models';
import { EstimationDetailPreviewFragment$key } from 'gql/__generated__/EstimationDetailPreviewFragment.graphql';
import { graphql, useFragment } from 'react-relay';
import { dateFormat } from 'utils/date';
import {
  calculateDetailAmount,
  calculateSubtotalAmount,
  calculateTotalAmount,
  calculateTotalTaxAmount,
} from 'utils/priceCalculation';
import { mapPreviewDetailsToEstimationDetails } from '../utils/mapper';
import { EstimationDetailSpecPreview } from './EstimationDetailSpecPreview';

const EstimationDetailPreviewFragment = graphql`
  fragment EstimationDetailPreviewFragment on Estimation
  {
    id
    demand: company {
      name
    }
    detailMessage
    expirationPeriod
    showAmountSummary
    createdAt
    previewDetails: details(orderBy: {direction: ASC, field: ORDER_NUMBER}, where: {documentDisplayStatus: shown}) {
      edges {
        node {
          type
          orderNumber
          documentDisplayStatus
          cost {
            name
            note
            unitPrice
            unitSellingPrice
            quantity
            taxCategory {
              id
              rate
            }
          }
          item {
            name
            category
            taxCategory {
              id
              rate
            }
            unitPrice
            unitSellingPrice
            quantity
            cardboard {
              id
              size
              type
              material
              thickness
              printingColor
              processing
              other
            }
            flexiblePackage {
              id
              size
              type
              material
              printingColor
              processing
              other
            }
            giftBox {
              id
              size
              type
              paperType
              printingColor
              processing
              other
            }
            paperBag {
              id
              size
              paperType
              printingColor
              processing
              handle
              other
            }
            other {
              id
              specText
            }
          }
        }
      }
    }
  }
`;

type Props = {
  queryRef: EstimationDetailPreviewFragment$key;
};

export const EstimationDetailPreview = ({ queryRef }: Props) => {
  const { demand, createdAt, expirationPeriod, detailMessage, showAmountSummary, previewDetails } =
    useFragment(EstimationDetailPreviewFragment, queryRef);

  const details = mapPreviewDetailsToEstimationDetails(previewDetails);

  const detailsForCalculation = details.map((detail: EstimationDetail) => ({
    unitPrice: detail.unitPrice || 0,
    unitSellingPrice: detail.unitSellingPrice || 0,
    quantity: detail.quantity || 0,
    tax: detail.tax || 0,
  }));

  const items = deduplicateAdjacentNames<EstimationDetail>(details);

  return (
    <VStack align="stretch" p={10} spacing={6} borderWidth="1px" borderRadius="6px" w="full">
      <VStack align="stretch" spacing={0}>
        <HStack align="end" pb="1rem" justify="space-between">
          <SubHeading label="見積書" />
          <HStack spacing={4}>
            <Text fontSize="sm">{dateFormat(createdAt)}</Text>
          </HStack>
        </HStack>
        <Divider />
      </VStack>
      <HStack justify="space-between" align="flex-start">
        <VStack spacing={2} w="320px" align="start">
          <Text fontSize="xl" fontWeight="bold">
            {demand.name} 御中
          </Text>
          <Text fontSize="sm">有効期限：{expirationPeriod}</Text>
          <Text color="gray.500" fontSize="sm">
            下記のとおりお見積申し上げます
          </Text>
          {showAmountSummary && (
            <VStack spacing={1} w="full" h={41}>
              <HStack align="end" justify="space-between" w="full">
                <Text>お見積金額</Text>
                <HStack spacing={1} align="end">
                  <Text fontSize="2xl" fontWeight="bold">
                    {calculateTotalAmount(detailsForCalculation).toLocaleString()}
                  </Text>
                  <Text>円</Text>
                </HStack>
              </HStack>
              <Divider borderColor="gray.700" />
            </VStack>
          )}
        </VStack>
        <Box w="320px">
          <InformationShizai />
        </Box>
      </HStack>
      <VStack align="start" justify="space-between">
        <Table>
          <Thead>
            <Tr>
              <Th color="gray.700" w="100%" pl="0">
                品名
              </Th>
              <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap">
                数量
              </Th>
              <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap">
                単価
              </Th>
              <Th isNumeric color="gray.700" w="28rem" whiteSpace="nowrap" pr="0">
                金額 (税抜)
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.map((item, i) => (
              <Tr key={`detail-${item.name}-${i}`} verticalAlign="top">
                <Td pl="0" whiteSpace="pre-wrap" fontSize="xs">
                  {item.name}
                  {item.name !== '同上' && <EstimationDetailSpecPreview detail={item} />}
                </Td>
                <Td isNumeric color="gray.700" fontSize="xs">
                  {detailsForCalculation[i].quantity.toLocaleString()}
                </Td>
                <Td isNumeric color="gray.700" fontSize="xs">
                  {detailsForCalculation[i].unitSellingPrice.toLocaleString()}
                </Td>
                <Td isNumeric color="gray.700" pr="0" fontSize="xs">
                  {calculateDetailAmount({
                    quantity: detailsForCalculation[i].quantity,
                    price: detailsForCalculation[i].unitSellingPrice,
                  }).toLocaleString()}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </VStack>
      <HStack justify="space-between" align="flex-start">
        <VStack spacing={2} w="400px" align="start">
          <Text fontSize="sm">備考</Text>
          <Text whiteSpace="pre-wrap" fontSize="sm">
            {detailMessage}
          </Text>
        </VStack>
        {showAmountSummary && (
          <VStack align="left" spacing={2} w="240px">
            <Flex mt={2} justify="space-between">
              <Box w="140px" fontSize="sm">
                小計
              </Box>
              <Box fontSize="sm">
                {calculateSubtotalAmount(detailsForCalculation).toLocaleString()}
              </Box>
            </Flex>
            <Flex mt={4} justify="space-between">
              <Box whiteSpace="nowrap" w="140px" fontSize="sm">
                消費税
              </Box>
              <Box fontSize="sm">
                {calculateTotalTaxAmount(detailsForCalculation).toLocaleString()}
              </Box>
            </Flex>
            <Flex mt={4} justify="space-between">
              <Box w="140px" fontWeight="bold">
                合計金額
              </Box>
              <Box fontWeight="bold">
                {calculateTotalAmount(detailsForCalculation).toLocaleString()}
              </Box>
            </Flex>
          </VStack>
        )}
      </HStack>
    </VStack>
  );
};
