import { Flex } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { useFilterParameter } from 'features/demandItem/parameter/useFilterParameter';
import { DemandRecurringCostForm } from 'features/demandRecurringCost/components/DemandRecurringCostForm';
import { DemandRecurringCostFormType } from 'features/demandRecurringCost/form';
import { create_RecurringCostCreatePageQuery } from 'gql/__generated__/create_RecurringCostCreatePageQuery.graphql';
import { useNavigationBlocker } from 'hooks/useNavigationBlocker';
import { useFormContext } from 'react-hook-form';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';

const RecurringCostCreatePageQuery = graphql`
  query create_RecurringCostCreatePageQuery {
    ...DemandRecurringCostFormFragment
  }
`;

export const DemandRecurringCostCreatePage = () => {
  const navigate = useNavigate();
  const { demandCode } = useParams();
  if (!demandCode) {
    throw new MissingRequiredParamError('demandCode');
  }

  const { itemCategories, itemSuppliers } = useFilterParameter();

  const query = useLazyLoadQuery<create_RecurringCostCreatePageQuery>(
    RecurringCostCreatePageQuery,
    {
      fetchPolicy: 'network-only',
    },
  );

  const { allowNavigation } = useNavigationBlocker();

  const form = useFormContext<DemandRecurringCostFormType>();

  const handleFormConfirm = form.handleSubmit(() => {
    allowNavigation();
    navigate(paths.demands._demandCode(demandCode).recurringCosts.new.confirm.url());
  });

  return (
    <>
      <Flex justify="space-between" width="768px">
        <RouterLink
          to={paths.demands._demandCode(demandCode).url({
            itemCategories,
            itemSuppliers,
          })}
        >
          <PageBack />
        </RouterLink>
      </Flex>
      <SubHeading label="継続費用の追加" />
      <DemandRecurringCostForm navigateToConfirm={handleFormConfirm} queryRef={query} />
    </>
  );
};
