import { Button, Td, Tr } from '@chakra-ui/react';
import { DemandsTableRowFragment$key } from 'gql/__generated__/DemandsTableRowFragment.graphql';
import { useFragment } from 'react-relay';
import { Link as RouterLink } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';

const DemandsTableRowFragment = graphql`
  fragment DemandsTableRowFragment on Company {
    code
    name
    invoiceRecipients {
        id
        email
    }
  }
`;

export const DemandsTableRow = ({ queryRef }: { queryRef: DemandsTableRowFragment$key }) => {
  const { name, code, invoiceRecipients } = useFragment(DemandsTableRowFragment, queryRef);
  const recipients = invoiceRecipients?.map((recipient) => recipient.email) || [];

  return (
    <Tr>
      <Td>{code}</Td>
      <Td>{name || '-'} </Td>
      <Td>{recipients.join(' / ') || '-'} </Td>
      <Td w="40px">
        <Button
          as={RouterLink}
          colorScheme="blue"
          size="sm"
          to={paths.demands._demandCode(code).url()}
        >
          詳細
        </Button>
      </Td>
    </Tr>
  );
};
