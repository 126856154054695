import { DEMAND_ITEM_CATEGORY } from 'features/demandItem/models';
import {} from 'features/salesOrdersV2/models';

export type EstimationDetailSpecPreviewRow = {
  label: string;
  node: React.ReactNode | undefined;
};

export type EstimationDetailSpecCardboard = {
  size: string;
  type: string;
  material: string;
  thickness: string;
  printingColor: string;
  processing: string;
  other: string;
};

export type EstimationDetailSpecFlexiblePackage = {
  size: string;
  type: string;
  material: string;
  printingColor: string;
  processing: string;
  other: string;
};

export type EstimationDetailSpecGiftBox = {
  size: string;
  type: string;
  paperType: string;
  printingColor: string;
  processing: string;
  other: string;
};

export type EstimationDetailSpecPaperBag = {
  size: string;
  paperType: string;
  printingColor: string;
  processing: string;
  handle: string;
  other: string;
};

export type EstimationDetailSpecOther = {
  specText: string;
};

export type EstimationDetailSpecCost = {
  note: string;
};

export type EstimationDetailSpec = {
  cardboard?: EstimationDetailSpecCardboard | undefined;
  flexiblePackage?: EstimationDetailSpecFlexiblePackage | undefined;
  giftBox?: EstimationDetailSpecGiftBox | undefined;
  paperBag?: EstimationDetailSpecPaperBag | undefined;
  other?: EstimationDetailSpecOther | undefined;
  cost?: EstimationDetailSpecCost | undefined;
};

export const getItemSpecs = (
  category: string,
  spec: EstimationDetailSpec,
): EstimationDetailSpecPreviewRow[] => {
  switch (category) {
    case DEMAND_ITEM_CATEGORY.Cardboard:
      return getCardboardSpec(spec?.cardboard);
    case DEMAND_ITEM_CATEGORY.FlexiblePackage:
      return getFlexiblePackageSpec(spec?.flexiblePackage);
    case DEMAND_ITEM_CATEGORY.GiftBox:
      return getGiftBoxSpec(spec?.giftBox);
    case DEMAND_ITEM_CATEGORY.PaperBag:
      return getPaperBagSpec(spec?.paperBag);
    case DEMAND_ITEM_CATEGORY.Other:
      return getOtherSpec(spec?.other);
    case 'cost':
      return getCostSpec(spec?.cost);
    default:
      return [];
  }
};

const getCardboardSpec = (
  spec: EstimationDetailSpec['cardboard'],
): EstimationDetailSpecPreviewRow[] => {
  return [
    {
      label: 'サイズ',
      node: spec?.size,
    },
    {
      label: '形状',
      node: spec?.type,
    },
    {
      label: '構成',
      node: spec?.material,
    },
    {
      label: '段厚',
      node: spec?.thickness,
    },
    {
      label: '印刷',
      node: spec?.printingColor,
    },
    {
      label: '加工',
      node: spec?.processing,
    },
    {
      label: 'その他',
      node: spec?.other,
    },
  ];
};

const getFlexiblePackageSpec = (
  spec: EstimationDetailSpec['flexiblePackage'],
): EstimationDetailSpecPreviewRow[] => {
  return [
    {
      label: 'サイズ',
      node: spec?.size,
    },
    {
      label: '形状',
      node: spec?.type,
    },
    {
      label: '構成',
      node: spec?.material,
    },
    {
      label: '印刷',
      node: spec?.printingColor,
    },
    {
      label: '加工',
      node: spec?.processing,
    },
    {
      label: 'その他',
      node: spec?.other,
    },
  ];
};

const getGiftBoxSpec = (
  spec: EstimationDetailSpec['giftBox'],
): EstimationDetailSpecPreviewRow[] => {
  return [
    {
      label: 'サイズ',
      node: spec?.size,
    },
    {
      label: '形状',
      node: spec?.type,
    },
    {
      label: '用紙',
      node: spec?.paperType,
    },
    {
      label: '印刷',
      node: spec?.printingColor,
    },
    {
      label: '加工',
      node: spec?.processing,
    },
    {
      label: 'その他',
      node: spec?.other,
    },
  ];
};

const getPaperBagSpec = (
  spec: EstimationDetailSpec['paperBag'],
): EstimationDetailSpecPreviewRow[] => {
  return [
    {
      label: 'サイズ',
      node: spec?.size,
    },
    {
      label: '用紙',
      node: spec?.paperType,
    },
    {
      label: '印刷',
      node: spec?.printingColor,
    },
    {
      label: '加工',
      node: spec?.processing,
    },
    {
      label: '持ち手',
      node: spec?.handle,
    },
    {
      label: 'その他',
      node: spec?.other,
    },
  ];
};

const getOtherSpec = (spec: EstimationDetailSpec['other']): EstimationDetailSpecPreviewRow[] => {
  return [
    {
      label: 'その他',
      node: spec?.specText,
    },
  ];
};

export const getCostSpec = (
  spec: EstimationDetailSpec['cost'],
): EstimationDetailSpecPreviewRow[] => {
  return [
    {
      label: '補足',
      node: spec?.note,
    },
  ];
};
