import { Box, Button, VStack } from '@chakra-ui/react';
import { Link } from 'components/Link';
import { SalesOrdersDetailPageSidebarTransactionId } from 'features/salesOrdersV2/components/SalesOrdersDetailPageSidebarTransactionId';
import { SalesOrdersDetailPageSidebarFragment$key } from 'gql/__generated__/SalesOrdersDetailPageSidebarFragment.graphql';
import { useFragment } from 'react-relay';
import { useNavigate } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { dateFormat, dateFormatWithTime } from 'utils/date';
import { concatFullName } from 'utils/label';
import { paths } from 'utils/paths';

const SalesOrdersDetailPageSidebarFragment = graphql`
  fragment SalesOrdersDetailPageSidebarFragment on SalesOrder {
    id
    title
    createdAt
    demand {
      id
      name
      code
    }
    supplier {
      id
      name
    }
    createdBy {
      profile {
        lastName
        firstName
      }
    }
    transactionID
    receivedDate
    estimations {
      id
      title
      estimationResponse {
        assignee {
          estimationRequest {
            id
            requestTitle
          }
        }
      }
    }
    orders {
      id
      title
    }
    invoices {
      id
      title
    }
    internalAssignees {
      user {
        id
        profile {
          lastName
          firstName
        }
      }
    }
  }
`;

type Props = {
  queryRef: SalesOrdersDetailPageSidebarFragment$key;
};

export const SalesOrdersDetailPageSidebar = ({ queryRef }: Props) => {
  const navigate = useNavigate();

  const {
    id,
    title,
    transactionID,
    createdAt,
    demand,
    supplier,
    createdBy,
    estimations,
    orders,
    invoices,
    receivedDate,
    internalAssignees: internalAssigneesData,
  } = useFragment(SalesOrdersDetailPageSidebarFragment, queryRef);

  const estimationRequest = estimations
    ? estimations?.[0]?.estimationResponse?.assignee?.estimationRequest
    : null;
  const estimation = estimations ? estimations?.[0] : null;
  const order = orders ? orders[0] : null;
  const invoice = invoices ? invoices[0] : null;

  return (
    <VStack spacing={6} alignItems="flex-start" w="100%">
      <SalesOrdersDetailPageSidebarTransactionId transactionID={transactionID} />

      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">作成日時</Box>
        <Box>{dateFormatWithTime(createdAt)}</Box>
      </VStack>

      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">受注日</Box>
        <Box>{dateFormat(receivedDate)}</Box>
      </VStack>

      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">件名</Box>
        <Box>{title}</Box>
      </VStack>

      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">デマンド</Box>
        <Box>
          <Link to={paths.demands._demandCode(demand.code).url()}>{demand.name}</Link>
        </Box>
      </VStack>

      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">サプライヤー</Box>
        <Box>
          <Box>
            <Link to={paths.supplier._id(supplier.id).url()}>{supplier.name}</Link>
          </Box>
        </Box>
      </VStack>

      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">担当者</Box>
        <Box>
          {internalAssigneesData?.map((assignee) =>
            assignee.user.profile?.[0]?.lastName && assignee.user.profile?.[0]?.firstName ? (
              <Box key={assignee.user.id}>
                {concatFullName({
                  lastName: assignee.user.profile[0].lastName,
                  firstName: assignee.user.profile[0].firstName,
                })}
              </Box>
            ) : (
              <Box key={assignee.user.id}>-</Box>
            ),
          ) || '-'}
        </Box>
      </VStack>

      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">作成者</Box>
        <Box>
          {createdBy.profile?.[0]?.lastName && createdBy.profile?.[0]?.firstName
            ? concatFullName({
                lastName: createdBy.profile[0].lastName,
                firstName: createdBy.profile[0].firstName,
              })
            : '-'}
        </Box>
      </VStack>

      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">見積依頼</Box>
        <Box>
          {estimationRequest ? (
            <Link to={paths.estimationRequest._id(estimationRequest.id)}>
              {estimationRequest.requestTitle}
            </Link>
          ) : (
            'なし'
          )}
        </Box>
      </VStack>

      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">見積書</Box>
        <Box>
          {estimation ? (
            <Link to={paths.estimation._id(estimation.id).url}>{estimation.title}</Link>
          ) : (
            'なし'
          )}
        </Box>
      </VStack>

      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">発注書</Box>
        {order ? (
          <Link to={paths.order._id(order.id)}>{order.title}</Link>
        ) : (
          <Button
            colorScheme="blue"
            size="sm"
            onClick={() => navigate(paths.order.new.urlWithSalesOrderId(id))}
          >
            内容を引用して作成
          </Button>
        )}
      </VStack>

      <VStack spacing={1} alignItems="flex-start">
        <Box color="gray.500">請求書</Box>
        <Box>
          {invoice ? <Link to={paths.invoice._id(invoice.id)}>{invoice.title}</Link> : 'なし'}
        </Box>
      </VStack>
    </VStack>
  );
};
