import { Box, HStack, VStack } from '@chakra-ui/react';

type Props = {
  values: {
    label: React.ReactNode;
    node: React.ReactNode;
  }[];
};

export const MasterRegistrationSettingInfo = ({ values }: Props) => {
  return (
    <VStack spacing={3} alignItems="flex-start" fontSize="sm" w="578px">
      {values.map(({ label, node }, index) => (
        <HStack spacing={2} key={index} w="100%" alignItems="flex-start">
          <Box color="gray.500" w="5.5rem" whiteSpace="nowrap" textAlign="right">
            {label}
          </Box>
          <Box>{node}</Box>
        </HStack>
      ))}
    </VStack>
  );
};
