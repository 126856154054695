/**
 * @generated SignedSource<<114979a049ceaba3b99a589ee568e206>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ItemCategory = "cardboard" | "flexiblePackage" | "giftBox" | "other" | "paperBag" | "unknown" | "%future added value";
export type SalesOrderDetailType = "item" | "onetime_cost" | "recurring_cost" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SalesOrdersDetailPagePreviewFragment$data = {
  readonly demand: {
    readonly name: string;
  };
  readonly detailMessage: string | null;
  readonly details: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly branchNumber: any;
        readonly deliveryEvents: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly fixedDeliveryDate: any;
            } | null;
          } | null> | null;
        };
        readonly id: string;
        readonly item: {
          readonly cardboard: {
            readonly material: string;
            readonly other: string;
            readonly printingColor: string;
            readonly processing: string;
            readonly size: string;
            readonly thickness: string;
            readonly type: string;
          } | null;
          readonly category: ItemCategory | null;
          readonly flexiblePackage: {
            readonly material: string;
            readonly other: string;
            readonly printingColor: string;
            readonly processing: string;
            readonly size: string;
            readonly type: string;
          } | null;
          readonly giftBox: {
            readonly other: string;
            readonly paperType: string;
            readonly printingColor: string;
            readonly processing: string;
            readonly size: string;
            readonly type: string;
          } | null;
          readonly id: string;
          readonly name: string;
          readonly other: {
            readonly specText: string;
          } | null;
          readonly paperBag: {
            readonly handle: string;
            readonly other: string;
            readonly paperType: string;
            readonly printingColor: string;
            readonly processing: string;
            readonly size: string;
          } | null;
          readonly quantity: any;
          readonly taxCategory: {
            readonly id: string;
            readonly rate: number;
          };
          readonly unitPrice: number;
          readonly unitSellingPrice: number;
        } | null;
        readonly memos: ReadonlyArray<{
          readonly body: string;
        }> | null;
        readonly notionPage: {
          readonly notionPageURL: string;
        } | null;
        readonly onetimeCost: {
          readonly id: string;
          readonly name: string;
          readonly note: string;
          readonly quantity: any;
          readonly taxCategory: {
            readonly id: string;
            readonly rate: number;
          };
          readonly unitPrice: number;
          readonly unitSellingPrice: number;
        } | null;
        readonly recurringCost: {
          readonly id: string;
          readonly name: string;
          readonly note: string;
          readonly quantity: any;
          readonly taxCategory: {
            readonly id: string;
            readonly rate: number;
          };
          readonly unitPrice: number;
          readonly unitSellingPrice: number;
        } | null;
        readonly type: SalesOrderDetailType;
      } | null;
    } | null> | null;
  };
  readonly receivedDate: any;
  readonly transactionID: any;
  readonly " $fragmentType": "SalesOrdersDetailPagePreviewFragment";
};
export type SalesOrdersDetailPagePreviewFragment$key = {
  readonly " $data"?: SalesOrdersDetailPagePreviewFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SalesOrdersDetailPagePreviewFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitPrice",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitSellingPrice",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "TaxCategory",
  "kind": "LinkedField",
  "name": "taxCategory",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rate",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "material",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "printingColor",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "processing",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "other",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paperType",
  "storageKey": null
},
v13 = [
  (v1/*: any*/),
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "note",
    "storageKey": null
  },
  (v5/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v6/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SalesOrdersDetailPagePreviewFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transactionID",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receivedDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "demand",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "detailMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "direction": "ASC",
            "field": "ORDER_NUMBER"
          }
        }
      ],
      "concreteType": "SalesOrderDetailConnection",
      "kind": "LinkedField",
      "name": "details",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SalesOrderDetailEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SalesOrderDetail",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "branchNumber",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SalesOrderDetailMemo",
                  "kind": "LinkedField",
                  "name": "memos",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "body",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "where",
                      "value": {
                        "isValid": true
                      }
                    }
                  ],
                  "concreteType": "SalesOrderDetailDeliveryEventConnection",
                  "kind": "LinkedField",
                  "name": "deliveryEvents",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesOrderDetailDeliveryEventEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SalesOrderDetailDeliveryEvent",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "fixedDeliveryDate",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "deliveryEvents(where:{\"isValid\":true})"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SalesOrderItem",
                  "kind": "LinkedField",
                  "name": "item",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "category",
                      "storageKey": null
                    },
                    (v3/*: any*/),
                    (v4/*: any*/),
                    (v5/*: any*/),
                    (v6/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesOrderItemCardboard",
                      "kind": "LinkedField",
                      "name": "cardboard",
                      "plural": false,
                      "selections": [
                        (v7/*: any*/),
                        (v2/*: any*/),
                        (v8/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "thickness",
                          "storageKey": null
                        },
                        (v9/*: any*/),
                        (v10/*: any*/),
                        (v11/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesOrderItemFlexiblePackage",
                      "kind": "LinkedField",
                      "name": "flexiblePackage",
                      "plural": false,
                      "selections": [
                        (v7/*: any*/),
                        (v2/*: any*/),
                        (v8/*: any*/),
                        (v9/*: any*/),
                        (v10/*: any*/),
                        (v11/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesOrderItemGiftBox",
                      "kind": "LinkedField",
                      "name": "giftBox",
                      "plural": false,
                      "selections": [
                        (v7/*: any*/),
                        (v2/*: any*/),
                        (v12/*: any*/),
                        (v9/*: any*/),
                        (v10/*: any*/),
                        (v11/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesOrderItemPaperBag",
                      "kind": "LinkedField",
                      "name": "paperBag",
                      "plural": false,
                      "selections": [
                        (v7/*: any*/),
                        (v12/*: any*/),
                        (v9/*: any*/),
                        (v10/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "handle",
                          "storageKey": null
                        },
                        (v11/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesOrderItemOther",
                      "kind": "LinkedField",
                      "name": "other",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "specText",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SalesOrderRecurringCost",
                  "kind": "LinkedField",
                  "name": "recurringCost",
                  "plural": false,
                  "selections": (v13/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SalesOrderOnetimeCost",
                  "kind": "LinkedField",
                  "name": "onetimeCost",
                  "plural": false,
                  "selections": (v13/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SalesOrderDetailNotionPage",
                  "kind": "LinkedField",
                  "name": "notionPage",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "notionPageURL",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "details(orderBy:{\"direction\":\"ASC\",\"field\":\"ORDER_NUMBER\"})"
    }
  ],
  "type": "SalesOrder",
  "abstractKey": null
};
})();

(node as any).hash = "6087daa931a503ffaf438a58b3c1088d";

export default node;
