import { DEMAND_ITEM_CATEGORY } from 'features/demandItem/models';
import { EstimationFormType } from 'features/estimations/form';
import { ESTIMATION_DETAIL_CATEGORY_COST } from 'features/estimations/form/schema';
import { EstimationDetailDocumentDisplayStatus } from 'features/estimations/models';
import { layout_EstimationCreateLayoutQuery$data } from 'gql/__generated__/layout_EstimationCreateLayoutQuery.graphql';
import { concatFullName } from 'utils/label';

export type EstimationResponseType = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<layout_EstimationCreateLayoutQuery$data['originalEstimation']>['edges']
    >[0]
  >['node']
>;

export const mapEstimationToEstimationForm = (
  estimation: EstimationResponseType,
): EstimationFormType => {
  const assignees =
    estimation.internalAssignees?.map((assignee) => ({
      value: assignee.user.id,
      label: concatFullName({
        lastName: assignee.user.profile?.[0]?.lastName || '',
        firstName: assignee.user.profile?.[0]?.firstName || '',
      }),
    })) || [];

  const details = [...(estimation.details?.edges || [])]
    .map((edge) => edge?.node)
    .filter((v): v is NonNullable<typeof v> => v != null);

  return {
    demand: {
      id: estimation.demand.id,
      name: estimation.demand.name,
    },
    supplier: {
      id: estimation.supplier.id,
      name: estimation.supplier.name,
    },
    title: estimation.title,
    detailMessage: estimation.detailMessage || '',
    expirationPeriod: estimation.expirationPeriod,
    showAmountSummary: estimation.showAmountSummary,
    internalAssignees: assignees,
    details: mapEstimationToEstimationFormDetails(details),
  };
};

type EstimationResponseDetailType = NonNullable<
  NonNullable<NonNullable<NonNullable<EstimationResponseType['details']>['edges']>[0]>['node']
>;

const mapEstimationToEstimationFormDetails = (details: EstimationResponseDetailType[]) => {
  return details.map((detail) => {
    if (detail.type === ESTIMATION_DETAIL_CATEGORY_COST) {
      const cost = detail.cost;
      return {
        name: cost?.name || '',
        unitPrice: cost?.unitPrice || 0,
        unitSellingPrice: cost?.unitSellingPrice || 0,
        quantity: cost?.quantity || 0,
        tax: {
          id: cost?.taxCategory?.id || '',
          rate: cost?.taxCategory?.rate || 0,
        },
        documentDisplayStatus:
          detail.documentDisplayStatus as EstimationDetailDocumentDisplayStatus,
        category: ESTIMATION_DETAIL_CATEGORY_COST,
        spec: {
          cost: {
            note: cost?.note || '',
          },
        },
      };
    }

    const item = detail.item;
    return {
      name: item?.name || '',
      unitPrice: item?.unitPrice || 0,
      quantity: item?.quantity || 0,
      unitSellingPrice: item?.unitSellingPrice || 0,
      tax: {
        id: item?.taxCategory?.id || '',
        rate: item?.taxCategory?.rate || 0,
      },
      documentDisplayStatus: detail.documentDisplayStatus as EstimationDetailDocumentDisplayStatus,
      category: item?.category || DEMAND_ITEM_CATEGORY.Cardboard,
      spec: {
        cardboard: item?.cardboard || {
          size: '',
          type: '',
          material: '',
          thickness: '',
          printingColor: '',
          processing: '',
          other: '',
        },
        flexiblePackage: item?.flexiblePackage || {
          size: '',
          type: '',
          material: '',
          printingColor: '',
          processing: '',
          other: '',
        },
        giftBox: item?.giftBox || {
          size: '',
          type: '',
          paperType: '',
          printingColor: '',
          processing: '',
          other: '',
        },
        paperBag: item?.paperBag || {
          size: '',
          paperType: '',
          printingColor: '',
          processing: '',
          handle: '',
          other: '',
        },
        other: {
          specText: item?.other?.specText || '',
        },
      },
    };
  });
};
