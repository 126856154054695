import { Box, Button, HStack, Icon, Text } from '@chakra-ui/react';
import { Info, Loading } from '@icon-park/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { SalesOrdersEditForm } from 'features/salesOrdersV2/components/SalesOrdersEditForm';
import { SalesOrdersEditFormType } from 'features/salesOrdersV2/form';
import { useNavigationBlocker } from 'hooks/useNavigationBlocker';
import { toast } from 'lib/toast';
import { Suspense } from 'react';
import { SubmitErrorHandler, useFormContext } from 'react-hook-form';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { paths } from 'utils/paths';

export const SalesOrdersEditPage = () => {
  const navigate = useNavigate();

  const { getValues, handleSubmit } = useFormContext<SalesOrdersEditFormType>();

  const salesOrderId = getValues('salesOrderId');
  const detailPagePath = paths.salesOrders.id(salesOrderId).url();
  const editConfirmPagePath = paths.salesOrders.id(salesOrderId).edit.confirm.url();

  const { allowNavigation } = useNavigationBlocker();

  const handleValidSubmit = () => {
    allowNavigation();
    navigate(editConfirmPagePath);
  };

  const handleSubmitErrors: SubmitErrorHandler<SalesOrdersEditFormType> = (submitErrors) => {
    if (submitErrors.details) {
      toast({
        title: submitErrors.details.message,
        status: 'error',
      });
      return;
    }
  };

  return (
    <Suspense fallback={<Loading />}>
      <Box>
        <RouterLink to={detailPagePath}>
          <PageBack />
        </RouterLink>
      </Box>

      <Box mt={6}>
        <SubHeading label="発注請書の編集" />
      </Box>

      <HStack
        w="fit-content"
        mt={6}
        px={4}
        py={3}
        borderWidth={2}
        borderColor="gray.500"
        borderRadius="0.375rem"
        alignItems="center"
      >
        <Icon as={Info} w={5} h={5} size={20} color="gray.500" />
        <Text ml={1} fontSize="sm" fontWeight="bold">
          品名・仕様を更新したい場合、マスタを直接編集して商品を選び直してください
        </Text>
        <Button
          as={RouterLink}
          to={paths.demands._demandCode(getValues('demand').code).url()}
          target="_blank"
          size="xs"
          colorScheme="blue"
          borderRadius="4px"
        >
          マスタ一覧へ
        </Button>
      </HStack>

      <Box mt={6}>
        <form onSubmit={handleSubmit(handleValidSubmit, handleSubmitErrors)}>
          <SalesOrdersEditForm />
        </form>
      </Box>
    </Suspense>
  );
};
