import { Box, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { EfferentFour, Notes } from '@icon-park/react';
import { Link } from 'components/Link';
import { Loading } from 'components/Loading';
import { useDeleteDemandItem } from 'features/demandItem/api/useDeleteDemandItem';
import { useDemandItemPrimaryImageThumbnails } from 'features/demandItem/api/useDemandItemPrimaryImageThumbnails';
import {
  DemandItemDetailBlock,
  DemandItemDetailBlockBody,
  DemandItemDetailBlockFooter,
  DemandItemDetailBlockInfo,
  DemandItemDetailBlockSpecTableContainer,
} from 'features/demandItem/components/DemandItemDetailBlock';
import { DemandItemDetailBlockNoImageThumbnail } from 'features/demandItem/components/DemandItemDetailBlock/DemandItemDetailBlockNoImageThumbnail';
import { DemandItemDetailBlockNoItem } from 'features/demandItem/components/DemandItemDetailBlock/DemandItemDetailBlockNoItem';
import { DemandItemDetailBlockThumbnail } from 'features/demandItem/components/DemandItemDetailBlock/DemandItemDetailBlockThumbnail';
import { DemandItemImageSlider } from 'features/demandItem/components/DemandItemImageSlider';
import { DemandItemPriceTable } from 'features/demandItem/components/DemandItemPriceTable';
import { useDemandItemImageSlider } from 'features/demandItem/hooks/useDemandItemImageSlider';
import {
  DEMAND_ITEM_CATEGORY,
  DEMAND_ITEM_CATEGORY_LABEL,
  DemandItemCategory,
  buildDemandItemCode,
} from 'features/demandItem/models';
import { DemandItemDetailBlocksQuery } from 'gql/__generated__/DemandItemDetailBlocksQuery.graphql';
import {
  ItemCategory as DemandItemDetailBlocksItemCategory,
  DemandItemDetailBlocksRefetchFragment$key,
} from 'gql/__generated__/DemandItemDetailBlocksRefetchFragment.graphql';
import { useEffect, useTransition } from 'react';
import { graphql, useLazyLoadQuery, useRefetchableFragment } from 'react-relay';
import { paths } from 'utils/paths';

// 本来、detail_DemandDetailPageQueryにFragmentを渡して1回のリクエストでデータを取得したいが、
// そのやり方で、useRefetchableFragmentを使用するとエラーになる。
// そのため、Fragmentを親コンポーネントに渡さず、DemandItemDetailBlocks内でQueryを発行しデータを取得するようにしている。
const demandItemDetailBlocksQuery = graphql`
  query DemandItemDetailBlocksQuery  ($demandId: ID!, $categories: [ItemCategory!], $supplierIds: [ID!]) {
    ...DemandItemDetailBlocksRefetchFragment @arguments(demandId: $demandId, categories: $categories, supplierIds: $supplierIds)
  }
`;

const demandItemDetailBlocksRefetchFragment = graphql`
  fragment DemandItemDetailBlocksRefetchFragment on Query
  @refetchable(queryName: "DemandItemDetailBlocksRefetchQuery")
  @argumentDefinitions(demandId: {type: "ID"}, categories: {type: "[ItemCategory!]"}, supplierIds: {type: "[ID!]"})
  {
    demandItems(where: {isCurrent: true, demandID: $demandId, categoryIn: $categories, supplierIDIn: $supplierIds}, orderBy: {field: UPDATED_AT, direction: DESC}) {
      edges {
        node {
          id
          name
          category
          supplier {
            name
          }
          demand {
            id
            code
          }
          prices {
            id
            quantity
            unitPrice
            unitSellingPrice
          }
          images {
            id
            displayOrder
          }
          attachments {
            fileName
          }
          itemNumber
          version
          internalMemos {
            body
          }
          tax: taxCategory {
            name
          }
          activeSalesOrderItems: salesOrderItems(
            where: {
              hasSalesOrderDetailWith: {
                deletedAtIsNil: true,
                hasSalesOrderWith: {
                  deletedAtIsNil: true,
                },
              }
            },
            first: 1
          ) {
            edges {
              node {
                id
              }
            }
          }
          latestOrderInfo: salesOrderItems(
            where: {
              hasSalesOrderDetailWith: {
                deletedAtIsNil: true,
                hasSalesOrderWith: {
                  deletedAtIsNil: true,
                  hasOrdersWith: {
                    deletedAtIsNil: true,
                  },
                }
              }
            },
            orderBy: {direction:DESC, field:CREATED_AT},
            first: 1
          ) {
            edges {
              node {
                salesOrderDetail {
                  salesOrder {
                    orders {
                      id
                      title
                    }
                  }
                }
              }
            }
          }
          ...DemandItemDetailBlockSpecTableContainerFragment
        }
      }
    }
  }
`;

type Props = {
  demandId: string;
  itemCategories: DemandItemCategory[];
  itemSuppliers: string[];
};

// TODO: keyにDemandItemDetailBlocksItemCategory型を使用したオブジェクトにリファクタ予定
const getDemandItemCategory = (category: DemandItemDetailBlocksItemCategory) => {
  if (category === DEMAND_ITEM_CATEGORY.Cardboard) return DEMAND_ITEM_CATEGORY.Cardboard;
  if (category === DEMAND_ITEM_CATEGORY.FlexiblePackage)
    return DEMAND_ITEM_CATEGORY.FlexiblePackage;
  if (category === DEMAND_ITEM_CATEGORY.GiftBox) return DEMAND_ITEM_CATEGORY.GiftBox;
  if (category === DEMAND_ITEM_CATEGORY.PaperBag) return DEMAND_ITEM_CATEGORY.PaperBag;
  return DEMAND_ITEM_CATEGORY.Other;
};

export const DemandItemDetailBlocks = ({ demandId, itemCategories, itemSuppliers }: Props) => {
  const query = useLazyLoadQuery<DemandItemDetailBlocksQuery>(
    demandItemDetailBlocksQuery,
    { demandId, categories: itemCategories, supplierIds: itemSuppliers },
    { fetchPolicy: 'network-only' },
  );

  const [data, refetch] = useRefetchableFragment(
    demandItemDetailBlocksRefetchFragment,
    query as DemandItemDetailBlocksRefetchFragment$key,
  );
  const { demandItems } = data;
  const demandItemsHasImages =
    demandItems.edges?.filter(
      (edge) => edge?.node?.images?.length !== undefined && edge?.node?.images?.length > 0,
    ) || [];

  const { data: thumbnails, fetch: fetchThumbnails } = useDemandItemPrimaryImageThumbnails({
    items: demandItemsHasImages.map((item) => ({
      id: item?.node?.id || '',
    })),
  });

  const [isPending, startTransition] = useTransition();
  const { selectedItemId, imageCount, isOpenSlider, handleOpenSlider, handleCloseSlider } =
    useDemandItemImageSlider();
  const { deleteDemandItem, isMutationInFlight } = useDeleteDemandItem();

  useEffect(() => {
    fetchThumbnails();
  }, [data]);

  if (
    !demandItems ||
    !demandItems.edges ||
    demandItems.edges.length === 0 ||
    !demandItems.edges[0]?.node ||
    demandItems.edges[0]?.node === null
  )
    return <DemandItemDetailBlockNoItem />;

  const items = demandItems.edges
    .map((edge) => {
      if (!edge?.node) return null;
      return edge.node;
    })
    .filter((value) => value != null);

  const handleDeleteCallback = () => {
    startTransition(() => {
      refetch(
        { demandId, categories: itemCategories, supplierIds: itemSuppliers },
        { fetchPolicy: 'network-only' },
      );
    });
  };

  return (
    <>
      {isOpenSlider && (
        <DemandItemImageSlider
          itemId={selectedItemId}
          imageCount={imageCount}
          isOpen={isOpenSlider}
          onClose={handleCloseSlider}
        />
      )}

      {isPending ? (
        <Box width="100%" textAlign="center" mt={40}>
          <Loading />
        </Box>
      ) : (
        items.map((item) => {
          const latestOrder =
            item.latestOrderInfo.edges?.[0]?.node?.salesOrderDetail?.salesOrder?.orders?.[0];
          const isDeletable = item.activeSalesOrderItems.edges?.length === 0;
          const imageCount = item.images?.length ?? 0;
          const thumbnailUrl = thumbnails?.find((data) => data?.itemID === item.id)?.url;
          const demandItemCode = buildDemandItemCode({
            demandCode: item.demand.code,
            category: item.category as DemandItemCategory,
            itemNumber: item.itemNumber,
            version: item.version,
          });

          const getDetailBlockInfoValues = () => {
            const baseValues = [
              {
                label: 'コード',
                node: demandItemCode,
              },
              {
                label: 'サプライヤー',
                node: item.supplier?.name,
              },
              {
                label: 'カテゴリー',
                node: DEMAND_ITEM_CATEGORY_LABEL[getDemandItemCategory(item.category)].categoryName,
              },
              {
                label: '社内メモ',
                node: <Text whiteSpace="pre-wrap">{item.internalMemos?.[0]?.body || '-'}</Text>,
              },
              {
                label: '最終発注',
                node: latestOrder ? (
                  <Link to={paths.order._id(latestOrder.id)} target="_blank">
                    <HStack gap={1}>
                      <Box>{latestOrder.title}</Box>
                      <EfferentFour theme="outline" />
                    </HStack>
                  </Link>
                ) : (
                  '-'
                ),
              },
            ];

            if (item.attachments && item.attachments.length > 0) {
              baseValues.push({
                label: 'その他のデータ',
                node: (
                  <VStack spacing={1} alignItems="flex-start">
                    {Array.from(item.attachments).map((attachment, index) => (
                      <HStack
                        key={index}
                        w="fit-content"
                        maxW="282px"
                        minH="24px"
                        spacing={1}
                        alignItems="flex-start"
                      >
                        <Icon mt="4.5px" w={4} h={4} size={16} as={Notes} />
                        <Text fontSize="sm" whiteSpace="pre-wrap">
                          {attachment.fileName}
                        </Text>
                      </HStack>
                    ))}
                  </VStack>
                ),
              });
            }

            return baseValues;
          };

          return (
            <DemandItemDetailBlock key={item.id}>
              <DemandItemDetailBlockSpecTableContainer queryRef={item} />
              <DemandItemDetailBlockBody>
                <HStack spacing={4}>
                  {imageCount > 0 ? (
                    <Box
                      onClick={() => handleOpenSlider(item.id, item.images?.length ?? 0)}
                      cursor="pointer"
                    >
                      <DemandItemDetailBlockThumbnail
                        thumbnailUrl={thumbnailUrl}
                        imageCount={imageCount}
                      />
                    </Box>
                  ) : (
                    <DemandItemDetailBlockNoImageThumbnail />
                  )}
                  <DemandItemDetailBlockInfo values={getDetailBlockInfoValues()} />
                </HStack>
                <Box w="382px">
                  <DemandItemPriceTable
                    values={(item.prices || []).map((price) => ({
                      id: price.id,
                      quantity: price.quantity,
                      unitPrice: price.unitPrice,
                      unitSellingPrice: price.unitSellingPrice,
                    }))}
                    tax={item.tax.name}
                  />
                </Box>
              </DemandItemDetailBlockBody>
              <DemandItemDetailBlockFooter
                editPath={paths.demands
                  ._demandCode(item.demand.code)
                  .items._itemCode({
                    itemNumber: item.itemNumber,
                    category: item.category as DemandItemCategory,
                  })
                  .edit.url()}
                detailPath={paths.demands
                  ._demandCode(item.demand.code)
                  .items._itemCode({
                    category: item.category as DemandItemCategory,
                    itemNumber: item.itemNumber,
                  })
                  .url()}
                onDelete={(callback) => {
                  deleteDemandItem(item.id, () => {
                    callback && callback();
                    handleDeleteCallback();
                  });
                }}
                isDeletable={isDeletable}
                isDeleting={isMutationInFlight}
              />
            </DemandItemDetailBlock>
          );
        })
      )}
    </>
  );
};
