import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { Loading } from '@icon-park/react';
import { AddOne } from '@icon-park/react';
import { Link } from 'components/Link';
import { LoadMoreButton } from 'components/LoadMoreButton';
import { PageHeading } from 'components/PageHeading';
import { DemandsTableRow } from 'features/demand/components/DemandsTableRow';
import { demandsPageFragment$key } from 'gql/__generated__/demandsPageFragment.graphql';
import { demandsPageQuery } from 'gql/__generated__/demandsPageQuery.graphql';
import { KeyboardEvent, useState, useTransition } from 'react';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';

const DemandsPageQuery = graphql`
  query demandsPageQuery {
    ...demandsPageFragment
  }
`;

const DemandsPageFragment = graphql`
  fragment demandsPageFragment on Query
    @refetchable(queryName: "demandsPageFragmentRefetchQuery")
    @argumentDefinitions (
      count: { type: "Int", defaultValue: 100 }
      after: { type: "Cursor" }
      direction: { type: "OrderDirection", defaultValue: DESC }
      field: { type: "CompanyOrderField", defaultValue: CREATED_AT }
      where: { type: "CompanyWhereInput", defaultValue: null }
    )
  {
    companies(after: $after, first: $count, orderBy: {direction: $direction, field: $field}, where: $where) @connection(key: "demandsPageFragment_companies") {
      edges {
        cursor
        node {
          id
          ...DemandsTableRowFragment
        }
      }
      totalCount
    }
  }
`;

export function DemandsPage() {
  const [isPending, startTransition] = useTransition();
  const [searchValue, setSearchValue] = useState('');
  const query = useLazyLoadQuery<demandsPageQuery>(
    DemandsPageQuery,
    {},
    { fetchPolicy: 'network-only' },
  );
  const { data, hasNext, loadNext, refetch, isLoadingNext } = usePaginationFragment(
    DemandsPageFragment,
    query as demandsPageFragment$key,
  );

  const handleClickMore = () => loadNext(100);

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      startTransition(() => {
        refetch({ where: { nameContains: searchValue } });
      });
    }
  };

  const demands = (data.companies.edges || [])
    .map((edge) => {
      if (!edge?.node) return null;
      return edge.node;
    })
    .filter((value) => value != null);

  return (
    <VStack spacing={8} alignItems="flex-start" width="100%">
      <PageHeading label="デマンド" />
      <Button
        colorScheme="blue"
        width="208px"
        as={Link}
        to={paths.demands.new.url()}
        leftIcon={<AddOne />}
      >
        新規デマンド追加
      </Button>
      <FormControl width="360px">
        <FormLabel mb={1}>デマンド名</FormLabel>
        <Input
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          placeholder="検索"
          onKeyPress={handleKeyPress}
        />
      </FormControl>
      {isPending ? (
        <Loading />
      ) : (
        <Box width="100%">
          <Table size="md">
            <Thead>
              <Tr whiteSpace="nowrap">
                <Th w="4.5rem">コード</Th>
                <Th w="31.75rem">デマンド名</Th>
                <Th w="31.75rem">請求書送付先</Th>
                <Th w="4.5rem" />
              </Tr>
            </Thead>
            <Tbody>
              {demands.map((demand) => (
                <DemandsTableRow key={demand.id} queryRef={demand} />
              ))}
            </Tbody>
          </Table>
          <Box my={3} mx={4}>
            <LoadMoreButton
              hasNext={hasNext}
              onClickMore={handleClickMore}
              totalCount={data.companies.totalCount}
              currentLength={data.companies.edges?.length}
              loading={isLoadingNext}
            />
          </Box>
        </Box>
      )}
    </VStack>
  );
}
