import { Box, Flex, HStack, VStack } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { useQueryParams } from 'features/estimation/hooks/useEstimatoinQueryPrams';
import { detail_EstimationsDetailPageQuery as detail_EstimationsDetailPageQueryType } from 'gql/__generated__/detail_EstimationsDetailPageQuery.graphql';
import { useLazyLoadQuery } from 'react-relay';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';
import { EstimationDetailAside } from './components/EstimationDetailAside';
import { EstimationDetailHeader } from './components/EstimationDetailHeader';
import { EstimationDetailItemPriceList } from './components/EstimationDetailItemPriceList';
import { EstimationDetailPreview } from './components/EstimationDetailPreview';

const EstimationsDetailPageQuery = graphql`
  query detail_EstimationsDetailPageQuery  ($id: ID!) {
    estimations(where: {id: $id}) {
      edges {
        node {
          ...EstimationDetailHeaderFragment
          ...EstimationDetailAsideFragment
          ...EstimationDetailPreviewFragment
          ...EstimationDetailItemPriceListFragment
        }
      }
    }
  }
`;

export const EstimationsDetailPage = () => {
  const { queryParams } = useQueryParams();
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const { estimations } = useLazyLoadQuery<detail_EstimationsDetailPageQueryType>(
    EstimationsDetailPageQuery,
    {
      id,
    },
    { fetchPolicy: 'network-only' },
  );

  const estimation = (estimations.edges || [])[0]?.node;

  if (!estimation) return null;

  return (
    <Box w="992px">
      <Box mb={6}>
        <Flex justify="space-between">
          <RouterLink
            to={paths.estimations.url({
              assignee: queryParams.assignee,
              demand: queryParams.demand,
              supplier: queryParams.supplier,
            })}
          >
            <PageBack />
          </RouterLink>
          <EstimationDetailHeader queryRef={estimation} />
        </Flex>
      </Box>
      <Box mb={10}>
        <Box mb={6}>
          <SubHeading label="見積書の詳細" />
        </Box>
        <HStack spacing={4} align="start">
          <Box w="208px">
            <EstimationDetailAside queryRef={estimation} />
          </Box>
          <VStack w="768px" spacing={8}>
            <EstimationDetailPreview queryRef={estimation} />
            <EstimationDetailItemPriceList queryRef={estimation} />
          </VStack>
        </HStack>
      </Box>
    </Box>
  );
};
