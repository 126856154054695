import { CloseIcon } from '@chakra-ui/icons';
import { Box, IconButton } from '@chakra-ui/react';
import { EstimationFormType } from 'features/estimations/form';
import { useFormContext } from 'react-hook-form';

type Props = {
  remove: () => void;
};

export const EstimationFormLineItemDeleteButton = ({ remove }: Props) => {
  const form = useFormContext<EstimationFormType>();
  const details = form.getValues('details');
  const isDisabledDelete = details.length === 1;

  return (
    <Box>
      <IconButton
        mt="25px"
        size="sm"
        onClick={remove}
        variant="ghost"
        isDisabled={isDisabledDelete}
        isRound
        aria-label="close"
        icon={<CloseIcon />}
      />
    </Box>
  );
};
