import {
  Box,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useToken,
} from '@chakra-ui/react';
import { Calendar, DeleteKey } from '@icon-park/react';
import { ja } from 'date-fns/locale';
import { forwardRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { cdateJST, dateFormat } from 'utils/date';

registerLocale('ja', ja);

type CustomInputProps = {
  onClear: () => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

// カスタム入力コンポーネント
const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
  ({ onClick, value, onClear }, ref) => {
    const gray400 = useToken('colors', 'gray.400');

    return (
      <InputGroup>
        <InputLeftElement pl={3} pr={2}>
          <Calendar size="16" />
        </InputLeftElement>
        <Input
          value={value || ''}
          onClick={onClick}
          placeholder="全期間"
          ref={ref}
          readOnly
          fontSize="sm"
          sx={{
            '&::placeholder': {
              color: 'gray.900',
            },
          }}
        />
        {value && (
          <InputRightElement pl={2} pr={3}>
            <IconButton
              aria-label="clear-date-range"
              icon={<DeleteKey size="1rem" fill={gray400} />}
              size="sm"
              variant="text"
              onClick={onClear}
            />
          </InputRightElement>
        )}
      </InputGroup>
    );
  },
);

type DateRangePickerProps = {
  startDate: Date | null;
  endDate: Date | null;
  onChange: (dates: [Date | null, Date | null]) => void;
  onClear: () => void;
};

export const DateRangePicker = ({
  startDate,
  endDate,
  onChange,
  onClear,
}: DateRangePickerProps) => {
  let displayValue = '';

  if (startDate && endDate) {
    const formattedStartDate = dateFormat(startDate);
    displayValue = `${formattedStartDate} - ${dateFormat(endDate)}`;
  } else if (startDate) {
    displayValue = `${dateFormat(startDate)} - `;
  }

  return (
    <Box
      sx={{
        '.date-picker-full-width': {
          width: '100%',
        },
      }}
    >
      <DatePicker
        selectsRange
        dateFormatCalendar="yyyy年 MM月"
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        locale="ja"
        customInput={<CustomInput value={displayValue} onClear={onClear} />}
        dateFormat="yyyy/MM/dd"
        minDate={cdateJST().add(-1, 'year').startOf('month').toDate()}
        maxDate={cdateJST().add(1, 'year').endOf('month').toDate()}
        showMonthYearDropdown
        dropdownMode="select"
        wrapperClassName="date-picker-full-width"
      />
    </Box>
  );
};
