/**
 * @generated SignedSource<<8e98e09791500b98f37ef0aea886c1f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ItemCategory = "cardboard" | "flexiblePackage" | "giftBox" | "other" | "paperBag" | "unknown" | "%future added value";
export type SalesOrderDetailType = "item" | "onetime_cost" | "recurring_cost" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SalesOrdersDetailPageMasterDataFragment$data = {
  readonly details: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly item: {
          readonly demandItem: {
            readonly attachments: ReadonlyArray<{
              readonly fileName: string;
            }> | null;
            readonly category: ItemCategory;
            readonly demand: {
              readonly code: string;
            };
            readonly id: string;
            readonly images: ReadonlyArray<{
              readonly displayOrder: any;
              readonly id: string;
            }> | null;
            readonly internalMemos: ReadonlyArray<{
              readonly body: string;
            }> | null;
            readonly itemNumber: any;
            readonly name: string;
            readonly prices: ReadonlyArray<{
              readonly id: string;
              readonly quantity: any;
              readonly unitPrice: number;
              readonly unitSellingPrice: number;
            }> | null;
            readonly salesOrderItems: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly salesOrderDetail: {
                    readonly salesOrder: {
                      readonly orders: ReadonlyArray<{
                        readonly id: string;
                        readonly title: string;
                      }> | null;
                    };
                  };
                } | null;
              } | null> | null;
            };
            readonly supplier: {
              readonly name: string;
            };
            readonly tax: {
              readonly id: string;
              readonly name: string;
              readonly rate: number;
            };
            readonly version: any;
            readonly " $fragmentSpreads": FragmentRefs<"DemandItemDetailBlockSpecTableContainerFragment">;
          } | null;
        } | null;
        readonly recurringCost: {
          readonly demandRecurringCost: {
            readonly costNumber: any;
            readonly demand: {
              readonly code: string;
            };
            readonly id: string;
            readonly internalMemos: ReadonlyArray<{
              readonly body: string;
            }> | null;
            readonly name: string;
            readonly note: string;
            readonly prices: ReadonlyArray<{
              readonly id: string;
              readonly quantity: any;
              readonly unitPrice: number;
              readonly unitSellingPrice: number;
            }> | null;
            readonly salesOrderRecurringCosts: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly salesOrderDetail: {
                    readonly salesOrder: {
                      readonly orders: ReadonlyArray<{
                        readonly id: string;
                        readonly title: string;
                      }> | null;
                    };
                  };
                } | null;
              } | null> | null;
            };
            readonly supplier: {
              readonly name: string;
            } | null;
            readonly tax: {
              readonly id: string;
              readonly name: string;
              readonly rate: number;
            };
          } | null;
        } | null;
        readonly type: SalesOrderDetailType;
      } | null;
    } | null> | null;
  };
  readonly " $fragmentType": "SalesOrdersDetailPageMasterDataFragment";
};
export type SalesOrdersDetailPageMasterDataFragment$key = {
  readonly " $data"?: SalesOrdersDetailPageMasterDataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SalesOrdersDetailPageMasterDataFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Company",
  "kind": "LinkedField",
  "name": "demand",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Supplier",
  "kind": "LinkedField",
  "name": "supplier",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "body",
    "storageKey": null
  }
],
v5 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "quantity",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unitPrice",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unitSellingPrice",
    "storageKey": null
  }
],
v6 = {
  "alias": "tax",
  "args": null,
  "concreteType": "TaxCategory",
  "kind": "LinkedField",
  "name": "taxCategory",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rate",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "direction": "DESC",
      "field": "CREATED_AT"
    }
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "hasSalesOrderDetailWith": {
        "deletedAtIsNil": true,
        "hasSalesOrderWith": {
          "deletedAtIsNil": true,
          "hasOrdersWith": {
            "deletedAtIsNil": true
          }
        }
      }
    }
  }
],
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SalesOrderDetail",
    "kind": "LinkedField",
    "name": "salesOrderDetail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SalesOrder",
        "kind": "LinkedField",
        "name": "salesOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Order",
            "kind": "LinkedField",
            "name": "orders",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SalesOrdersDetailPageMasterDataFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "direction": "ASC",
            "field": "ORDER_NUMBER"
          }
        }
      ],
      "concreteType": "SalesOrderDetailConnection",
      "kind": "LinkedField",
      "name": "details",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SalesOrderDetailEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SalesOrderDetail",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SalesOrderItem",
                  "kind": "LinkedField",
                  "name": "item",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "DemandItem",
                      "kind": "LinkedField",
                      "name": "demandItem",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "category",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "itemNumber",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "version",
                          "storageKey": null
                        },
                        (v2/*: any*/),
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "DemandItemInternalMemo",
                          "kind": "LinkedField",
                          "name": "internalMemos",
                          "plural": true,
                          "selections": (v4/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "DemandItemPrice",
                          "kind": "LinkedField",
                          "name": "prices",
                          "plural": true,
                          "selections": (v5/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "DemandItemImage",
                          "kind": "LinkedField",
                          "name": "images",
                          "plural": true,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "displayOrder",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "DemandItemAttachment",
                          "kind": "LinkedField",
                          "name": "attachments",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "fileName",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        (v6/*: any*/),
                        {
                          "alias": null,
                          "args": (v7/*: any*/),
                          "concreteType": "SalesOrderItemConnection",
                          "kind": "LinkedField",
                          "name": "salesOrderItems",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "SalesOrderItemEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "SalesOrderItem",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": (v8/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": "salesOrderItems(first:1,orderBy:{\"direction\":\"DESC\",\"field\":\"CREATED_AT\"},where:{\"hasSalesOrderDetailWith\":{\"deletedAtIsNil\":true,\"hasSalesOrderWith\":{\"deletedAtIsNil\":true,\"hasOrdersWith\":{\"deletedAtIsNil\":true}}}})"
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "DemandItemDetailBlockSpecTableContainerFragment"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SalesOrderRecurringCost",
                  "kind": "LinkedField",
                  "name": "recurringCost",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "DemandRecurringCost",
                      "kind": "LinkedField",
                      "name": "demandRecurringCost",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "note",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "costNumber",
                          "storageKey": null
                        },
                        (v2/*: any*/),
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "DemandRecurringCostInternalMemo",
                          "kind": "LinkedField",
                          "name": "internalMemos",
                          "plural": true,
                          "selections": (v4/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "DemandRecurringCostPrice",
                          "kind": "LinkedField",
                          "name": "prices",
                          "plural": true,
                          "selections": (v5/*: any*/),
                          "storageKey": null
                        },
                        (v6/*: any*/),
                        {
                          "alias": null,
                          "args": (v7/*: any*/),
                          "concreteType": "SalesOrderRecurringCostConnection",
                          "kind": "LinkedField",
                          "name": "salesOrderRecurringCosts",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "SalesOrderRecurringCostEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "SalesOrderRecurringCost",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": (v8/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": "salesOrderRecurringCosts(first:1,orderBy:{\"direction\":\"DESC\",\"field\":\"CREATED_AT\"},where:{\"hasSalesOrderDetailWith\":{\"deletedAtIsNil\":true,\"hasSalesOrderWith\":{\"deletedAtIsNil\":true,\"hasOrdersWith\":{\"deletedAtIsNil\":true}}}})"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "details(orderBy:{\"direction\":\"ASC\",\"field\":\"ORDER_NUMBER\"})"
    }
  ],
  "type": "SalesOrder",
  "abstractKey": null
};
})();

(node as any).hash = "f560287f23369c47eb419f8cc798e0b0";

export default node;
