import { OrderPreviewFragment$data } from 'gql/__generated__/OrderPreviewFragment.graphql';

type OrderDetailType = NonNullable<OrderPreviewFragment$data['details']>;

export const defaultMailBodyMessage = (currentUser: { readonly lastName: string }) => {
  return `様

いつもお世話になっております。
株式会社shizaiの${currentUser.lastName}でございます。

掲題の件、発注させていただきたく発注書を添付いたしましたので、ご確認のほどよろしくお願いいたします。
納品日が決まりましたら、ご教示いただきたく存じます。

ご多用中にお手数をおかけいたしますがどうぞよろしくお願いいたします。`;
};

export const convertItemFormat = (detail: {
  branchNumber: number;
  item: NonNullable<NonNullable<OrderDetailType[number]>['item']>;
}) => {
  return {
    id: detail.item.id,
    branchNumber: detail.branchNumber,
    name: detail.item.name,
    unitPrice: detail.item.unitPrice,
    quantity: detail.item.quantity,
    tax: {
      rate: detail.item.taxCategory.rate,
    },
  };
};

export const detailNumber = (transactionId: number, branchNumber: number): string => {
  // transactionId-branchNumberの形式, branchNumberは2桁で0埋めする
  return `${transactionId}-${branchNumber.toString().padStart(2, '0')}`;
};
