import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Link,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Info } from '@icon-park/react';
import { ErrorMessage } from 'components/ErrorMessage';
import { Loading } from 'components/Loading';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { REORDER_SUPPORT_LINK } from 'constants/externalLinks';
import { CompanyComboBox } from 'features/company/components/CompanyComboBox';
import { DemandMemo } from 'features/company/components/DemandMemo';
import { SalesOrdersReorderForm } from 'features/salesOrdersV2/components/SalesOrdersReorderForm';
import { SalesOrdersReorderFormType } from 'features/salesOrdersV2/form';
import { useQueryParams } from 'features/salesOrdersV2/hooks/useSalesOrdersQueryParams';
import { useNavigationBlocker } from 'hooks/useNavigationBlocker';
import { toast } from 'lib/toast';

import { DemandIdContext } from 'pages/sales-orders/reorder/context';
import { Suspense } from 'react';
import { Controller, SubmitErrorHandler, useFormContext, useWatch } from 'react-hook-form';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { paths } from 'utils/paths';

export const SalesOrdersReorderPage = () => {
  const { queryParams } = useQueryParams();
  const navigate = useNavigate();
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useFormContext<SalesOrdersReorderFormType>();

  const demandId = useWatch({ control, name: 'demand.id' });

  const { allowNavigation } = useNavigationBlocker();

  const handleValidSubmit = () => {
    allowNavigation();
    navigate(paths.salesOrders.reorder.confirm.url());
  };

  const handleSubmitErrors: SubmitErrorHandler<SalesOrdersReorderFormType> = (submitErrors) => {
    if (submitErrors.details) {
      toast({
        title: submitErrors.details.message,
        status: 'error',
      });
      return;
    }

    if (submitErrors.supplier) {
      toast({
        title: '費用のみではリピート発注できません',
        description: (
          <Text>
            <Link href={REORDER_SUPPORT_LINK} target="_blank" textDecoration="underline">
              対応方法
            </Link>
            を確認
          </Text>
        ),
        status: 'error',
      });
    }
  };

  const onClearDemandInput = () => {
    setValue('demand', { id: '', name: '' });
  };

  return (
    <DemandIdContext.Provider value={demandId}>
      <Suspense fallback={<Loading />}>
        <Box>
          <RouterLink
            to={paths.salesOrders.url({
              assignee: queryParams.assignee,
              demand: queryParams.demand,
              transactionId: queryParams.transactionId,
              supplier: queryParams.supplier,
            })}
          >
            <PageBack />
          </RouterLink>
        </Box>

        <Box mt={6}>
          <SubHeading label="発注請書の作成：リピート発注" />
        </Box>

        <Box w="768px" mt={6}>
          <form onSubmit={handleSubmit(handleValidSubmit, handleSubmitErrors)}>
            <VStack spacing={6} alignItems="flex-start">
              <FormControl isInvalid={!!errors.demand} isRequired>
                <VStack gap={2} align="stretch">
                  <Box>
                    <FormLabel>デマンド</FormLabel>
                    <Controller
                      name="demand"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <CompanyComboBox
                          defaultSelectedItem={getValues('demand')}
                          onChangeSelected={(value) => {
                            setValue('supplier', { id: '', name: '' });
                            setValue('details', []);
                            onChange(value);
                          }}
                          onClearInput={onClearDemandInput}
                        />
                      )}
                    />
                    <ErrorMessage name="company" errors={errors} />
                  </Box>
                  <Suspense>
                    <DemandMemo demandId={demandId} />
                  </Suspense>
                </VStack>
              </FormControl>

              <Suspense
                fallback={
                  <HStack width="100%" justify="center">
                    <Loading />
                  </HStack>
                }
              >
                {!demandId ? (
                  <Stack>
                    <HStack
                      width="fit-content"
                      px={4}
                      py={3}
                      borderWidth={2}
                      borderColor="gray.500"
                      borderRadius="0.375rem"
                      alignItems="center"
                      gap={1}
                    >
                      <Icon as={Info} w={5} h={5} size={20} />
                      <Text fontSize="sm" fontWeight="bold">
                        デマンドの選択後に明細を入力できます
                      </Text>
                    </HStack>
                  </Stack>
                ) : (
                  <SalesOrdersReorderForm />
                )}
              </Suspense>
            </VStack>
          </form>
        </Box>
      </Suspense>
    </DemandIdContext.Provider>
  );
};
