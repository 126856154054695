import { Box, Flex } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { DemandItemCategory } from 'features/demandItem/models';
import { EstimationConfirmV2 } from 'features/estimations/components/EstimationConfirm';
import { EstimationDetailType, EstimationFormType } from 'features/estimations/form';
import { confirm_EstimationEditConfirmPageMutation } from 'gql/__generated__/confirm_EstimationEditConfirmPageMutation.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { useNavigationBlocker } from 'hooks/useNavigationBlocker';
import { toast } from 'lib/toast';
import { useFormContext } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';

const getDetails = (details: EstimationDetailType[]) => {
  return details.map((detail, index) => {
    if (detail.category === 'cost') {
      return {
        cost: {
          documentDisplayStatus: detail.documentDisplayStatus,
          name: detail.name,
          note: detail.spec.cost?.note || '',
          orderNumber: index + 1,
          quantity: Number(detail.quantity),
          taxCategoryID: detail.tax.id,
          unitPrice: Number(detail.unitPrice),
          unitSellingPrice: Number(detail.unitSellingPrice),
        },
      };
    } else {
      return {
        item: {
          category: detail.category as DemandItemCategory,
          documentDisplayStatus: detail.documentDisplayStatus,
          name: detail.name,
          orderNumber: index + 1,
          quantity: Number(detail.quantity),
          spec: {
            cardboard: detail.spec.cardboard,
            flexiblePackage: detail.spec.flexiblePackage,
            giftBox: detail.spec.giftBox,
            paperBag: detail.spec.paperBag,
            other: detail.spec.other,
          },
          taxCategoryID: detail.tax.id,
          unitPrice: Number(detail.unitPrice),
          unitSellingPrice: Number(detail.unitSellingPrice),
        },
      };
    }
  });
};

export const EstimationEditConfirmPage = () => {
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const navigate = useNavigate();
  const form = useFormContext<EstimationFormType>();

  const { commitMutation, isMutationInFlight } =
    useMutationWrapper<confirm_EstimationEditConfirmPageMutation>(
      graphql`
      mutation confirm_EstimationEditConfirmPageMutation(
        $id: ID!
        $input: EstimationUpdateInputV2!
      ) {
        updateEstimationV2(id: $id, input: $input) {
          id
        }
      }
    `,
    );

  const { allowNavigation } = useNavigationBlocker(true, false);

  const { getValues } = form;
  const { title } = getValues();

  if (!title) return <Navigate to={paths.estimations.new.url()} />;

  const handleSubmit = (data: EstimationFormType) => {
    allowNavigation();

    commitMutation({
      variables: {
        id,
        input: {
          companyID: data.demand.id,
          detailMessage: data.detailMessage,
          details: getDetails(data.details),
          expirationPeriod: data.expirationPeriod,
          internalAssignees: [
            ...(data.internalAssignees?.map((assignee) => ({ userID: assignee.value })) || []),
          ],
          showAmountSummary: data.showAmountSummary,
          supplierID: data.supplier.id,
          title: data.title,
        },
      },
      onError() {
        toast({
          title: '見積書の更新に失敗しました',
          status: 'error',
        });
      },
      onCompleted: () => {
        toast({
          title: '見積書の更新が完了しました',
          status: 'success',
        });
        navigate(paths.estimations.url());
      },
    });
  };

  const handleBack = () => {
    allowNavigation();
    navigate(paths.estimations.edit._estimationId(id).url());
  };

  return (
    <>
      <Flex justify="space-between" width="768px">
        <Box onClick={handleBack}>
          <PageBack />
        </Box>
      </Flex>
      <SubHeading label="内容の確認" />
      <EstimationConfirmV2
        confirmButtonLabel="更新"
        onSubmit={handleSubmit}
        isSubmitting={isMutationInFlight}
      />
    </>
  );
};
