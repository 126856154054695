import { Box, Button, Flex, VStack } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { DemandItemCategory } from 'features/demandItem/models';
import {
  MASTER_REGISTRATION_SETTING_COST_TYPE_ONE_TIME,
  NewSalesOrderDetailFormType,
  NewSalesOrderFormType,
  NewSalesOrderMasterRegistrationCostFormType,
  NewSalesOrderMasterRegistrationItemFormType,
  SALES_ORDER_DETAIL_CATEGORY_COST,
} from 'features/salesOrdersV2/form';
import { confirm_NewSalesOrderConfirmMutation } from 'gql/__generated__/confirm_NewSalesOrderConfirmMutation.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { useNavigationBlocker } from 'hooks/useNavigationBlocker';
import { toast } from 'lib/toast';
import { useFormContext } from 'react-hook-form';
import { ScrollRestoration, useNavigate, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { cdateJST } from 'utils/date';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';
import { NewSalesOrderConfirm } from './components/NewSalesOrderConfirm';
import { NewSalesOrderMasterRegistrationConfirm } from './components/NewSalesOrderMasterRegistrationConfirm';

const getCostDetail = (detail: NewSalesOrderDetailFormType) => {
  return {
    estimationDetailID: detail.estimationDetailID,
    fixedDeliveryDate: detail.fixedDeliveryDate
      ? cdateJST(detail.fixedDeliveryDate).toDate()
      : null,
    memo: detail.memo,
    quantity: Number(detail.quantity),
    taxCategoryID: detail.tax.id,
    unitPrice: Number(detail.unitPrice),
    unitSellingPrice: Number(detail.unitSellingPrice),
  };
};

const getItemDetail = (detail: NewSalesOrderDetailFormType) => {
  return {
    estimationDetailID: detail.estimationDetailID,
    fixedDeliveryDate: detail.fixedDeliveryDate
      ? cdateJST(detail.fixedDeliveryDate).toDate()
      : null,
    memo: detail.memo,
    quantity: Number(detail.quantity),
    taxCategoryID: detail.tax.id,
    unitPrice: Number(detail.unitPrice),
    unitSellingPrice: Number(detail.unitSellingPrice),
  };
};

const getDetails = (details: NewSalesOrderDetailFormType[]) => {
  return details.map((detail) => {
    if (detail.category === SALES_ORDER_DETAIL_CATEGORY_COST) {
      return {
        cost: getCostDetail(detail),
      };
    } else {
      return {
        item: getItemDetail(detail),
      };
    }
  });
};

const getMasterRegistrationItems = (
  details: NewSalesOrderMasterRegistrationItemFormType[] | undefined,
) => {
  if (!details) {
    return [];
  }

  return details.map((detail) => {
    return {
      category: detail.category as DemandItemCategory,
      demandItemID: detail.demandItem?.id || null,
      estimationDetailIDs: detail.estimationDetailIds,
      name: detail.name,
      prices: detail.prices.map((price) => ({
        unitPrice: Number(price.unitPrice),
        unitSellingPrice: Number(price.unitSellingPrice),
        quantity: Number(price.quantity),
      })),
      spec: detail.spec,
      taxCategoryID: detail.tax.id,
    };
  });
};

const getMasterRegistrationRecurringCosts = (
  details: NewSalesOrderMasterRegistrationCostFormType[] | undefined,
) => {
  if (!details) {
    return [];
  }

  return details
    .map((detail) => {
      if (detail.type === MASTER_REGISTRATION_SETTING_COST_TYPE_ONE_TIME) {
        return null;
      }

      return {
        estimationDetailIDs: detail.estimationDetailIds,
        name: detail.name,
        note: detail.note || '',
        internalMemo: detail.memo || null,
        prices: detail.prices.map((price) => ({
          unitPrice: Number(price.unitPrice),
          unitSellingPrice: Number(price.unitSellingPrice),
          quantity: Number(price.quantity),
        })),
        taxCategoryID: detail.tax.id,
      };
    })
    .filter((v): v is NonNullable<typeof v> => v != null);
};

export const NewSalesOrderConfirmPage = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const { commitMutation, isMutationInFlight } =
    useMutationWrapper<confirm_NewSalesOrderConfirmMutation>(
      graphql`
      mutation confirm_NewSalesOrderConfirmMutation(
        $input: CreateSalesOrderInputV2!
      ) {
        createSalesOrderV2(input: $input) {
          id
        }
      }
    `,
    );

  const { allowNavigation } = useNavigationBlocker(true, false);

  const handleBack = () => {
    allowNavigation();
    navigate(paths.estimations._estimationId(id).newSalesOrder.masterRegistrationSetting.url());
  };

  const form = useFormContext<NewSalesOrderFormType>();
  const { handleSubmit: formSubmit } = form;

  const handleSubmit = (data: NewSalesOrderFormType) => {
    allowNavigation();

    commitMutation({
      variables: {
        input: {
          demandID: data.demand.id,
          detailMessage: data.detailMessage,
          details: getDetails(data.details),
          estimationID: data.estimationID,
          internalAssignees: [
            ...(data.internalAssignees?.map((assignee) => ({ userID: assignee.value })) || []),
          ],
          internalMemo: data.memo,
          masterRegistrationItems: getMasterRegistrationItems(data.masterRegistrationItems),
          masterRegistrationRecurringCosts: getMasterRegistrationRecurringCosts(
            data.masterRegistrationCosts,
          ),
          receivedDate: cdateJST(data.receivedDate).toDate(),
          supplierID: data.supplier.id,
          title: data.title,
        },
      },
      onError() {
        toast({
          title: '発注請書の作成に失敗しました',
          status: 'error',
        });
      },
      onCompleted: (res) => {
        toast({
          title: '発注請書を作成しました',
          description: '受注DBと案件DBの紐付けをお願いします',
          status: 'success',
        });
        navigate(paths.salesOrders.id(res.createSalesOrderV2.id).url());
      },
    });
  };

  return (
    <>
      <ScrollRestoration />
      <Flex justify="space-between" width="768px">
        <Box onClick={handleBack}>
          <PageBack />
        </Box>
      </Flex>
      <VStack as="form" onSubmit={formSubmit(handleSubmit)} spacing={6} alignItems="flex-start">
        {/* 発注請書の確認 */}
        <NewSalesOrderConfirm />

        {/* マスタへの登録 */}
        <NewSalesOrderMasterRegistrationConfirm />

        <Button
          colorScheme="blue"
          w="full"
          type="submit"
          isDisabled={isMutationInFlight}
          isLoading={isMutationInFlight}
          loadingText="作成中"
        >
          作成
        </Button>
      </VStack>
    </>
  );
};
