/**
 * @generated SignedSource<<45eeafdf6a4fa188bcfdf67b29f58692>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DemandRecurringCostFormFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TaxSelectBoxFragment">;
  readonly " $fragmentType": "DemandRecurringCostFormFragment";
};
export type DemandRecurringCostFormFragment$key = {
  readonly " $data"?: DemandRecurringCostFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DemandRecurringCostFormFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DemandRecurringCostFormFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaxSelectBoxFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "c354d474a3110498cd4967dee2b16d1f";

export default node;
