/**
 * @generated SignedSource<<f6f5249f0e8c522e9f761bab73182262>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EstimationDetailHeaderFragment$data = {
  readonly id: string;
  readonly orders: ReadonlyArray<{
    readonly id: string;
  }> | null;
  readonly " $fragmentType": "EstimationDetailHeaderFragment";
};
export type EstimationDetailHeaderFragment$key = {
  readonly " $data"?: EstimationDetailHeaderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EstimationDetailHeaderFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EstimationDetailHeaderFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Order",
      "kind": "LinkedField",
      "name": "orders",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Estimation",
  "abstractKey": null
};
})();

(node as any).hash = "6665a538300423498a1b45d97475c132";

export default node;
