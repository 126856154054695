import { Grid, GridItem, VStack } from '@chakra-ui/react';
import { SubHeading } from 'components/SubHeading';
import { NewSalesOrderFormType } from 'features/salesOrdersV2/form';
import { useFormContext } from 'react-hook-form';
import { NewSalesOrderConfirmPreview } from './NewSalesOrderConfirmPreview';

export const NewSalesOrderConfirm = () => {
  const form = useFormContext<NewSalesOrderFormType>();
  const { getValues } = form;
  const { title, internalAssignees } = getValues();

  return (
    <>
      <SubHeading label="内容の確認" />

      <VStack w="1024px" spacing={6} alignItems="flex-start">
        <Grid gridTemplateColumns="max-content 1fr" gap="12px 8px">
          <GridItem color="gray.500" textAlign="right" fontSize="sm">
            件名
          </GridItem>
          <GridItem fontSize="sm">{title}</GridItem>
          <GridItem color="gray.500" textAlign="right" fontSize="sm">
            担当者
          </GridItem>
          <GridItem fontSize="sm">
            {internalAssignees.map((assignee) => assignee.label).join(' / ')}
          </GridItem>
        </Grid>

        {/* 発注請書部分 */}
        <NewSalesOrderConfirmPreview />
      </VStack>
    </>
  );
};
