import { DemandItemWhereInput, DemandItem as GraphqlDemandItem } from 'gql/graphql.types';
import {
  DEMAND_ITEM_CATEGORY_LABEL,
  DemandItemCategory,
  DemandItemCategoryCode,
  getDemandItemCategoryKeyByValue,
} from './DemandItemCategory';

export type DemandItem = GraphqlDemandItem;

export const buildDemandItemCode = ({
  demandCode,
  category,
  itemNumber,
  version,
}: {
  demandCode: string;
  category: DemandItemCategory;
  itemNumber: number;
  version: number;
}) => {
  if (itemNumber > 999) {
    throw new Error('itemNumber must be less than 1000');
  }
  if (version > 99) {
    throw new Error('version must be less than 100');
  }
  const itemNumberStr = itemNumber.toString().padStart(3, '0');
  const versionStr = version.toString().padStart(2, '0');
  return `${demandCode}-${DEMAND_ITEM_CATEGORY_LABEL[category].code}${itemNumberStr}-${versionStr}`;
};

export const buildDemandItemCodeForUrl = ({
  category,
  itemNumber,
  version,
}: {
  category: DemandItemCategory;
  itemNumber: number;
  version?: number;
}) => {
  if (itemNumber > 999) {
    throw new Error('itemNumber must be less than 1000');
  }
  if (version && version > 99) {
    throw new Error('version must be less than 100');
  }

  const itemNumberStr = itemNumber.toString().padStart(3, '0');
  const versionStr = version?.toString().padStart(2, '0') || '';
  if (versionStr === '') {
    return `${DEMAND_ITEM_CATEGORY_LABEL[category].code}${itemNumberStr}`;
  }
  return `${DEMAND_ITEM_CATEGORY_LABEL[category].code}${itemNumberStr}-${versionStr}`;
};

export const buildDemandItemWhere = ({
  demandId,
  category,
  itemNumber,
  version,
}: {
  demandId: string;
  category: DemandItemCategory;
  itemNumber: number;
  version?: number;
}): DemandItemWhereInput => {
  const where: DemandItemWhereInput = {
    demandID: demandId,
    category,
    itemNumber,
  };

  if (version) {
    where.version = version;
  } else {
    where.isCurrent = true;
  }

  return where;
};

export const parseDemandItemCodeForUrl = (input: string) => {
  const match = input.match(/^([A-Z]+)(\d+)(?:-(\d+))?$/);

  if (!match) {
    throw new Error(`Invalid input format: ${input}`);
  }

  const category = getDemandItemCategoryKeyByValue(match[1] as DemandItemCategoryCode);
  if (!category) {
    throw new Error(`Invalid category code: ${match[1]}`);
  }
  const itemNumber = parseInt(match[2], 10);
  const version = match[3] ? parseInt(match[3], 10) : undefined;

  return { category, itemNumber, version };
};
