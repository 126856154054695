import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { DEMAND_ITEM_CATEGORY, DemandItemCategory } from 'features/demandItem/models';
import { SALES_ORDER_DETAIL_CATEGORY_COST } from 'features/salesOrdersV2/form';
import {
  SalesOrderSpec,
  SalesOrderSpecCardboard,
  SalesOrderSpecCost,
  SalesOrderSpecFlexiblePackage,
  SalesOrderSpecGiftBox,
  SalesOrderSpecOther,
  SalesOrderSpecPaperBag,
} from 'features/salesOrdersV2/models';

const getSpecFields = ({
  category,
  spec,
  name,
}: {
  name: string;
  category: DemandItemCategory | 'cost';
  spec: SalesOrderSpec | null;
}) => {
  switch (category) {
    case DEMAND_ITEM_CATEGORY.Cardboard: {
      const cardboard = spec?.cardboard as SalesOrderSpecCardboard;
      return [
        { label: '商品名', value: name },
        { label: 'サイズ', value: cardboard.size },
        { label: '形状', value: cardboard.type },
        { label: '構成', value: cardboard.material },
        { label: '段厚', value: cardboard.thickness },
        { label: '印刷', value: cardboard.printingColor },
        { label: '加工', value: cardboard.processing },
        { label: 'その他', value: cardboard.other },
      ].filter((item) => item.value !== '');
    }
    case DEMAND_ITEM_CATEGORY.FlexiblePackage: {
      const flexiblePackage = spec?.flexiblePackage as SalesOrderSpecFlexiblePackage;
      return [
        { label: '商品名', value: name },
        { label: 'サイズ', value: flexiblePackage.size },
        { label: '形状', value: flexiblePackage.type },
        { label: '構成', value: flexiblePackage.material },
        { label: '印刷', value: flexiblePackage.printingColor },
        { label: '加工', value: flexiblePackage.processing },
        { label: 'その他', value: flexiblePackage.other },
      ].filter((item) => item.value !== '');
    }
    case DEMAND_ITEM_CATEGORY.GiftBox: {
      const giftBox = spec?.giftBox as SalesOrderSpecGiftBox;
      return [
        { label: '商品名', value: name },
        { label: 'サイズ', value: giftBox.size },
        { label: '形状', value: giftBox.type },
        { label: '用紙', value: giftBox.paperType },
        { label: '印刷', value: giftBox.printingColor },
        { label: '加工', value: giftBox.processing },
        { label: 'その他', value: giftBox.other },
      ].filter((item) => item.value !== '');
    }
    case DEMAND_ITEM_CATEGORY.PaperBag: {
      const paperBag = spec?.paperBag as SalesOrderSpecPaperBag;
      return [
        { label: '商品名', value: name },
        { label: 'サイズ', value: paperBag.size },
        { label: '用紙', value: paperBag.paperType },
        { label: '印刷', value: paperBag.printingColor },
        { label: '加工', value: paperBag.processing },
        { label: '持ち手', value: paperBag.handle },
        { label: 'その他', value: paperBag.other },
      ].filter((item) => item.value !== '');
    }
    case DEMAND_ITEM_CATEGORY.Other: {
      const other = spec?.other as SalesOrderSpecOther;
      return [
        { label: '商品名', value: name },
        { label: 'その他', value: other.specText },
      ].filter((item) => item.value !== '');
    }
    case SALES_ORDER_DETAIL_CATEGORY_COST: {
      const cost = spec?.cost as SalesOrderSpecCost;
      return [
        { label: '費用名', value: name },
        { label: '補足', value: cost.note },
      ].filter((item) => item.value !== '');
    }
  }
};

type MasterRegistrationSettingSpecInfoTableProps = {
  name: string;
  category: DemandItemCategory | 'cost';
  spec: SalesOrderSpec | null;
};

export const MasterRegistrationSettingSpecInfoTable = ({
  name,
  category,
  spec,
}: MasterRegistrationSettingSpecInfoTableProps) => {
  const specFields = getSpecFields({ category, spec, name });

  return (
    <Table w="100%">
      <Thead>
        <Tr>
          {specFields.map((field, index) => (
            <Th color="gray.900" bg="gray.50" key={`${field.label}-${index}`}>
              {field.label}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        <Tr fontSize="sm">
          {specFields.map((field, index) => (
            <Td key={`${field.value}-${index}`}>{field.value}</Td>
          ))}
        </Tr>
      </Tbody>
    </Table>
  );
};
