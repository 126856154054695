import { Badge, Button, Menu, MenuButton, MenuItem, MenuList, Td, Tr } from '@chakra-ui/react';
import { More } from '@icon-park/react';
import { Link } from 'components/Link';
import { InvoiceTableRowFragment$key } from 'gql/__generated__/InvoiceTableRowFragment.graphql';
import { useFragment } from 'react-relay';
import { Link as RouterLink } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { dateFormat } from 'utils/date';
import { concatFullName } from 'utils/label';
import { paths } from 'utils/paths';

const InvoiceTableRowFragment = graphql`
  fragment InvoiceTableRowFragment on Invoice {
    id
    freeeInvoice {
      freeeInvoiceNumber
    }
    title
    bookedAt
    isSent
    company {
      id
      name
    }
    createdBy {
      profile {
        lastName
        firstName
      }
    }
  }
`;

export const InvoiceTableRow = ({ queryRef }: { queryRef: InvoiceTableRowFragment$key }) => {
  const { id, bookedAt, company, createdBy, title, isSent, freeeInvoice } = useFragment(
    InvoiceTableRowFragment,
    queryRef,
  );

  return (
    <Tr>
      <Td whiteSpace="nowrap">{freeeInvoice?.freeeInvoiceNumber}</Td>
      <Td whiteSpace="nowrap">
        <Badge
          variant="outline"
          colorScheme={isSent ? 'teal' : 'orange'}
          width="56px"
          textAlign="center"
          rounded="sm"
          fontWeight="bold"
        >
          {isSent ? '送付済み' : '未送付'}
        </Badge>
      </Td>
      <Td>{title}</Td>
      <Td>
        <Link to={paths.company._id(company.id).url}>{company.name}</Link>
      </Td>
      <Td whiteSpace="nowrap">{dateFormat(bookedAt)}</Td>
      <Td whiteSpace="nowrap">
        {createdBy.profile?.[0]?.lastName && createdBy.profile?.[0]?.firstName
          ? concatFullName({
              lastName: createdBy.profile[0].lastName,
              firstName: createdBy.profile[0].firstName,
            })
          : '-'}
      </Td>
      <Td>
        <Menu>
          <MenuButton>
            <More />
          </MenuButton>
          <MenuList>
            <MenuItem as={RouterLink} to={paths.invoice.edit._id(id)}>
              編集
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
      <Td w="40px">
        <Button as={RouterLink} colorScheme="blue" size="sm" to={paths.invoice._id(id)}>
          詳細
        </Button>
      </Td>
    </Tr>
  );
};
