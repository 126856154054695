import { Box, Button, Flex, HStack, Heading, VStack } from '@chakra-ui/react';
import { AddOne, Edit } from '@icon-park/react';
import { PageBack } from 'components/PageBack';
import { PageHeading } from 'components/PageHeading';
import { RouteLinkWithReferrer } from 'components/RouteLinkWithReferrer';
import { SubHeading } from 'components/SubHeading';
import { detail_DemandDetailPageQuery } from 'gql/__generated__/detail_DemandDetailPageQuery.graphql';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Link as RouterLink, ScrollRestoration, useParams } from 'react-router-dom';
import { dateFormatWithTime } from 'utils/date';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';
import { DemandItemsAndFilterWrapper } from './components/DemandItemsAndFilterWrapper';
import { DemandRecipients } from './components/DemandRecipients';
import { DemandRecurringCostBlocks } from './components/DemandRecurringCostBlocks';

const DemandDetailPageQuery = graphql`
  query detail_DemandDetailPageQuery  ($code: String!) {
    companies(where: {code: $code}) {
      edges {
        node {
          id
          name
          code
          createdAt
          internalMemos {
            body
          }
          ...DemandRecipientsFragment
          ...DemandItemsAndFilterWrapperFragment
        }
      }
    }
  }
`;

export function DemandsDetailPage() {
  const { demandCode } = useParams();
  if (!demandCode) {
    throw new MissingRequiredParamError('demandCode');
  }

  const { companies } = useLazyLoadQuery<detail_DemandDetailPageQuery>(
    DemandDetailPageQuery,
    { code: demandCode },
    { fetchPolicy: 'network-only' },
  );

  if (
    !companies ||
    !companies.edges ||
    companies.edges.length === 0 ||
    !companies.edges[0]?.node ||
    companies.edges[0]?.node === null
  )
    return null;

  const demand = companies.edges[0].node;
  const memo = demand.internalMemos?.[0]?.body || '-';

  return (
    <>
      <ScrollRestoration getKey={(location) => location.pathname} />
      <VStack spacing={8} alignItems="flex-start">
        <PageHeading label={`デマンド：${demand.name}`} />
        <Flex justify="space-between" width="768px">
          <RouterLink to={paths.demands.url()}>
            <PageBack />
          </RouterLink>
        </Flex>
        <SubHeading label="デマンドの詳細" />

        <VStack spacing={10} alignItems="flex-start" minW="1160px">
          <VStack spacing={4} alignItems="flex-start">
            <HStack spacing={4}>
              <VStack spacing={1} alignItems="flex-start">
                <Box color="gray.500">デマンド名</Box>
                <Box>{demand.name}</Box>
              </VStack>
              <VStack spacing={1} alignItems="flex-start">
                <Box color="gray.500">コード</Box>
                <Box>{demand.code}</Box>
              </VStack>
              <VStack spacing={1} alignItems="flex-start">
                <Box color="gray.500">作成日時</Box>
                <Box>{dateFormatWithTime(demand.createdAt)}</Box>
              </VStack>
            </HStack>
            <VStack spacing={1} alignItems="flex-start">
              <Box color="gray.500">メモ</Box>
              <Box whiteSpace="pre-wrap">{memo}</Box>
            </VStack>
            <DemandRecipients queryRef={demand} />
            <HStack spacing={4}>
              <Button
                colorScheme="blue"
                leftIcon={<Edit />}
                as={RouteLinkWithReferrer}
                to={paths.demands._demandCode(demandCode).edit.url()}
              >
                編集
              </Button>
            </HStack>
          </VStack>

          <VStack spacing={4} alignItems="flex-start" w="100%">
            <Heading as="h3" fontSize="xl">
              商品
            </Heading>
            <Button
              colorScheme="blue"
              leftIcon={<AddOne />}
              width="15rem"
              as={RouteLinkWithReferrer}
              to={paths.demands._demandCode(demandCode).items.new.url()}
            >
              新規商品追加
            </Button>
            <DemandItemsAndFilterWrapper queryRef={demand} demandId={demand.id} />
          </VStack>

          <VStack spacing={4} alignItems="flex-start" w="100%">
            <Heading as="h3" fontSize="xl">
              継続費用
            </Heading>
            <Button
              colorScheme="blue"
              leftIcon={<AddOne />}
              width="15rem"
              as={RouteLinkWithReferrer}
              to={paths.demands._demandCode(demandCode).recurringCosts.new.url()}
            >
              新規継続費用追加
            </Button>
            <HStack
              width="100%"
              border="1px solid"
              borderColor="gray.200"
              borderRadius={8}
              overflow="hidden"
              alignItems="flex-start"
              spacing={0}
            >
              <Box w="100%" h="100%" overflow="scroll">
                <DemandRecurringCostBlocks demandId={demand.id} />
              </Box>
            </HStack>
          </VStack>
        </VStack>
      </VStack>
    </>
  );
}
