import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import {
  DEMAND_ITEM_CATEGORY_LABEL,
  DemandItemCardboard,
  DemandItemCategory,
  DemandItemFlexiblePackage,
  DemandItemGiftBox,
  DemandItemOther,
  DemandItemPaperBag,
} from 'features/demandItem/models';

type DemandItemSpecType =
  | DemandItemCardboard
  | DemandItemFlexiblePackage
  | DemandItemGiftBox
  | DemandItemPaperBag
  | DemandItemOther;

type Props<T extends DemandItemSpecType> = {
  category: DemandItemCategory;
  itemName: string;
  values: T;
};

export const DemandItemDetailBlockSpecTable = <T extends DemandItemSpecType>({
  category,
  itemName,
  values,
}: Props<T>) => {
  const labels = DEMAND_ITEM_CATEGORY_LABEL[category].tableLabels;

  return (
    <Table w="100%">
      <Thead>
        <Tr>
          <Th bg="gray.50">商品名</Th>
          {labels.map((label) => (
            <Th bg="gray.50" key={label} whiteSpace="nowrap">
              {label}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        <Tr fontSize="sm">
          <Td>{itemName}</Td>
          <DemandItemSpecTableData values={values} />
        </Tr>
      </Tbody>
    </Table>
  );
};

const DemandItemSpecTableData = ({ values }: { values: DemandItemSpecType }) => {
  return (
    <>
      {values.__typename === 'DemandItemCardboard' && <CardboardSpecTableData values={values} />}
      {values.__typename === 'DemandItemFlexiblePackage' && (
        <FlexiblePackageSpecTableData values={values} />
      )}
      {values.__typename === 'DemandItemGiftBox' && <GiftBoxSpecTableData values={values} />}
      {values.__typename === 'DemandItemPaperBag' && <PaperBagSpecTableData values={values} />}
      {values.__typename === 'DemandItemOther' && <OtherSpecTableData values={values} />}
    </>
  );
};

const CardboardSpecTableData = ({ values }: { values: DemandItemCardboard }) => {
  return (
    <>
      <Td>{values.size || '-'}</Td>
      <Td>{values.type || '-'}</Td>
      <Td>{values.material || '-'}</Td>
      <Td>{values.thickness || '-'}</Td>
      <Td>{values.printingColor || '-'}</Td>
      <Td>{values.processing || '-'}</Td>
      <Td>{values.other || '-'}</Td>
    </>
  );
};

const FlexiblePackageSpecTableData = ({ values }: { values: DemandItemFlexiblePackage }) => {
  return (
    <>
      <Td>{values.size || '-'}</Td>
      <Td>{values.type || '-'}</Td>
      <Td>{values.material || '-'}</Td>
      <Td>{values.printingColor || '-'}</Td>
      <Td>{values.processing || '-'}</Td>
      <Td>{values.other || '-'}</Td>
    </>
  );
};

const GiftBoxSpecTableData = ({ values }: { values: DemandItemGiftBox }) => {
  return (
    <>
      <Td>{values.size || '-'}</Td>
      <Td>{values.type || '-'}</Td>
      <Td>{values.paperType || '-'}</Td>
      <Td>{values.printingColor || '-'}</Td>
      <Td>{values.processing || '-'}</Td>
      <Td>{values.other || '-'}</Td>
    </>
  );
};

const PaperBagSpecTableData = ({ values }: { values: DemandItemPaperBag }) => {
  return (
    <>
      <Td>{values.size || '-'}</Td>
      <Td>{values.paperType || '-'}</Td>
      <Td>{values.printingColor || '-'}</Td>
      <Td>{values.processing || '-'}</Td>
      <Td>{values.handle || '-'}</Td>
      <Td>{values.other || '-'}</Td>
    </>
  );
};

const OtherSpecTableData = ({ values }: { values: DemandItemOther }) => {
  return (
    <>
      <Td>{values.specText || '-'}</Td>
    </>
  );
};
