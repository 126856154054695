import { Box, Checkbox, Td, Tooltip, Tr } from '@chakra-ui/react';

type Props = {
  value: {
    id: string;
    quantity: number | string;
    unitPrice: number | string;
    unitSellingPrice: number | string;
  };
  enableCheckbox?: boolean;
  checked?: boolean;
  disabled?: boolean;
  showTooltip?: boolean;
  tooltipText?: string;
  handleChangeValue?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const DemandItemPriceTableRow = ({
  value,
  enableCheckbox = false,
  checked = false,
  disabled = false,
  showTooltip = false,
  tooltipText = '',
  handleChangeValue,
}: Props) => {
  return (
    <Tr
      sx={{
        ':last-of-type td': {
          border: 'none',
        },
      }}
    >
      {enableCheckbox && (
        <Td w="24px" textAlign="center" p={2}>
          <Tooltip
            hasArrow
            p={2}
            rounded={4}
            placement="top"
            textAlign="center"
            whiteSpace="pre-wrap"
            label={tooltipText}
            isDisabled={!showTooltip}
          >
            <Box>
              <Checkbox
                value={value.id}
                onChange={handleChangeValue}
                isChecked={checked}
                isDisabled={disabled}
              />
            </Box>
          </Tooltip>
        </Td>
      )}
      <Td isNumeric fontSize="xs" p={2}>
        {Number(value.quantity).toLocaleString()}
      </Td>
      <Td isNumeric fontSize="xs" p={2}>
        {Number(value.unitPrice).toLocaleString()}
      </Td>
      <Td isNumeric fontSize="xs" p={2}>
        {Number(value.unitSellingPrice).toLocaleString()}
      </Td>
    </Tr>
  );
};
