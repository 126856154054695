import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { AddOne } from '@icon-park/react';
import { ErrorMessage } from 'components/ErrorMessage';
import { DemandFormDataType, demandFormSchema } from 'features/demand/form';
import { CompanyInvoiceRecipientRecipientType } from 'gql/graphql.types';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { DemandFormRecipientRow } from './DemandFormRecipientRow';

type FormType = 'new' | 'edit';

const getSubmitButtonLabel = (type: FormType) => {
  switch (type) {
    case 'new':
      return '追加';
    case 'edit':
      return '更新';
  }
};

type Props = {
  type?: FormType;
  onSubmit: (data: DemandFormDataType) => void;
  isMutationInFlight: boolean;
  value?: DemandFormDataType;
};

export const DemandForm = ({
  type = 'new',
  onSubmit,
  isMutationInFlight,
  value = {
    name: '',
  },
}: Props) => {
  const methods = useForm<DemandFormDataType>({
    resolver: zodResolver(demandFormSchema),
    defaultValues: value,
  });

  const {
    register,
    control,
    formState: { errors },
    handleSubmit: hookFormSubmit,
  } = methods;

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'recipients',
  });

  const handleSubmit = (data: DemandFormDataType) => {
    onSubmit(data);
  };

  // 新しい連絡先を追加
  const handleAddRecipient = () => {
    append({
      id: '',
      email: '',
      recipientType: CompanyInvoiceRecipientRecipientType.To,
    });
  };

  const updateField = (
    index: number,
    value: NonNullable<DemandFormDataType['recipients']>[number],
  ) => {
    update(index, value);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={hookFormSubmit(handleSubmit)}>
        <VStack spacing={8} alignItems="flex-start">
          <VStack spacing={6} w="full" alignItems="flex-start">
            <FormControl isInvalid={!!errors.name} isRequired>
              <FormLabel>デマンド名</FormLabel>
              <Input type="string" {...register('name')} />
              <ErrorMessage name="name" errors={errors} />
            </FormControl>

            <FormControl isInvalid={!!errors.memo}>
              <FormLabel>メモ</FormLabel>
              <Textarea {...register('memo')} />
              <ErrorMessage name="memo" errors={errors} />
            </FormControl>

            {/* 請求書送付先のフォーム */}
            <VStack align="stretch" spacing={4} w="full">
              <Text fontSize="xl" mb={2} fontWeight="bold">
                請求書送付先
              </Text>
              <VStack align="stretch" spacing={1}>
                {fields.length > 0 && (
                  <Flex justifyContent="space-between">
                    <Text fontSize="sm">送信方法</Text>
                    <Text fontSize="sm" flex="1" ml={10}>
                      メールアドレス
                    </Text>
                  </Flex>
                )}
                <VStack align="flex-start" w="full" spacing={2} mt={1}>
                  {fields.map((field, index) => (
                    <DemandFormRecipientRow
                      key={field.id}
                      index={index}
                      field={field}
                      remove={remove}
                      update={updateField}
                    />
                  ))}
                  <Button
                    leftIcon={<AddOne size={14} />}
                    borderRadius="md"
                    bg="gray.100"
                    color="gray.700"
                    height="32px"
                    fontSize="13px"
                    onClick={handleAddRecipient}
                  >
                    連絡先追加
                  </Button>
                </VStack>
              </VStack>
              {errors.recipients && !Array.isArray(errors.recipients) && (
                <Text color="red.500" fontSize="xs" mt={1}>
                  {errors.recipients.message}
                </Text>
              )}
            </VStack>
          </VStack>

          <Box w="full">
            <Button colorScheme="blue" w="full" type="submit" isDisabled={isMutationInFlight}>
              {getSubmitButtonLabel(type)}
            </Button>
          </Box>
        </VStack>
      </form>
    </FormProvider>
  );
};
