import { DEMAND_ITEM_CATEGORY, DemandItemCategory } from 'features/demandItem/models';
import { ESTIMATION_DETAIL_TYPE } from 'features/estimations/models';
import {
  NewSalesOrderDetailFormType,
  SALES_ORDER_DETAIL_CATEGORY_COST,
} from 'features/salesOrdersV2/form';
import {
  SalesOrderSpec,
  SalesOrderSpecCardboard,
  SalesOrderSpecFlexiblePackage,
  SalesOrderSpecGiftBox,
  SalesOrderSpecOther,
  SalesOrderSpecPaperBag,
} from 'features/salesOrdersV2/models';
import { masterRegistrationSetting_Query$data } from 'gql/__generated__/masterRegistrationSetting_Query.graphql';

export type MasterRegistrationItemType = {
  demandId: string;
  supplier: {
    id: string;
    name: string;
  };
  estimationDetailIds: string[];
  name: string;
  category: DemandItemCategory;
  spec: SalesOrderSpec | null;
  prices: {
    unitPrice: number;
    unitSellingPrice: number;
    quantity: number;
  }[];
  tax: {
    id: string;
    rate: string | number;
    name: string;
  };
};

type MasterRegistrationRecurringCostType = {
  supplier: {
    id: string;
    name: string;
  };
  estimationDetailIds: string[];
  name: string;
  note: string;
  prices: {
    unitPrice: number;
    unitSellingPrice: number;
    quantity: number;
  }[];
  tax: {
    id: string;
    rate: string | number;
    name: string;
  };
};

export type MasterRegistrationSettingEstimationDetailType = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<NonNullable<masterRegistrationSetting_Query$data['estimations']>['edges']>[0]
    >['node']
  >['details']
>;

const buildNormalizeItemKey = (name: string, category: string, spec: string) => {
  return `${name}_${category}_${spec}`;
};

const buildNormalizeCostKey = (name: string, note: string) => {
  return `${name}_${note}`;
};

/**
 * 発注請書明細行の同一の費用をまとめMapを返す。
 * nameとnoteを_で連結した文字列をkeyとし、'${name}_${note}'が同じであれば同一の費用とみなす
 * 同一のkeyが存在している場合は、pricesとestimationDetailIdsを追加していく
 */
export const normalizeSalesOrderCosts = ({
  supplier,
  salesOrderDetails,
}: {
  supplier: {
    id: string;
    name: string;
  };
  salesOrderDetails: NewSalesOrderDetailFormType[];
}) => {
  const normalizedCostMap = new Map<string, MasterRegistrationRecurringCostType>();

  for (const detail of salesOrderDetails) {
    if (detail.category === SALES_ORDER_DETAIL_CATEGORY_COST) {
      const cost = detail.spec.cost;

      const name = detail.name;
      const note = cost?.note || '';
      const key = buildNormalizeCostKey(name, note);
      const priceInfo = {
        unitPrice: Number(detail.unitPrice),
        unitSellingPrice: Number(detail.unitSellingPrice),
        quantity: Number(detail.quantity),
      };

      if (normalizedCostMap.has(key)) {
        const entry = normalizedCostMap.get(key);
        entry?.prices.push(priceInfo);
        entry?.prices.sort((a, b) => a.quantity - b.quantity);

        if (detail.estimationDetailID) {
          entry?.estimationDetailIds.push(detail.estimationDetailID);
        }
      } else {
        const estimationDetailIds = detail.estimationDetailID ? [detail.estimationDetailID] : [];
        normalizedCostMap.set(key, {
          supplier: {
            id: supplier.id,
            name: supplier.name,
          },
          name,
          note,
          estimationDetailIds,
          prices: [priceInfo],
          tax: {
            id: detail.tax.id,
            rate: detail.tax.rate,
            name: detail.tax.name,
          },
        });
      }
    }
  }

  return normalizedCostMap;
};

const getSpec = (category: string, spec: Omit<SalesOrderSpec, 'cost'>) => {
  switch (category) {
    case DEMAND_ITEM_CATEGORY.Cardboard:
      return spec.cardboard
        ? {
            cardboard: {
              size: spec.cardboard.size,
              material: spec.cardboard.material,
              type: spec.cardboard.type,
              other: spec.cardboard.other,
              thickness: spec.cardboard.thickness,
              printingColor: spec.cardboard.printingColor,
              processing: spec.cardboard.processing,
            },
          }
        : null;
    case DEMAND_ITEM_CATEGORY.FlexiblePackage:
      return spec.flexiblePackage
        ? {
            flexiblePackage: {
              size: spec.flexiblePackage.size,
              material: spec.flexiblePackage.material,
              type: spec.flexiblePackage.type,
              other: spec.flexiblePackage.other,
              printingColor: spec.flexiblePackage.printingColor,
              processing: spec.flexiblePackage.processing,
            },
          }
        : null;
    case DEMAND_ITEM_CATEGORY.GiftBox:
      return spec.giftBox
        ? {
            giftBox: {
              size: spec.giftBox.size,
              printingColor: spec.giftBox.printingColor,
              processing: spec.giftBox.processing,
              paperType: spec.giftBox.paperType,
              other: spec.giftBox.other,
              type: spec.giftBox.type,
            },
          }
        : null;
    case DEMAND_ITEM_CATEGORY.PaperBag:
      return spec.paperBag
        ? {
            paperBag: {
              size: spec.paperBag.size,
              printingColor: spec.paperBag.printingColor,
              processing: spec.paperBag.processing,
              paperType: spec.paperBag.paperType,
              handle: spec.paperBag.handle,
              other: spec.paperBag.other,
            },
          }
        : null;
    case DEMAND_ITEM_CATEGORY.Other:
      return spec.other
        ? {
            other: {
              specText: spec.other.specText,
            },
          }
        : null;
    default:
      return null;
  }
};

/**
 * 発注請書明細行の同一のアイテムをまとめMapを返す。
 * nameとcategoryとspecを_で連結した文字列をkeyとし、'${name}_${category}_${spec}'が同じであれば同一のアイテムとみなす
 * 同一のkeyが存在している場合は、pricesとestimationDetailIdsを追加していく
 */
export const normalizeSalesOrderItems = ({
  demandId,
  supplier,
  salesOrderDetails,
}: {
  demandId: string;
  supplier: {
    id: string;
    name: string;
  };
  salesOrderDetails: NewSalesOrderDetailFormType[];
}) => {
  const normalizedItemMap = new Map<string, MasterRegistrationItemType>();

  for (const detail of salesOrderDetails) {
    if (detail.category !== SALES_ORDER_DETAIL_CATEGORY_COST) {
      const name = detail.name;
      const category = detail.category;
      const spec = getSpec(detail.category, detail.spec) || {};

      const key = buildNormalizeItemKey(
        name,
        category,
        Object.keys(spec)
          .sort()
          .map((k) => {
            const value = spec[k as keyof typeof spec];
            return `${k}:${typeof value === 'object' ? JSON.stringify(value) : value}`;
          })
          .join('_'),
      );
      const priceInfo = {
        unitPrice: Number(detail.unitPrice),
        unitSellingPrice: Number(detail.unitSellingPrice),
        quantity: Number(detail.quantity),
      };

      if (normalizedItemMap.has(key)) {
        const entry = normalizedItemMap.get(key);
        entry?.prices.push(priceInfo);
        entry?.prices.sort((a, b) => a.quantity - b.quantity);

        if (detail.estimationDetailID) {
          entry?.estimationDetailIds.push(detail.estimationDetailID);
        }
      } else {
        const estimationDetailIds = detail.estimationDetailID ? [detail.estimationDetailID] : [];
        normalizedItemMap.set(key, {
          demandId,
          supplier: {
            id: supplier.id,
            name: supplier.name,
          },
          name,
          category: detail.category as DemandItemCategory,
          spec,
          estimationDetailIds,
          prices: [priceInfo],
          tax: {
            id: detail.tax.id,
            rate: detail.tax.rate,
            name: detail.tax.name,
          },
        });
      }
    }
  }

  return normalizedItemMap;
};

/**
 * normalizeSalesOrderItemsで正規化された発注請書明細行のアイテムに対して、見積書明細のアイテムをマージする。
 * 同一のkeyが存在している場合、pricesのquantityを比較し、存在しないquantityの場合はpricesに追加する。
 */
export const mergeEstimationDetailItems = ({
  normalizedSalesOrderItems,
  estimationDetails,
}: {
  normalizedSalesOrderItems: Map<string, MasterRegistrationItemType>;
  estimationDetails: MasterRegistrationSettingEstimationDetailType;
}) => {
  const masterRegistrationItems = normalizedSalesOrderItems;

  const details = estimationDetails?.edges
    ?.map((detailEdge) => detailEdge?.node)
    .filter((v): v is NonNullable<typeof v> => v != null);

  details?.forEach((detail) => {
    if (detail.type === ESTIMATION_DETAIL_TYPE.Item) {
      const item = detail.item;
      if (!item) return;
      const name = item.name;
      const category = item.category || '';
      const spec =
        getSpec(category, {
          cardboard: item.cardboard as SalesOrderSpecCardboard,
          flexiblePackage: item.flexiblePackage as SalesOrderSpecFlexiblePackage,
          giftBox: item.giftBox as SalesOrderSpecGiftBox,
          paperBag: item.paperBag as SalesOrderSpecPaperBag,
          other: item.other as SalesOrderSpecOther,
        }) || {};

      const key = buildNormalizeItemKey(
        name,
        category,
        Object.keys(spec)
          .sort()
          .map((k) => {
            const value = spec[k as keyof typeof spec];
            return `${k}:${typeof value === 'object' ? JSON.stringify(value) : value}`;
          })
          .join('_'),
      );
      const priceInfo = {
        unitPrice: item.unitPrice,
        unitSellingPrice: item.unitSellingPrice,
        quantity: Number(item.quantity),
      };

      if (masterRegistrationItems.has(key)) {
        const entry = masterRegistrationItems.get(key);
        const exists = entry?.prices.some((price) => price.quantity === priceInfo.quantity);
        if (!exists) {
          entry?.prices.push(priceInfo);
          entry?.prices.sort((a, b) => a.quantity - b.quantity);
        }
      }
    }
  });

  return masterRegistrationItems;
};

/**
 * normalizeSalesOrderCostsで正規化された発注請書明細行の費用に対して、見積書明細の費用をマージする。
 * 同一のkeyが存在している場合、pricesのquantityを比較し、存在しないquantityの場合はpricesに追加する。
 */
export const mergeEstimationDetailCosts = ({
  normalizedSalesOrderCosts,
  estimationDetails,
}: {
  normalizedSalesOrderCosts: Map<string, MasterRegistrationRecurringCostType>;
  estimationDetails: MasterRegistrationSettingEstimationDetailType;
}) => {
  const masterRegistrationCosts = normalizedSalesOrderCosts;

  const details = estimationDetails?.edges
    ?.map((detailEdge) => detailEdge?.node)
    .filter((v): v is NonNullable<typeof v> => v != null);

  details?.forEach((detail) => {
    if (detail.type === ESTIMATION_DETAIL_TYPE.Cost) {
      const cost = detail.cost;
      if (!cost) return;
      const name = cost.name;
      const note = cost.note || '';
      const key = buildNormalizeCostKey(name, note);
      const priceInfo = {
        unitPrice: cost.unitPrice,
        unitSellingPrice: cost.unitSellingPrice,
        quantity: Number(cost.quantity),
      };

      if (masterRegistrationCosts.has(key)) {
        const entry = masterRegistrationCosts.get(key);
        const exists = entry?.prices.some((price) => price.quantity === priceInfo.quantity);
        if (!exists) {
          entry?.prices.push(priceInfo);
          entry?.prices.sort((a, b) => a.quantity - b.quantity);
        }
      }
    }
  });

  return masterRegistrationCosts;
};
