import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { DemandItemDetailBlockNoItem } from 'features/demandItem/components/DemandItemDetailBlock/DemandItemDetailBlockNoItem';
import { DemandItemCategory, buildDemandItemCode } from 'features/demandItem/models';
import {
  MASTER_REGISTRATION_SETTING_ITEM_TYPE_UPDATE,
  NewSalesOrderFormType,
} from 'features/salesOrdersV2/form';
import {
  ItemCategory,
  MasterRegistrationSettingModalQuery,
  MasterRegistrationSettingModalQuery$data,
} from 'gql/__generated__/MasterRegistrationSettingModalQuery.graphql';
import RelayEnvironment from 'lib/relay/RelayEnvironment';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { fetchQuery, graphql } from 'relay-runtime';
import { dateFormat } from 'utils/date';

const masterRegistrationSettingModalQuery = graphql`
  query MasterRegistrationSettingModalQuery($demandId: ID!, $category: ItemCategory!) {
    demandItems(where: {demandID: $demandId, category: $category, isCurrent: true}) {
      edges {
        node {
          id
          name
          demand {
            code
          }
          category
          itemNumber
          version
          salesOrderItems(
            where: {
              hasSalesOrderDetailWith: {
                deletedAtIsNil: true,
                hasSalesOrderWith: {
                  deletedAtIsNil: true,
                  hasOrdersWith: {
                    deletedAtIsNil: true,
                  },
                }
              }
            },
            orderBy: {direction:DESC, field:CREATED_AT},
            first: 1
          ) {
            edges {
              node {
                salesOrderDetail {
                  salesOrder {
                    orders {
                      id
                      createdAt
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

type DemandItemType = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<MasterRegistrationSettingModalQuery$data['demandItems']>['edges']
    >[number]
  >['node']
>[];

type Props = {
  isOpen: boolean;
  onClose: () => void;
  demandId: string;
  index: number;
  category: DemandItemCategory;
};

export const MasterRegistrationSettingModal = ({
  isOpen,
  onClose,
  demandId,
  index,
  category,
}: Props) => {
  const [demandItems, setDemandItems] = useState<DemandItemType>([]);

  const fetch = async () => {
    const res = await fetchQuery<MasterRegistrationSettingModalQuery>(
      RelayEnvironment,
      masterRegistrationSettingModalQuery,
      { demandId, category: category as ItemCategory },
      { fetchPolicy: 'network-only' },
    ).toPromise();

    const demandItems = res?.demandItems.edges
      ?.map((demandItem) => demandItem?.node)
      .filter((v): v is NonNullable<typeof v> => v != null);

    setDemandItems(demandItems || []);
  };

  const form = useFormContext<NewSalesOrderFormType>();
  const { setValue, clearErrors } = form;

  const handleSelectDemandItem = (demandItem: {
    id: string;
    name: string;
    itemNumber: string;
    version: string;
    category: DemandItemCategory;
    demandCode: string;
  }) => {
    setValue(`masterRegistrationItems.${index}.type`, MASTER_REGISTRATION_SETTING_ITEM_TYPE_UPDATE);

    setValue(`masterRegistrationItems.${index}.demandItem`, {
      ...demandItem,
    });
    clearErrors(`masterRegistrationItems.${index}.type`);
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      fetch();
    }
  }, [isOpen]);

  if (!demandItems) return null;

  return (
    <Modal isCentered isOpen={isOpen} onClose={handleClose} size="2xl" closeOnEsc={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <VStack alignItems="flex-start" spacing={1}>
            <Text fontSize="xl" fontWeight="bold">
              仕様変更前の商品を選択
            </Text>
            <Text fontSize="sm" color="gray.600" fontWeight="normal">
              仕様変更の場合、すでに存在している仕様変更前のマスタに上書きする必要があります
            </Text>
          </VStack>
        </ModalHeader>

        <ModalBody p={0}>
          <HStack
            w="100%"
            fontWeight="bold"
            fontSize="xs"
            color="gray.900"
            spacing={0}
            borderBottom="1px solid"
            borderColor="gray.200"
          >
            <Box px={4} py={3} w="160px" maxW="160px">
              コード
            </Box>
            <Box px={4} py={3} w="352px" maxW="352px">
              商品名
            </Box>
            <Box px={4} py={3} w="160px" maxW="160px">
              最終発注日
            </Box>
          </HStack>
          <VStack w="100%" spacing={0} h="400px" overflowY="scroll" overscrollBehavior="auto">
            {demandItems.length > 0 ? (
              demandItems.map((demandItem) => {
                const latestOrder =
                  demandItem.salesOrderItems.edges?.[0]?.node?.salesOrderDetail?.salesOrder
                    ?.orders?.[0];
                return (
                  <HStack
                    fontSize="xs"
                    key={demandItem.id}
                    width="100%"
                    borderBottom="1px solid"
                    borderColor="gray.200"
                    spacing={0}
                    cursor="pointer"
                    _hover={{ backgroundColor: 'gray.50' }}
                    onClick={() =>
                      handleSelectDemandItem({
                        id: demandItem.id,
                        name: demandItem.name,
                        itemNumber: String(demandItem.itemNumber),
                        version: String(demandItem.version),
                        category: demandItem.category as DemandItemCategory,
                        demandCode: demandItem.demand?.code || '',
                      })
                    }
                  >
                    <Box p={4} w="160px" maxW="160px">
                      {buildDemandItemCode({
                        demandCode: demandItem.demand?.code || '',
                        category: demandItem.category as DemandItemCategory,
                        itemNumber: demandItem.itemNumber,
                        version: demandItem.version,
                      })}
                    </Box>
                    <Box p={4} w="352px" maxW="352px">
                      {demandItem.name}
                    </Box>
                    <Box p={4} w="160px" maxW="160px">
                      {latestOrder?.createdAt ? dateFormat(latestOrder?.createdAt) : '-'}
                    </Box>
                  </HStack>
                );
              })
            ) : (
              <DemandItemDetailBlockNoItem />
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
