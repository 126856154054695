import {
  Box,
  Divider,
  Flex,
  HStack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { InformationShizai } from 'components/InformationShizai';
import { SubHeading } from 'components/SubHeading';
import { NewSalesOrderFormType } from 'features/salesOrdersV2/form';
import { SalesOrderDetail, deduplicateAdjacentNames } from 'features/salesOrdersV2/models';
import { useFormContext } from 'react-hook-form';
import { dateFormat, getToday } from 'utils/date';
import {
  calculateDetailAmount,
  calculateSubtotalAmount,
  calculateTotalAmount,
  calculateTotalTaxAmount,
} from 'utils/priceCalculation';
import { NewSalesOrderConfirmSpecPreview } from './NewSalesOrderConfirmSpecPreview';

export const NewSalesOrderConfirmPreview = () => {
  const form = useFormContext<NewSalesOrderFormType>();
  const { getValues } = form;

  const { detailMessage, demand, details, receivedDate } = getValues();

  const detailsForCalculation = details.map((detail: SalesOrderDetail) => ({
    unitPrice: detail.unitPrice || 0,
    unitSellingPrice: detail.unitSellingPrice || 0,
    quantity: detail.quantity || 0,
    tax: detail.tax || 0,
  }));
  const items = deduplicateAdjacentNames(details);

  return (
    <VStack align="stretch" p={8} spacing={6} borderWidth="1px" borderRadius="6px" w="full">
      <VStack align="stretch" spacing={0}>
        <HStack align="end" pb="1rem" justify="space-between">
          <SubHeading label="発注請書" />
          <HStack spacing={4}>
            <Text fontSize="sm">発行日：{getToday()}</Text>
            <Text fontSize="sm">受注日：{dateFormat(receivedDate)}</Text>
          </HStack>
        </HStack>
        <Divider />
      </VStack>
      <HStack justify="space-between" align="flex-start">
        <VStack spacing={2} w="320px" align="start">
          <Text fontSize="xl" fontWeight="bold">
            {demand.name} 御中
          </Text>
          <Text color="gray.500" fontSize="sm">
            下記のとおりご発注を承りました
          </Text>
          <VStack spacing={1} w="full" h={41}>
            <HStack align="end" justify="space-between" w="full">
              <Text>お見積金額</Text>
              <HStack spacing={1} align="end">
                <Text fontSize="2xl" fontWeight="bold">
                  {calculateTotalAmount(detailsForCalculation).toLocaleString()}
                </Text>
                <Text>円</Text>
              </HStack>
            </HStack>
            <Divider borderColor="gray.700" />
          </VStack>
        </VStack>
        <Box w="320px">
          <InformationShizai />
        </Box>
      </HStack>
      <VStack align="start" justify="space-between">
        <Table>
          <Thead>
            <Tr color="gray.700">
              <Th minW="412px" pl="0">
                品名
              </Th>
              <Th minW="104px" whiteSpace="nowrap">
                メモ
              </Th>
              <Th minW="104px" whiteSpace="nowrap">
                確定納品日
              </Th>
              <Th isNumeric minW="76px" whiteSpace="nowrap">
                数量
              </Th>
              <Th isNumeric minW="76px" whiteSpace="nowrap">
                単価
              </Th>
              <Th isNumeric minW="84px" whiteSpace="nowrap" pr="0">
                金額 (税抜)
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.length > 0 &&
              items.map((item, index) => (
                <Tr key={index} verticalAlign="top" fontSize="xs">
                  <Td pl="0" whiteSpace="pre-wrap">
                    {item.name}
                    {item.name !== '同上' && (
                      <Box mt={2} ml={4}>
                        <NewSalesOrderConfirmSpecPreview detail={item} />
                      </Box>
                    )}
                  </Td>
                  <Td color="gray.700">{item.memo || '-'}</Td>
                  <Td color="gray.700" whiteSpace="nowrap">
                    {item.fixedDeliveryDate ? dateFormat(item.fixedDeliveryDate) : '-'}
                  </Td>
                  <Td isNumeric color="gray.700">
                    {item?.quantity?.toLocaleString() || '-'}
                  </Td>
                  <Td isNumeric color="gray.700">
                    {item?.unitSellingPrice?.toLocaleString() || '-'}
                  </Td>
                  <Td isNumeric color="gray.700" pr="0">
                    {calculateDetailAmount({
                      quantity: item?.quantity || 0,
                      price: item?.unitSellingPrice || 0,
                    }).toLocaleString()}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </VStack>
      <HStack justify="space-between" align="flex-start">
        <VStack spacing={2} w="400px" align="start">
          <Text fontSize="sm">備考</Text>
          <Text fontSize="sm" whiteSpace="pre-wrap">
            {detailMessage}
          </Text>
        </VStack>

        <VStack align="left" spacing={2} w="240px">
          <Flex justify="space-between">
            <Box w="140px" fontSize="sm">
              小計
            </Box>
            <Box fontSize="sm">
              {calculateSubtotalAmount(detailsForCalculation).toLocaleString()}
            </Box>
          </Flex>
          <Flex justify="space-between">
            <Box whiteSpace="nowrap" w="140px" fontSize="sm">
              消費税
            </Box>
            <Box fontSize="sm">
              {calculateTotalTaxAmount(detailsForCalculation).toLocaleString()}
            </Box>
          </Flex>
          <Flex justify="space-between">
            <Box w="140px" fontWeight="bold">
              合計金額
            </Box>
            <Box fontWeight="bold">
              {calculateTotalAmount(detailsForCalculation).toLocaleString()}
            </Box>
          </Flex>
        </VStack>
      </HStack>
    </VStack>
  );
};
