import { Grid, GridItem } from '@chakra-ui/react';
import { SalesOrdersDetailSpec } from 'features/salesOrdersV2/models';
import { Fragment, memo } from 'react';

export const SalesOrdersFormDetailSpec = memo(({ specs }: { specs: SalesOrdersDetailSpec[] }) => {
  return (
    <Grid
      w="full"
      mt={0}
      ml={0}
      pl={3}
      fontSize="xs"
      borderLeft="2px"
      borderColor="gray.200"
      gap="4px 12px"
      gridTemplateColumns="max-content 1fr"
    >
      {specs.map(
        (spec, i) =>
          spec.node && (
            <Fragment key={`spec-${i}`}>
              <GridItem textAlign="right">{spec.label}</GridItem>
              <GridItem whiteSpace="pre-wrap">{spec.node}</GridItem>
            </Fragment>
          ),
      )}
    </Grid>
  );
});
