/**
 * @generated SignedSource<<0fef3cd5f327231e0e7f0dd1fa7c7733>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useDownloadEstimationPdfV2Query$variables = {
  id: string;
};
export type useDownloadEstimationPdfV2Query$data = {
  readonly downloadEstimationPdf: string;
};
export type useDownloadEstimationPdfV2Query = {
  response: useDownloadEstimationPdfV2Query$data;
  variables: useDownloadEstimationPdfV2Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ScalarField",
    "name": "downloadEstimationPdf",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDownloadEstimationPdfV2Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDownloadEstimationPdfV2Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "09cf242a4c0d56e1773d9167778b5890",
    "id": null,
    "metadata": {},
    "name": "useDownloadEstimationPdfV2Query",
    "operationKind": "query",
    "text": "query useDownloadEstimationPdfV2Query(\n  $id: ID!\n) {\n  downloadEstimationPdf(id: $id)\n}\n"
  }
};
})();

(node as any).hash = "36c97ebb91392426e2f70d8da5be6a88";

export default node;
