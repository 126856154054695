import Excel from 'exceljs';
import { ZodEstimationRequestNewFormData } from 'features/estimationRequest/zod';
import {
  CreateEstimationRequestDetailInput,
  EstimationDetailTypeItemSpecInput,
  useEstimationRequestFormMutation,
} from 'gql/__generated__/useEstimationRequestFormMutation.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { VersionWatcherContext } from 'hooks/useVersionWatcher';
import { toast } from 'lib/toast';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { removeEstimationRequestDraft } from 'repositories/estimationRequestDraftRepository';
import { paths } from 'utils/paths';

const dt = new DataTransfer();

export type EstimationRequestFormState = {
  company: {
    id: string;
    name: string;
  };
  specText: string;
  requestTitle: string;
  mailSubject: string;
  items: {
    name: string;
    specJson?: Map<string, Excel.CellValue>;
    quantity?: number | string;
  }[];
  suppliers: {
    id: string;
    name: string;
    contacts: {
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      memo?: string;
    }[];
    headerMessage: string;
    footerMessage: string;
    defaultDetailMessage?: string;
  }[];
  attachments: FileList;
  internalAssignees: { value: string; label: string }[];
};

export const initialEstimationRequestFormState: EstimationRequestFormState = {
  company: {
    id: '',
    name: '',
  },
  requestTitle: '',
  mailSubject: '',
  specText:
    '依頼主：\n内容物：\n納品場所：\n納品条件：パレ降ろし、時間指定なし、その他付帯作業なし\n納期：',
  items: [],
  suppliers: [
    {
      id: '',
      name: '',
      contacts: [],
      headerMessage: '',
      footerMessage: '',
    },
  ],
  attachments: dt.files,
  internalAssignees: [{ value: '', label: '' }],
};

export const useEstimationRequestForm = (
  state: EstimationRequestFormState = initialEstimationRequestFormState,
) => {
  const versionWatcher = useContext(VersionWatcherContext);
  useEffect(versionWatcher.disableSoftDeleteEffect, []);

  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');

  const navigate = useNavigate();
  const [data, setData] = useState<EstimationRequestFormState>();
  const [showPreview, setPreview] = useState(false);
  const { commitMutation, isMutationInFlight } =
    useMutationWrapper<useEstimationRequestFormMutation>(
      graphql`
      mutation useEstimationRequestFormMutation(
        $input: CreateBulkEstimationRequestInput!
      ) {
      createBulkEstimationRequest(input: $input) {
        id
        requestTitle
      }
    }
    `,
    );

  const onClickToConfirm = (data: EstimationRequestFormState) => {
    setData({ ...data });
    setPreview(true);
  };

  const onSubmit = (data: ZodEstimationRequestNewFormData) => {
    const uploadables = Array.from(data.attachments).map((attachment, index) => {
      return {
        [`variables.input.attachments.${[index]}.file`]: attachment,
      };
    });

    commitMutation({
      variables: {
        input: {
          companyID: data.company.id,
          requestTitle: data.requestTitle,
          specText: data.specText,
          mailSubject: data.mailSubject,
          suppliers: data.suppliers.map((supplier) => ({
            id: supplier.id,
            toContactIDs: supplier.contacts.map((c) => c.id),
            headerMessage: supplier.headerMessage,
            footerMessage: supplier.footerMessage,
            defaultDetailMessage: supplier.defaultDetailMessage,
          })),
          details: [
            ...data.items.map(
              (item): CreateEstimationRequestDetailInput => ({
                type: 'item',
                value: {
                  item: {
                    name: item.specJson?.get('name') || item.name,
                    category: item.specJson?.get('category'),
                    annualQuantity: item.specJson?.get('annualQuantity'),
                    askingUnitPrice: item.specJson?.get('askingUnitPrice'),
                    quantity: Number(item.quantity),
                    spec: buildSpec(item.specJson),
                  },
                },
              }),
            ),
          ],
          attachments: Array.from(data.attachments).map(() => ({ file: null })),
          internalAssignees: [
            ...(data.internalAssignees?.map((assignee) => ({ userID: assignee.value })) || []),
          ],
        },
      },
      uploadables: Object.assign({}, ...uploadables),
      onCompleted: () => {
        toast({
          title: '見積依頼を作成しました',
          status: 'success',
        });
        if (type === 'draft') removeEstimationRequestDraft();
        navigate(paths.estimationRequest.url());
      },
    });
  };

  const onClickToNewForm = () => setPreview(false);

  useEffect(() => {
    setData(state);
  }, [state]);

  return {
    data,
    setData,
    showPreview,
    setPreview,
    onClickToConfirm,
    onSubmit,
    isMutationInFlight,
    onClickToNewForm,
  };
};

const buildSpec = (
  specJson?: Map<string, string | null | undefined>,
): EstimationDetailTypeItemSpecInput | null => {
  switch (specJson?.get('category')) {
    case 'cardboard':
      return {
        cardboard: {
          material: specJson.get('material'),
          other: specJson.get('other'),
          printingColor: specJson.get('printingColor'),
          processing: specJson.get('processings'),
          size: specJson.get('size'),
          thickness: specJson.get('thickness'),
          type: specJson.get('type'),
        },
      };
    case 'flexiblePackage':
      return {
        flexiblePackage: {
          material: specJson.get('material'),
          other: specJson.get('other'),
          printingColor: specJson.get('printingColor'),
          processing: specJson.get('processings'),
          size: specJson.get('size'),
          type: specJson.get('type'),
        },
      };
    case 'giftBox':
      return {
        giftBox: {
          other: specJson.get('other'),
          paperType: specJson.get('paperType'),
          printingColor: specJson.get('printingColor'),
          processing: specJson.get('processings'),
          size: specJson.get('size'),
          type: specJson.get('type'),
        },
      };
    case 'paperBag':
      return {
        paperBag: {
          handle: specJson.get('handle'),
          other: specJson.get('other'),
          paperType: specJson.get('paperType'),
          printingColor: specJson.get('printingColor'),
          processing: specJson.get('processings'),
          size: specJson.get('size'),
        },
      };
    case 'other':
      return {
        other: {
          other: specJson.get('other'),
        },
      };
  }

  return {
    other: {},
  };
};
