import { Box, FormControl, HStack, Textarea, VStack } from '@chakra-ui/react';
import {
  MASTER_REGISTRATION_SETTING_COST_TYPE_RECURRING,
  NewSalesOrderFormType,
} from 'features/salesOrdersV2/form';
import { useFormContext, useWatch } from 'react-hook-form';

export const MasterRegistrationSettingCostMemo = ({
  index,
}: {
  index: number;
}) => {
  const form = useFormContext<NewSalesOrderFormType>();
  const { register } = form;

  const type = useWatch({
    control: form.control,
    name: `masterRegistrationCosts.${index}.type`,
  });

  if (type !== MASTER_REGISTRATION_SETTING_COST_TYPE_RECURRING) {
    return null;
  }

  return (
    <VStack spacing={3} alignItems="flex-start" fontSize="sm" w="100%">
      <HStack spacing={2} alignItems="flex-start" fontSize="sm" w="100%">
        <Box color="gray.500" minW="5.5rem" whiteSpace="nowrap" textAlign="right">
          社内メモ
        </Box>
        <Box w="100%">
          <FormControl>
            <Textarea
              {...register(`masterRegistrationCosts.${index}.memo`)}
              minH="80px"
              size="sm"
              placeholder="リピート発注時に参考になる事項があれば記載しましょう"
            />
          </FormControl>
        </Box>
      </HStack>
    </VStack>
  );
};
