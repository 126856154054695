import { DemandItemCategory } from 'features/demandItem/models';
import { EstimationDetailType as GraphQLEstimationDetailType } from 'gql/graphql.types';
import {
  EstimationDetailDocumentDisplayStatus,
  isDocumentDisplayStatusShown,
} from './EstimationDetailDocumentDisplayStatus';
import { EstimationDetailSpec } from './EstimationDetailSpec';

export const ESTIMATION_DETAIL_TYPE = GraphQLEstimationDetailType;

export type EstimationDetail = {
  documentDisplayStatus: EstimationDetailDocumentDisplayStatus;
  name: string;
  tax: {
    id: string;
    rate: string | number;
  };
  category: string;
  spec: EstimationDetailSpec;
  unitPrice?: string | number | undefined;
  quantity?: string | number | undefined;
  unitSellingPrice?: string | number | undefined;
};

export const filterByDocumentDisplayStatusShown = <T extends EstimationDetail>(details: T[]) => {
  return details.filter((detail) => isDocumentDisplayStatusShown(detail.documentDisplayStatus));
};

export const deduplicateAdjacentNames = <T extends EstimationDetail>(details: T[]): T[] => {
  if (details.length === 0) return [];

  const result: T[] = [{ ...details[0] }];

  for (let i = 1; i < details.length; i++) {
    const prev = details[i - 1];
    const curr = details[i];
    const newElement: T = { ...curr };

    const prevSpec = prev.spec[prev.category as DemandItemCategory];
    const currSpec = curr.spec[curr.category as DemandItemCategory];

    if (
      prev.name === curr.name &&
      prev.category === curr.category &&
      JSON.stringify(prevSpec, removeIdReplacer) === JSON.stringify(currSpec, removeIdReplacer)
    ) {
      newElement.name = '同上';
    }

    result.push(newElement);
  }

  return result;
};

// 仕様同士で比較するとき xid が邪魔なので削除する replacer
const removeIdReplacer = (key: string, value: unknown) => {
  if (key === 'id') return undefined;
  return value;
};
