import { Box, Checkbox, CheckboxGroup, HStack, Heading, Text, VStack } from '@chakra-ui/react';
import { Loading } from 'components/Loading';
import { DEMAND_ITEM_CATEGORY_LABEL, DemandItemCategory } from 'features/demandItem/models';
import { DemandItemsAndFilterWrapperFragment$key } from 'gql/__generated__/DemandItemsAndFilterWrapperFragment.graphql';
import { Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useSearchParams } from 'react-router-dom';
import { DemandItemDetailBlocks } from './DemandItemDetailBlocks';

const demandItemsAndFilterWrapperFragment = graphql`
  fragment DemandItemsAndFilterWrapperFragment on Company {
    demandItems(where: {isCurrent: true}) {
      edges {
        node {
          supplier {
            id
            name
          }
        }
      }
    }
  }
`;

type Props = {
  queryRef: DemandItemsAndFilterWrapperFragment$key;
  demandId: string;
};

const removeDuplicates = <T, K extends keyof T>(array: T[], key: K): T[] => {
  const seen = new Set<T[K]>();
  return array.filter((item) => {
    if (seen.has(item[key])) {
      return false;
    }
    seen.add(item[key]);
    return true;
  });
};

export const DemandItemsAndFilterWrapper = ({ queryRef, demandId }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const itemCategories = searchParams.get('itemCategories')?.split(',') || [];
  const itemSuppliers = searchParams.get('itemSuppliers')?.split(',') || [];

  const items = useFragment(demandItemsAndFilterWrapperFragment, queryRef);

  const suppliers = removeDuplicates<{ id: string; name: string }, 'id'>(
    (items?.demandItems?.edges || []).map(
      (edge) => edge?.node?.supplier as { id: string; name: string },
    ),
    'id',
  );

  const handleFilterCategories = (values: (string | number)[]) => {
    setSearchParams((prev) => {
      if (values.length === 0) {
        prev.delete('itemCategories');
      } else {
        prev.set('itemCategories', values.join(','));
      }
      return prev;
    });
  };

  const handleFilterSuppliers = (values: (string | number)[]) => {
    setSearchParams((prev) => {
      if (values.length === 0) {
        prev.delete('itemSuppliers');
      } else {
        prev.set('itemSuppliers', values.join(','));
      }
      return prev;
    });
  };

  return (
    <HStack
      width="100%"
      border="1px solid"
      borderColor="gray.200"
      borderRadius={8}
      height="1024px"
      overflow="hidden"
      alignItems="flex-start"
      spacing={0}
      maxWidth="1160px"
    >
      <VStack
        w="200px"
        alignItems="flex-start"
        p={4}
        flexShrink={0}
        borderRight="1px solid"
        borderColor="gray.200"
        h="100%"
        spacing={4}
      >
        <Heading as="h4" fontSize="md">
          フィルター
        </Heading>

        <VStack spacing={2} alignItems="flex-start">
          <Text fontSize="sm" color="gray.500">
            カテゴリー
          </Text>
          <VStack spacing={2} alignItems="flex-start">
            <CheckboxGroup onChange={handleFilterCategories} value={itemCategories}>
              {Object.values(DEMAND_ITEM_CATEGORY_LABEL).map((category, i) => (
                <HStack key={category.categoryName} alignItems="flex-start">
                  <Box pt={0.5}>
                    <Checkbox
                      key={Object.keys(DEMAND_ITEM_CATEGORY_LABEL)[i]}
                      value={Object.keys(DEMAND_ITEM_CATEGORY_LABEL)[i]}
                      id={Object.keys(DEMAND_ITEM_CATEGORY_LABEL)[i]}
                    />
                  </Box>
                  <label
                    htmlFor={Object.keys(DEMAND_ITEM_CATEGORY_LABEL)[i]}
                    style={{ fontSize: '14px', cursor: 'pointer' }}
                  >
                    {category.categoryName}
                  </label>
                </HStack>
              ))}
            </CheckboxGroup>
          </VStack>
        </VStack>
        <VStack spacing={2} alignItems="flex-start">
          <Text fontSize="sm" color="gray.500">
            サプライヤー
          </Text>
          <VStack spacing={2} alignItems="flex-start">
            <CheckboxGroup onChange={handleFilterSuppliers} value={itemSuppliers}>
              {suppliers.map((supplier) => (
                <HStack key={supplier.id} alignItems="flex-start">
                  <Box pt={0.5}>
                    <Checkbox key={supplier.id} value={supplier.id} id={supplier.id} />
                  </Box>
                  <label htmlFor={supplier.id} style={{ fontSize: '14px', cursor: 'pointer' }}>
                    {supplier.name}
                  </label>
                </HStack>
              ))}
            </CheckboxGroup>
          </VStack>
        </VStack>
      </VStack>
      <Box w="100%" h="100%" overflow="scroll" overscrollBehavior="auto">
        <Suspense fallback={<Loading />}>
          <DemandItemDetailBlocks
            demandId={demandId}
            itemCategories={itemCategories as DemandItemCategory[]}
            itemSuppliers={itemSuppliers}
          />
        </Suspense>
      </Box>
    </HStack>
  );
};
