import { Alert, Box, Button, Heading, Text, VStack, useDisclosure } from '@chakra-ui/react';
import { Info, Send } from '@icon-park/react';
import { Loading } from 'components/Loading';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { InvoiceBulkSendTable } from 'features/invoice/components/InvoiceBulkSendTable';
import { InvoiceRecipientEditFormDataType } from 'features/invoice/components/InvoiceRecipientEditForm';
import { InvoiceRecipientEditModal } from 'features/invoice/components/InvoiceRecipientEditModal';
import { ZipFileUploadButton } from 'features/invoice/components/ZipFileUploadButton';
import { useCreateInvoiceBulkSendJob } from 'features/invoice/hooks/useCreateInvoiceBulkSendJob';
import { useExtractInvoiceBulkSendJobDocuments } from 'features/invoice/hooks/useExtractInvoiceBulkSendJobDocuments';
import { useInvoiceDocuments } from 'features/invoice/hooks/useInvoiceDocuments';
import { useQueryParams } from 'features/invoice/hooks/useInvoiceQueryPrams';
import { GroupedInvoiceType } from 'features/invoice/type';
import { bulkSend_InvoiceBulkSendPageMutation } from 'gql/__generated__/bulkSend_InvoiceBulkSendPageMutation.graphql';
import {
  CompanyInvoiceRecipientRecipientType,
  CreateInvoiceBulkSendJobInput,
} from 'gql/graphql.types';
import { InvoiceBulkSendJobDetailRecipientRecipientType } from 'gql/graphql.types';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { usePreventNavigation } from 'hooks/usePreventNavigation';
import { toast } from 'lib/toast';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';

export const InvoiceBulkSendPage = () => {
  const [showBlocker, setShowBlocker] = useState(false);
  const [invoiceBulkSendJobData, setInvoiceBulkSendJobData] = useState<{
    objectName: string | null;
    groupedInvoices: GroupedInvoiceType[];
  }>({
    objectName: null,
    groupedInvoices: [],
  });
  const [selectedInvoiceGroup, setSelectedInvoiceGroup] = useState<GroupedInvoiceType | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  // モーダルの開閉状態を管理
  const {
    isOpen: isOpenRecipientEditModal,
    onOpen: onOpenRecipientEditModal,
    onClose: onCloseRecipientEditModal,
  } = useDisclosure();

  usePreventNavigation(showBlocker);
  const navigate = useNavigate();
  const { queryParams } = useQueryParams();
  const { extractDocuments, isMutationInFlight: isExtractMutationInFlight } =
    useExtractInvoiceBulkSendJobDocuments();
  const { fetchDocuments, isFetching } = useInvoiceDocuments();
  const { createInvoiceBulkSendJob, isMutationInFlight: isSendMutationInFlight } =
    useCreateInvoiceBulkSendJob();

  const { commitMutation, isMutationInFlight: isUpdateMutationInFlight } =
    useMutationWrapper<bulkSend_InvoiceBulkSendPageMutation>(
      graphql`
        mutation bulkSend_InvoiceBulkSendPageMutation(
          $id: ID!
          $input: UpdateCompanyInvoiceRecipientsInput!
        ) {
          updateCompanyInvoiceRecipients(id: $id, input: $input) {
            id
          }
        }
      `,
    );

  // zipファイルをアップロードした際の処理
  const handleUploaded = async (objectName: string) => {
    // アップロードしたzipファイルから請求書ファイルを抽出
    const extractedDocuments = await extractDocuments({ objectName });
    // 抽出した請求書ファイルと関連するデータを取得
    const documents = await fetchDocuments(extractedDocuments.map((document) => document.id));

    // company 毎に documents をグループ化
    const groupedDocuments =
      documents?.reduce(
        (acc, document) => {
          const company = document?.invoice?.company;
          if (!company) return acc;

          const recipients = company.invoiceRecipients;
          // NOTE: 請求書送付先が存在しない、または空の場合はスキップ
          if (!recipients || recipients.length === 0) return acc;

          if (!acc[company.id]) {
            acc[company.id] = {
              company,
              documents: [],
              recipients,
            };
          }

          if (document) {
            acc[company.id].documents.push(document);
          }
          return acc;
        },
        {} as Record<string, GroupedInvoiceType>,
      ) || {};

    if (Object.keys(groupedDocuments).length === 0) {
      toast({
        title: '請求先が見つかりませんでした',
        description: 'デマンドの請求書送付先を確認してください',
        status: 'error',
      });

      return;
    }

    // NOTE: 請求先一覧を表示した後はページを離脱する際に警告を表示する
    setShowBlocker(true);
    setInvoiceBulkSendJobData({
      objectName,
      groupedInvoices: Object.values(groupedDocuments),
    });
  };

  // zipファイルをアップロードした際のエラー処理
  const handleUploadError = (message: string) => {
    toast({
      title: 'ZIPファイルを読み込めませんでした',
      description: message,
      status: 'error',
    });
  };

  // 請求書を送付ボタンをクリックした際の処理
  const handleSendButtonClick = () => {
    const isSentDocuments = invoiceBulkSendJobData.groupedInvoices.flatMap((group) =>
      group.documents.filter((document) => document.invoice.isSent),
    );

    if (isSentDocuments.length > 0) {
      // 送付済みの請求書がある場合はダイアログで送付するかどうかを確認する
      const isConfirmed = window.confirm(
        '送付済みの請求書がありますが送付しますか？\n以下の請求書は送付済みです\n' +
          isSentDocuments.map((document) => document.fileName).join('\n'),
      );
      if (!isConfirmed) return;
    }

    // 請求書送付処理
    const input: CreateInvoiceBulkSendJobInput = {
      objectName: String(invoiceBulkSendJobData.objectName),
      details: invoiceBulkSendJobData.groupedInvoices.map((group) => ({
        companyID: group.company.id,
        invoiceDocumentIDs: group.documents.map((document) => document.id),
        invoiceRecipients: group.recipients.map((recipient) => ({
          email: recipient.email,
          recipientType:
            recipient.recipientType.toLowerCase() === 'to'
              ? InvoiceBulkSendJobDetailRecipientRecipientType.To
              : InvoiceBulkSendJobDetailRecipientRecipientType.Cc,
        })),
      })),
    };

    setShowBlocker(false);
    createInvoiceBulkSendJob(input, () => {
      setShowBlocker(true);
    });
  };

  // 送付先修正ボタンをクリックした際の処理
  const handleEditRecipients = (index: number) => {
    const group = invoiceBulkSendJobData.groupedInvoices[index];
    if (!group) return;

    setSelectedInvoiceGroup(group);
    onOpenRecipientEditModal();
  };

  // 送付先編集モーダルを閉じる際の処理
  const handleCloseModal = () => {
    setSelectedInvoiceGroup(null);
    onCloseRecipientEditModal();
  };

  // 送付先編集モーダルで保存ボタンをクリックした際の処理
  const handleSaveRecipients = (recipients: InvoiceRecipientEditFormDataType['recipients']) => {
    commitMutation({
      variables: {
        id: selectedInvoiceGroup?.company.id || '',
        input: {
          invoiceSendRecipients: recipients.map((recipient) => ({
            email: recipient.email,
            recipientType: recipient.recipientType as CompanyInvoiceRecipientRecipientType,
          })),
        },
      },
      onError() {
        toast({
          title: '送付先を更新に失敗しました',
          status: 'error',
        });
      },
      onCompleted: () => {
        const updatedGroupedInvoices = invoiceBulkSendJobData.groupedInvoices.map((group) => {
          if (group.company.id === selectedInvoiceGroup?.company.id) {
            return {
              ...group,
              recipients: recipients.map((recipient) => ({
                ...recipient,
                id: recipient.id || '',
              })),
            };
          }
          return group;
        });

        setInvoiceBulkSendJobData({
          ...invoiceBulkSendJobData,
          groupedInvoices: updatedGroupedInvoices,
        });

        toast({
          title: '送付先を更新しました',
          status: 'success',
        });
      },
    });
  };

  return (
    <>
      <Box mb={6}>
        <PageBack
          onClick={() =>
            navigate(
              paths.invoice.url({
                creator: queryParams.creator,
                demand: queryParams.demand,
                isSent: queryParams.isSent,
                startBookedAt: queryParams.startBookedAt,
                endBookedAt: queryParams.endBookedAt,
              }),
            )
          }
        />
      </Box>
      <VStack align="start" gap={6}>
        <VStack align="start" gap={2}>
          <SubHeading label="請求書の一括送付" />
          <Text color="gray.600" fontSize="sm">
            freeeでダウンロードした請求書を一括で送付できます
            <br />
            PDFデータがまとまっているZIPファイルをアップロードしましょう
          </Text>
        </VStack>
        <Box w="full">
          <ZipFileUploadButton
            onUploaded={handleUploaded}
            onError={handleUploadError}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
          />
        </Box>
        <VStack align="start" gap={4}>
          <Heading as="h3" fontSize="xl">
            送付先一覧
          </Heading>
          <Alert
            status="info"
            borderRadius="md"
            border="2px solid"
            borderColor="gray.500"
            bg="white"
            color="gray.900"
            width="fit-content"
          >
            <Box mr={1}>
              <Info theme="outline" size="20" fill="#718096" />
            </Box>
            <Text fontWeight="bold" fontSize="sm">
              請求書送付先が登録されているデマンドにのみ送付されます
            </Text>
          </Alert>
          {isExtractMutationInFlight || isFetching ? (
            <Box width="100%" textAlign="center" mt={40}>
              <Loading />
            </Box>
          ) : (
            <>
              {invoiceBulkSendJobData.groupedInvoices.length > 0 ? (
                <>
                  <Button
                    colorScheme="blue"
                    width="13rem"
                    leftIcon={<Send />}
                    onClick={handleSendButtonClick}
                    isDisabled={isUploading || isSendMutationInFlight}
                    isLoading={isSendMutationInFlight}
                    loadingText="送付中"
                  >
                    請求書を送付
                  </Button>
                  <InvoiceBulkSendTable
                    groupedInvoiceData={invoiceBulkSendJobData.groupedInvoices}
                    isUploading={isUploading}
                    onEditRecipients={handleEditRecipients}
                  />
                </>
              ) : (
                <Text color="gray.500">
                  ZIPファイルをアップロードすると送付先が一覧で表示されます
                </Text>
              )}
            </>
          )}
        </VStack>
      </VStack>

      {/* 送付先編集モーダル */}
      {isOpenRecipientEditModal && selectedInvoiceGroup && (
        <InvoiceRecipientEditModal
          isOpen={isOpenRecipientEditModal}
          onClose={handleCloseModal}
          companyName={selectedInvoiceGroup.company.name}
          recipients={
            selectedInvoiceGroup.recipients as InvoiceRecipientEditFormDataType['recipients']
          }
          onSave={handleSaveRecipients}
          isLoading={isUpdateMutationInFlight}
        />
      )}
    </>
  );
};
