import { Box, Checkbox, HStack, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import { DemandItemPriceTableHeader } from 'features/demandItem/components/DemandItemPriceTable/DemandItemPriceTableHeader';
import { NewSalesOrderFormType } from 'features/salesOrdersV2/form';
import { ChangeEvent } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

export const MasterRegistrationSettingItemPriceTable = ({
  priceValues,
  index,
}: {
  priceValues: {
    unitPrice: number;
    unitSellingPrice: number;
    quantity: number;
  }[];
  index: number;
}) => {
  // 表示用の金額
  const sortedPrices = priceValues?.toSorted((a, b) => Number(a.quantity) - Number(b.quantity));

  const form = useFormContext<NewSalesOrderFormType>();
  const { getValues, setValue } = form;

  const selectedPrices = useWatch({
    control: form.control,
    name: `masterRegistrationItems.${index}.prices`,
  });

  const handleChangePrice =
    (price: { unitPrice: number; unitSellingPrice: number; quantity: number }) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        const currentPrices = getValues(`masterRegistrationItems.${index}.prices`);
        setValue(`masterRegistrationItems.${index}.prices`, [
          ...currentPrices,
          {
            unitPrice: price.unitPrice,
            unitSellingPrice: price.unitSellingPrice,
            quantity: price.quantity,
          },
        ]);
      } else {
        const currentPrices = getValues(`masterRegistrationItems.${index}.prices`);
        const newPrices = currentPrices.filter(
          (currentPrice) => currentPrice.quantity !== price.quantity,
        );
        setValue(`masterRegistrationItems.${index}.prices`, newPrices);
      }
    };

  return (
    <Box w="422px">
      <HStack spacing={4} alignItems="stretch" w="100%">
        <Text
          border="1px solid"
          borderColor="gray.200"
          lineHeight={1.2}
          p={2}
          fontSize="sm"
          fontWeight="bold"
          textAlign="center"
          sx={{ writingMode: 'vertical-rl' }}
        >
          単価表
        </Text>
        <Table w="100%">
          <DemandItemPriceTableHeader enableCheckbox={true} />
          <Tbody>
            {sortedPrices?.map((price) => {
              const isChecked = selectedPrices?.some(
                (selectedPrice) => selectedPrice.quantity === price.quantity,
              );

              return (
                <Tr
                  key={`${price.quantity}_${price.unitPrice}_${price.unitSellingPrice}`}
                  sx={{
                    ':last-of-type td': {
                      border: 'none',
                    },
                  }}
                  _hover={{
                    backgroundColor: 'gray.50',
                  }}
                >
                  <Td w="24px" textAlign="center" p={2}>
                    <Box position="relative" top="2px">
                      <Checkbox
                        onChange={handleChangePrice(price)}
                        isDisabled={selectedPrices?.length <= 1 && isChecked}
                        isChecked={isChecked}
                      />
                    </Box>
                  </Td>
                  <Td isNumeric fontSize="xs" p={2}>
                    {Number(price.quantity).toLocaleString()}
                  </Td>
                  <Td isNumeric fontSize="xs" p={2}>
                    {Number(price.unitPrice).toLocaleString()}
                  </Td>
                  <Td isNumeric fontSize="xs" p={2}>
                    {Number(price.unitSellingPrice).toLocaleString()}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </HStack>
    </Box>
  );
};
