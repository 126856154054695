import { SalesOrdersFormPrices } from 'features/salesOrdersV2/components/SalesOrdersFormPrices';
import { NewSalesOrderFormType } from 'features/salesOrdersV2/form';
import { useFormContext, useWatch } from 'react-hook-form';

export const NewSalesOrderFormDetailPrices = () => {
  const { control } = useFormContext<NewSalesOrderFormType>();
  const details = useWatch({ control, name: 'details' });

  return <SalesOrdersFormPrices details={details} />;
};
