import { Text, VStack } from '@chakra-ui/react';
import { SalesOrdersDetailPageInternalMemoFragment$key } from 'gql/__generated__/SalesOrdersDetailPageInternalMemoFragment.graphql';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';

const SalesOrdersDetailPageInternalMemoFragment = graphql`
  fragment SalesOrdersDetailPageInternalMemoFragment on SalesOrder {
    internalMemos {
      body
    }
  }
`;

type Props = {
  queryRef: SalesOrdersDetailPageInternalMemoFragment$key;
};

export const SalesOrdersDetailPageInternalMemo = ({ queryRef }: Props) => {
  const { internalMemos } = useFragment(SalesOrdersDetailPageInternalMemoFragment, queryRef);

  if (!internalMemos || internalMemos.length === 0) {
    return <></>;
  }

  return (
    <VStack
      w="full"
      spacing={0}
      alignItems="flex-start"
      p={8}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="8px"
    >
      <Text fontSize="xl" fontWeight="bold">
        社内メモ
      </Text>
      <Text mt={1} fontSize="sm" whiteSpace="pre-wrap">
        {internalMemos[0].body}
      </Text>
    </VStack>
  );
};
