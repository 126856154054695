import { Checkbox, CheckboxGroup, Text, VStack } from '@chakra-ui/react';
import { DEMAND_ITEM_CATEGORY_LABEL } from 'features/demandItem/models';
import { SalesOrdersFormItemModalType } from 'features/salesOrdersV2/form';
import { SalesOrdersFormItemModalFilterQuery as SalesOrdersFormItemModalFilterQueryType } from 'gql/__generated__/SalesOrdersFormItemModalFilterQuery.graphql';
import { useFormContext } from 'react-hook-form';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';

const salesOrdersFormItemModalFilterQuery = graphql`
  query SalesOrdersFormItemModalFilterQuery($demandId: ID!) {
    demandItems(where: {isCurrent: true, demandID: $demandId}) {
      edges {
        node {
          supplier {
            id
            name
          }
        }
      }
    }
  }
`;

export const SalesOrdersFormItemModalFilter = ({
  categoryFilters,
  supplierFilters,
  setSupplierFilters,
  setCategoryFilters,
}: {
  categoryFilters: string[];
  supplierFilters: string[];
  setSupplierFilters: (supplierFilters: string[]) => void;
  setCategoryFilters: (categoryFilters: string[]) => void;
}) => {
  const { getValues } = useFormContext<SalesOrdersFormItemModalType>();

  const { demandItems } = useLazyLoadQuery<SalesOrdersFormItemModalFilterQueryType>(
    salesOrdersFormItemModalFilterQuery,
    { demandId: getValues('demand').id },
    { fetchPolicy: 'network-only' },
  );

  if (!demandItems) {
    return null;
  }

  const getUniqueSuppliers = () => {
    const result: { id: string | undefined; name: string | undefined }[] = [];
    const map = new Map(
      demandItems.edges?.map((edge) => [edge?.node?.supplier.id, edge?.node?.supplier.name]),
    );
    map.forEach((value, key) => {
      result.push({ id: key, name: value });
    });
    return result;
  };

  return (
    <VStack
      minW="200px"
      h="100%"
      p={4}
      spacing={4}
      alignItems="flex-start"
      borderRight="1px solid"
      borderColor="gray.200"
      overflowY="scroll"
    >
      <Text fontWeight="bold">フィルター</Text>

      <VStack spacing={2} alignItems="flex-start">
        <Text fontSize="sm" color="gray.500">
          カテゴリー
        </Text>
        <CheckboxGroup value={categoryFilters} onChange={setCategoryFilters}>
          {Object.values(DEMAND_ITEM_CATEGORY_LABEL).map((category, i) => (
            <Checkbox
              key={Object.keys(DEMAND_ITEM_CATEGORY_LABEL)[i]}
              id={Object.keys(DEMAND_ITEM_CATEGORY_LABEL)[i]}
              value={Object.keys(DEMAND_ITEM_CATEGORY_LABEL)[i]}
              h={5}
            >
              <Text fontSize="sm">{category.categoryName}</Text>
            </Checkbox>
          ))}
        </CheckboxGroup>
      </VStack>

      <VStack spacing={2} alignItems="flex-start">
        <Text fontSize="sm" color="gray.500">
          サプライヤー
        </Text>
        <CheckboxGroup value={supplierFilters} onChange={setSupplierFilters}>
          {getUniqueSuppliers().map((supplier) => (
            <Checkbox key={supplier.id} id={supplier.id} value={supplier.id}>
              <Text fontSize="sm">{supplier?.name}</Text>
            </Checkbox>
          ))}
        </CheckboxGroup>
      </VStack>
    </VStack>
  );
};
