import { Box, HStack, Icon, Table, Tbody, Text, VStack } from '@chakra-ui/react';
import { EfferentFour, Notes } from '@icon-park/react';
import { Link } from 'components/Link';
import { useDemandItemPrimaryImageThumbnails } from 'features/demandItem/api/useDemandItemPrimaryImageThumbnails';
import {
  DemandItemDetailBlock,
  DemandItemDetailBlockBody,
  DemandItemDetailBlockInfo,
  DemandItemDetailBlockSpecTableContainer,
} from 'features/demandItem/components/DemandItemDetailBlock';
import { DemandItemDetailBlockNoImageThumbnail } from 'features/demandItem/components/DemandItemDetailBlock/DemandItemDetailBlockNoImageThumbnail';
import { DemandItemDetailBlockNoItem } from 'features/demandItem/components/DemandItemDetailBlock/DemandItemDetailBlockNoItem';
import { DemandItemDetailBlockThumbnail } from 'features/demandItem/components/DemandItemDetailBlock/DemandItemDetailBlockThumbnail';
import { DemandItemPriceTableHeader } from 'features/demandItem/components/DemandItemPriceTable/DemandItemPriceTableHeader';
import { DemandItemPriceTableRow } from 'features/demandItem/components/DemandItemPriceTable/DemandItemPriceTableRow';
import {
  DEMAND_ITEM_CATEGORY,
  DEMAND_ITEM_CATEGORY_LABEL,
  DemandItemCategory,
} from 'features/demandItem/models';
import { buildDemandItemCode } from 'features/demandItem/models';
import {
  SalesOrdersEditFormDetailItemType,
  SalesOrdersFormItemModalType,
  SalesOrdersReorderFormDetailItemType,
} from 'features/salesOrdersV2/form';
import { SalesOrdersFormItemModalDetailsQuery as SalesOrdersFormItemModalDetailsQueryType } from 'gql/__generated__/SalesOrdersFormItemModalDetailsQuery.graphql';
import { ItemCategory, SalesOrderDetailType } from 'gql/graphql.types';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';

const SalesOrdersFormItemModalDetailsQuery = graphql`
  query SalesOrdersFormItemModalDetailsQuery($demandId: ID!, $categories: [ItemCategory!], $supplierIds: [ID!]) {
    demandItems(where: {isCurrent: true, demandID: $demandId, categoryIn: $categories, supplierIDIn: $supplierIds}, orderBy: {field: UPDATED_AT, direction: DESC}) {
      edges {
        node {
          id
          name
          category
          demand {
            id
            code
          }
          supplier {
            id
            name
          }
          taxCategory {
            id
            rate
          }
          cardboard {
            __typename
            size
            type
            material
            thickness
            printingColor
            processing
            other
          }
          flexiblePackage {
            __typename
            size
            type
            material
            printingColor
            processing
            other
          }
          giftBox {
            __typename
            size
            type
            paperType
            printingColor
            processing
            other
          }
          paperBag {
            __typename
            size
            paperType
            printingColor
            processing
            handle
            other
          }
          other {
            __typename
            specText
          }
          prices {
            id
            quantity
            unitPrice
            unitSellingPrice
          }
          images {
            id
            displayOrder
          }
          attachments {
            fileName
          }
          itemNumber
          version
          internalMemos {
            body
          }
          salesOrderItems(
            where: {
              hasSalesOrderDetailWith: {
                deletedAtIsNil: true,
                hasSalesOrderWith: {
                  deletedAtIsNil: true,
                  hasOrdersWith: {
                    deletedAtIsNil: true,
                  },
                }
              }
            },
            orderBy: {direction:DESC, field:CREATED_AT},
            first: 1
          ) {
            edges {
              node {
                salesOrderDetail {
                  salesOrder {
                    orders {
                      id
                      title
                    }
                  }
                }
              }
            }
          }
          ...DemandItemDetailBlockSpecTableContainerFragment
        }
      }
    }
  }
`;

type SalesOrdersFormItemModalDetailsItemsType = NonNullable<
  SalesOrdersFormItemModalDetailsQueryType['response']['demandItems']['edges']
>;
type SalesOrdersFormItemModalDetailsItemType = NonNullable<
  NonNullable<SalesOrdersFormItemModalDetailsItemsType[number]>['node']
>;
type SalesOrdersFormItemModalDetailsItemPriceType = NonNullable<
  NonNullable<SalesOrdersFormItemModalDetailsItemType['prices']>[number]
>;

export const SalesOrdersFormItemModalDetails = ({
  categoryFilters,
  supplierFilters,
}: {
  categoryFilters: string[];
  supplierFilters: string[];
}) => {
  const { getValues, setValue } = useFormContext<SalesOrdersFormItemModalType>();

  const { demandItems } = useLazyLoadQuery<SalesOrdersFormItemModalDetailsQueryType>(
    SalesOrdersFormItemModalDetailsQuery,
    {
      demandId: getValues('demand').id,
      categories: categoryFilters as ItemCategory[],
      supplierIds: supplierFilters,
    },
    { fetchPolicy: 'network-only' },
  );
  const demandItemsHasImages =
    demandItems.edges?.filter(
      (edge) => edge?.node?.images?.length !== undefined && edge?.node?.images?.length > 0,
    ) || [];

  const { data: thumbnails, fetch: fetchThumbnails } = useDemandItemPrimaryImageThumbnails({
    items: demandItemsHasImages.map((item) => ({
      id: item?.node?.id || '',
    })),
  });

  useEffect(() => {
    if (thumbnails) return;
    fetchThumbnails();
  }, [demandItems]);

  if (
    !demandItems ||
    !demandItems.edges ||
    demandItems.edges.length === 0 ||
    !demandItems.edges[0]?.node ||
    demandItems.edges[0]?.node === null
  ) {
    return <DemandItemDetailBlockNoItem />;
  }

  const items = demandItems.edges
    .map((edge) => {
      if (!edge?.node) return null;
      return edge.node;
    })
    .filter((value) => value != null);

  const handleChangePriceTableCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: SalesOrdersFormItemModalDetailsItemType,
    price: SalesOrdersFormItemModalDetailsItemPriceType,
  ) => {
    const details = getValues('details');

    if (e.target.checked) {
      setValue('details', [...details, buildFormDetail(item, price)]);
      setValue('supplier', item.supplier);
    } else {
      setValue(
        'details',
        details.filter((detail) => detail.priceId !== price.id),
      );
      if (getValues('details').length === 0) {
        setValue('supplier', { id: '', name: '' });
      }
    }
  };

  return (
    <VStack w="100%" h="100%" spacing={0} overflowY="scroll" overscrollBehavior="auto">
      {items.map((item) => {
        const latestOrder =
          item.salesOrderItems.edges?.[0]?.node?.salesOrderDetail?.salesOrder?.orders?.[0];
        const thumbnailUrl = thumbnails?.find((data) => data?.itemID === item.id)?.url;

        const getDetailBlockInfoValues = () => {
          const baseValues = [
            {
              label: 'コード',
              node:
                buildDemandItemCode({
                  demandCode: item.demand?.code || '',
                  category: item.category as DemandItemCategory,
                  itemNumber: item.itemNumber,
                  version: item.version,
                }) || '-',
            },
            {
              label: 'サプライヤー',
              node: item.supplier?.name || '-',
            },
            {
              label: 'カテゴリー',
              node:
                DEMAND_ITEM_CATEGORY_LABEL[item.category as DemandItemCategory].categoryName || '-',
            },
            {
              label: '最終発注',
              node: latestOrder ? (
                <Link to={paths.order._id(latestOrder.id)} target="_blank">
                  <HStack gap={1}>
                    <Box>{latestOrder.title}</Box>
                    <EfferentFour theme="outline" />
                  </HStack>
                </Link>
              ) : (
                '-'
              ),
            },
            {
              label: '社内メモ',
              node: item.internalMemos?.[0]?.body || '-',
            },
          ];

          if (item.attachments && item.attachments.length > 0) {
            baseValues.push({
              label: 'その他のデータ',
              node: (
                <VStack spacing={1} alignItems="flex-start">
                  {Array.from(item.attachments).map((attachment, index) => (
                    <HStack
                      key={index}
                      w="fit-content"
                      minH="24px"
                      spacing={1}
                      alignItems="flex-start"
                    >
                      <Icon mt="4.5px" size={16} as={Notes} />
                      <Text fontSize="sm">{attachment.fileName}</Text>
                    </HStack>
                  ))}
                </VStack>
              ),
            });
          }

          return baseValues;
        };

        return (
          <DemandItemDetailBlock key={item.id}>
            <DemandItemDetailBlockSpecTableContainer queryRef={item} />
            <DemandItemDetailBlockBody>
              <HStack spacing={4} alignItems="flex-start">
                {thumbnailUrl ? (
                  <DemandItemDetailBlockThumbnail thumbnailUrl={thumbnailUrl} />
                ) : (
                  <DemandItemDetailBlockNoImageThumbnail />
                )}
                <DemandItemDetailBlockInfo values={getDetailBlockInfoValues()} />
              </HStack>
              <DemandItemPriceTable
                item={item}
                handleChangePriceTableCheckbox={handleChangePriceTableCheckbox}
              />
            </DemandItemDetailBlockBody>
          </DemandItemDetailBlock>
        );
      })}
    </VStack>
  );
};

const DemandItemPriceTable = ({
  item,
  handleChangePriceTableCheckbox,
}: {
  item: SalesOrdersFormItemModalDetailsItemType;
  handleChangePriceTableCheckbox: (
    e: React.ChangeEvent<HTMLInputElement>,
    item: SalesOrdersFormItemModalDetailsItemType,
    price: SalesOrdersFormItemModalDetailsItemPriceType,
  ) => void;
}) => {
  const { control } = useFormContext<SalesOrdersFormItemModalType>();
  const supplier = useWatch({ control, name: 'supplier' });
  const details = useWatch({ control, name: 'details' });

  const sortedPrices = item.prices?.toSorted((a, b) => Number(a.quantity) - Number(b.quantity));

  return (
    <Box w="422px">
      <HStack spacing={4} alignItems="stretch" w="100%">
        <Text
          border="1px solid"
          borderColor="gray.200"
          lineHeight={1.2}
          p={2}
          fontSize="sm"
          fontWeight="bold"
          textAlign="center"
          sx={{ writingMode: 'vertical-rl' }}
        >
          単価表
        </Text>
        <Table w="100%">
          <DemandItemPriceTableHeader enableCheckbox={true} />
          <Tbody>
            {sortedPrices?.map((price) => (
              <DemandItemPriceTableRow
                key={price.id}
                value={price}
                enableCheckbox={true}
                checked={details.some((detail) => detail.priceId === price.id)}
                disabled={!!supplier.id && supplier.id !== item.supplier?.id}
                showTooltip={!!supplier.id && supplier.id !== item.supplier?.id}
                tooltipText={`選択中の商品と別のサプライヤーの商品は\n選択できません`}
                handleChangeValue={(e) => {
                  handleChangePriceTableCheckbox(e, item, price);
                }}
              />
            ))}
          </Tbody>
        </Table>
      </HStack>
    </Box>
  );
};

const buildFormDetail = (
  item: SalesOrdersFormItemModalDetailsItemType,
  price: SalesOrdersFormItemModalDetailsItemPriceType,
): SalesOrdersEditFormDetailItemType | SalesOrdersReorderFormDetailItemType => {
  return {
    type: SalesOrderDetailType.Item,
    supplier: {
      id: item.supplier?.id,
      name: item.supplier?.name,
    },
    category: item.category as ItemCategory,
    name: item.name,
    code: item.demand.code,
    itemNumber: item.itemNumber.toString(),
    version: item.version.toString(),
    itemId: item.id,
    priceId: price.id,
    quantity: price.quantity,
    unitPrice: price.unitPrice,
    unitSellingPrice: price.unitSellingPrice,
    tax: {
      id: item.taxCategory?.id,
      rate: item.taxCategory?.rate,
    },
    spec: {
      cardboard:
        item.category === DEMAND_ITEM_CATEGORY.Cardboard
          ? {
              size: item.cardboard?.size || '',
              type: item.cardboard?.type || '',
              material: item.cardboard?.material || '',
              thickness: item.cardboard?.thickness || '',
              printingColor: item.cardboard?.printingColor || '',
              processing: item.cardboard?.processing || '',
              other: item.cardboard?.other || '',
            }
          : undefined,
      flexiblePackage:
        item.category === DEMAND_ITEM_CATEGORY.FlexiblePackage
          ? {
              size: item.flexiblePackage?.size || '',
              type: item.flexiblePackage?.type || '',
              material: item.flexiblePackage?.material || '',
              printingColor: item.flexiblePackage?.printingColor || '',
              processing: item.flexiblePackage?.processing || '',
              other: item.flexiblePackage?.other || '',
            }
          : undefined,
      giftBox:
        item.category === DEMAND_ITEM_CATEGORY.GiftBox
          ? {
              size: item.giftBox?.size || '',
              type: item.giftBox?.type || '',
              paperType: item.giftBox?.paperType || '',
              printingColor: item.giftBox?.printingColor || '',
              processing: item.giftBox?.processing || '',
              other: item.giftBox?.other || '',
            }
          : undefined,
      paperBag:
        item.category === DEMAND_ITEM_CATEGORY.PaperBag
          ? {
              size: item.paperBag?.size || '',
              paperType: item.paperBag?.paperType || '',
              printingColor: item.paperBag?.printingColor || '',
              processing: item.paperBag?.processing || '',
              handle: item.paperBag?.handle || '',
              other: item.paperBag?.other || '',
            }
          : undefined,
      other:
        item.category === DEMAND_ITEM_CATEGORY.Other
          ? {
              specText: item.other?.specText || '',
            }
          : undefined,
    },
  };
};
