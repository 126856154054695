import { z } from 'zod';

const salesOrdersFormCardboardSchema = z.object({
  size: z.string(),
  type: z.string(),
  material: z.string(),
  thickness: z.string(),
  printingColor: z.string(),
  processing: z.string(),
  other: z.string(),
});

const salesOrdersFormFlexiblePackageSchema = z.object({
  size: z.string(),
  type: z.string(),
  material: z.string(),
  printingColor: z.string(),
  processing: z.string(),
  other: z.string(),
});

const salesOrdersFormGiftBoxSchema = z.object({
  size: z.string(),
  type: z.string(),
  paperType: z.string(),
  printingColor: z.string(),
  processing: z.string(),
  other: z.string(),
});

const salesOrdersFormPaperBagSchema = z.object({
  size: z.string(),
  paperType: z.string(),
  printingColor: z.string(),
  processing: z.string(),
  handle: z.string(),
  other: z.string(),
});

export const salesOrdersFormOtherSchema = z.object({
  specText: z.string(),
});

export const salesOrdersFormSpecSchema = z.object({
  cardboard: salesOrdersFormCardboardSchema.optional(),
  flexiblePackage: salesOrdersFormFlexiblePackageSchema.optional(),
  giftBox: salesOrdersFormGiftBoxSchema.optional(),
  paperBag: salesOrdersFormPaperBagSchema.optional(),
  other: salesOrdersFormOtherSchema.optional(),
});
