import { z } from 'zod';
import {
  SalesOrdersEditFormType,
  salesOrdersEditFormDetailCostSchema,
  salesOrdersEditFormDetailItemSchema,
} from './edit-schema';
import {
  SalesOrdersReorderFormType,
  salesOrdersReorderFormDetailCostSchema,
  salesOrdersReorderFormDetailItemSchema,
} from './reorder-schema';

// 商品選択モーダル用のform
export const salesOrdersFormItemModalSchema = z.object({
  demand: z.object({
    id: z.string(),
    name: z.string(),
  }),
  supplier: z.object({
    id: z.string(),
    name: z.string(),
  }),
  details: z.array(
    z.union([salesOrdersEditFormDetailItemSchema, salesOrdersReorderFormDetailItemSchema]),
  ),
});

export type SalesOrdersFormItemModalType = z.infer<typeof salesOrdersFormItemModalSchema>;
export type SalesOrdersFormItemModalAcceptableFormType =
  | SalesOrdersEditFormType
  | SalesOrdersReorderFormType;

// 継続費用選択モーダル用のform
export const salesOrdersFormRecurringCostModalSchema = z.object({
  demand: z.object({
    id: z.string(),
    name: z.string(),
  }),
  supplier: z.object({
    id: z.string(),
    name: z.string(),
  }),
  details: z.array(
    z.union([salesOrdersEditFormDetailCostSchema, salesOrdersReorderFormDetailCostSchema]),
  ),
});

export type SalesOrdersFormRecurringCostModalType = z.infer<
  typeof salesOrdersFormRecurringCostModalSchema
>;
export type SalesOrdersFormRecurringCostModalAcceptableFormType =
  | SalesOrdersEditFormType
  | SalesOrdersReorderFormType;
