/**
 * @generated SignedSource<<e668662e7d7ac2947572c1f0eb534596>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InvoiceSalesOrdersSelectFormFragment$data = {
  readonly salesOrders?: {
    readonly edges: ReadonlyArray<{
      readonly cursor: any;
      readonly node: {
        readonly details: {
          readonly edges: ReadonlyArray<{
            readonly cursor: any;
            readonly node: {
              readonly branchNumber: any;
              readonly deliveryEvents: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly fixedDeliveryDate: any;
                  } | null;
                } | null> | null;
              };
              readonly id: string;
              readonly invoiceDetails: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly id: string;
                  } | null;
                } | null> | null;
              };
              readonly item: {
                readonly id: string;
                readonly name: string;
                readonly quantity: any;
                readonly tax: {
                  readonly rate: number;
                };
                readonly unitSellingPrice: number;
              } | null;
              readonly memos: ReadonlyArray<{
                readonly body: string;
              }> | null;
              readonly onetimeCost: {
                readonly id: string;
                readonly name: string;
                readonly quantity: any;
                readonly tax: {
                  readonly rate: number;
                };
                readonly unitSellingPrice: number;
              } | null;
              readonly recurringCost: {
                readonly id: string;
                readonly name: string;
                readonly quantity: any;
                readonly tax: {
                  readonly rate: number;
                };
                readonly unitSellingPrice: number;
              } | null;
            } | null;
          } | null> | null;
        };
        readonly id: string;
        readonly title: string;
        readonly transactionID: any;
      } | null;
    } | null> | null;
  };
  readonly " $fragmentType": "InvoiceSalesOrdersSelectFormFragment";
};
export type InvoiceSalesOrdersSelectFormFragment$key = {
  readonly " $data"?: InvoiceSalesOrdersSelectFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InvoiceSalesOrdersSelectFormFragment">;
};

import InvoiceSalesOrdersSelectFormFragmentRefetchQuery_graphql from './InvoiceSalesOrdersSelectFormFragmentRefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "quantity",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unitSellingPrice",
    "storageKey": null
  },
  {
    "alias": "tax",
    "args": null,
    "concreteType": "TaxCategory",
    "kind": "LinkedField",
    "name": "taxCategory",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rate",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "isSkip"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "salesOrderDetailWhere"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "salesOrderWhere"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": InvoiceSalesOrdersSelectFormFragmentRefetchQuery_graphql
    }
  },
  "name": "InvoiceSalesOrdersSelectFormFragment",
  "selections": [
    {
      "condition": "isSkip",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "where",
              "variableName": "salesOrderWhere"
            }
          ],
          "concreteType": "SalesOrderConnection",
          "kind": "LinkedField",
          "name": "salesOrders",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SalesOrderEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SalesOrder",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "transactionID",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "orderBy",
                          "value": {
                            "direction": "ASC",
                            "field": "ORDER_NUMBER"
                          }
                        },
                        {
                          "kind": "Variable",
                          "name": "where",
                          "variableName": "salesOrderDetailWhere"
                        }
                      ],
                      "concreteType": "SalesOrderDetailConnection",
                      "kind": "LinkedField",
                      "name": "details",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SalesOrderDetailEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "SalesOrderDetail",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "branchNumber",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "InvoiceDetailConnection",
                                  "kind": "LinkedField",
                                  "name": "invoiceDetails",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "InvoiceDetailEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "InvoiceDetail",
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": [
                                            (v1/*: any*/)
                                          ],
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": [
                                    {
                                      "kind": "Literal",
                                      "name": "where",
                                      "value": {
                                        "isValid": true
                                      }
                                    }
                                  ],
                                  "concreteType": "SalesOrderDetailDeliveryEventConnection",
                                  "kind": "LinkedField",
                                  "name": "deliveryEvents",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "SalesOrderDetailDeliveryEventEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "SalesOrderDetailDeliveryEvent",
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": [
                                            {
                                              "alias": null,
                                              "args": null,
                                              "kind": "ScalarField",
                                              "name": "fixedDeliveryDate",
                                              "storageKey": null
                                            }
                                          ],
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": "deliveryEvents(where:{\"isValid\":true})"
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "SalesOrderItem",
                                  "kind": "LinkedField",
                                  "name": "item",
                                  "plural": false,
                                  "selections": (v2/*: any*/),
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "SalesOrderRecurringCost",
                                  "kind": "LinkedField",
                                  "name": "recurringCost",
                                  "plural": false,
                                  "selections": (v2/*: any*/),
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "SalesOrderOnetimeCost",
                                  "kind": "LinkedField",
                                  "name": "onetimeCost",
                                  "plural": false,
                                  "selections": (v2/*: any*/),
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "SalesOrderDetailMemo",
                                  "kind": "LinkedField",
                                  "name": "memos",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "body",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "e81e9b9581effaebaa4006b8124e3e0d";

export default node;
