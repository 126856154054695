import { ItemCategory, SalesOrderDetailType } from 'gql/graphql.types';
import {
  errorMessages,
  quantitySchema,
  unitPriceSchema,
  unitSellingPriceSchema,
} from 'utils/validation';
import { z } from 'zod';
import { salesOrdersFormSpecSchema } from './common-schema';

export const salesOrdersEditFormDetailBaseSchema = z.object({
  detailId: z.string().optional(), // 編集元の明細行id
  memo: z
    .string()
    .max(500, { message: errorMessages.MAX_LENGTH(500) })
    .optional(),
  fixedDeliveryDate: z.string().optional(),
  quantity: quantitySchema(),
  unitPrice: unitPriceSchema(),
  unitSellingPrice: unitSellingPriceSchema,
  tax: z.object({
    id: z.string(),
    rate: z.union([z.string(), z.number()]),
  }),
  type: z.enum([
    SalesOrderDetailType.Item,
    SalesOrderDetailType.OnetimeCost,
    SalesOrderDetailType.RecurringCost,
  ]),
});

export const salesOrdersEditFormDetailItemSchema = salesOrdersEditFormDetailBaseSchema.merge(
  z.object({
    // demand_item_id: 紐付く商品マスタのid
    itemId: z.string().optional(),
    // demand_item_price_id: 紐付く商品単価マスタのid
    priceId: z.string().optional(),
    supplier: z.object({
      id: z.string(),
      name: z.string(),
    }),
    name: z.string(),
    code: z.string(),
    category: z.nativeEnum(ItemCategory),
    itemNumber: z.string().readonly(),
    version: z.string().readonly(),
    spec: salesOrdersFormSpecSchema,
  }),
);

export const salesOrdersEditFormDetailCostSchema = salesOrdersEditFormDetailBaseSchema.merge(
  z.object({
    // demand_recurring_cost_id: 紐付く継続費用マスタのid
    costId: z.string().optional(),
    // demand_recurring_cost_price_id: 紐付く継続費用単価マスタのid
    priceId: z.string().optional(),
    supplier: z
      .object({
        id: z.string(),
        name: z.string(),
      })
      .optional(),
    name: z.string(),
    note: z.string(),
  }),
);

export const salesOrdersEditFormDetailSchema = z.union([
  salesOrdersEditFormDetailItemSchema,
  salesOrdersEditFormDetailCostSchema,
]);

export const salesOrdersEditFormSchema = z.object({
  salesOrderId: z.string(),
  demand: z.object({
    id: z.string(),
    name: z.string(),
    code: z.string(),
  }),
  supplier: z.object({
    id: z.string(),
    name: z.string(),
  }),
  title: z
    .string()
    .min(1, { message: errorMessages.REQUIRED })
    .max(70, { message: errorMessages.MAX_LENGTH(70) }),
  receivedDate: z.string(),
  details: z.array(salesOrdersEditFormDetailSchema).min(1, { message: '明細を入力してください' }),
  detailMessage: z.string().max(2000, { message: errorMessages.MAX_LENGTH(2000) }),
  memo: z
    .string()
    .max(500, { message: errorMessages.MAX_LENGTH(500) })
    .optional(),
  internalAssignees: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      }),
    )
    .min(1, { message: '担当者を選択しましょう' }),
});

export type SalesOrdersEditFormDetailItemType = z.infer<typeof salesOrdersEditFormDetailItemSchema>;
export type SalesOrdersEditFormDetailCostType = z.infer<typeof salesOrdersEditFormDetailCostSchema>;
export type SalesOrdersEditFormDetailType = z.infer<typeof salesOrdersEditFormDetailSchema>;
export type SalesOrdersEditFormType = z.infer<typeof salesOrdersEditFormSchema>;
