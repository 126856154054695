import { GridItem } from '@chakra-ui/react';
import {
  DEMAND_ITEM_CATEGORY,
  DemandItemCardboard,
  DemandItemFlexiblePackage,
  DemandItemGiftBox,
  DemandItemOther,
  DemandItemPaperBag,
} from 'features/demandItem/models';
import {
  DemandItemSpecInfoFragment$data,
  DemandItemSpecInfoFragment$key,
} from 'gql/__generated__/DemandItemSpecInfoFragment.graphql';
import { Fragment } from 'react';
import { graphql, useFragment } from 'react-relay';

const DemandItemSpecInfoFragment = graphql`
  fragment DemandItemSpecInfoFragment on DemandItem {
    category
    cardboard {
      size
      type
      material
      thickness
      printingColor
      processing
      other
    }
    flexiblePackage {
      size
      material
      printingColor
      processing
      other
    }
    giftBox {
      size
      type
      paperType
      printingColor
      processing
      other
    }
    paperBag {
      size
      paperType
      printingColor
      processing
      handle
      other
    }
    other {
      specText
    }
  }
`;

type Props = {
  queryRef: DemandItemSpecInfoFragment$key;
};

type ItemSpec = {
  label: string;
  value: string;
};

export const DemandItemSpecInfo = ({ queryRef }: Props) => {
  const item = useFragment(DemandItemSpecInfoFragment, queryRef);

  return <ItemSpecInfo specs={getCategorySpecs(item)} />;
};

const ItemSpecInfo = ({ specs }: { specs: ItemSpec[] }) => {
  return (
    <>
      {specs.map((spec) => (
        <Fragment key={spec.label}>
          <GridItem
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            minHeight="24px"
            fontSize="sm"
            color="gray.500"
            whiteSpace="nowrap"
          >
            {spec.label}
          </GridItem>
          <GridItem
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            minHeight="24px"
            fontSize="sm"
          >
            {spec.value}
          </GridItem>
        </Fragment>
      ))}
    </>
  );
};

const getCategorySpecs = (item: DemandItemSpecInfoFragment$data): ItemSpec[] => {
  if (item.category === DEMAND_ITEM_CATEGORY.Cardboard) {
    return getCardboardSpecs(item.cardboard as DemandItemCardboard);
  }
  if (item.category === DEMAND_ITEM_CATEGORY.FlexiblePackage) {
    return getFlexiblePackageSpecs(item.flexiblePackage as DemandItemFlexiblePackage);
  }
  if (item.category === DEMAND_ITEM_CATEGORY.GiftBox) {
    return getGiftBoxSpecs(item.giftBox as DemandItemGiftBox);
  }
  if (item.category === DEMAND_ITEM_CATEGORY.PaperBag) {
    return getPaperBagSpecs(item.paperBag as DemandItemPaperBag);
  }
  return getOtherSpecs(item.other as DemandItemOther);
};

const getCardboardSpecs = (cardboard: DemandItemCardboard): ItemSpec[] => [
  {
    label: 'サイズ',
    value: cardboard.size || '-',
  },
  {
    label: '形状',
    value: cardboard.type || '-',
  },
  {
    label: '構成',
    value: cardboard.material || '-',
  },
  {
    label: '段厚',
    value: cardboard.thickness || '-',
  },
  {
    label: '印刷',
    value: cardboard.printingColor || '-',
  },
  {
    label: '加工',
    value: cardboard.processing || '-',
  },
  {
    label: 'その他',
    value: cardboard.other || '-',
  },
];

const getFlexiblePackageSpecs = (flexiblePackage: DemandItemFlexiblePackage): ItemSpec[] => [
  {
    label: 'サイズ',
    value: flexiblePackage.size || '-',
  },
  {
    label: '形状',
    value: flexiblePackage.type || '-',
  },
  {
    label: '構成',
    value: flexiblePackage.material || '-',
  },
  {
    label: '印刷',
    value: flexiblePackage.printingColor || '-',
  },
  {
    label: '加工',
    value: flexiblePackage.processing || '-',
  },
  {
    label: 'その他',
    value: flexiblePackage.other || '-',
  },
];

const getGiftBoxSpecs = (giftBox: DemandItemGiftBox): ItemSpec[] => [
  {
    label: 'サイズ',
    value: giftBox.size || '-',
  },
  {
    label: '形状',
    value: giftBox.type || '-',
  },
  {
    label: '用紙',
    value: giftBox.paperType || '-',
  },
  {
    label: '印刷',
    value: giftBox.printingColor || '-',
  },
  {
    label: '印刷',
    value: giftBox.printingColor || '-',
  },
  {
    label: '加工',
    value: giftBox.processing || '-',
  },
  {
    label: 'その他',
    value: giftBox.other || '-',
  },
];

const getPaperBagSpecs = (paperBag: DemandItemPaperBag): ItemSpec[] => [
  {
    label: 'サイズ',
    value: paperBag.size || '-',
  },
  {
    label: '用紙',
    value: paperBag.paperType || '-',
  },
  {
    label: '印刷',
    value: paperBag.printingColor || '-',
  },
  {
    label: '加工',
    value: paperBag.processing || '-',
  },
  {
    label: '持ち手',
    value: paperBag.handle || '-',
  },
  {
    label: 'その他',
    value: paperBag.other || '-',
  },
];

const getOtherSpecs = (other: DemandItemOther): ItemSpec[] => [
  {
    label: 'その他',
    value: other.specText || '-',
  },
];
