import { Box, Flex } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { DemandItemConfirm } from 'features/demandItem/components/DemandItemConfirm/DemandItemConfirm';
import { DemandItemFormType } from 'features/demandItem/form';
import { confirm_DemandItemCreateConfirmPageMutation } from 'gql/__generated__/confirm_DemandItemCreateConfirmPageMutation.graphql';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { useNavigationBlocker } from 'hooks/useNavigationBlocker';
import { toast } from 'lib/toast';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';
import { DemandIdContext } from '../context';

export const DemandItemCreateConfirmPage = () => {
  const navigate = useNavigate();
  const form = useFormContext<DemandItemFormType>();

  const { demandCode } = useParams();
  if (!demandCode) {
    throw new MissingRequiredParamError('demandCode');
  }

  const { commitMutation, isMutationInFlight } =
    useMutationWrapper<confirm_DemandItemCreateConfirmPageMutation>(
      graphql`
      mutation confirm_DemandItemCreateConfirmPageMutation(
        $input: DemandItemInput!
      ) {
        createDemandItem(input: $input) {
          id
        }
      }
    `,
    );

  const { getValues } = form;
  const { name } = getValues();

  const demandId = useContext(DemandIdContext);
  if (!demandId) {
    throw new Error('demandId is null');
  }

  const { allowNavigation } = useNavigationBlocker(true, false);

  if (!name) return <Navigate to={paths.demands._demandCode(demandCode).items.new.url()} />;

  const handleSubmit = (data: DemandItemFormType) => {
    allowNavigation();

    const uploadables = Array.from(data.attachments).map((attachment, index) => {
      return {
        [`variables.input.attachments.${[index]}.file`]: attachment.file,
      };
    });

    commitMutation({
      variables: {
        input: {
          category: data.category,
          demandID: demandId,
          name: data.name,
          prices: data.prices.map((price) => ({
            quantity: Number(price.quantity) || 0,
            unitPrice: Number(price.unitPrice) || 0,
            unitSellingPrice: Number(price.unitSellingPrice) || 0,
          })),
          spec: {
            cardboard: data.cardboard,
            flexiblePackage: data.flexiblePackage,
            giftBox: data.giftBox,
            paperBag: data.paperBag,
            other: data.other,
          },
          supplierID: data.supplier.id,
          taxCategoryID: data.tax.id,
          internalMemo: data.memo,
          images: data.images?.map((image, index) => ({
            displayOrder: index + 1,
            objectName: image.objectName,
          })),
          attachments: Array.from(data.attachments).map(() => ({ file: null })),
        },
      },
      uploadables: Object.assign({}, ...uploadables),
      onError() {
        toast({
          title: '商品の作成に失敗しました',
          status: 'error',
        });
      },
      onCompleted: () => {
        toast({
          title: '商品を作成しました',
          status: 'success',
        });
        navigate(paths.demands._demandCode(demandCode).url());
      },
    });
  };

  const handleBack = () => {
    allowNavigation();
    navigate(paths.demands._demandCode(demandCode).items.new.url());
  };

  return (
    <>
      <Flex justify="space-between" width="768px">
        <Box onClick={handleBack}>
          <PageBack />
        </Box>
      </Flex>
      <SubHeading label="新規商品の追加" />
      <DemandItemConfirm onSubmit={handleSubmit} isSubmitting={isMutationInFlight} />
    </>
  );
};
