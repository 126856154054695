/**
 * @generated SignedSource<<6ccc260338ceefdbaef1c235b02f68cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SalesOrdersFormRecurringCostModalDetailsQuery$variables = {
  demandId: string;
};
export type SalesOrdersFormRecurringCostModalDetailsQuery$data = {
  readonly demandRecurringCosts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly costNumber: any;
        readonly demand: {
          readonly code: string;
        };
        readonly id: string;
        readonly internalMemos: ReadonlyArray<{
          readonly body: string;
        }> | null;
        readonly name: string;
        readonly note: string;
        readonly prices: ReadonlyArray<{
          readonly id: string;
          readonly quantity: any;
          readonly unitPrice: number;
          readonly unitSellingPrice: number;
        }> | null;
        readonly salesOrderRecurringCosts: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly salesOrderDetail: {
                readonly salesOrder: {
                  readonly orders: ReadonlyArray<{
                    readonly id: string;
                    readonly title: string;
                  }> | null;
                };
              };
            } | null;
          } | null> | null;
        };
        readonly supplier: {
          readonly id: string;
          readonly name: string;
        } | null;
        readonly taxCategory: {
          readonly id: string;
          readonly rate: number;
        };
      } | null;
    } | null> | null;
  };
};
export type SalesOrdersFormRecurringCostModalDetailsQuery = {
  response: SalesOrdersFormRecurringCostModalDetailsQuery$data;
  variables: SalesOrdersFormRecurringCostModalDetailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "demandId"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "direction": "DESC",
      "field": "UPDATED_AT"
    }
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "demandID",
        "variableName": "demandId"
      },
      {
        "kind": "Literal",
        "name": "isCurrent",
        "value": true
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Supplier",
  "kind": "LinkedField",
  "name": "supplier",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "DemandRecurringCostPrice",
  "kind": "LinkedField",
  "name": "prices",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unitPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unitSellingPrice",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "TaxCategory",
  "kind": "LinkedField",
  "name": "taxCategory",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rate",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "direction": "DESC",
      "field": "CREATED_AT"
    }
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "hasSalesOrderDetailWith": {
        "deletedAtIsNil": true,
        "hasSalesOrderWith": {
          "deletedAtIsNil": true,
          "hasOrdersWith": {
            "deletedAtIsNil": true
          }
        }
      }
    }
  }
],
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Order",
  "kind": "LinkedField",
  "name": "orders",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SalesOrdersFormRecurringCostModalDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DemandRecurringCostConnection",
        "kind": "LinkedField",
        "name": "demandRecurringCosts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DemandRecurringCostEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DemandRecurringCost",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DemandRecurringCostInternalMemo",
                    "kind": "LinkedField",
                    "name": "internalMemos",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "demand",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": (v11/*: any*/),
                    "concreteType": "SalesOrderRecurringCostConnection",
                    "kind": "LinkedField",
                    "name": "salesOrderRecurringCosts",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SalesOrderRecurringCostEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SalesOrderRecurringCost",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SalesOrderDetail",
                                "kind": "LinkedField",
                                "name": "salesOrderDetail",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SalesOrder",
                                    "kind": "LinkedField",
                                    "name": "salesOrder",
                                    "plural": false,
                                    "selections": [
                                      (v12/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "salesOrderRecurringCosts(first:1,orderBy:{\"direction\":\"DESC\",\"field\":\"CREATED_AT\"},where:{\"hasSalesOrderDetailWith\":{\"deletedAtIsNil\":true,\"hasSalesOrderWith\":{\"deletedAtIsNil\":true,\"hasOrdersWith\":{\"deletedAtIsNil\":true}}}})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SalesOrdersFormRecurringCostModalDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DemandRecurringCostConnection",
        "kind": "LinkedField",
        "name": "demandRecurringCosts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DemandRecurringCostEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DemandRecurringCost",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DemandRecurringCostInternalMemo",
                    "kind": "LinkedField",
                    "name": "internalMemos",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "demand",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": (v11/*: any*/),
                    "concreteType": "SalesOrderRecurringCostConnection",
                    "kind": "LinkedField",
                    "name": "salesOrderRecurringCosts",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SalesOrderRecurringCostEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SalesOrderRecurringCost",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SalesOrderDetail",
                                "kind": "LinkedField",
                                "name": "salesOrderDetail",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SalesOrder",
                                    "kind": "LinkedField",
                                    "name": "salesOrder",
                                    "plural": false,
                                    "selections": [
                                      (v12/*: any*/),
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "salesOrderRecurringCosts(first:1,orderBy:{\"direction\":\"DESC\",\"field\":\"CREATED_AT\"},where:{\"hasSalesOrderDetailWith\":{\"deletedAtIsNil\":true,\"hasSalesOrderWith\":{\"deletedAtIsNil\":true,\"hasOrdersWith\":{\"deletedAtIsNil\":true}}}})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c2b92df5e0f98251bc60355ee6de0448",
    "id": null,
    "metadata": {},
    "name": "SalesOrdersFormRecurringCostModalDetailsQuery",
    "operationKind": "query",
    "text": "query SalesOrdersFormRecurringCostModalDetailsQuery(\n  $demandId: ID!\n) {\n  demandRecurringCosts(where: {isCurrent: true, demandID: $demandId}, orderBy: {field: UPDATED_AT, direction: DESC}) {\n    edges {\n      node {\n        id\n        name\n        note\n        costNumber\n        supplier {\n          id\n          name\n        }\n        internalMemos {\n          body\n          id\n        }\n        prices {\n          id\n          quantity\n          unitPrice\n          unitSellingPrice\n        }\n        demand {\n          code\n          id\n        }\n        taxCategory {\n          id\n          rate\n        }\n        salesOrderRecurringCosts(where: {hasSalesOrderDetailWith: {deletedAtIsNil: true, hasSalesOrderWith: {deletedAtIsNil: true, hasOrdersWith: {deletedAtIsNil: true}}}}, orderBy: {direction: DESC, field: CREATED_AT}, first: 1) {\n          edges {\n            node {\n              salesOrderDetail {\n                salesOrder {\n                  orders {\n                    id\n                    title\n                  }\n                  id\n                }\n                id\n              }\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5df3f475e8ae854428d83d7f6e674a51";

export default node;
