/**
 * @generated SignedSource<<26798e8b646bfde18d130db79796ed3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewSalesOrderFormDetailsFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TaxSelectBoxFragment">;
  readonly " $fragmentType": "NewSalesOrderFormDetailsFragment";
};
export type NewSalesOrderFormDetailsFragment$key = {
  readonly " $data"?: NewSalesOrderFormDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewSalesOrderFormDetailsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewSalesOrderFormDetailsFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaxSelectBoxFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "ff733dd2246f24a2ba73e16c38c969b9";

export default node;
