import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Image,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import { AddOne, Copy } from '@icon-park/react';
import { AutoResizeTextarea } from 'components/AutoResizeTextarea';
import { ChangeOrderButton } from 'components/ChangeOrderButton';
import { ConfigurableNumberInput } from 'components/ConfigurableNumberInput';
import { ErrorMessage } from 'components/ErrorMessage';
import { DEMAND_ITEM_CATEGORY, DEMAND_ITEM_CATEGORY_UNKNOWN } from 'features/demandItem/models';
import { ESTIMATION_DETAIL_CATEGORIES, EstimationFormType } from 'features/estimations/form';
import { ESTIMATION_DETAIL_DOCUMENT_DISPLAY_STATUS } from 'features/estimations/models';
import { TaxSelectBox } from 'features/tax/components/TaxSelectBox';
import { EstimationFormLineItemsFragment$key } from 'gql/__generated__/EstimationFormLineItemsFragment.graphql';
import TextEstimationDocumentDisplayStatus from 'public/images/text_estimation_document_display_status.svg';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { graphql, useFragment } from 'react-relay';
import { EstimationFormItemAmount } from './EstimationFormItemAmount';
import { EstimationFormItemDisplayStatus } from './EstimationFormItemDisplayStatus';
import { EstimationFormItemGrossRatio } from './EstimationFormItemGrossRatio';
import { EstimationFormLineItemDeleteButton } from './EstimationFormLineItemDeleteButton';
import { EstimationFormLineItemSpecForm } from './EstimationFormLineItemSpecForm';

const estimationFormLineItemsFragment = graphql`
  fragment EstimationFormLineItemsFragment on Query {
    ...TaxSelectBoxFragment,
    tax: taxCategories {
      edges {
        node {
          id
          name
          rate
        }
      }
    },
  }
`;

type Props = {
  queryRef: EstimationFormLineItemsFragment$key;
  hideSpec: boolean;
};

export const EstimationFormLineItems = ({ queryRef, hideSpec }: Props) => {
  const query = useFragment(estimationFormLineItemsFragment, queryRef);
  const { tax } = query;

  const form = useFormContext<EstimationFormType>();
  const {
    control,
    setValue,
    getValues,
    register,
    formState: { errors },
  } = form;

  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: 'details',
  });

  const appendDetail = () => {
    append({
      name: '',
      unitPrice: '',
      quantity: '',
      unitSellingPrice: '',
      documentDisplayStatus: ESTIMATION_DETAIL_DOCUMENT_DISPLAY_STATUS.Shown,
      tax: {
        id: tax?.edges?.[0]?.node?.id ?? '',
        rate: tax?.edges?.[0]?.node?.rate ?? '',
      },
      category: DEMAND_ITEM_CATEGORY.Cardboard,
      spec: {},
    });
  };

  const handleClickUp = (index: number) => {
    const details = getValues('details');
    details.splice(index - 1, 2, details[index], details[index - 1]);
    setValue('details', details);
  };

  const handleClickDown = (index: number) => {
    const details = getValues('details');
    details.splice(index, 2, details[index + 1], details[index]);
    setValue('details', details);
  };

  const handleDuplicationItem = (index: number) => {
    const value = getValues(`details.${index}`);
    insert(
      index + 1,
      { ...value },
      { shouldFocus: true, focusName: `details.${index + 1}.quantity` },
    );
  };

  const isDisabledUpButton = (index: number) => index === 0;

  const isDisabledDownButton = (index: number) => index + 1 === fields.length;

  return (
    <VStack align="start" w="fit-content">
      <Image
        src={TextEstimationDocumentDisplayStatus}
        alt="開いた目を閉じた目にすると、見積書に記載せず、単価を記録しておけます"
      />

      <VStack w="full" align="stretch" spacing={4}>
        {fields.map((field, index) => (
          <HStack key={field.id} w="full" align="flex-start" spacing={2}>
            {/* 並び順変更する矢印 */}
            <VStack mt="21px">
              <ChangeOrderButton
                type="up"
                onClick={() => handleClickUp(index)}
                isDisabled={isDisabledUpButton(index)}
              />
              <ChangeOrderButton
                type="down"
                onClick={() => handleClickDown(index)}
                isDisabled={isDisabledDownButton(index)}
              />
            </VStack>

            <EstimationFormItemDisplayStatus index={index} />

            {/* 品名 */}
            <FormControl isInvalid={!!(errors.details || [])[index]?.name} isRequired w="12.5rem">
              <FormLabel>品名</FormLabel>
              <AutoResizeTextarea
                fontSize="sm"
                lineHeight={1.5}
                h="32px"
                px={3}
                py={1}
                {...register(`details.${index}.name`)}
              />
              <ErrorMessage name={`details.${index}.name`} errors={errors} />
            </FormControl>

            {/* カテゴリー */}
            <FormControl
              isInvalid={!!(errors.details || [])[index]?.category}
              isRequired
              w="7.5rem"
            >
              <FormLabel>カテゴリー</FormLabel>
              <Select fontSize="sm" h="32px" {...register(`details.${index}.category`)} isRequired>
                {/* 商品管理導入前のデータに対応するため未選択という選択肢を表示する */}
                <option value={DEMAND_ITEM_CATEGORY_UNKNOWN} hidden>
                  未選択
                </option>
                {Object.entries(ESTIMATION_DETAIL_CATEGORIES).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Select>
              <ErrorMessage name={`details.${index}.category`} errors={errors} />
            </FormControl>

            {/* 仕様 */}
            <Box hidden={hideSpec} w="17.75rem">
              <Text fontSize="sm" mb={1}>
                仕様
              </Text>
              <EstimationFormLineItemSpecForm index={index} />
            </Box>

            {/* 数量 */}
            <FormControl
              isInvalid={!!(errors.details || [])[index]?.quantity}
              isRequired
              flex={1}
              w="6rem"
            >
              <FormLabel>数量</FormLabel>
              <ConfigurableNumberInput h="32px" {...register(`details.${index}.quantity`)} />
              <ErrorMessage name={`details.${index}.quantity`} errors={errors} />
            </FormControl>

            {/* 仕入単価 */}
            <FormControl
              isInvalid={!!(errors.details || [])[index]?.unitPrice}
              isRequired
              flex={1}
              w="6rem"
            >
              <FormLabel>仕入単価</FormLabel>
              <ConfigurableNumberInput
                h="32px"
                step="0.01"
                {...register(`details.${index}.unitPrice`)}
              />
              <ErrorMessage name={`details.${index}.unitPrice`} errors={errors} />
            </FormControl>

            {/* 顧客単価 */}
            <FormControl
              isInvalid={!!(errors.details || [])[index]?.unitSellingPrice}
              isRequired
              flex={1}
              w="6rem"
            >
              <FormLabel>顧客単価</FormLabel>
              <ConfigurableNumberInput
                h="32px"
                step="0.01"
                {...register(`details.${index}.unitSellingPrice`)}
              />
              <EstimationFormItemGrossRatio index={index} />
              <ErrorMessage name={`details.${index}.unitSellingPrice`} errors={errors} />
            </FormControl>

            {/* 税区分 */}
            <FormControl
              isInvalid={!!(errors.details || [])[index]?.tax?.id}
              isRequired
              flex={1}
              w="6rem"
            >
              <FormLabel>税区分</FormLabel>
              <Controller
                name={`details.${index}.tax`}
                control={control}
                render={() => (
                  <TaxSelectBox
                    h="32px"
                    defaultValue={getValues(`details.${index}.tax.id`)}
                    queryRef={query}
                    onChange={(e) => {
                      setValue(`details.${index}.tax`, {
                        ...getValues(`details.${index}.tax`),
                        ...{
                          id: e.target.value,
                          rate: e.target[e.target.selectedIndex].getAttribute(
                            'data-rate',
                          ) as string,
                        },
                      });
                    }}
                  />
                )}
              />
              <ErrorMessage name={`orderLines.${index}.tax.id`} errors={errors} />
            </FormControl>

            {/* 金額 */}
            <Box w="6.5rem">
              <Text fontSize="sm" mb={1}>
                金額 (税抜)
              </Text>
              <EstimationFormItemAmount index={index} />
            </Box>

            {/* 複製ボタン */}
            <Box mt="25px">
              <IconButton
                size="sm"
                variant="ghost"
                isRound
                aria-label="copy"
                icon={<Copy />}
                onClick={() => handleDuplicationItem(index)}
              />
            </Box>

            {/* 削除ボタン */}
            <EstimationFormLineItemDeleteButton remove={() => remove(index)} />
          </HStack>
        ))}
        <Button w="768px" colorScheme="gray" leftIcon={<AddOne />} onClick={() => appendDetail()}>
          追加
        </Button>
      </VStack>
    </VStack>
  );
};
