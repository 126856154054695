import { Box, Select, VStack } from '@chakra-ui/react';

type SentStatus = {
  value: 'true' | 'false';
  label: string;
};

const SENT_STATUS_OPTIONS: SentStatus[] = [
  { value: 'true', label: '送付済み' },
  { value: 'false', label: '未送付' },
];

type Props = {
  isSent: boolean;
  isDisabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const SentStatusSelect = ({ isSent, isDisabled, onChange }: Props) => {
  return (
    <VStack spacing={1} alignItems="flex-start" width="full">
      <Box color="gray.500">送付ステータス</Box>
      <Select
        value={isSent ? 'true' : 'false'}
        onChange={onChange}
        isDisabled={isDisabled}
        size="sm"
        borderRadius="4px"
        borderColor={isSent ? 'teal.500' : 'orange.500'}
        color={isSent ? 'teal.500' : 'orange.500'}
      >
        {SENT_STATUS_OPTIONS.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </VStack>
  );
};
