import { Box, HStack, Icon, Text, useRadio } from '@chakra-ui/react';
import { CheckSmall } from '@icon-park/react';

export const MasterRegistrationSettingRadioButton = (props: {
  children: React.ReactNode;
  isChecked: boolean;
  isInvalid?: boolean;
}) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <HStack
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="999px"
        fontSize="xs"
        _checked={{
          color: 'blue.600',
          borderColor: 'blue.500',
        }}
        _hover={{
          backgroundColor: 'gray.50',
        }}
        px={3}
        py={1}
        alignItems="center"
        spacing={1}
        {...(props.isInvalid && {
          borderColor: 'red.500',
        })}
      >
        {props.isChecked ? (
          <Icon
            w="16px"
            h="16px"
            size="16px"
            orientation="vertical"
            as={CheckSmall}
            fill="blue.600"
          />
        ) : (
          ''
        )}
        <Text>{props.children}</Text>
      </HStack>
      <input {...input} />
    </Box>
  );
};
