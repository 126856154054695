import { FormControl, HStack, useRadioGroup } from '@chakra-ui/react';
import { ErrorMessage } from 'components/ErrorMessage';
import {
  MASTER_REGISTRATION_SETTING_COST_TYPE,
  NewSalesOrderFormType,
} from 'features/salesOrdersV2/form';
import { useFormContext } from 'react-hook-form';
import { MasterRegistrationSettingRadioButton } from './MasterRegistrationSettingRadioButton';

export const MasterRegistrationSettingCostRadioGroup = ({ index }: { index: number }) => {
  const form = useFormContext<NewSalesOrderFormType>();
  const {
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = form;

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: `costRegisterType-${index}`,
    onChange: (value) => {
      setValue(`masterRegistrationCosts.${index}.type`, value);
      clearErrors(`masterRegistrationCosts.${index}.type`);
    },
    defaultValue: getValues(`masterRegistrationCosts.${index}.type`),
  });

  const group = getRootProps();

  return (
    <FormControl isInvalid={!!(errors?.masterRegistrationCosts || [])[index]?.type}>
      <HStack {...group}>
        {Object.entries(MASTER_REGISTRATION_SETTING_COST_TYPE).map(([key, value]) => {
          const radio = getRadioProps({ value: key });
          return (
            <MasterRegistrationSettingRadioButton
              key={key}
              isChecked={radio.isChecked}
              isInvalid={!!(errors?.masterRegistrationCosts || [])[index]?.type}
              {...radio}
            >
              {value}
            </MasterRegistrationSettingRadioButton>
          );
        })}
      </HStack>
      <ErrorMessage name={`masterRegistrationCosts.${index}.type`} errors={errors} />
    </FormControl>
  );
};
