import { getOrRefreshFirebaseToken } from 'hooks/useRegularlyRefreshFirebaseToken';
import { toast } from 'lib/toast';
import {
  CacheConfig,
  Environment,
  Network,
  RecordSource,
  RequestParameters,
  Store,
  UploadableMap,
  Variables,
} from 'relay-runtime';
import { captureException } from 'utils/error';

type errorBodyType = { [key: string]: string };

const fetchRelay = async (
  params: RequestParameters,
  variables: Variables,
  _: CacheConfig,
  uploadables?: UploadableMap | null,
) => {
  const headers = new Headers({ Accept: 'application/json' });

  let body: string | FormData;

  if (uploadables) {
    const formData = new FormData();
    formData.append('operations', JSON.stringify({ query: params.text, variables }));

    const map: Record<string, string[]> = {};
    Object.keys(uploadables).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
        map[key] = [key];
      }
    });
    formData.append('map', JSON.stringify(map));

    Object.keys(uploadables).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
        formData.append(key, uploadables[key]);
      }
    });

    body = formData;
  } else {
    headers.append('Content-Type', 'application/json');

    body = JSON.stringify({
      query: params.text,
      variables,
    });
  }

  const tokenResult = await getOrRefreshFirebaseToken();
  if (tokenResult !== null) {
    headers.append('Authorization', `Bearer ${tokenResult.token}`);
  }

  let response: Response;
  try {
    response = await fetch(import.meta.env.VITE_GRAPHQL_ENDPOINT, {
      credentials: 'include',
      method: 'POST',
      mode: 'cors',
      headers,
      body,
    });
  } catch (e) {
    // ネットワークエラーなどの場合
    handleError(e as Error);
    return {};
  }

  const resData = await response.json();

  if (!response.ok) {
    // レスポンスステータスが400以上の場合(認証エラーなど)
    let errorBody: errorBodyType = { statusText: response.statusText };
    try {
      errorBody = await response.json();
    } catch (_) {
      // nop
    }
    handleError(new RelayFetchError(response.status, errorBody, params.text, variables));
    return resData;
  }

  if (resData.errors) {
    // GraphQLのエラーの場合
    handleError(new Error(resData.errors.map((e: { message: string }) => e.message).join('\n')));
    return resData;
  }

  return resData;
};

const handleError = (e: Error) => {
  toast({
    title: 'エラーが発生しました',
    description: e.message,
    containerStyle: {
      whiteSpace: 'pre-wrap',
      maxWidth: '400px',
    },
    status: 'error',
    duration: 60000,
  });
  captureException(e);
};

/**
 * RelayFetchError Relayのfetchに失敗したときに投げられるエラー
 */
export class RelayFetchError extends Error {
  private statusCode: number;
  private body: errorBodyType;
  private query: string | null;
  private variables: Variables;

  /**
   *
   * @param statusCode レスポンスのHTTPステータスコード
   * @param body レスポンスのボディ
   * @param query リクエストのクエリ
   * @param variables リクエストのクエリ変数
   */
  constructor(statusCode: number, body: errorBodyType, query: string | null, variables: Variables) {
    super(`RelayFetchError: code:${statusCode} body:${JSON.stringify(body)}`);
    this.statusCode = statusCode;
    this.body = body;
    this.query = query;
    this.variables = variables;
  }

  /**
   * @returns レスポンスのHTTPステータスコード
   */
  getStatusCode(): number {
    return this.statusCode;
  }

  /**
   * @returns レスポンスのボディ
   */
  getBody(): errorBodyType {
    return this.body;
  }

  /**
   * @returns リクエストのクエリ
   */
  getQuery() {
    return this.query;
  }

  /**
   * @returns リクエストのクエリ変数
   */
  getVariables() {
    return this.variables;
  }
}

export default new Environment({
  network: Network.create(fetchRelay),
  store: new Store(new RecordSource()),
});
