/**
 * @generated SignedSource<<389bdedf9baf6d0e3bb0140275f5c2d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewSalesOrderFormQuery$variables = {};
export type NewSalesOrderFormQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NewSalesOrderFormLineItemsFragment">;
};
export type NewSalesOrderFormQuery = {
  response: NewSalesOrderFormQuery$data;
  variables: NewSalesOrderFormQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewSalesOrderFormQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "NewSalesOrderFormLineItemsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NewSalesOrderFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TaxCategoryConnection",
        "kind": "LinkedField",
        "name": "taxCategories",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TaxCategoryEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TaxCategory",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9a5f4923f23e2c69d7a74759284faaf1",
    "id": null,
    "metadata": {},
    "name": "NewSalesOrderFormQuery",
    "operationKind": "query",
    "text": "query NewSalesOrderFormQuery {\n  ...NewSalesOrderFormLineItemsFragment\n}\n\nfragment NewSalesOrderFormDetailsFragment on Query {\n  ...TaxSelectBoxFragment\n}\n\nfragment NewSalesOrderFormLineItemsFragment on Query {\n  ...NewSalesOrderFormDetailsFragment\n}\n\nfragment TaxSelectBoxFragment on Query {\n  taxCategories {\n    edges {\n      node {\n        id\n        name\n        rate\n      }\n    }\n  }\n}\n"
  }
};

(node as any).hash = "468049ae604cb9db6ad5defd491a35af";

export default node;
