// src/components/FileSelectButton/index.tsx
import { Button, ButtonProps, FormControl, InputGroup } from '@chakra-ui/react';
import { ChangeEvent, useRef } from 'react';

type Props = {
  onFileSelected: (files: File[]) => void;
  label: string;
  accept?: string;
  multiple?: boolean;
  leftIcon?: React.ReactElement;
  buttonProps?: ButtonProps;
  isLoading?: boolean;
};

export const FileSelectButton = ({
  onFileSelected,
  label,
  accept = '*',
  multiple = false,
  leftIcon,
  buttonProps,
  isLoading = false,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;

    const fileArray = Array.from(files);

    onFileSelected(fileArray);

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <FormControl>
      <InputGroup>
        <input
          type="file"
          multiple={multiple}
          accept={accept}
          style={{ display: 'none' }}
          ref={inputRef}
          onChange={handleChange}
        />
        <Button
          leftIcon={leftIcon}
          onClick={() => inputRef.current?.click()}
          isLoading={isLoading}
          {...buttonProps}
        >
          {label}
        </Button>
      </InputGroup>
    </FormControl>
  );
};
