/**
 * @generated SignedSource<<7d2ce1a9a2f253b53d07b9c0a6597bcd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ItemCategory = "cardboard" | "flexiblePackage" | "giftBox" | "other" | "paperBag" | "unknown" | "%future added value";
export type SalesOrderDetailType = "item" | "onetime_cost" | "recurring_cost" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type new_OrderNewPageFragment$data = {
  readonly currentUser: {
    readonly firstName: string;
    readonly lastName: string;
    readonly phoneNumber: string | null;
    readonly userID: string;
  };
  readonly salesOrders?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly demand: {
          readonly id: string;
          readonly name: string;
        };
        readonly details: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly item: {
                readonly cardboard: {
                  readonly material: string;
                  readonly other: string;
                  readonly printingColor: string;
                  readonly processing: string;
                  readonly size: string;
                  readonly thickness: string;
                  readonly type: string;
                } | null;
                readonly category: ItemCategory | null;
                readonly flexiblePackage: {
                  readonly material: string;
                  readonly other: string;
                  readonly printingColor: string;
                  readonly processing: string;
                  readonly size: string;
                  readonly type: string;
                } | null;
                readonly giftBox: {
                  readonly other: string;
                  readonly paperType: string;
                  readonly printingColor: string;
                  readonly processing: string;
                  readonly size: string;
                } | null;
                readonly name: string;
                readonly other: {
                  readonly specText: string;
                } | null;
                readonly paperBag: {
                  readonly handle: string;
                  readonly other: string;
                  readonly paperType: string;
                  readonly printingColor: string;
                  readonly processing: string;
                  readonly size: string;
                } | null;
                readonly quantity: any;
                readonly taxCategory: {
                  readonly id: string;
                  readonly rate: number;
                };
                readonly unitPrice: number;
                readonly unitSellingPrice: number;
              } | null;
              readonly onetimeCost: {
                readonly name: string;
                readonly note: string;
                readonly quantity: any;
                readonly taxCategory: {
                  readonly id: string;
                  readonly rate: number;
                };
                readonly unitPrice: number;
                readonly unitSellingPrice: number;
              } | null;
              readonly recurringCost: {
                readonly name: string;
                readonly note: string;
                readonly quantity: any;
                readonly taxCategory: {
                  readonly id: string;
                  readonly rate: number;
                };
                readonly unitPrice: number;
                readonly unitSellingPrice: number;
              } | null;
              readonly type: SalesOrderDetailType;
            } | null;
          } | null> | null;
        };
        readonly id: string;
        readonly internalAssignees: ReadonlyArray<{
          readonly user: {
            readonly email: string;
            readonly id: string;
            readonly profile: ReadonlyArray<{
              readonly firstName: string;
              readonly lastName: string;
              readonly phoneNumber: string | null;
            }> | null;
          };
        }> | null;
        readonly supplier: {
          readonly id: string;
          readonly name: string;
        };
      } | null;
    } | null> | null;
  };
  readonly taxCategories: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly rate: number;
      } | null;
    } | null> | null;
  };
  readonly " $fragmentType": "new_OrderNewPageFragment";
};
export type new_OrderNewPageFragment$key = {
  readonly " $data"?: new_OrderNewPageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"new_OrderNewPageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitPrice",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitSellingPrice",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "TaxCategory",
  "kind": "LinkedField",
  "name": "taxCategory",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "note",
    "storageKey": null
  },
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "material",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "printingColor",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "processing",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "other",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paperType",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "isSkipToLoadSalesOrder"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "salesOrderId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "new_OrderNewPageFragment",
  "selections": [
    {
      "condition": "isSkipToLoadSalesOrder",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "id",
                  "variableName": "salesOrderId"
                }
              ],
              "kind": "ObjectValue",
              "name": "where"
            }
          ],
          "concreteType": "SalesOrderConnection",
          "kind": "LinkedField",
          "name": "salesOrders",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SalesOrderEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SalesOrder",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Company",
                      "kind": "LinkedField",
                      "name": "demand",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Supplier",
                      "kind": "LinkedField",
                      "name": "supplier",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesOrderDetailConnection",
                      "kind": "LinkedField",
                      "name": "details",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SalesOrderDetailEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "SalesOrderDetail",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v3/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "SalesOrderOnetimeCost",
                                  "kind": "LinkedField",
                                  "name": "onetimeCost",
                                  "plural": false,
                                  "selections": (v9/*: any*/),
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "SalesOrderRecurringCost",
                                  "kind": "LinkedField",
                                  "name": "recurringCost",
                                  "plural": false,
                                  "selections": (v9/*: any*/),
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "SalesOrderItem",
                                  "kind": "LinkedField",
                                  "name": "item",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "category",
                                      "storageKey": null
                                    },
                                    (v4/*: any*/),
                                    (v5/*: any*/),
                                    (v6/*: any*/),
                                    (v8/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "SalesOrderItemCardboard",
                                      "kind": "LinkedField",
                                      "name": "cardboard",
                                      "plural": false,
                                      "selections": [
                                        (v10/*: any*/),
                                        (v3/*: any*/),
                                        (v11/*: any*/),
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "thickness",
                                          "storageKey": null
                                        },
                                        (v12/*: any*/),
                                        (v13/*: any*/),
                                        (v14/*: any*/)
                                      ],
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "SalesOrderItemFlexiblePackage",
                                      "kind": "LinkedField",
                                      "name": "flexiblePackage",
                                      "plural": false,
                                      "selections": [
                                        (v10/*: any*/),
                                        (v3/*: any*/),
                                        (v11/*: any*/),
                                        (v12/*: any*/),
                                        (v13/*: any*/),
                                        (v14/*: any*/)
                                      ],
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "SalesOrderItemGiftBox",
                                      "kind": "LinkedField",
                                      "name": "giftBox",
                                      "plural": false,
                                      "selections": [
                                        (v10/*: any*/),
                                        (v15/*: any*/),
                                        (v12/*: any*/),
                                        (v13/*: any*/),
                                        (v14/*: any*/)
                                      ],
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "SalesOrderItemPaperBag",
                                      "kind": "LinkedField",
                                      "name": "paperBag",
                                      "plural": false,
                                      "selections": [
                                        (v10/*: any*/),
                                        (v15/*: any*/),
                                        (v12/*: any*/),
                                        (v13/*: any*/),
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "handle",
                                          "storageKey": null
                                        },
                                        (v14/*: any*/)
                                      ],
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "SalesOrderItemOther",
                                      "kind": "LinkedField",
                                      "name": "other",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "specText",
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesOrderInternalAssignee",
                      "kind": "LinkedField",
                      "name": "internalAssignees",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "User",
                          "kind": "LinkedField",
                          "name": "user",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "email",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "UserProfile",
                              "kind": "LinkedField",
                              "name": "profile",
                              "plural": true,
                              "selections": [
                                (v16/*: any*/),
                                (v17/*: any*/),
                                (v18/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CurrentUser",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userID",
          "storageKey": null
        },
        (v17/*: any*/),
        (v16/*: any*/),
        (v18/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaxCategoryConnection",
      "kind": "LinkedField",
      "name": "taxCategories",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TaxCategoryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TaxCategory",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v7/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "c25bcd2699723d9e20413e859fd1c8a6";

export default node;
