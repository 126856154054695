import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { Close, Copy } from '@icon-park/react';
import { AutoResizeTextarea } from 'components/AutoResizeTextarea';
import { ChangeOrderButton } from 'components/ChangeOrderButton';
import { ConfigurableNumberInput } from 'components/ConfigurableNumberInput';
import { ErrorMessage } from 'components/ErrorMessage';
import { ItemAmountPreview } from 'components/ItemAmountPreview';
import { DemandItemCategory, buildDemandItemCode } from 'features/demandItem/models';
import {
  SalesOrdersReorderFormDetailCostType,
  SalesOrdersReorderFormDetailItemType,
  SalesOrdersReorderFormDetailType,
  SalesOrdersReorderFormType,
} from 'features/salesOrdersV2/form';
import {
  SalesOrdersDetailSpec,
  getItemSpecs,
  getRecurringCostSpecs,
} from 'features/salesOrdersV2/models';
import { TaxSelectBox } from 'features/tax/components/TaxSelectBox';
import { SalesOrdersReorderFormDetailsQuery as SalesOrdersReorderFormDetailsQueryType } from 'gql/__generated__/SalesOrdersReorderFormDetailsQuery.graphql';
import { SalesOrderDetailType } from 'gql/graphql.types';
import {
  Controller,
  FieldArrayWithId,
  FieldErrors,
  UseFieldArrayInsert,
  UseFieldArrayRemove,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';
import { calculateDetailAmount, calculateGrossProfitMargin } from 'utils/priceCalculation';
import { SalesOrdersFormDetailSpec } from '../SalesOrdersFormDetailSpec';

const salesOrdersReorderFormDetailsQuery = graphql`
  query SalesOrdersReorderFormDetailsQuery {
    ...TaxSelectBoxFragment
  }
`;

export const SalesOrdersReorderFormDetails = ({
  fields,
  insert,
  remove,
}: {
  fields: FieldArrayWithId<SalesOrdersReorderFormType, 'details', 'id'>[];
  insert: UseFieldArrayInsert<SalesOrdersReorderFormType>;
  remove: UseFieldArrayRemove;
}) => {
  const {
    control,
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<SalesOrdersReorderFormType>();

  const query = useLazyLoadQuery<SalesOrdersReorderFormDetailsQueryType>(
    salesOrdersReorderFormDetailsQuery,
    {},
    { fetchPolicy: 'network-only' },
  );

  const handleClickUp = (index: number) => {
    const details = getValues('details');
    details.splice(index - 1, 2, details[index], details[index - 1]);
    setValue('details', details);
  };

  const handleClickDown = (index: number) => {
    const details = getValues('details');
    details.splice(index, 2, details[index + 1], details[index]);
    setValue('details', details);
  };

  const isDisabledUpButton = (index: number) => index === 0;
  const isDisabledDownButton = (index: number) => index + 1 === fields.length;

  const handleDuplicationItem = (index: number) => {
    const value = getValues(`details.${index}`);
    insert(
      index + 1,
      { ...value },
      { shouldFocus: true, focusName: `details.${index + 1}.quantity` },
    );
  };

  const handleRemoveItem = (index: number) => {
    remove(index);

    // フォームに存在する明細行がいずれもサプライヤーと紐づいていなければ
    // 選択済みのサプライヤーをリセットする
    const detailsWithSupplier = getValues('details').filter((detail) => {
      return detail.supplier && detail.supplier.id !== '';
    });
    if (detailsWithSupplier.length === 0) {
      setValue('supplier', { id: '', name: '' });
    }
  };

  return (
    <VStack spacing={4}>
      {fields.map((field, index) => (
        <HStack key={field.id} padding={2} spacing={2} alignItems="flex-start">
          {/* 並び替えボタン */}
          <VStack mt="25px">
            <ChangeOrderButton
              type="up"
              onClick={() => handleClickUp(index)}
              isDisabled={isDisabledUpButton(index)}
            />
            <ChangeOrderButton
              type="down"
              onClick={() => handleClickDown(index)}
              isDisabled={isDisabledDownButton(index)}
            />
          </VStack>

          {/* 品名 */}
          <VStack alignItems="flex-start" w="12.5rem" spacing={1}>
            <Text fontSize="sm">品名</Text>
            <ItemNameForm field={field} index={index} />
          </VStack>

          {/* 仕様 */}
          <VStack alignItems="flex-start" w="17.75rem" spacing={1}>
            <Text fontSize="sm">仕様</Text>
            <ItemSpecPreview index={index} />
          </VStack>

          {/* メモ */}
          <VStack alignItems="flex-start" w="11.25rem">
            <FormControl isInvalid={!!(errors.details || [])[index]?.memo} flex={1}>
              <FormLabel>メモ</FormLabel>
              <Textarea
                fontSize="sm"
                {...register(`details.${index}.memo`)}
                w="full"
                px={3}
                py={2}
              />
              <ErrorMessage name={`details.${index}.memo`} errors={errors} />
            </FormControl>
          </VStack>

          {/* 確定納品日 */}
          <VStack alignItems="flex-start" w="8rem">
            <FormControl isInvalid={!!(errors.details || [])[index]?.fixedDeliveryDate}>
              <FormLabel>確定納品日</FormLabel>
              <Input
                fontSize="sm"
                h="32px"
                px={3}
                type="date"
                {...register(`details.${index}.fixedDeliveryDate`)}
                w="full"
              />
              <ErrorMessage name={`details.${index}.fixedDeliveryDate`} errors={errors} />
            </FormControl>
          </VStack>

          {/* 数量 */}
          <VStack alignItems="flex-start" w="6rem">
            <FormControl isInvalid={!!(errors.details || [])[index]?.quantity} isRequired flex={1}>
              <FormLabel>数量</FormLabel>
              <ConfigurableNumberInput
                fontSize="sm"
                px={3}
                h="32px"
                {...register(`details.${index}.quantity`)}
              />
              <ErrorMessage name={`details.${index}.quantity`} errors={errors} />
            </FormControl>
          </VStack>

          {/* 仕入単価 */}
          <VStack alignItems="flex-start" w="6rem">
            <FormControl isInvalid={!!(errors.details || [])[index]?.unitPrice} isRequired flex={1}>
              <FormLabel>仕入単価</FormLabel>
              <ConfigurableNumberInput
                fontSize="sm"
                px={3}
                h="32px"
                {...register(`details.${index}.unitPrice`)}
                step="0.01"
              />
              <ErrorMessage name={`details.${index}.unitPrice`} errors={errors} />
            </FormControl>
          </VStack>

          {/* 顧客単価 */}
          <VStack alignItems="flex-start" w="6rem">
            <FormControl
              isInvalid={!!(errors.details || [])[index]?.unitSellingPrice}
              isRequired
              flex={1}
            >
              <FormLabel>顧客単価</FormLabel>
              <ConfigurableNumberInput
                fontSize="sm"
                px={3}
                h="32px"
                {...register(`details.${index}.unitSellingPrice`)}
                step="0.01"
              />
              <ItemGrossProfitMarginPreview index={index} />
              <ErrorMessage name={`details.${index}.unitSellingPrice`} errors={errors} />
            </FormControl>
          </VStack>

          {/* 税区分 */}
          <VStack alignItems="flex-start" w="6.5rem">
            <FormControl isInvalid={!!(errors.details || [])[index]?.tax?.id} isRequired flex={1}>
              <FormLabel>税区分</FormLabel>
              <Controller
                name={`details.${index}.tax`}
                control={control}
                render={() => (
                  <TaxSelectBox
                    fontSize="sm"
                    h="32px"
                    defaultValue={getValues(`details.${index}.tax.id`)}
                    queryRef={query}
                    onChange={(e) => {
                      setValue(`details.${index}.tax`, {
                        ...getValues(`details.${index}.tax`),
                        ...{
                          id: e.target.value,
                          rate: e.target[e.target.selectedIndex].getAttribute(
                            'data-rate',
                          ) as string,
                        },
                      });
                    }}
                  />
                )}
              />
              <ErrorMessage name={`orderLines.${index}.tax.id`} errors={errors} />
            </FormControl>
          </VStack>

          {/* 金額 */}
          <VStack alignItems="flex-start" w="7.5rem" spacing={1}>
            <Text fontSize="sm">金額</Text>
            <Box w="full">
              <ItemTotalAmountPreview index={index} />
            </Box>
          </VStack>

          {/* 複製ボタン */}
          <Box mt="25px">
            <IconButton
              onClick={() => handleDuplicationItem(index)}
              variant="ghost"
              isRound
              aria-label="copy"
              icon={<Copy />}
            />
          </Box>

          {/* 削除ボタン */}
          <Box mt="25px">
            <IconButton
              onClick={() => {
                handleRemoveItem(index);
              }}
              variant="ghost"
              isRound
              aria-label="close"
              icon={<Close size="16px" />}
            />
          </Box>
        </HStack>
      ))}
    </VStack>
  );
};

const ItemNameForm = ({
  field,
  index,
}: {
  field: FieldArrayWithId<SalesOrdersReorderFormType, 'details', 'id'>;
  index: number;
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<SalesOrdersReorderFormType>();

  return (
    <>
      {field.type === SalesOrderDetailType.OnetimeCost ? (
        <FormControl isInvalid={!!(errors.details || [])[index]?.name} isRequired w="full">
          <AutoResizeTextarea
            {...register(`details.${index}.name`)}
            w="full"
            h="32px"
            px={3}
            py={1}
          />
          <ErrorMessage name={`details.${index}.name`} errors={errors} />
        </FormControl>
      ) : (
        <Box px={3} py={2} bg="gray.50" rounded={4} w="full">
          <Text fontSize="sm">{field.name}</Text>
        </Box>
      )}
    </>
  );
};

const ItemSpecPreview = ({ index }: { index: number }) => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<SalesOrdersReorderFormType>();
  const detail = useWatch({ control, name: `details.${index}` });

  if (detail.type === SalesOrderDetailType.OnetimeCost) {
    const detailsErrors = errors.details as FieldErrors<SalesOrdersReorderFormDetailCostType[]>;
    return (
      <SalesOrdersFormDetailSpec
        specs={[
          {
            label: '補足',
            node: (
              <FormControl isInvalid={!!(detailsErrors || [])[index]?.note} w="full">
                <AutoResizeTextarea
                  {...register(`details.${index}.note`)}
                  fontSize="xs"
                  w="full"
                  px={2}
                  py={1}
                />
                <ErrorMessage name={`details.${index}.note`} errors={errors} />
              </FormControl>
            ),
          },
        ]}
      />
    );
  }

  const specs = getDetailSpecs(detail);

  // 表示すべき仕様がない場合
  if (!specs || !Object.values(specs).some((spec) => spec.node)) {
    return (
      <Text fontSize="xs" color="gray.600">
        仕様がありません
      </Text>
    );
  }

  return <SalesOrdersFormDetailSpec specs={specs} />;
};

const ItemTotalAmountPreview = ({ index }: { index: number }) => {
  const { control } = useFormContext<SalesOrdersReorderFormType>();
  const detail = useWatch({ control, name: `details.${index}` });

  return (
    <ItemAmountPreview
      fontSize="sm"
      h="32px"
      amount={calculateDetailAmount({
        quantity: detail.quantity || 0,
        price: detail.unitSellingPrice || 0,
      })}
    />
  );
};

const ItemGrossProfitMarginPreview = ({ index }: { index: number }) => {
  const { control } = useFormContext<SalesOrdersReorderFormType>();
  const detail = useWatch({ control, name: `details.${index}` });

  return (
    <Text fontSize="sm" color="gray.500" textAlign="right" mt={2}>
      粗利率:{' '}
      {calculateGrossProfitMargin(Number(detail.unitPrice), Number(detail.unitSellingPrice))}%
    </Text>
  );
};

const getDetailSpecs = (detail: SalesOrdersReorderFormDetailType) => {
  if (detail.type === SalesOrderDetailType.Item) {
    const itemDetail = detail as SalesOrdersReorderFormDetailItemType;

    const baseSpecs: SalesOrdersDetailSpec[] = [
      {
        label: 'コード',
        node: buildDemandItemCode({
          demandCode: itemDetail.code,
          category: itemDetail.category as DemandItemCategory,
          itemNumber: Number(itemDetail.itemNumber),
          version: Number(itemDetail.version),
        }),
      },
    ];
    return baseSpecs.concat(getItemSpecs(itemDetail.category, itemDetail.spec));
  }

  if (detail.type === SalesOrderDetailType.RecurringCost) {
    return getRecurringCostSpecs(detail as SalesOrdersReorderFormDetailCostType);
  }
};
