import { Box, HStack, VStack } from '@chakra-ui/react';
import {
  NewSalesOrderDetailFormType,
  SalesOrdersEditFormDetailType,
  SalesOrdersReorderFormDetailType,
} from 'features/salesOrdersV2/form';
import {
  calculateSubtotalAmount,
  calculateTotalAmount,
  calculateTotalGrossProfitAmount,
  calculateTotalTaxAmount,
} from 'utils/priceCalculation';

type DetailsType =
  | SalesOrdersEditFormDetailType[]
  | SalesOrdersReorderFormDetailType[]
  | NewSalesOrderDetailFormType[];

type Props = {
  details: DetailsType;
};

export const SalesOrdersFormPrices = ({ details }: Props) => {
  const detailsForCalculation = details.map((detail) => ({
    quantity: detail.quantity || 0,
    unitPrice: detail.unitPrice || 0,
    unitSellingPrice: detail.unitSellingPrice || 0,
    tax: detail.tax,
  }));

  return (
    <Box w="240px">
      <VStack alignItems="flex-start" spacing={2}>
        <HStack w="100%" justifyContent="space-between">
          <Box fontSize="sm">小計</Box>
          <Box fontSize="sm">{calculateSubtotalAmount(detailsForCalculation).toLocaleString()}</Box>
        </HStack>
        <HStack w="100%" justifyContent="space-between">
          <Box
            color="gray.600"
            fontSize="xs"
            borderColor="gray.200"
            borderStyle="solid"
            borderLeftWidth="2px"
            pl={2}
          >
            粗利
          </Box>
          <Box color="gray.600" fontSize="xs">
            {calculateTotalGrossProfitAmount(detailsForCalculation).toLocaleString()}
          </Box>
        </HStack>
      </VStack>

      <VStack mt={2} alignItems="flex-start" spacing={2}>
        <HStack w="100%" justifyContent="space-between">
          <Box fontSize="sm">消費税</Box>
          <Box fontSize="sm">{calculateTotalTaxAmount(detailsForCalculation).toLocaleString()}</Box>
        </HStack>
      </VStack>

      <VStack mt={2} justify="space-between" spacing={2}>
        <HStack w="100%" justifyContent="space-between">
          <Box fontWeight="bold">合計金額</Box>
          <Box fontWeight="bold">
            {calculateTotalAmount(detailsForCalculation).toLocaleString()}
          </Box>
        </HStack>
      </VStack>
    </Box>
  );
};
