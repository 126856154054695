import {
  useExtractInvoiceBulkSendJobDocumentsMutation,
  useExtractInvoiceBulkSendJobDocumentsMutation$data,
} from 'gql/__generated__/useExtractInvoiceBulkSendJobDocumentsMutation.graphql';
import {} from 'gql/__generated__/useInvoiceDocumentsQuery.graphql';
import { ExtractInvoiceBulkSendJobDocumentsInput } from 'gql/graphql.types';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { graphql } from 'relay-runtime';

export const useExtractInvoiceBulkSendJobDocuments = () => {
  const { commitMutation, isMutationInFlight } =
    useMutationWrapper<useExtractInvoiceBulkSendJobDocumentsMutation>(
      graphql`
  mutation useExtractInvoiceBulkSendJobDocumentsMutation($input: ExtractInvoiceBulkSendJobDocumentsInput!) {
    extractInvoiceBulkSendJobDocuments(input: $input) {
      id
    }
  }
    `,
    );

  const extractDocuments = async (input: ExtractInvoiceBulkSendJobDocumentsInput) => {
    return new Promise<
      useExtractInvoiceBulkSendJobDocumentsMutation$data['extractInvoiceBulkSendJobDocuments']
    >((resolve, reject) => {
      commitMutation({
        variables: { input },
        onCompleted: (res) => {
          resolve(res.extractInvoiceBulkSendJobDocuments);
        },
        onError: (error) => {
          reject(error);
        },
      });
    });
  };

  return { extractDocuments, isMutationInFlight };
};
