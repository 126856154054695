import { Box, Button, FormControl, FormLabel, Text, VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loading } from '@icon-park/react';
import { ErrorMessage } from 'components/ErrorMessage';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { CompanyComboBox } from 'features/company/components/CompanyComboBox';
import { DemandMemo } from 'features/company/components/DemandMemo';
import {
  INVOICE_FORM_TYPE,
  InvoiceFormType,
  InvoiceSalesOrdersSelectFormValueType,
  getFormPageLabel,
  invoiceSalesOrdersSelectFormSchema,
} from 'features/invoice/form';
import { useQueryParams } from 'features/invoice/hooks/useInvoiceQueryPrams';
import {
  InvoiceSalesOrdersSelectFormFragment$data,
  InvoiceSalesOrdersSelectFormFragment$key,
} from 'gql/__generated__/InvoiceSalesOrdersSelectFormFragment.graphql';
import { InvoiceSalesOrdersSelectFormQuery } from 'gql/__generated__/InvoiceSalesOrdersSelectFormQuery.graphql';
import { toast } from 'lib/toast';
import { useTransition } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLazyLoadQuery, useRefetchableFragment } from 'react-relay';
import { Link } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';
import { SalesOrderDetailsTable } from './SalesOrderDetailsTable';
import { SelectPeriodSalesOrdersForm } from './SelectPeriodSalesOrdersForm';

type Props = {
  type?: InvoiceFormType;
  defaultValues?: InvoiceSalesOrdersSelectFormValueType;
  previousUrl?: string;
  relatedSalesOrderDetailIds?: string[];
  onSubmit: (
    data: InvoiceSalesOrdersSelectFormValueType,
    salesOrders: NonNullable<
      NonNullable<InvoiceSalesOrdersSelectFormFragment$data['salesOrders']>['edges']
    >,
  ) => void;
};

const invoiceSalesOrdersSelectFormQuery = graphql`
  query InvoiceSalesOrdersSelectFormQuery ($isSkip: Boolean!, $salesOrderWhere: SalesOrderWhereInput, $salesOrderDetailWhere: SalesOrderDetailWhereInput){
    ...InvoiceSalesOrdersSelectFormFragment @arguments(isSkip: $isSkip, salesOrderWhere: $salesOrderWhere, salesOrderDetailWhere: $salesOrderDetailWhere)
  }
`;

const invoiceSalesOrdersSelectFormFragment = graphql`
  fragment InvoiceSalesOrdersSelectFormFragment on Query
    @refetchable(queryName: "InvoiceSalesOrdersSelectFormFragmentRefetchQuery")
    @argumentDefinitions (
      isSkip: {type: "Boolean", defaultValue: true}
      salesOrderWhere: {type: "SalesOrderWhereInput", defaultValue: null}
      salesOrderDetailWhere: {type: "SalesOrderDetailWhereInput", defaultValue: null}
    )
  {
    salesOrders(where: $salesOrderWhere) @skip(if: $isSkip) {
      edges {
        cursor
        node {
          id
          title
          transactionID
          details (orderBy: { direction: ASC, field: ORDER_NUMBER }, where: $salesOrderDetailWhere) {
            edges {
              cursor
              node {
                id
                branchNumber
                invoiceDetails {
                  edges {
                    node {
                      id
                    }
                  }
                }
                deliveryEvents(where: {isValid: true}) {
                  edges {
                    node {
                      fixedDeliveryDate
                    }
                  }
                }
                item {
                  id
                  name
                  quantity
                  unitSellingPrice
                  tax: taxCategory {
                    rate
                  }
                }
                recurringCost {
                  id
                  name
                  quantity
                  unitSellingPrice
                  tax: taxCategory {
                    rate
                  }
                }
                onetimeCost {
                  id
                  name
                  quantity
                  unitSellingPrice
                  tax: taxCategory {
                    rate
                  }
                }
                memos {
                  body
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const InvoiceSalesOrdersSelectForm = ({
  previousUrl,
  type = INVOICE_FORM_TYPE.new,
  defaultValues,
  relatedSalesOrderDetailIds,
  onSubmit,
}: Props) => {
  const { queryParams } = useQueryParams();
  const [isPending, startTransition] = useTransition();
  const query = useLazyLoadQuery<InvoiceSalesOrdersSelectFormQuery>(
    invoiceSalesOrdersSelectFormQuery,
    {
      salesOrderWhere: getSalesOrderWhereQuery({
        demandId: defaultValues?.demand.id || '',
      }),
      salesOrderDetailWhere: getSalesOrderDetailWhereQuery({
        type,
        salesOrderDetailIds: relatedSalesOrderDetailIds || [],
      }),
      isSkip: !defaultValues?.demand.id,
    },
    { fetchPolicy: 'network-only' },
  );

  const [salesOrderData, refetch] = useRefetchableFragment(
    invoiceSalesOrdersSelectFormFragment,
    query as InvoiceSalesOrdersSelectFormFragment$key,
  );

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
    clearErrors,
    watch,
  } = useForm<InvoiceSalesOrdersSelectFormValueType>({
    resolver: zodResolver(invoiceSalesOrdersSelectFormSchema),
    defaultValues,
  });

  const demandId = watch('demand').id;

  const handleClearClientInput = () => setValue('demand', { id: '', name: '' });

  const handleChangeCheckbox = (isChecked: boolean, detailId: string) => {
    const currentValue = getValues('salesOrderDetailIds');
    if (isChecked) {
      setValue('salesOrderDetailIds', [...currentValue, detailId]);
      clearErrors('salesOrderDetailIds');
    } else {
      setValue(
        'salesOrderDetailIds',
        currentValue.filter((id) => id !== detailId),
      );
    }
  };

  const handleClickAllSelect = (values: string[]) => {
    const ids = new Set([...getValues('salesOrderDetailIds'), ...values]);
    setValue('salesOrderDetailIds', Array.from(ids));
    toast({
      title: '選択しました',
      status: 'success',
    });
  };

  const hadleClearSelections = () => {
    setValue('salesOrderDetailIds', []);
    toast({
      title: '選択をクリアしました',
      status: 'success',
    });
  };

  const salesOrders = salesOrderData.salesOrders?.edges;

  return (
    <>
      <Box mb={6}>
        <Link
          to={
            previousUrl ??
            paths.invoice.url({
              creator: queryParams.creator,
              demand: queryParams.demand,
              isSent: queryParams.isSent,
              startBookedAt: queryParams.startBookedAt,
              endBookedAt: queryParams.endBookedAt,
            })
          }
        >
          <PageBack />
        </Link>
      </Box>
      <Box mb={6}>
        <SubHeading label={getFormPageLabel(type)} />
      </Box>
      <form
        onSubmit={handleSubmit((data) =>
          onSubmit(data, ((!salesOrders || salesOrders.length === 0) && salesOrders) || []),
        )}
      >
        <VStack spacing={6} alignItems="flex-start">
          <FormControl w="376px" isRequired>
            <VStack gap={2} align="stretch">
              <Box>
                <FormLabel mb={1}>デマンド</FormLabel>
                <Controller
                  name="demand"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <CompanyComboBox
                      isDisabled={type === INVOICE_FORM_TYPE.edit}
                      defaultSelectedItem={getValues('demand')}
                      onClearInput={handleClearClientInput}
                      onChangeSelected={(value) => {
                        onChange(value);
                        if (value) {
                          startTransition(() => {
                            // 見積を再取得されるタイミングでリセット
                            setValue('salesOrderDetailIds', []);
                            refetch(
                              {
                                salesOrderWhere: getSalesOrderWhereQuery({
                                  demandId: value.id,
                                }),
                                salesOrderDetailWhere: getSalesOrderDetailWhereQuery({
                                  type,
                                  salesOrderDetailIds: relatedSalesOrderDetailIds || [],
                                }),
                                isSkip: !value.id,
                              },
                              {
                                fetchPolicy: 'network-only',
                              },
                            );
                          });
                        }
                      }}
                    />
                  )}
                />
              </Box>
              <DemandMemo demandId={demandId} />
            </VStack>
          </FormControl>
          <VStack spacing={4} alignItems="flex-start">
            <Text fontSize="xl" fontWeight="bold">
              請求する発注請書明細
            </Text>

            {isPending ? (
              <Loading />
            ) : (
              <Box mt={4}>
                {!getValues('demand.id') ? (
                  <Text color="gray.500">
                    デマンドを選択すると未請求の発注請書明細が表示されます
                  </Text>
                ) : (
                  <>
                    {!salesOrders ||
                    !salesOrders?.some(
                      (salesOrder) => (salesOrder?.node?.details.edges || []).length > 0,
                    ) ? (
                      <Text color="gray.500">未請求の発注請書明細がありません</Text>
                    ) : (
                      <VStack spacing={6} alignItems="flex-start" w="7xl">
                        <SelectPeriodSalesOrdersForm
                          salesOrders={salesOrders}
                          onClickAllSelect={handleClickAllSelect}
                          onClickClearSelections={hadleClearSelections}
                        />
                        <FormControl isInvalid={!!errors.salesOrderDetailIds}>
                          <SalesOrderDetailsTable
                            control={control}
                            salesOrders={salesOrders}
                            onChangeCheckbox={handleChangeCheckbox}
                          />
                          <ErrorMessage name="salesOrderDetailIds" errors={errors} />
                        </FormControl>
                        <Button colorScheme="blue" w="full" type="submit" isLoading={isSubmitting}>
                          選択を完了して情報入力画面へ
                        </Button>
                      </VStack>
                    )}
                  </>
                )}
              </Box>
            )}
          </VStack>
        </VStack>
      </form>
    </>
  );
};

const getSalesOrderWhereQuery = ({ demandId }: { demandId: string }) => ({
  hasDemandWith: [{ id: demandId }],
  hasOrdersWith: [{ deletedAtIsNil: true }],
});

const getSalesOrderDetailWhereQuery = ({
  type,
  salesOrderDetailIds,
}: { type: InvoiceFormType; salesOrderDetailIds?: string[] }) => {
  switch (type) {
    case INVOICE_FORM_TYPE.new:
      return {
        or: [
          { not: { hasInvoiceDetailsWith: [{ deletedAtIsNil: true, hasSalesOrderDetail: true }] } },
          { hasInvoiceDetails: false },
        ],
      };
    case INVOICE_FORM_TYPE.edit:
      return {
        or: [
          // 削除されてない請求書明細を持っていない
          { not: { hasInvoiceDetailsWith: [{ deletedAtIsNil: true, hasSalesOrderDetail: true }] } },
          { hasInvoiceDetails: false },
          { idIn: salesOrderDetailIds || [] },
        ],
      };
  }
};
