import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { SalesOrdersFormItemModalContent } from 'features/salesOrdersV2/components/SalesOrdersFormItemModal/SalesOrdersFormItemModalContent';
import {
  SalesOrdersFormItemModalAcceptableFormType,
  SalesOrdersFormItemModalType,
  salesOrdersFormItemModalSchema,
} from 'features/salesOrdersV2/form';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

export const SalesOrdersFormItemModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  // 大元のフォーム
  const rootForm = useFormContext<SalesOrdersFormItemModalAcceptableFormType>();

  // モーダル上での選択を保持するフォーム
  const modalForm = useForm<SalesOrdersFormItemModalType>({
    resolver: zodResolver(salesOrdersFormItemModalSchema),
    defaultValues: {
      demand: rootForm.getValues('demand'),
      supplier: rootForm.getValues('supplier'),
      details: [],
    },
  });

  const handleAddDetails = () => {
    const details = rootForm.getValues('details');
    const selectedDetails = modalForm.getValues('details');

    const newDetails = [...details, ...selectedDetails];
    rootForm.setValue('details', newDetails);

    const selectedSupplier = modalForm.getValues('supplier');
    rootForm.setValue('supplier', selectedSupplier);

    onClose();
  };

  return (
    <FormProvider {...modalForm}>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="6xl" closeOnEsc={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pt={6} px={4} pb={3} borderBottom="1px solid" borderColor="gray.200">
            <HStack justifyContent="space-between" alignItems="baseline">
              <Text>単価表からリピート商品を選択</Text>
              <Text fontSize="xs" color="red.500" fontWeight="normal">
                ※ 同一商品を別の納品先に送る場合、商品追加後に明細を複製してください
              </Text>
            </HStack>
          </ModalHeader>

          <ModalBody p={0}>
            <SalesOrdersFormItemModalContent />
          </ModalBody>

          <ModalFooter p={4} borderTop="1px solid" borderColor="gray.200">
            <Button colorScheme="blue" onClick={handleAddDetails}>
              明細に追加
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
};
