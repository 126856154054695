/**
 * @generated SignedSource<<096a35f1e248f0f361aa63a1dfa06b52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type layout_SalesOrdersReorderLayoutQuery$variables = {};
export type layout_SalesOrdersReorderLayoutQuery$data = {
  readonly currentUser: {
    readonly firstName: string;
    readonly lastName: string;
    readonly userID: string;
  };
};
export type layout_SalesOrdersReorderLayoutQuery = {
  response: layout_SalesOrdersReorderLayoutQuery$data;
  variables: layout_SalesOrdersReorderLayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CurrentUser",
    "kind": "LinkedField",
    "name": "currentUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "layout_SalesOrdersReorderLayoutQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "layout_SalesOrdersReorderLayoutQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "762e093ba34f0098dfa959a4a84adf5a",
    "id": null,
    "metadata": {},
    "name": "layout_SalesOrdersReorderLayoutQuery",
    "operationKind": "query",
    "text": "query layout_SalesOrdersReorderLayoutQuery {\n  currentUser {\n    userID\n    firstName\n    lastName\n  }\n}\n"
  }
};
})();

(node as any).hash = "364bbe695eef75a726da8978a7fc92fc";

export default node;
