import { Environments } from 'constants/environment';
import { DemandItemCategory, buildDemandItemCodeForUrl } from 'features/demandItem/models';
import { generatePath } from 'react-router-dom';
import { getEnvironment, isItemManagementEnabled } from './environment';

const buildQueryParameter = (params?: { [key: string]: string | string[] | undefined }): string => {
  if (!params) return '';

  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        searchParams.append(key, value.join(','));
      }
    } else if (value !== undefined && value !== '') {
      searchParams.append(key, value);
    }
  });

  const queryString = searchParams.toString();
  return queryString;
};

// 商品管理お掃除対応:
//   本リリース前に本番環境でのみ別のパスにするための関数
//   本リリース後は /sales-orders に統一する
const getSalesOrdersPaths = () => {
  const isProd = getEnvironment() === Environments.Production;

  // 本番環境かつ商品管理が未リリースの場合のみ、/v2/sales-orders にする
  const isTempPathEnabled = isProd && !isItemManagementEnabled();

  const basePath = isTempPathEnabled ? '/v2/sales-orders' : '/sales-orders';

  return {
    url: (params?: {
      assignee?: string;
      demand?: string;
      transactionId?: string;
      supplier?: string;
    }) => {
      const queryParams = buildQueryParameter(params);
      if (queryParams) return `${basePath}?${queryParams}`;
      return basePath;
    },
    id: (id: string) => ({
      url: () => generatePath(`${basePath}/:id`, { id }),
      edit: {
        url: () => generatePath(`${basePath}/:id/edit`, { id }),
        confirm: {
          url: () => generatePath(`${basePath}/:id/edit/confirm`, { id }),
        },
      },
    }),
    reorder: {
      url: () => `${basePath}/reorder`,
      confirm: {
        url: () => `${basePath}/reorder/confirm`,
      },
    },
  };
};

export const paths = {
  root: () => '/',
  estimationRequest: {
    _id: (id: string) => generatePath('/estimation-request/:id', { id }),
    url: (params?: {
      assignee?: string;
      demand?: string;
      supplier?: string;
      searchWord?: string;
    }) => {
      const queryParams = buildQueryParameter(params);
      if (queryParams) return `/estimation-request?${queryParams}`;
      return '/estimation-request';
    },
    new: {
      url: (type?: 'draft') =>
        type === 'draft' ? '/estimation-request/new?type=draft' : '/estimation-request/new',
    },
    duplication: {
      _id: (id: string) => generatePath('/estimation-request/duplication/:id', { id }),
    },
    addAssignees: {
      _id: (id: string) => generatePath('/estimation-request/add-assignees/:id', { id }),
    },
  },
  estimationResponse: {
    url: (token: string, isInternal = true) => {
      return `/estimation-response?token=${token}&isInternal=${isInternal}`;
    },
  },
  // 商品管理お掃除対応: リリース後しばらくしたら削除する
  estimation: {
    _id: (id: string) => ({
      url: generatePath('/estimation/:id', { id }),
    }),
    url: (params?: { assignee?: string; demand?: string; supplier?: string }) => {
      const queryParams = buildQueryParameter(params);
      if (queryParams) return `/estimation?${queryParams}`;
      return '/estimation';
    },
    new: {
      url: (
        params: { responseId?: string; requestAssigneeId?: string } | undefined = undefined,
      ) => {
        const queryParams = buildQueryParameter(params);
        if (queryParams) return `/estimation/new?${queryParams}`;
        return '/estimation/new';
      },
    },
    duplication: {
      _id: (id: string) => generatePath('/estimation/duplication/:id', { id }),
    },
    edit: {
      _id: (id: string) => generatePath('/estimation/edit/:id', { id }),
    },
  },
  estimations: {
    url: (params?: { assignee?: string; demand?: string; supplier?: string }) => {
      const queryParams = buildQueryParameter(params);
      if (queryParams) return `/estimations?${queryParams}`;
      return '/estimations';
    },
    _estimationId: (estimationId: string) => ({
      url: () => generatePath('/estimations/:estimationId', { estimationId }),
      newSalesOrder: {
        url: () => generatePath('/estimations/:estimationId/new-sales-order', { estimationId }),
        masterRegistrationSetting: {
          url: () =>
            generatePath('/estimations/:estimationId/new-sales-order/master-registration-setting', {
              estimationId,
            }),
        },
        confirm: {
          url: () =>
            generatePath('/estimations/:estimationId/new-sales-order/confirm', { estimationId }),
        },
      },
    }),
    new: {
      url: (
        params: { responseId?: string; originalEstimateId?: string } | undefined = undefined,
      ) => {
        const queryParams = buildQueryParameter(params);
        if (queryParams) return `/estimations/new?${queryParams}`;
        return '/estimations/new';
      },
      confirm: {
        url: (params: { responseId?: string } | undefined = undefined) => {
          const queryParams = buildQueryParameter(params);
          if (queryParams) return `/estimations/new/confirm?${queryParams}`;
          return '/estimations/new/confirm';
        },
      },
    },
    edit: {
      _estimationId: (estimationId: string) => ({
        url: () => generatePath('/estimations/:estimationId/edit', { estimationId }),
        confirm: {
          url: () => generatePath('/estimations/:estimationId/edit/confirm', { estimationId }),
        },
      }),
    },
  },
  order: {
    _id: (id: string) => generatePath('/order/:id', { id }),
    url: (params?: { assignee?: string; demand?: string; supplier?: string }) => {
      const queryParams = buildQueryParameter(params);
      if (queryParams) return `/order?${queryParams}`;
      return '/order';
    },
    new: {
      url: (estimationId?: string) =>
        estimationId ? `/order/new?estimationId=${estimationId}` : '/order/new',
      urlWithSalesOrderId: (salesOrderId: string) => `/order/new?salesOrderId=${salesOrderId}`,
    },
    duplication: {
      _id: (id: string) => generatePath('/order/duplication/:id', { id }),
    },
    edit: {
      _id: (id: string) => generatePath('/order/edit/:id', { id }),
    },
    sending: {
      _id: (id: string) => generatePath('/order/sending/:id', { id }),
    },
  },
  // 商品管理お掃除対応: リリース後しばらくしたら削除する
  salesOrder: {
    id: (id: string) => ({
      url: () => generatePath('/sales-orders/:id', { id }),
      edit: {
        url: () => generatePath('/sales-orders/:id/edit', { id }),
      },
    }),
    url: (params?: {
      assignee?: string;
      demand?: string;
      transactionId?: string;
      supplier?: string;
    }) => {
      const queryParams = buildQueryParameter(params);
      if (queryParams) return `/sales-orders?${queryParams}`;
      return '/sales-orders';
    },
    new: {
      url: (estimationId?: string) => {
        const base = '/sales-orders/new';
        return estimationId ? `${base}?estimationId=${estimationId}` : base;
      },
    },
  },
  salesOrders: getSalesOrdersPaths(),
  invoice: {
    _id: (id: string) => generatePath('/invoice/:id', { id }),
    url: (params?: {
      creator?: string;
      demand?: string;
      isSent?: string;
      startBookedAt?: string;
      endBookedAt?: string;
    }) => {
      const queryParams = buildQueryParameter(params);
      if (queryParams) return `/invoice?${queryParams}`;
      return '/invoice';
    },
    new: {
      url: () => '/invoice/new',
    },
    edit: {
      _id: (id: string) => generatePath('/invoice/edit/:id', { id }),
    },
    bulkSend: {
      url: () => '/invoice/bulk-send',
    },
  },
  company: {
    _id: (id: string) => ({
      url: generatePath('/company/:id', { id }),
    }),
    new: {
      url: () => '/company/new',
    },
    edit: {
      _id: (id: string) => generatePath('/company/:id/edit', { id }),
    },
    url: () => '/company',
  },
  supplier: {
    _id: (id: string) => ({
      url: () => generatePath('/supplier/:id', { id }),
    }),
    new: {
      url: () => '/supplier/new',
    },
    edit: {
      _id: (id: string) => generatePath('/supplier/edit/:id', { id }),
    },
    url: () => '/supplier',
  },
  demands: {
    new: {
      url: () => '/demands/new',
    },
    _demandCode: (demandCode: string) => ({
      url: (params?: { itemCategories?: string[]; itemSuppliers?: string[] }) => {
        const queryParams = buildQueryParameter(params);
        const path = generatePath('/demands/:demandCode', { demandCode });
        if (queryParams) return `${path}?${queryParams}`;
        return path;
      },
      edit: {
        url: () => generatePath('/demands/:demandCode/edit', { demandCode }),
      },
      items: {
        new: {
          url: () => generatePath('/demands/:demandCode/demand-items/new', { demandCode }),
          confirm: {
            url: () =>
              generatePath('/demands/:demandCode/demand-items/new/confirm', { demandCode }),
          },
        },
        _itemCode: ({
          itemNumber,
          category,
          version,
        }: { itemNumber: number; category: DemandItemCategory; version?: number }) => ({
          url: () =>
            generatePath('/demands/:demandCode/demand-items/:itemCode', {
              demandCode,
              itemCode: buildDemandItemCodeForUrl({ itemNumber, category, version }),
            }),
          edit: {
            url: () =>
              generatePath('/demands/:demandCode/demand-items/:itemCode/edit', {
                demandCode,
                itemCode: buildDemandItemCodeForUrl({ itemNumber, category, version }),
              }),
            confirm: {
              url: () =>
                generatePath('/demands/:demandCode/demand-items/:itemCode/edit/confirm', {
                  demandCode,
                  itemCode: buildDemandItemCodeForUrl({ itemNumber, category, version }),
                }),
            },
          },
        }),
      },
      recurringCosts: {
        new: {
          url: () => generatePath('/demands/:demandCode/recurring-costs/new', { demandCode }),
          confirm: {
            url: () =>
              generatePath('/demands/:demandCode/recurring-costs/new/confirm', { demandCode }),
          },
        },
        _costNumber: (costNumber: string) => ({
          edit: {
            url: () =>
              generatePath('/demands/:demandCode/recurring-costs/:costNumber/edit', {
                demandCode,
                costNumber,
              }),
            confirm: {
              url: () =>
                generatePath('/demands/:demandCode/recurring-costs/:costNumber/edit/confirm', {
                  demandCode,
                  costNumber,
                }),
            },
          },
        }),
      },
    }),
    url: () => '/demands',
  },
  settings: {
    profile: {
      url: () => '/settings/profile',
    },
  },
  login: {
    url: () => '/login',
  },
};
