export const FARE_MESSAGE =
  '・配送先が２階以上、もしくは２トントラック以下しか入れない等の場合は、別途運賃が発生する可能性がございます。';
export const MESSAGE_FOR_ERROR_OF_TAX_CALCULATION =
  '・消費税の端数処理により、請求時に誤差が発生する可能性があります。';
export const DEFAULT_CANCEL_MESSAGE =
  '・ご発注後の製造が進行している場合、キャンセルはお受け致しかねます。';
export const DETAIL_MESSAGE_TEMPLATE = `・振込手数料は貴社にてご負担ください。
${DEFAULT_CANCEL_MESSAGE}
${MESSAGE_FOR_ERROR_OF_TAX_CALCULATION}
${FARE_MESSAGE}
`;
