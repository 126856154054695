import { Flex, HStack } from '@chakra-ui/react';
import { Loading } from 'components/Loading';
import { SalesOrdersFormItemModalDetails } from 'features/salesOrdersV2/components/SalesOrdersFormItemModal/SalesOrdersFormItemModalDetails';
import { SalesOrdersFormItemModalFilter } from 'features/salesOrdersV2/components/SalesOrdersFormItemModal/SalesOrdersFormItemModalFilter';
import { Suspense, useState } from 'react';

export const SalesOrdersFormItemModalContent = () => {
  const [supplierFilters, setSupplierFilters] = useState<string[]>([]);
  const [categoryFilters, setCategoryFilters] = useState<string[]>([]);

  return (
    <HStack spacing={0} h="70vh">
      <Suspense
        fallback={
          <Flex w="100%" h="100%" justifyContent="center" alignItems="center">
            <Loading />
          </Flex>
        }
      >
        <SalesOrdersFormItemModalFilter
          categoryFilters={categoryFilters}
          supplierFilters={supplierFilters}
          setSupplierFilters={setSupplierFilters}
          setCategoryFilters={setCategoryFilters}
        />
      </Suspense>

      <Suspense
        fallback={
          <Flex w="100%" h="100%" justifyContent="center" alignItems="center">
            <Loading />
          </Flex>
        }
      >
        <SalesOrdersFormItemModalDetails
          categoryFilters={categoryFilters}
          supplierFilters={supplierFilters}
        />
      </Suspense>
    </HStack>
  );
};
