import { VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { EstimationFormType, estimationFormSchema } from 'features/estimations/form';
import { layout_EstimationEditLayoutQuery } from 'gql/__generated__/layout_EstimationEditLayoutQuery.graphql';
import { FormProvider, useForm } from 'react-hook-form';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Outlet, useParams } from 'react-router-dom';
import { MissingRequiredParamError } from 'utils/error';
import { EstimationResponseType, mapEstimationToEstimationForm } from './utils';

const estimationEditLayoutQuery = graphql`
  query layout_EstimationEditLayoutQuery($estimationId: ID!) {
    estimations(where: {id: $estimationId}) {
      edges {
        node {
          id
          demand: company {
            id
            name
          }
          supplier {
            id
            name
          }
          title
          detailMessage
          expirationPeriod
          showAmountSummary
          createdAt
          details(orderBy: {direction: ASC, field: ORDER_NUMBER}) {
            edges {
              node {
                type
                orderNumber
                documentDisplayStatus
                cost {
                  name
                  note
                  unitPrice
                  unitSellingPrice
                  quantity
                  taxCategory {
                    id
                    rate
                  }
                }
                item {
                  name
                  category
                  taxCategory {
                    id
                    rate
                  }
                  unitPrice
                  unitSellingPrice
                  quantity
                  cardboard {
                    size
                    type
                    material
                    thickness
                    printingColor
                    processing
                    other
                  }
                  flexiblePackage {
                    size
                    type
                    material
                    printingColor
                    processing
                    other
                  }
                  giftBox {
                    size
                    type
                    paperType
                    printingColor
                    processing
                    other
                  }
                  paperBag {
                    size
                    paperType
                    printingColor
                    processing
                    handle
                    other
                  }
                  other {
                    specText
                  }
                }
              }
            }
          }
          internalAssignees {
            user {
              id
              profile {
                lastName
                firstName
              }
            }
          }
        }
      }
    }
  }
`;

const getDefaultValue = (estimation: EstimationResponseType): EstimationFormType => {
  return mapEstimationToEstimationForm(estimation);
};

export const EstimationEditLayout = () => {
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const { estimations } = useLazyLoadQuery<layout_EstimationEditLayoutQuery>(
    estimationEditLayoutQuery,
    { estimationId: id },
    { fetchPolicy: 'network-only' },
  );

  const estimation = estimations?.edges?.[0]?.node;

  if (!estimation) {
    throw new Error('Estimation not found');
  }

  const form = useForm<EstimationFormType>({
    resolver: zodResolver(estimationFormSchema),
    defaultValues: getDefaultValue(estimation),
  });

  return (
    <VStack spacing={6} alignItems="flex-start">
      <FormProvider {...form}>
        <Outlet />
      </FormProvider>
    </VStack>
  );
};
