import { VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  EstimationFormType,
  createDefaultEstimationForm,
  estimationFormSchema,
  mapResponseToEstimationForm,
} from 'features/estimations/form';
import {
  layout_EstimationCreateLayoutQuery,
  layout_EstimationCreateLayoutQuery$data,
} from 'gql/__generated__/layout_EstimationCreateLayoutQuery.graphql';
import { FormProvider, useForm } from 'react-hook-form';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Outlet, useSearchParams } from 'react-router-dom';
import { mapEstimationToEstimationForm } from './utils';

const estimationCreateLayoutQuery = graphql`
  query layout_EstimationCreateLayoutQuery(
    $responseId: ID!
    $isResponseSkip: Boolean!
    $originalEstimateId: ID!
    $isOriginalEstimateSkip: Boolean!
  ) {
    # 見積依頼からの引用時のデータ取得
    response: estimationResponses(where: {id: $responseId}) @skip(if: $isResponseSkip) {
      edges {
        node {
          id
          importantNotes
          details {
            type
            item {
              id
              name
              quantity
              unitPrice
              specJSON
            }
          }
          assignee {
            supplier {
              id
              name
            }
            estimationRequest {
              id
              company {
                id
                name
              }
              internalAssignees {
                user {
                  id
                  profile {
                    lastName
                    firstName
                  }
                }
              }
            }
          }
        }
      }
    }

    # 見積複製時のデータ取得
    originalEstimation: estimations(where: {id: $originalEstimateId}) @skip(if: $isOriginalEstimateSkip) {
      edges {
        node {
          id
          demand: company {
            id
            name
          }
          supplier {
            id
            name
          }
          title
          detailMessage
          expirationPeriod
          showAmountSummary
          createdAt
          details(orderBy: {direction: ASC, field: ORDER_NUMBER}) {
            edges {
              node {
                type
                orderNumber
                documentDisplayStatus
                cost {
                  name
                  note
                  unitPrice
                  unitSellingPrice
                  quantity
                  taxCategory {
                    id
                    rate
                  }
                }
                item {
                  name
                  category
                  taxCategory {
                    id
                    rate
                  }
                  unitPrice
                  unitSellingPrice
                  quantity
                  cardboard {
                    size
                    type
                    material
                    thickness
                    printingColor
                    processing
                    other
                  }
                  flexiblePackage {
                    size
                    type
                    material
                    printingColor
                    processing
                    other
                  }
                  giftBox {
                    size
                    type
                    paperType
                    printingColor
                    processing
                    other
                  }
                  paperBag {
                    size
                    paperType
                    printingColor
                    processing
                    handle
                    other
                  }
                  other {
                    specText
                  }
                }
              }
            }
          }
          internalAssignees {
            user {
              id
              profile {
                lastName
                firstName
              }
            }
          }
        }
      }
    }

    tax: taxCategories {
      edges {
        node {
          id
          name
          rate
        }
      }
    },

    currentUser {
      userID
      firstName
      lastName
      phoneNumber
    }
  }
`;

type ResponseType = NonNullable<
  NonNullable<NonNullable<layout_EstimationCreateLayoutQuery$data['response']>['edges']>[0]
>['node'];

type OriginalEstimationType = NonNullable<
  NonNullable<
    NonNullable<layout_EstimationCreateLayoutQuery$data['originalEstimation']>['edges']
  >[0]
>['node'];

const getDefaultValue = ({
  tax,
  currentUser,
  response,
  originalEstimation,
}: {
  tax: {
    id: string;
    rate: number;
  };
  currentUser: {
    userID: string;
    firstName: string;
    lastName: string;
  };
  response?: ResponseType;
  originalEstimation?: OriginalEstimationType;
}) => {
  if (originalEstimation) {
    return mapEstimationToEstimationForm(originalEstimation);
  }

  if (response) {
    return mapResponseToEstimationForm({
      demand: {
        id: response.assignee.estimationRequest.company.id,
        name: response.assignee.estimationRequest.company.name,
      },
      supplier: {
        id: response.assignee.supplier.id,
        name: response.assignee.supplier.name,
      },
      importantNotes: response.importantNotes,
      assignees:
        response.assignee.estimationRequest.internalAssignees?.map((assignee) => ({
          id: assignee.user.id,
          firstName: assignee.user.profile?.[0].firstName ?? '',
          lastName: assignee.user.profile?.[0]?.lastName ?? '',
        })) ?? [],
      details:
        response.details?.map((detail) => ({
          id: detail.item?.id ?? '',
          name: detail.item?.name ?? '',
          unitPrice: detail.item?.unitPrice ?? 0,
          quantity: Number(detail.item?.quantity) ?? 0,
          specJSON: detail.item?.specJSON ?? {},
        })) || [],
      tax,
    });
  }

  return createDefaultEstimationForm({
    tax,
    currentUser,
  });
};

export const EstimationCreateLayout = () => {
  const [searchParams] = useSearchParams();
  const responseId = searchParams.get('responseId');
  const originalEstimateId = searchParams.get('originalEstimateId');

  const { tax, currentUser, response, originalEstimation } =
    useLazyLoadQuery<layout_EstimationCreateLayoutQuery>(
      estimationCreateLayoutQuery,
      {
        responseId: responseId || '',
        isResponseSkip: !responseId,
        originalEstimateId: originalEstimateId || '',
        isOriginalEstimateSkip: !originalEstimateId,
      },
      { fetchPolicy: 'network-only' },
    );

  const sourceResponse = response?.edges?.[0]?.node;
  const sourceOriginalEstimation = originalEstimation?.edges?.[0]?.node;

  const form = useForm<EstimationFormType>({
    resolver: zodResolver(estimationFormSchema),
    defaultValues: getDefaultValue({
      tax: {
        id: tax?.edges?.[0]?.node?.id ?? '',
        rate: tax?.edges?.[0]?.node?.rate ?? 0,
      },
      currentUser,
      response: sourceResponse,
      originalEstimation: sourceOriginalEstimation,
    }),
  });

  return (
    <VStack spacing={6} alignItems="flex-start">
      <FormProvider {...form}>
        <Outlet />
      </FormProvider>
    </VStack>
  );
};
