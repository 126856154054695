import { Flex } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { EstimationFormV2 } from 'features/estimations/components/EstimationForm';
import { edit_EstimationEditPageV2Query } from 'gql/__generated__/edit_EstimationEditPageV2Query.graphql';
import { useNavigationBlocker } from 'hooks/useNavigationBlocker';
import { useLazyLoadQuery } from 'react-relay';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';

const EstimationEditPageV2Query = graphql`
  query edit_EstimationEditPageV2Query {
    ...EstimationFormV2Fragment
  }
`;

export const EstimationEditPageV2 = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const query = useLazyLoadQuery<edit_EstimationEditPageV2Query>(
    EstimationEditPageV2Query,
    {},
    { fetchPolicy: 'network-only' },
  );

  const { allowNavigation } = useNavigationBlocker();

  const handleFormConfirm = () => {
    allowNavigation();
    navigate(paths.estimations.edit._estimationId(id).confirm.url());
  };

  return (
    <>
      <Flex justify="space-between" width="768px">
        <RouterLink to={paths.estimations.url()}>
          <PageBack />
        </RouterLink>
      </Flex>
      <SubHeading label="見積書の編集" />
      <EstimationFormV2 queryRef={query} navigateToConfirm={handleFormConfirm} />
    </>
  );
};
