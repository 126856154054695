import { DEMAND_ITEM_CATEGORY } from 'features/demandItem/models';
import { SalesOrderNodeType } from 'features/order/type';
import { ZodOrderFormData } from 'features/order/zod';
import { SALES_ORDER_DETAIL_TYPE } from 'features/salesOrdersV2/models';
import { concatFullName } from 'utils/label';

const defaultDetailMessage = (
  companyInfo: { name: string; fullAddress: string },
  assignees: { lastName: string; phoneNumber: string | null; email: string }[],
) => {
  const assigneesInfo = assignees.map((assignee) => {
    return `${assignee.lastName} TEL: ${assignee.phoneNumber || '-'} EMAIL: ${assignee.email}`;
  });

  return `荷主：
${companyInfo.fullAddress}
${companyInfo.name}
${assigneesInfo.join('\n')}

その他：
新規発注時はshizai宛に量産見本を3枚お送りください。
  ※リピート発注時は不要です
出来高生産など、発注数量に対して増産減産がある場合は必ずshizai担当者に事前連絡ください。
`;
};

type SalesOrderDetailType = NonNullable<
  NonNullable<SalesOrderNodeType['details']>['edges']
>[number];
type SalesOrderDetailNodeType = NonNullable<SalesOrderDetailType>['node'];
type ItemType = NonNullable<NonNullable<SalesOrderDetailNodeType>['item']>;
type CardboardType = NonNullable<NonNullable<ItemType['cardboard']>>;
type FlexiblePackageType = NonNullable<NonNullable<ItemType['flexiblePackage']>>;
type GiftBoxType = NonNullable<NonNullable<ItemType['giftBox']>>;
type PaperBagType = NonNullable<NonNullable<ItemType['paperBag']>>;

const buildCardboardSpecText = (spec: CardboardType) => {
  const specText = [];

  if (spec.size) {
    specText.push(`サイズ：${spec.size}`);
  }

  if (spec.type) {
    specText.push(`形状：${spec.type}`);
  }

  if (spec.material) {
    specText.push(`構成：${spec.material}`);
  }

  if (spec.thickness) {
    specText.push(`段厚：${spec.thickness}`);
  }

  if (spec.printingColor) {
    specText.push(`印刷：${spec.printingColor}`);
  }

  if (spec.processing) {
    specText.push(`加工：${spec.processing}`);
  }

  if (spec.other) {
    specText.push(`その他の仕様：${spec.other}`);
  }

  return specText.join('\n');
};

const buildFlexiblePackageSpecText = (spec: FlexiblePackageType) => {
  const specText = [];

  if (spec.size) {
    specText.push(`サイズ：${spec.size}`);
  }

  if (spec.type) {
    specText.push(`形状：${spec.type}`);
  }

  if (spec.material) {
    specText.push(`構成：${spec.material}`);
  }

  if (spec.printingColor) {
    specText.push(`印刷：${spec.printingColor}`);
  }

  if (spec.processing) {
    specText.push(`加工：${spec.processing}`);
  }

  if (spec.other) {
    specText.push(`その他の仕様：${spec.other}`);
  }

  return specText.join('\n');
};

const buildGiftBoxSpecText = (spec: GiftBoxType) => {
  const specText = [];

  if (spec.size) {
    specText.push(`サイズ：${spec.size}`);
  }

  if (spec.paperType) {
    specText.push(`用紙：${spec.paperType}`);
  }

  if (spec.printingColor) {
    specText.push(`印刷：${spec.printingColor}`);
  }

  if (spec.processing) {
    specText.push(`加工：${spec.processing}`);
  }

  if (spec.other) {
    specText.push(`その他の仕様：${spec.other}`);
  }

  return specText.join('\n');
};

const buildPaperBagSpecText = (spec: PaperBagType) => {
  const specText = [];

  if (spec.size) {
    specText.push(`サイズ：${spec.size}`);
  }

  if (spec.paperType) {
    specText.push(`用紙：${spec.paperType}`);
  }

  if (spec.printingColor) {
    specText.push(`印刷：${spec.printingColor}`);
  }

  if (spec.processing) {
    specText.push(`加工：${spec.processing}`);
  }

  if (spec.handle) {
    specText.push(`持ち手：${spec.handle}`);
  }

  if (spec.other) {
    specText.push(`その他の仕様：${spec.other}`);
  }

  return specText.join('\n');
};

const buildItemName = (item: ItemType) => {
  const name = item.name;

  if (item.category === DEMAND_ITEM_CATEGORY.Cardboard && item.cardboard) {
    const specText = buildCardboardSpecText(item.cardboard);
    if (specText.length > 0) {
      return [name, specText].join('\n');
    }
    return name;
  }

  if (item.category === DEMAND_ITEM_CATEGORY.FlexiblePackage && item.flexiblePackage) {
    const specText = buildFlexiblePackageSpecText(item.flexiblePackage);
    if (specText.length > 0) {
      return [name, specText].join('\n');
    }
    return name;
  }

  if (item.category === DEMAND_ITEM_CATEGORY.GiftBox && item.giftBox) {
    const specText = buildGiftBoxSpecText(item.giftBox);
    if (specText.length > 0) {
      return [name, specText].join('\n');
    }
    return name;
  }

  if (item.category === DEMAND_ITEM_CATEGORY.PaperBag && item.paperBag) {
    const specText = buildPaperBagSpecText(item.paperBag);
    if (specText.length > 0) {
      return [name, specText].join('\n');
    }
    return name;
  }

  if (item.category === DEMAND_ITEM_CATEGORY.Other && item.other) {
    if (item.other.specText) {
      return [name, `その他の仕様：${item.other.specText}`].join('\n');
    }
    return name;
  }

  return name;
};

export const mapSalesOrderToOrderForm = ({
  salesOrder,
  companyInfo,
}: {
  salesOrder?: SalesOrderNodeType | null;
  companyInfo: { name: string; fullAddress: string };
}): ZodOrderFormData | null => {
  if (!salesOrder) return null;

  const details = (salesOrder.details.edges || [])
    .map((edge) => edge?.node)
    .filter((v): v is NonNullable<typeof v> => v != null);

  const internalAssignees =
    salesOrder.internalAssignees?.map((assignee) => ({
      value: assignee.user.id,
      label: concatFullName({
        lastName: assignee.user?.profile?.[0].lastName || '',
        firstName: assignee.user?.profile?.[0].firstName || '',
      }),
    })) || [];

  return {
    company: {
      id: salesOrder.demand.id,
      name: salesOrder.demand.name,
    },
    supplier: {
      id: salesOrder.supplier.id,
      name: salesOrder.supplier.name,
    },
    title: '',
    detailMessage: defaultDetailMessage(
      companyInfo,
      salesOrder.internalAssignees?.map((assignee) => ({
        lastName: assignee.user.profile?.[0].lastName || '',
        phoneNumber: assignee.user.profile?.[0].phoneNumber || null,
        email: assignee.user.email,
      })) || [],
    ),
    deliveryInfo: {},
    salesOrderId: salesOrder.id,
    details: [
      ...details
        .map((detail) => {
          if (detail.type === SALES_ORDER_DETAIL_TYPE.Item) {
            const item = detail.item;
            if (!item) return null;

            return {
              name: buildItemName(item),
              unitPrice: item.unitPrice,
              quantity: item.quantity,
              tax: {
                id: item.taxCategory.id,
                rate: item.taxCategory.rate,
              },
              salesOrderDetailId: detail.id,
            };
          } else if (detail.type === SALES_ORDER_DETAIL_TYPE.OnetimeCost) {
            const onetimeCost = detail.onetimeCost;
            if (!onetimeCost) return null;

            const name =
              onetimeCost.note && onetimeCost.note.length > 0
                ? [onetimeCost.name, `補足：${onetimeCost.note}`].join('\n')
                : onetimeCost.name;

            return {
              name,
              unitPrice: onetimeCost.unitPrice,
              quantity: onetimeCost.quantity,
              tax: {
                id: onetimeCost.taxCategory.id,
                rate: onetimeCost.taxCategory.rate,
              },
              salesOrderDetailId: detail.id,
            };
          } else if (detail.type === SALES_ORDER_DETAIL_TYPE.RecurringCost) {
            const recurringCost = detail.recurringCost;
            if (!recurringCost) return null;

            const name =
              recurringCost.note && recurringCost.note.length > 0
                ? [recurringCost.name, `補足：${recurringCost.note}`].join('\n')
                : recurringCost.name;

            return {
              name,
              unitPrice: recurringCost.unitPrice,
              quantity: recurringCost.quantity,
              tax: {
                id: recurringCost.taxCategory.id,
                rate: recurringCost.taxCategory.rate,
              },
              salesOrderDetailId: detail.id,
            };
          }

          return null;
        })
        .filter((v): v is NonNullable<typeof v> => v != null),
    ],
    internalAssignees,
  };
};
