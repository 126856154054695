import { useCallback, useEffect, useRef } from 'react';
import { useBlocker, useNavigate } from 'react-router-dom';

export const useNavigationBlocker = (
  shouldBlock = true,
  shouldBrowserBackBlock = true,
  message = '入力内容が保存されていません。このページを離れますか？',
) => {
  const navigate = useNavigate();
  const bypassBlockRef = useRef(false);
  const pendingNavigationRef = useRef<string | null>(null);

  // ナビゲーションブロックを一時的に許可する関数(submitボタンなどで使用)
  const allowNavigation = useCallback(() => {
    bypassBlockRef.current = true;
  }, []);

  const blocker = useBlocker(({ currentLocation, nextLocation, historyAction }) => {
    if (bypassBlockRef.current) {
      bypassBlockRef.current = false;
      return false;
    }

    if (!shouldBrowserBackBlock && historyAction === 'POP') {
      return false;
    }

    // 現在のパスと次のパスが異なる場合のみブロック
    return shouldBlock && currentLocation.pathname !== nextLocation.pathname;
  });

  // ページ遷移時のブロック処理
  useEffect(() => {
    if (blocker.state === 'blocked') {
      pendingNavigationRef.current = blocker.location.pathname;
      const confirmed = window.confirm(message);

      if (confirmed) {
        bypassBlockRef.current = true;
        navigate(pendingNavigationRef.current);
      } else {
        pendingNavigationRef.current = null;
      }
    }
  }, [blocker, message, navigate]);

  // リロード・ブラウザ離脱時のブロック処理
  useEffect(() => {
    if (!shouldBlock) return;

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (bypassBlockRef.current) {
        return;
      }
      event.preventDefault();
      return message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldBlock, message]);

  return {
    isBlocking: blocker.state === 'blocked',
    allowNavigation,
  };
};
