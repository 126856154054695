import { Box, HStack, Heading, Text, VStack } from '@chakra-ui/react';
import { EfferentFour } from '@icon-park/react';
import { Link } from 'components/Link';
import { SubHeading } from 'components/SubHeading';
import { DemandItemPriceTable } from 'features/demandItem/components/DemandItemPriceTable';
import { DEMAND_ITEM_CATEGORY_LABEL, DemandItemCategory } from 'features/demandItem/models';
import {
  MASTER_REGISTRATION_SETTING_COST_TYPE_ONE_TIME,
  MASTER_REGISTRATION_SETTING_ITEM_TYPE,
  NewSalesOrderFormType,
} from 'features/salesOrdersV2/form';
import { useFormContext } from 'react-hook-form';
import { paths } from 'utils/paths';
import { MasterRegistrationSettingInfo } from '../../../master-registration-setting/components/MasterRegistrationSettingInfo';
import { MasterRegistrationSettingSpecInfoTable } from '../../../master-registration-setting/components/MasterRegistrationSettingSpecInfoTable';

export const NewSalesOrderMasterRegistrationConfirm = () => {
  const form = useFormContext<NewSalesOrderFormType>();
  const { masterRegistrationItems, masterRegistrationCosts, supplier } = form.getValues();

  const masterRegistrationCostsWithoutOnetime = masterRegistrationCosts?.filter(
    (cost) => cost.type !== MASTER_REGISTRATION_SETTING_COST_TYPE_ONE_TIME,
  );

  const hasMasterRegistrationItems = masterRegistrationItems && masterRegistrationItems.length > 0;
  const hasMasterRegistrationCosts =
    masterRegistrationCostsWithoutOnetime && masterRegistrationCostsWithoutOnetime.length > 0;

  if (!hasMasterRegistrationItems && !hasMasterRegistrationCosts) {
    return null;
  }

  return (
    <VStack w="1024px" spacing={6} alignItems="flex-start">
      <SubHeading label="マスタへの登録" />
      {hasMasterRegistrationItems && (
        <VStack w="full" spacing={4} alignItems="flex-start">
          <Heading fontSize="xl" fontWeight="bold">
            商品
          </Heading>
          <VStack w="full" spacing={8} alignItems="flex-start">
            {masterRegistrationItems?.map((item, index) => (
              <VStack key={item.name} w="full" spacing={4}>
                <MasterRegistrationSettingSpecInfoTable
                  name={item.name}
                  category={item.category as DemandItemCategory}
                  spec={item.spec}
                />
                <HStack spacing={4} alignItems="flex-start" justify="space-between" w="100%" px={4}>
                  <MasterRegistrationSettingInfo
                    values={[
                      { label: 'サプライヤー', node: supplier.name },
                      {
                        label: 'カテゴリー',
                        node:
                          DEMAND_ITEM_CATEGORY_LABEL[item.category as DemandItemCategory]
                            .categoryName || '-',
                      },
                      {
                        label: '商品区分',
                        node: (
                          <HStack gap={0}>
                            <Text>
                              {
                                MASTER_REGISTRATION_SETTING_ITEM_TYPE[
                                  item.type as keyof typeof MASTER_REGISTRATION_SETTING_ITEM_TYPE
                                ]
                              }
                            </Text>
                            {item.demandItem?.id && (
                              <HStack gap={0}>
                                <Text>：</Text>
                                <Link
                                  to={paths.demands
                                    ._demandCode(item.demandItem.demandCode)
                                    .items._itemCode({
                                      itemNumber: Number(item.demandItem.itemNumber),
                                      category: item.demandItem.category as DemandItemCategory,
                                      version: Number(item.demandItem.version),
                                    })
                                    .url()}
                                  target="_blank"
                                >
                                  <HStack gap={1}>
                                    <Box>{item.demandItem.name}</Box>
                                    <EfferentFour theme="outline" />
                                  </HStack>
                                </Link>
                              </HStack>
                            )}
                          </HStack>
                        ),
                      },
                    ]}
                  />
                  <VStack w="382px" justifyContent="flex-end">
                    <DemandItemPriceTable
                      values={item.prices.map((price) => ({
                        id: index.toString(),
                        unitPrice: Number(price.unitPrice),
                        unitSellingPrice: Number(price.unitSellingPrice),
                        quantity: Number(price.quantity),
                      }))}
                      tax={item.tax.name}
                    />
                  </VStack>
                </HStack>
              </VStack>
            ))}
          </VStack>
        </VStack>
      )}

      {hasMasterRegistrationCosts && (
        <VStack w="full" spacing={4} alignItems="flex-start">
          <Heading fontSize="xl" fontWeight="bold">
            費用
          </Heading>
          <VStack w="full" spacing={8} alignItems="flex-start">
            {masterRegistrationCostsWithoutOnetime?.map((cost, index) => (
              <VStack key={cost.name} w="full" spacing={4}>
                <MasterRegistrationSettingSpecInfoTable
                  name={cost.name}
                  category="cost"
                  spec={{ cost: { note: cost.note } }}
                />
                <HStack spacing={4} alignItems="flex-start" justify="space-between" w="100%" px={4}>
                  <MasterRegistrationSettingInfo
                    values={[
                      { label: 'サプライヤー', node: supplier.name },
                      {
                        label: '社内メモ',
                        node: cost.memo || '-',
                      },
                    ]}
                  />
                  <VStack minW="382px" justifyContent="flex-end">
                    <DemandItemPriceTable
                      values={cost.prices.map((price) => ({
                        id: index.toString(),
                        unitPrice: Number(price.unitPrice),
                        unitSellingPrice: Number(price.unitSellingPrice),
                        quantity: Number(price.quantity),
                      }))}
                      tax={cost.tax.name}
                    />
                  </VStack>
                </HStack>
              </VStack>
            ))}
          </VStack>
        </VStack>
      )}
    </VStack>
  );
};
