import {
  useDownloadEstimationPdfV2Query$data,
  useDownloadEstimationPdfV2Query as useDownloadEstimationPdfV2QueryType,
} from 'gql/__generated__/useDownloadEstimationPdfV2Query.graphql';
import { VersionWatcherContext } from 'hooks/useVersionWatcher';
import RelayEnvironment from 'lib/relay/RelayEnvironment';
import { useContext } from 'react';
import { fetchQuery, graphql } from 'relay-runtime';

const useDownloadEstimationPdfV2Query = graphql`
  query useDownloadEstimationPdfV2Query ($id: ID!) {
    downloadEstimationPdf(id: $id)
  }
`;

export const useDownloadEstimationPdfV2 = () => {
  const versionWatcher = useContext(VersionWatcherContext);

  const downloadEstimationPdf = async (id: string) => {
    const enableSoftDelete = versionWatcher.disableSoftDeleteEffect();
    let data: useDownloadEstimationPdfV2Query$data | undefined = undefined;
    try {
      data = await fetchQuery<useDownloadEstimationPdfV2QueryType>(
        RelayEnvironment,
        useDownloadEstimationPdfV2Query,
        {
          id,
        },
      ).toPromise();
    } catch (_error) {
      // nop: useErrorHandlableRelayEnvironmentでエラー処理している
      return;
    } finally {
      enableSoftDelete();
    }

    window.open(data?.downloadEstimationPdf, '_blank', 'noopener, noreferrer');
  };

  return {
    downloadEstimationPdf,
  };
};
