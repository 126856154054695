import { zodResolver } from '@hookform/resolvers/zod';
import { DETAIL_MESSAGE_TEMPLATE } from 'features/salesOrders/form';
import {
  SalesOrdersReorderFormType,
  salesOrdersReorderFormSchema,
} from 'features/salesOrdersV2/form';
import { layout_SalesOrdersReorderLayoutQuery } from 'gql/__generated__/layout_SalesOrdersReorderLayoutQuery.graphql';
import { FormProvider, useForm } from 'react-hook-form';
import { useLazyLoadQuery } from 'react-relay';
import { Outlet } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { dateFormatHyphenDate } from 'utils/date';
import { concatFullName } from 'utils/label';

const salesOrdersReorderLayoutQuery = graphql`
  query layout_SalesOrdersReorderLayoutQuery {
    currentUser {
      userID
      firstName
      lastName
    }
  }
`;

export const SalesOrdersReorderLayout = () => {
  const { currentUser } = useLazyLoadQuery<layout_SalesOrdersReorderLayoutQuery>(
    salesOrdersReorderLayoutQuery,
    {},
    { fetchPolicy: 'network-only' },
  );

  const form = useForm<SalesOrdersReorderFormType>({
    resolver: zodResolver(salesOrdersReorderFormSchema),
    defaultValues: {
      demand: { id: '', name: '' },
      supplier: { id: '', name: '' },
      title: '',
      receivedDate: dateFormatHyphenDate(),
      details: [],
      detailMessage: DETAIL_MESSAGE_TEMPLATE,
      memo: '',
      internalAssignees: [
        {
          value: currentUser.userID,
          label: concatFullName({
            lastName: currentUser.lastName,
            firstName: currentUser.firstName,
          }),
        },
      ],
    },
  });

  return (
    <FormProvider {...form}>
      <Outlet />
    </FormProvider>
  );
};
