import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { NewSalesOrderDetailFormType } from 'features/salesOrdersV2/form';
import { NewSalesOrderFormType } from 'features/salesOrdersV2/form';
import { SalesOrderSpec } from 'features/salesOrdersV2/models';
import { masterRegistrationSetting_Query } from 'gql/__generated__/masterRegistrationSetting_Query.graphql';
import { useNavigationBlocker } from 'hooks/useNavigationBlocker';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';
import {
  MasterRegistrationSettingEstimationDetailType,
  mergeEstimationDetailCosts,
  mergeEstimationDetailItems,
  normalizeSalesOrderCosts,
  normalizeSalesOrderItems,
} from '../utils/normalizer';
import { MasterRegistrationSettingCostMemo } from './components/MasterRegistrationSettingCostMemo';
import { MasterRegistrationSettingInfo } from './components/MasterRegistrationSettingInfo';
import { MasterRegistrationSettingItem } from './components/MasterRegistrationSettingItem';
import { MasterRegistrationSettingCostPriceTable } from './components/MasterRegistrationSettingPriceTable';
import { MasterRegistrationSettingCostRadioGroup } from './components/MasterRegistrationSettingRadioGroup';
import { MasterRegistrationSettingRequiredFieldLabel } from './components/MasterRegistrationSettingRequiredFieldLabel';
import { MasterRegistrationSettingSpecInfoTable } from './components/MasterRegistrationSettingSpecInfoTable';

const masterRegistrationSettingQuery = graphql`
  query masterRegistrationSetting_Query($estimationId: ID!) {
    estimations(where: {id: $estimationId}) {
      edges {
        node {
          id
          details(orderBy: {direction: ASC, field: ORDER_NUMBER}) {
            edges {
              node {
                id
                type
                orderNumber
                cost {
                  id
                  name
                  note
                  unitPrice
                  unitSellingPrice
                  quantity
                  tax: taxCategory {
                    id
                    rate
                  }
                }
                item {
                  id
                  name
                  category
                  tax: taxCategory {
                    id
                    rate
                  }
                  unitPrice
                  unitSellingPrice
                  quantity
                  cardboard {
                    size
                    type
                    material
                    thickness
                    printingColor
                    processing
                    other
                  }
                  flexiblePackage {
                    size
                    type
                    material
                    printingColor
                    processing
                    other
                  }
                  giftBox {
                    size
                    type
                    paperType
                    printingColor
                    processing
                    other
                  }
                  paperBag {
                    size
                    paperType
                    printingColor
                    processing
                    handle
                    other
                  }
                  other {
                    specText
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const aggregateRegistration = ({
  demandId,
  supplier,
  salesOrderDetails,
  estimationDetails,
}: {
  demandId: string;
  supplier: {
    id: string;
    name: string;
  };
  salesOrderDetails: NewSalesOrderDetailFormType[];
  estimationDetails: MasterRegistrationSettingEstimationDetailType;
}) => {
  const normalizedSalesOrderItems = normalizeSalesOrderItems({
    demandId,
    supplier,
    salesOrderDetails,
  });

  const masterRegistrationItems = mergeEstimationDetailItems({
    normalizedSalesOrderItems,
    estimationDetails,
  });

  const normalizedSalesOrderCosts = normalizeSalesOrderCosts({
    supplier,
    salesOrderDetails,
  });

  const masterRegistrationCosts = mergeEstimationDetailCosts({
    normalizedSalesOrderCosts,
    estimationDetails,
  });

  return { masterRegistrationItems, masterRegistrationCosts };
};

export const NewSalesOrderMasterRegistrationSetting = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const newSalesOrderForm = useFormContext<NewSalesOrderFormType>();
  const { getValues, setValue, handleSubmit: onSubmit } = newSalesOrderForm;
  const salesOrderDetails = getValues('details');
  const supplier = getValues('supplier');
  const demandId = getValues('demand.id');

  const query = useLazyLoadQuery<masterRegistrationSetting_Query>(
    masterRegistrationSettingQuery,
    { estimationId: id },
    { fetchPolicy: 'network-only' },
  );
  const { estimations } = query;

  if (!estimations?.edges?.[0]?.node) {
    throw new Error('Estimations not found in NewSalesOrderLayout');
  }

  const estimation = estimations.edges[0]?.node;

  const { masterRegistrationItems, masterRegistrationCosts } = aggregateRegistration({
    demandId,
    supplier,
    salesOrderDetails,
    estimationDetails: estimation?.details,
  });

  const { allowNavigation } = useNavigationBlocker();

  const handleSubmit = () => {
    allowNavigation();
    navigate(paths.estimations._estimationId(id).newSalesOrder.confirm.url());
  };

  const handleBack = () => {
    allowNavigation();
    navigate(paths.estimations._estimationId(id).newSalesOrder.url());
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!getValues('masterRegistrationItems')) {
      setValue(
        'masterRegistrationItems',
        Array.from(masterRegistrationItems.values()).map((item) => ({
          estimationDetailIds: item.estimationDetailIds,
          name: item.name,
          category: item.category,
          prices: item.prices,
          spec: item.spec as Omit<SalesOrderSpec, 'cost'>,
          tax: item.tax,
          type: '',
        })),
      );
    }
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!getValues('masterRegistrationCosts')) {
      setValue(
        'masterRegistrationCosts',
        Array.from(masterRegistrationCosts.values()).map((cost) => ({
          estimationDetailIds: cost.estimationDetailIds,
          name: cost.name,
          note: cost.note,
          prices: cost.prices,
          tax: cost.tax,
          type: '',
        })),
      );
    }
  }, []);

  return (
    <>
      <Flex justify="space-between" width="768px">
        <Box onClick={handleBack}>
          <PageBack />
        </Box>
      </Flex>

      <VStack spacing={2} alignItems="flex-start">
        <SubHeading label="マスタへの登録" />
        <UnorderedList fontSize="sm" color="gray.600">
          <ListItem>
            商品と継続費用をマスタに登録し、リピート発注時に情報を呼び出せるようにします
          </ListItem>
          <ListItem>
            仕様変更の場合は変更前の商品を選択し、情報を上書きしてください（過去データは履歴として残ります）
          </ListItem>
          <ListItem>単価表に不要な情報がある場合「登録」のチェックを外してください</ListItem>
        </UnorderedList>
      </VStack>

      <VStack
        as="form"
        w="1024px"
        spacing={6}
        alignItems="flex-start"
        onSubmit={onSubmit(handleSubmit)}
      >
        {masterRegistrationItems.size > 0 && (
          <VStack w="full" spacing={4} alignItems="flex-start">
            <Heading fontSize="xl" fontWeight="bold">
              商品
            </Heading>
            <VStack w="full" spacing={8} alignItems="flex-start">
              {Array.from(masterRegistrationItems.entries()).map(([key, value], index) => (
                <MasterRegistrationSettingItem
                  key={key}
                  index={index}
                  value={value}
                  supplier={supplier}
                />
              ))}
            </VStack>
          </VStack>
        )}

        {masterRegistrationCosts.size > 0 && (
          <VStack w="full" spacing={4} alignItems="flex-start">
            <Heading fontSize="xl" fontWeight="bold">
              費用
            </Heading>
            <VStack w="full" spacing={8} alignItems="flex-start">
              {Array.from(masterRegistrationCosts.entries()).map(([key, value], index) => (
                <VStack key={key} w="full" spacing={4}>
                  <MasterRegistrationSettingSpecInfoTable
                    name={value.name}
                    category="cost"
                    spec={{ cost: { note: value.note } }}
                  />
                  <HStack
                    spacing={4}
                    alignItems="flex-start"
                    justify="space-between"
                    w="100%"
                    px={4}
                  >
                    <VStack spacing={4} w="538px" maxW="538px" alignItems="flex-start">
                      <MasterRegistrationSettingInfo
                        values={[
                          { label: 'サプライヤー', node: supplier.name },
                          {
                            label: 'カテゴリー',
                            node: '費用',
                          },
                          {
                            label: <MasterRegistrationSettingRequiredFieldLabel label="費用区分" />,
                            node: <MasterRegistrationSettingCostRadioGroup index={index} />,
                          },
                        ]}
                      />
                      <MasterRegistrationSettingCostMemo index={index} />
                    </VStack>
                    <VStack justifyContent="flex-end">
                      <MasterRegistrationSettingCostPriceTable
                        index={index}
                        priceValues={value.prices.map((price) => ({
                          unitPrice: Number(price.unitPrice),
                          unitSellingPrice: Number(price.unitSellingPrice),
                          quantity: Number(price.quantity),
                        }))}
                      />
                      <HStack width="100%" justifyContent="flex-end">
                        <Text fontSize="xs" color="gray.500">
                          税区分：{value.tax.rate}%
                        </Text>
                      </HStack>
                    </VStack>
                  </HStack>
                </VStack>
              ))}
            </VStack>
          </VStack>
        )}

        <Button colorScheme="blue" w="full" type="submit">
          次へ
        </Button>
      </VStack>
    </>
  );
};
