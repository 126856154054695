import { SalesOrdersReorderFormType } from 'features/salesOrdersV2/form';
import { useFormContext, useWatch } from 'react-hook-form';
import { SalesOrdersFormPrices } from '../SalesOrdersFormPrices';

export const SalesOrdersReorderFormPrices = () => {
  const { control } = useFormContext<SalesOrdersReorderFormType>();
  const details = useWatch({ control, name: 'details' });

  return <SalesOrdersFormPrices details={details} />;
};
