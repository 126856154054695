import { DEMAND_ITEM_CATEGORY } from 'features/demandItem/models';
import {
  SalesOrdersEditFormDetailCostType,
  SalesOrdersEditFormDetailItemType,
  SalesOrdersEditFormDetailType,
} from 'features/salesOrdersV2/form';
import { SalesOrderSpec } from 'features/salesOrdersV2/models';
import { SalesOrderDetailType } from 'gql/graphql.types';
import { dateFormatHyphenDate } from 'utils/date';
import {
  SalesOrdersEditLayoutQueryDetailItemType,
  SalesOrdersEditLayoutQueryDetailNodeType,
  SalesOrdersEditLayoutQueryNodeType,
} from '../layout';

export const buildSalesOrdersEditFormDefaultDetails = (
  salesOrder: SalesOrdersEditLayoutQueryNodeType | undefined,
): SalesOrdersEditFormDetailType[] => {
  if (!salesOrder) {
    return [];
  }

  return (
    salesOrder?.details.edges
      ?.map((queryDetail) => {
        const node = queryDetail?.node;
        if (!node) {
          return undefined;
        }

        const formDetail = buildDetail(salesOrder.demand, salesOrder.supplier, node);
        return formDetail;
      })
      .filter((detail) => detail !== undefined) ?? []
  );
};

const buildDetail = (
  demand: NonNullable<SalesOrdersEditLayoutQueryNodeType>['demand'],
  supplier: NonNullable<SalesOrdersEditLayoutQueryNodeType>['supplier'],
  node: SalesOrdersEditLayoutQueryDetailNodeType,
): SalesOrdersEditFormDetailType | undefined => {
  if (!node) {
    return undefined;
  }

  const fixedDeliveryDate = node.deliveryEvents?.edges?.[0]?.node?.fixedDeliveryDate;

  if (node.type === SalesOrderDetailType.Item) {
    return {
      // ベースの明細行情報
      detailId: node.id,
      memo: node.memos?.map((memo) => memo.body)?.[0],
      fixedDeliveryDate: fixedDeliveryDate ? dateFormatHyphenDate(fixedDeliveryDate) : undefined,
      quantity: node.item?.quantity,
      unitPrice: node.item?.unitPrice,
      unitSellingPrice: node.item?.unitSellingPrice,
      tax: {
        id: node.item?.tax?.id,
        rate: node.item?.tax?.rate,
      },
      type: SalesOrderDetailType.Item,

      // 商品情報
      itemId: node.item?.demandItem?.id,
      priceId: undefined,
      supplier: supplier,
      name: node.item?.name,
      code: demand?.code ?? '',
      category: node.item?.category,
      itemNumber: node.item?.demandItem?.itemNumber.toString() ?? '',
      version: node.item?.demandItem?.version.toString() ?? '',
      spec: buildDetailItemSpec(node.item),
    } as SalesOrdersEditFormDetailItemType;
  }

  if (node.type === SalesOrderDetailType.RecurringCost) {
    return {
      // ベースの明細行情報
      detailId: node.id,
      memo: node.memos?.map((memo) => memo.body)?.[0],
      fixedDeliveryDate: fixedDeliveryDate ? dateFormatHyphenDate(fixedDeliveryDate) : undefined,
      quantity: node.recurringCost?.quantity,
      unitPrice: node.recurringCost?.unitPrice,
      unitSellingPrice: node.recurringCost?.unitSellingPrice,
      tax: {
        id: node.recurringCost?.tax?.id,
        rate: node.recurringCost?.tax?.rate,
      },
      type: SalesOrderDetailType.RecurringCost,

      // 継続費用情報
      costId: node.recurringCost?.demandRecurringCost?.id,
      priceId: undefined,
      supplier: supplier,
      name: node.recurringCost?.name,
      note: node.recurringCost?.note,
    } as SalesOrdersEditFormDetailCostType;
  }

  return {
    detailId: node.id,
    memo: node.memos?.map((memo) => memo.body)?.[0],
    fixedDeliveryDate: fixedDeliveryDate ? dateFormatHyphenDate(fixedDeliveryDate) : undefined,
    quantity: node.onetimeCost?.quantity,
    unitPrice: node.onetimeCost?.unitPrice,
    unitSellingPrice: node.onetimeCost?.unitSellingPrice,
    tax: {
      id: node.onetimeCost?.tax?.id,
      rate: node.onetimeCost?.tax?.rate,
    },
    type: SalesOrderDetailType.OnetimeCost,

    // 単発費用情報
    name: node.onetimeCost?.name,
    note: node.onetimeCost?.note,
  } as SalesOrdersEditFormDetailCostType;
};

const buildDetailItemSpec = (
  item: SalesOrdersEditLayoutQueryDetailItemType | undefined,
): SalesOrderSpec | undefined => {
  if (!item) {
    return undefined;
  }

  switch (item.category) {
    case DEMAND_ITEM_CATEGORY.Cardboard:
      return {
        cardboard: {
          size: item.cardboard?.size ?? '',
          type: item.cardboard?.type ?? '',
          material: item.cardboard?.material ?? '',
          thickness: item.cardboard?.thickness ?? '',
          printingColor: item.cardboard?.printingColor ?? '',
          processing: item.cardboard?.processing ?? '',
          other: item.cardboard?.other ?? '',
        },
      };
    case DEMAND_ITEM_CATEGORY.FlexiblePackage:
      return {
        flexiblePackage: {
          size: item.flexiblePackage?.size ?? '',
          type: item.flexiblePackage?.type ?? '',
          material: item.flexiblePackage?.material ?? '',
          printingColor: item.flexiblePackage?.printingColor ?? '',
          processing: item.flexiblePackage?.processing ?? '',
          other: item.flexiblePackage?.other ?? '',
        },
      };
    case DEMAND_ITEM_CATEGORY.GiftBox:
      return {
        giftBox: {
          size: item.giftBox?.size ?? '',
          type: item.giftBox?.type ?? '',
          paperType: item.giftBox?.paperType ?? '',
          printingColor: item.giftBox?.printingColor ?? '',
          processing: item.giftBox?.processing ?? '',
          other: item.giftBox?.other ?? '',
        },
      };
    case DEMAND_ITEM_CATEGORY.PaperBag:
      return {
        paperBag: {
          size: item.paperBag?.size ?? '',
          paperType: item.paperBag?.paperType ?? '',
          printingColor: item.paperBag?.printingColor ?? '',
          processing: item.paperBag?.processing ?? '',
          handle: item.paperBag?.handle ?? '',
          other: item.paperBag?.other ?? '',
        },
      };
    case DEMAND_ITEM_CATEGORY.Other:
      return {
        other: {
          specText: item.other?.specText ?? '',
        },
      };
  }
  return {};
};
