/**
 * @generated SignedSource<<87992b113e9317b08ffe720cdd60423d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DemandRecurringCostBlocksQuery$variables = {
  demandId: string;
};
export type DemandRecurringCostBlocksQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DemandRecurringCostBlocksRefetchFragment">;
};
export type DemandRecurringCostBlocksQuery = {
  response: DemandRecurringCostBlocksQuery$data;
  variables: DemandRecurringCostBlocksQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "demandId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DemandRecurringCostBlocksQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "demandId",
            "variableName": "demandId"
          }
        ],
        "kind": "FragmentSpread",
        "name": "DemandRecurringCostBlocksRefetchFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DemandRecurringCostBlocksQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": {
              "direction": "DESC",
              "field": "UPDATED_AT"
            }
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "demandID",
                "variableName": "demandId"
              },
              {
                "kind": "Literal",
                "name": "isCurrent",
                "value": true
              }
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "DemandRecurringCostConnection",
        "kind": "LinkedField",
        "name": "demandRecurringCosts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DemandRecurringCostEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DemandRecurringCost",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "note",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "costNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Supplier",
                    "kind": "LinkedField",
                    "name": "supplier",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DemandRecurringCostInternalMemo",
                    "kind": "LinkedField",
                    "name": "internalMemos",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "body",
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DemandRecurringCostPrice",
                    "kind": "LinkedField",
                    "name": "prices",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "quantity",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unitPrice",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unitSellingPrice",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "demand",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "tax",
                    "args": null,
                    "concreteType": "TaxCategory",
                    "kind": "LinkedField",
                    "name": "taxCategory",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 1
                      },
                      {
                        "kind": "Literal",
                        "name": "orderBy",
                        "value": {
                          "direction": "DESC",
                          "field": "CREATED_AT"
                        }
                      },
                      {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                          "hasSalesOrderDetailWith": {
                            "deletedAtIsNil": true,
                            "hasSalesOrderWith": {
                              "deletedAtIsNil": true,
                              "hasOrdersWith": {
                                "deletedAtIsNil": true
                              }
                            }
                          }
                        }
                      }
                    ],
                    "concreteType": "SalesOrderRecurringCostConnection",
                    "kind": "LinkedField",
                    "name": "salesOrderRecurringCosts",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SalesOrderRecurringCostEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SalesOrderRecurringCost",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SalesOrderDetail",
                                "kind": "LinkedField",
                                "name": "salesOrderDetail",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SalesOrder",
                                    "kind": "LinkedField",
                                    "name": "salesOrder",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Order",
                                        "kind": "LinkedField",
                                        "name": "orders",
                                        "plural": true,
                                        "selections": [
                                          (v1/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "title",
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      (v1/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v1/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "salesOrderRecurringCosts(first:1,orderBy:{\"direction\":\"DESC\",\"field\":\"CREATED_AT\"},where:{\"hasSalesOrderDetailWith\":{\"deletedAtIsNil\":true,\"hasSalesOrderWith\":{\"deletedAtIsNil\":true,\"hasOrdersWith\":{\"deletedAtIsNil\":true}}}})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6c5bb0cc78135a57a7aa06854fdae893",
    "id": null,
    "metadata": {},
    "name": "DemandRecurringCostBlocksQuery",
    "operationKind": "query",
    "text": "query DemandRecurringCostBlocksQuery(\n  $demandId: ID!\n) {\n  ...DemandRecurringCostBlocksRefetchFragment_RMyVA\n}\n\nfragment DemandRecurringCostBlocksRefetchFragment_RMyVA on Query {\n  demandRecurringCosts(where: {isCurrent: true, demandID: $demandId}, orderBy: {field: UPDATED_AT, direction: DESC}) {\n    edges {\n      node {\n        id\n        name\n        note\n        costNumber\n        supplier {\n          name\n          id\n        }\n        internalMemos {\n          body\n          id\n        }\n        prices {\n          id\n          quantity\n          unitPrice\n          unitSellingPrice\n        }\n        demand {\n          code\n          id\n        }\n        tax: taxCategory {\n          name\n          id\n        }\n        salesOrderRecurringCosts(where: {hasSalesOrderDetailWith: {deletedAtIsNil: true, hasSalesOrderWith: {deletedAtIsNil: true, hasOrdersWith: {deletedAtIsNil: true}}}}, orderBy: {direction: DESC, field: CREATED_AT}, first: 1) {\n          edges {\n            node {\n              salesOrderDetail {\n                salesOrder {\n                  orders {\n                    id\n                    title\n                  }\n                  id\n                }\n                id\n              }\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "64214fb769454def5ef7df1003efa2a5";

export default node;
