import { Box, BoxProps } from '@chakra-ui/react';
import { memo } from 'react';

type Props = {
  amount: string | number;
} & BoxProps;

export const ItemAmountPreview = memo(function ItemAmountPreview({ amount, ...props }: Props) {
  return (
    <Box
      bgColor="gray.50"
      px={4}
      py={2}
      minW="120px"
      height="40px"
      borderRadius="md"
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      {...props}
    >
      {amount.toLocaleString()}
    </Box>
  );
});
