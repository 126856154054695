/**
 * @generated SignedSource<<19daa9bdbf59da6d63a3dfb1efec3dcc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SalesOrdersEditFormDetailsQuery$variables = {
  detailIds: ReadonlyArray<string>;
  isSkip: boolean;
};
export type SalesOrdersEditFormDetailsQuery$data = {
  readonly salesOrderDetails?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null> | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"TaxSelectBoxFragment">;
};
export type SalesOrdersEditFormDetailsQuery = {
  response: SalesOrdersEditFormDetailsQuery$data;
  variables: SalesOrdersEditFormDetailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "detailIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isSkip"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "condition": "isSkip",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Literal",
              "name": "hasInvoiceDetailsWith",
              "value": {
                "deletedAtIsNil": true
              }
            },
            {
              "kind": "Variable",
              "name": "idIn",
              "variableName": "detailIds"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "SalesOrderDetailConnection",
      "kind": "LinkedField",
      "name": "salesOrderDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SalesOrderDetailEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SalesOrderDetail",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SalesOrdersEditFormDetailsQuery",
    "selections": [
      (v2/*: any*/),
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "TaxSelectBoxFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SalesOrdersEditFormDetailsQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "TaxCategoryConnection",
        "kind": "LinkedField",
        "name": "taxCategories",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TaxCategoryEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TaxCategory",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e83c52e2375d83bcc5e588b94c1c9259",
    "id": null,
    "metadata": {},
    "name": "SalesOrdersEditFormDetailsQuery",
    "operationKind": "query",
    "text": "query SalesOrdersEditFormDetailsQuery(\n  $detailIds: [ID!]!\n  $isSkip: Boolean!\n) {\n  salesOrderDetails(where: {idIn: $detailIds, hasInvoiceDetailsWith: {deletedAtIsNil: true}}) @skip(if: $isSkip) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n  ...TaxSelectBoxFragment\n}\n\nfragment TaxSelectBoxFragment on Query {\n  taxCategories {\n    edges {\n      node {\n        id\n        name\n        rate\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8fc9ba9b9c80634cf864a633e9d58da5";

export default node;
