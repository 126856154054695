import { HStack, Text, VStack, useDisclosure } from '@chakra-ui/react';
import { DEMAND_ITEM_CATEGORY_LABEL, DemandItemCategory } from 'features/demandItem/models';
import { MasterRegistrationItemType } from '../../../utils/normalizer';
import { MasterRegistrationSettingInfo } from '../MasterRegistrationSettingInfo';
import { MasterRegistrationSettingModal } from '../MasterRegistrationSettingModal';
import { MasterRegistrationSettingItemPriceTable } from '../MasterRegistrationSettingPriceTable';
import { MasterRegistrationSettingItemRadioGroup } from '../MasterRegistrationSettingRadioGroup';
import { MasterRegistrationSettingRequiredFieldLabel } from '../MasterRegistrationSettingRequiredFieldLabel';
import { MasterRegistrationSettingSpecInfoTable } from '../MasterRegistrationSettingSpecInfoTable';

type Props = {
  index: number;
  value: MasterRegistrationItemType;
  supplier: {
    id: string;
    name: string;
  };
};

export const MasterRegistrationSettingItem = ({ index, value, supplier }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <VStack w="full" spacing={4}>
        <MasterRegistrationSettingSpecInfoTable
          name={value.name}
          category={value.category as DemandItemCategory}
          spec={value.spec}
        />
        <HStack spacing={4} alignItems="flex-start" justify="space-between" w="100%" px={4}>
          <MasterRegistrationSettingInfo
            values={[
              { label: 'サプライヤー', node: supplier.name },
              {
                label: 'カテゴリー',
                node:
                  DEMAND_ITEM_CATEGORY_LABEL[value.category as DemandItemCategory].categoryName ||
                  '-',
              },
              {
                label: <MasterRegistrationSettingRequiredFieldLabel label="商品区分" />,
                node: (
                  <MasterRegistrationSettingItemRadioGroup index={index} onOpenModal={onOpen} />
                ),
              },
            ]}
          />
          <VStack justifyContent="flex-end">
            <MasterRegistrationSettingItemPriceTable
              index={index}
              priceValues={value.prices.map((price) => ({
                unitPrice: Number(price.unitPrice),
                unitSellingPrice: Number(price.unitSellingPrice),
                quantity: Number(price.quantity),
              }))}
            />
            <HStack width="100%" justifyContent="flex-end">
              <Text fontSize="xs" color="gray.500">
                税区分：{value.tax.name}
              </Text>
            </HStack>
          </VStack>
        </HStack>
      </VStack>

      <MasterRegistrationSettingModal
        isOpen={isOpen}
        onClose={onClose}
        demandId={value.demandId}
        category={value.category}
        index={index}
      />
    </>
  );
};
