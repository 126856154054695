import { Text } from '@chakra-ui/react';
import { DemandItemFormType } from 'features/demandItem/form';
import { useFormContext, useWatch } from 'react-hook-form';
import { calculateGrossProfitMargin } from 'utils/priceCalculation';

type Props = {
  index: number;
};

export const GrossProfitMarginText = ({ index }: Props) => {
  const form = useFormContext<DemandItemFormType>();
  const { unitPrice, unitSellingPrice } = useWatch({
    name: `prices.${index}`,
    control: form.control,
  });

  return (
    <Text fontSize="xs" color="gray.500" textAlign="right" mt={2}>
      粗利率: {calculateGrossProfitMargin(Number(unitPrice) || 0, Number(unitSellingPrice) || 0)}%
    </Text>
  );
};
