import {
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { Caution } from '@icon-park/react';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { DemandItemForm } from 'features/demandItem/components/DemandItemForm';
import { DemandItemFormType } from 'features/demandItem/form';
import { parseDemandItemCodeForUrl } from 'features/demandItem/models';
import { useFilterParameter } from 'features/demandItem/parameter/useFilterParameter';
import { edit_DemandItemEditPageQuery } from 'gql/__generated__/edit_DemandItemEditPageQuery.graphql';
import { useNavigationBlocker } from 'hooks/useNavigationBlocker';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { MissingCategoryError, MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';
import { DemandItemContext } from './context';
import { useVersionCheck } from './hooks';

const DemandItemEditPageQuery = graphql`
  query edit_DemandItemEditPageQuery {
    ...DemandItemFormFragment
  }
`;

export const DemandItemEditPage = () => {
  const navigate = useNavigate();
  const { demandCode, itemCode } = useParams();
  if (!demandCode) {
    throw new MissingRequiredParamError('demandCode');
  }
  if (!itemCode) {
    throw new MissingRequiredParamError('itemCode');
  }

  const { category, itemNumber } = parseDemandItemCodeForUrl(itemCode);
  if (!category) {
    throw new MissingCategoryError();
  }

  const demandItemContext = useContext(DemandItemContext);
  if (!demandItemContext) {
    throw new Error('demandItemContext is null');
  }
  const { isCurrentVersion } = demandItemContext;

  const location = useLocation();
  const { state } = location;
  const refererPathname = state?.pathname;

  const { itemCategories, itemSuppliers } = useFilterParameter();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const query = useLazyLoadQuery<edit_DemandItemEditPageQuery>(DemandItemEditPageQuery, {
    fetchPolicy: 'network-only',
  });

  const form = useFormContext<DemandItemFormType>();

  const { hasVersionCheckField, updateVersion, keepVersion } = useVersionCheck();

  const { allowNavigation } = useNavigationBlocker();

  const handleFormConfirm = form.handleSubmit(() => {
    if (hasVersionCheckField()) {
      onOpen();
      return;
    }

    allowNavigation();

    // ダイアログが表示されない時はバージョンを更新しない
    keepVersion();
    navigate(
      paths.demands
        ._demandCode(demandCode)
        .items._itemCode({ itemNumber, category })
        .edit.confirm.url(),
    );
  });

  const handleVersionUp = () => {
    allowNavigation();
    updateVersion();
    navigate(
      paths.demands
        ._demandCode(demandCode)
        .items._itemCode({ itemNumber, category })
        .edit.confirm.url(),
    );
  };

  const handleNotVersionUp = () => {
    allowNavigation();
    keepVersion();
    navigate(
      paths.demands
        ._demandCode(demandCode)
        .items._itemCode({ itemNumber, category })
        .edit.confirm.url(),
    );
  };

  return (
    <>
      <Flex justify="space-between" width="768px">
        <RouterLink
          to={
            refererPathname === paths.demands._demandCode(demandCode).url()
              ? paths.demands._demandCode(demandCode).url({
                  itemCategories,
                  itemSuppliers,
                })
              : paths.demands
                  ._demandCode(demandCode)
                  .items._itemCode({ itemNumber, category })
                  .url()
          }
        >
          <PageBack />
        </RouterLink>
      </Flex>
      <SubHeading label="商品の編集" />

      {!isCurrentVersion && (
        <VStack
          px={4}
          py={3}
          alignItems="flex-start"
          borderWidth={2}
          borderColor="yellow.500"
          borderRadius="6px"
          spacing={1}
        >
          <HStack spacing={2}>
            <Caution theme="outline" size="24" fill="#D69E2E" />
            <Text fontWeight="bold" fontSize="sm">
              過去のバージョンのため編集できません。
            </Text>
          </HStack>
        </VStack>
      )}

      <DemandItemForm
        navigateToConfirm={handleFormConfirm}
        queryRef={query}
        isDisabledSubmit={!isCurrentVersion}
        isDisabledCategory={true}
      />
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent data-testid="confirm-modal-button-modal-content">
          <ModalHeader>バージョンアップの確認</ModalHeader>
          <ModalBody>
            バージョンアップを行うと、コードが変更されます。
            <br />
            取引先との齟齬を防ぐため、以下の場合は「変更しない」を選択してください。
            <br />
            <br />
            ・単価表に新しい数量パターンを追加した
            <br />
            ・入力ミスを修正した
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button colorScheme="blue" onClick={handleVersionUp}>
                バージョンアップを行う
              </Button>
              <Button colorScheme="blue" onClick={handleNotVersionUp}>
                変更しない
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
