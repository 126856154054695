import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { InformationShizai } from 'components/InformationShizai';
import { detailNumber } from 'features/salesOrdersV2/models/SalesOrderDetail';
import {
  SalesOrdersDetailPagePreviewFragment$data,
  SalesOrdersDetailPagePreviewFragment$key,
} from 'gql/__generated__/SalesOrdersDetailPagePreviewFragment.graphql';
import { SalesOrderDetailType } from 'gql/graphql.types';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { dateFormat, getToday } from 'utils/date';
import { calculateTotalAmount } from 'utils/priceCalculation';
import {
  SalesOrdersDetailPagePreviewDetail,
  SalesOrdersDetailPagePreviewDetails,
} from '../SalesOrdersDetailPagePreviewDetails';
import { SalesOrdersPreviewHeader, SalesOrdersPreviewTotalPrice } from '../SalesOrdersPreview';

const SalesOrdersDetailPagePreviewFragment = graphql`
  fragment SalesOrdersDetailPagePreviewFragment on SalesOrder {
    transactionID
    receivedDate
    demand {
      name
    }
    detailMessage
    details(orderBy: { direction: ASC, field: ORDER_NUMBER }) {
      edges {
        node {
          id
          branchNumber
          type
          memos {
            body
          }
          deliveryEvents(where: {isValid: true}) {
            edges {
              node {
                fixedDeliveryDate
              }
            }
          }
          item {
            id
            name
            category
            unitPrice
            unitSellingPrice
            quantity
            taxCategory {
              id
              rate
            }
            cardboard {
              size
              type
              material
              thickness
              printingColor
              processing
              other
            }
            flexiblePackage {
              size
              type
              material
              printingColor
              processing
              other
            }
            giftBox {
              size
              type
              paperType
              printingColor
              processing
              other
            }
            paperBag {
              size
              paperType
              printingColor
              processing
              handle
              other
            }
            other {
              specText
            }
          }
          recurringCost {
            id
            name
            note
            quantity
            unitPrice
            unitSellingPrice
            taxCategory {
              id
              rate
            }
          }
          onetimeCost {
            id
            name
            note
            quantity
            unitPrice
            unitSellingPrice
            taxCategory {
              id
              rate
            }
          }
          notionPage {
            notionPageURL
          }
        }
      }
    }
  }
`;

type Props = {
  queryRef: SalesOrdersDetailPagePreviewFragment$key;
};

export const SalesOrdersDetailPagePreview = ({ queryRef }: Props) => {
  const { transactionID, receivedDate, demand, details, detailMessage } = useFragment(
    SalesOrdersDetailPagePreviewFragment,
    queryRef,
  );

  const convertedDetails = convertDetails(transactionID, details);
  const detailsForCalculation = convertedDetails?.map((detail) => {
    return {
      unitPrice: detail.unitPrice ?? 0,
      unitSellingPrice: detail.unitSellingPrice ?? 0,
      quantity: detail.quantity ?? 0,
      tax: detail.tax ?? { rate: 0 },
    };
  });

  return (
    <VStack
      w="full"
      spacing={0}
      alignItems="flex-start"
      p={8}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="8px"
    >
      {/* 書類名、発行日、受注日 */}
      <SalesOrdersPreviewHeader publishedAt={getToday()} orderedAt={dateFormat(receivedDate)} />

      {/* 合計金額、会社情報 */}
      <HStack mt={6} w="full" justify="space-between" align="flex-start">
        <Box w="320px">
          <SalesOrdersPreviewTotalPrice
            demandName={demand.name}
            totalPrice={calculateTotalAmount(detailsForCalculation)}
          />
        </Box>
        <Box w="320px">
          <InformationShizai />
        </Box>
      </HStack>

      {/* 明細行 */}
      <SalesOrdersDetailPagePreviewDetails
        details={convertedDetails}
        detailsForCalculation={detailsForCalculation}
      />

      {/* 備考、合計金額 */}
      <HStack mt={4} w="full" justify="space-between" align="flex-start" spacing={8}>
        <VStack w="full" fontSize="sm" spacing={1} alignItems="flex-start">
          <Text>備考</Text>
          <Text whiteSpace="pre-wrap">{detailMessage}</Text>
        </VStack>
      </HStack>
    </VStack>
  );
};

const convertDetails = (
  transactionId: number,
  details: SalesOrdersDetailPagePreviewFragment$data['details'],
): SalesOrdersDetailPagePreviewDetail[] => {
  if (!details.edges || details.edges.length === 0) return [];

  return details.edges.map((edge): SalesOrdersDetailPagePreviewDetail => {
    const node = edge?.node;

    const branchNumber = node?.branchNumber;
    const notionPageUrl = node?.notionPage?.notionPageURL;
    const fixedDeliveryDate = node?.deliveryEvents?.edges?.at(0)?.node?.fixedDeliveryDate;
    const memo = node?.memos?.[0]?.body;

    switch (node?.type) {
      case SalesOrderDetailType.Item:
        return {
          id: node.item?.id ?? '',
          detailNumber: detailNumber(transactionId, branchNumber),
          notionPageUrl: notionPageUrl ?? '',
          name: node.item?.name ?? '',
          tax: node.item?.taxCategory ?? { id: '', rate: 0 },
          fixedDeliveryDate,
          memo,
          type: SalesOrderDetailType.Item,
          category: node.item?.category ?? '',
          spec: {
            cardboard: node.item?.cardboard ?? undefined,
            flexiblePackage: node.item?.flexiblePackage ?? undefined,
            giftBox: node.item?.giftBox ?? undefined,
            paperBag: node.item?.paperBag ?? undefined,
            other: node.item?.other ?? undefined,
          },
          unitPrice: node.item?.unitPrice,
          unitSellingPrice: node.item?.unitSellingPrice,
          quantity: node.item?.quantity,
        };
      case SalesOrderDetailType.RecurringCost:
        return {
          id: node.recurringCost?.id ?? '',
          detailNumber: detailNumber(transactionId, branchNumber),
          notionPageUrl: notionPageUrl ?? '',
          name: node.recurringCost?.name ?? '',
          tax: node.recurringCost?.taxCategory ?? { id: '', rate: 0 },
          fixedDeliveryDate,
          memo,
          type: SalesOrderDetailType.RecurringCost,
          spec: {
            cost: {
              note: node.recurringCost?.note ?? '',
            },
          },
          unitPrice: node.recurringCost?.unitPrice,
          unitSellingPrice: node.recurringCost?.unitSellingPrice,
          quantity: node.recurringCost?.quantity,
        };
      case SalesOrderDetailType.OnetimeCost:
        return {
          id: node.onetimeCost?.id ?? '',
          detailNumber: detailNumber(transactionId, branchNumber),
          notionPageUrl: notionPageUrl ?? '',
          name: node.onetimeCost?.name ?? '',
          tax: node.onetimeCost?.taxCategory ?? { id: '', rate: 0 },
          fixedDeliveryDate,
          memo,
          type: SalesOrderDetailType.OnetimeCost,
          spec: {
            cost: {
              note: node.onetimeCost?.note ?? '',
            },
          },
          unitPrice: node.onetimeCost?.unitPrice,
          unitSellingPrice: node.onetimeCost?.unitSellingPrice,
          quantity: node.onetimeCost?.quantity,
        };
    }

    return {
      id: '',
      detailNumber: '',
      notionPageUrl: '',
      name: '',
      tax: { id: '', rate: 0 },
      fixedDeliveryDate: undefined,
      memo: '',
      category: '',
      type: SalesOrderDetailType.Item,
      spec: {},
      quantity: 0,
      unitPrice: 0,
      unitSellingPrice: 0,
    };
  });
};
