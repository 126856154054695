import { Button, Text } from '@chakra-ui/react';

type InvoiceIsSentFilterButtonProps = {
  label: string;
  isActive: boolean;
  onClick: () => void;
  count?: number;
  countColor?: string;
};

export const InvoiceIsSentFilterButton = ({
  label,
  isActive,
  onClick,
  count,
  countColor = 'gray.500',
}: InvoiceIsSentFilterButtonProps) => {
  return (
    <Button
      size="sm"
      variant="outline"
      colorScheme={isActive ? 'blue' : 'gray'}
      onClick={onClick}
      borderRadius="full"
    >
      {label}
      {count !== undefined && (
        <Text as="span" color={countColor} ml={2}>
          {count}
        </Text>
      )}
    </Button>
  );
};
