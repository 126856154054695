import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Copy } from '@icon-park/react';
import { AutoResizeTextarea } from 'components/AutoResizeTextarea';
import { ChangeOrderButton } from 'components/ChangeOrderButton';
import { ConfigurableNumberInput } from 'components/ConfigurableNumberInput';
import { ErrorMessage } from 'components/ErrorMessage';
import { NewSalesOrderFormType } from 'features/salesOrdersV2/form';
import { TaxSelectBox } from 'features/tax/components/TaxSelectBox';
import { TaxSelectBoxFragment$key } from 'gql/__generated__/TaxSelectBoxFragment.graphql';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NewSalesOrderConfirmSpecPreview } from '../../confirm/components/NewSalesOrderConfirm/NewSalesOrderConfirmSpecPreview';
import { NewSalesOrderFormDetailsItemAmountPreview } from './NewSalesOrderFormDetailsItemAmountPreview';
import { NewSalesOrderFormDetailsItemGrossProfitMargin } from './NewSalesOrderFormDetailsItemGrossProfitMargin';

type NewSalesOrderFormDetailsItemProps = {
  index: number;
  remove: () => void;
  duplication: () => void;
  isDisabledDelete: boolean;
  isFirstItem: boolean;
  isLastItem: boolean;
  queryRef: TaxSelectBoxFragment$key;
};

export const NewSalesOrderFormDetailsItem = ({
  index,
  remove,
  duplication,
  isDisabledDelete,
  isFirstItem,
  isLastItem,
  queryRef,
}: NewSalesOrderFormDetailsItemProps) => {
  const form = useFormContext<NewSalesOrderFormType>();
  const {
    control,
    getValues,
    setValue,
    register,
    formState: { errors },
  } = form;

  // タブ文字を全角スペースに置換
  useEffect(() => {
    const detailName = getValues(`details.${index}.name`);
    if (detailName && /\t/.test(detailName)) {
      setValue(`details.${index}.name`, detailName.replace(/\t/g, '　'));
    }
  }, [getValues, setValue, index]);

  const handleClickUp = () => {
    const details = getValues('details');
    details.splice(index - 1, 2, details[index], details[index - 1]);
    setValue('details', details);
  };

  const handleClickDown = () => {
    const details = getValues('details');
    details.splice(index, 2, details[index + 1], details[index]);
    setValue('details', details);
  };

  return (
    <HStack key={index} align="flex-start" spacing={2}>
      {/* 並び順変更する矢印 */}
      <VStack w={4} mt={5}>
        <ChangeOrderButton type="up" onClick={handleClickUp} isDisabled={isFirstItem} />
        <ChangeOrderButton type="down" onClick={handleClickDown} isDisabled={isLastItem} />
      </VStack>

      {/* 品名 */}
      <VStack align="flex-start" spacing={1} w="12.5rem">
        <Text fontSize="sm">品名</Text>
        <Box w="12.5rem">
          <Box
            bgColor="gray.50"
            borderRadius="md"
            display="flex"
            alignItems="center"
            minH="32px"
            px={3}
            py={1.5}
            width="100%"
            fontSize="sm"
          >
            {getValues(`details.${index}.name`)}
          </Box>
        </Box>
      </VStack>

      {/* 仕様 */}
      <VStack align="flex-start" spacing={1} w="17.75rem">
        <Text fontSize="sm">仕様</Text>
        <NewSalesOrderConfirmSpecPreview
          detail={getValues(`details.${index}`)}
          emptyView={
            <Text color="gray.600" fontSize="xs">
              仕様がありません
            </Text>
          }
        />
      </VStack>

      {/* メモ */}
      <VStack align="flex-start" spacing={1} w="180px">
        <FormControl isInvalid={!!(errors.details || [])[index]?.memo}>
          <FormLabel>メモ</FormLabel>
          <AutoResizeTextarea
            {...register(`details.${index}.memo`)}
            minH={55}
            size="sm"
            borderRadius="4px"
          />
          <ErrorMessage name={`details.${index}.memo`} errors={errors} />
        </FormControl>
      </VStack>

      {/* 確定納品日 */}
      <VStack align="flex-start" spacing={1} w="8rem">
        <FormControl isInvalid={!!(errors.details || [])[index]?.fixedDeliveryDate}>
          <FormLabel>確定納品日</FormLabel>
          <Input
            type="date"
            {...register(`details.${index}.fixedDeliveryDate`)}
            size="sm"
            borderRadius="4px"
          />
          <ErrorMessage name={`details.${index}.fixedDeliveryDate`} errors={errors} />
        </FormControl>
      </VStack>

      {/* 数量 */}
      <VStack align="flex-start" spacing={1} w="6rem">
        <FormControl isInvalid={!!(errors.details || [])[index]?.quantity} isRequired flex={1}>
          <FormLabel>数量</FormLabel>
          <ConfigurableNumberInput
            {...register(`details.${index}.quantity`)}
            size="sm"
            borderRadius="4px"
          />
          <ErrorMessage name={`details.${index}.quantity`} errors={errors} />
        </FormControl>
      </VStack>

      {/* 仕入単価 */}
      <VStack align="flex-start" spacing={1} w="6rem">
        <FormControl isInvalid={!!(errors.details || [])[index]?.unitPrice} isRequired>
          <FormLabel>仕入単価</FormLabel>
          <ConfigurableNumberInput
            {...register(`details.${index}.unitPrice`)}
            step="0.01"
            size="sm"
            borderRadius="4px"
          />
          <ErrorMessage name={`details.${index}.unitPrice`} errors={errors} />
        </FormControl>
      </VStack>

      {/* 顧客単価 */}
      <VStack align="flex-start" spacing={1} w="6rem">
        <FormControl isInvalid={!!(errors.details || [])[index]?.unitSellingPrice} isRequired>
          <FormLabel>顧客単価</FormLabel>
          <ConfigurableNumberInput
            {...register(`details.${index}.unitSellingPrice`)}
            step="0.01"
            size="sm"
            borderRadius="4px"
          />
          <NewSalesOrderFormDetailsItemGrossProfitMargin index={index} />
          <ErrorMessage name={`details.${index}.unitSellingPrice`} errors={errors} />
        </FormControl>
      </VStack>

      {/* 税区分 */}
      <VStack align="flex-start" spacing={1} w="6rem">
        <FormControl isInvalid={!!(errors.details || [])[index]?.tax?.id} isRequired flex={1}>
          <FormLabel>税区分</FormLabel>
          <Controller
            name={`details.${index}.tax`}
            control={control}
            render={() => (
              <TaxSelectBox
                size="sm"
                borderRadius="4px"
                defaultValue={getValues(`details.${index}.tax.id`)}
                queryRef={queryRef}
                onChange={(e) => {
                  setValue(`details.${index}.tax`, {
                    ...getValues(`details.${index}.tax`),
                    ...{
                      id: e.target.value,
                      rate: e.target[e.target.selectedIndex].getAttribute('data-rate') as string,
                      name: e.target[e.target.selectedIndex].getAttribute('data-name') as string,
                    },
                  });
                }}
              />
            )}
          />
          <ErrorMessage name={`orderLines.${index}.tax.id`} errors={errors} />
        </FormControl>
      </VStack>

      {/* 金額 */}
      <VStack align="flex-start" spacing={1} w="6rem">
        <Text fontSize="sm">金額</Text>
        <Box w="6rem">
          <NewSalesOrderFormDetailsItemAmountPreview index={index} />
        </Box>
      </VStack>

      {/* 複製ボタン */}
      <Box mt={6}>
        <IconButton
          onClick={duplication}
          variant="ghost"
          isRound
          aria-label="copy"
          icon={<Copy />}
          size="sm"
        />
      </Box>

      {/* 削除ボタン */}
      <Box mt={6}>
        <IconButton
          onClick={remove}
          variant="ghost"
          isDisabled={isDisabledDelete}
          isRound
          aria-label="close"
          icon={<CloseIcon />}
          size="sm"
        />
      </Box>
    </HStack>
  );
};
