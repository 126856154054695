import { Box, Button, HStack, Text, theme } from '@chakra-ui/react';

export type SalesOrdersFormStepperStep = {
  description: string;
  buttonLabel: string;
  buttonAction: React.MouseEventHandler<HTMLButtonElement>;
};

export const SalesOrdersFormStepper = ({
  steps,
}: {
  steps: SalesOrdersFormStepperStep[];
}) => {
  const calcStepWidth = (stepsCount: number) => {
    return `calc(100% / ${stepsCount})`;
  };

  return (
    <Box w="100%">
      <HStack gap={4}>
        {steps.map((step, index) => (
          <Box
            key={index}
            w={calcStepWidth(steps.length)}
            p={6}
            position="relative"
            borderRadius={8}
            boxSizing="border-box"
            backgroundColor="gray.50"
            _after={
              index < steps.length - 1
                ? {
                    content: '""',
                    position: 'absolute',
                    top: '50%',
                    right: '-16px',
                    width: '16px',
                    height: '1px',
                    backgroundColor: theme.colors.gray[200],
                  }
                : undefined
            }
          >
            <HStack>
              <Text fontWeight="bold" lineHeight={1.5}>
                STEP{index + 1}
              </Text>
            </HStack>
            <Text mt={1} fontSize="sm">
              {step.description}
            </Text>
            <Button size="sm" mt={4} w="100%" colorScheme="blue" onClick={step.buttonAction}>
              {step.buttonLabel}
            </Button>
          </Box>
        ))}
      </HStack>
    </Box>
  );
};
