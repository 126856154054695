/**
 * @generated SignedSource<<da127096db3498ffe0b8af5dffe7fd0d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CompanyInvoiceRecipientRecipientType = "cc" | "to" | "%future added value";
export type useInvoiceDocumentsQuery$variables = {
  ids: ReadonlyArray<string>;
};
export type useInvoiceDocumentsQuery$data = {
  readonly invoiceDocuments: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly fileName: string;
        readonly id: string;
        readonly invoice: {
          readonly company: {
            readonly id: string;
            readonly invoiceRecipients: ReadonlyArray<{
              readonly email: string;
              readonly id: string;
              readonly recipientType: CompanyInvoiceRecipientRecipientType;
            }> | null;
            readonly name: string;
          };
          readonly id: string;
          readonly isSent: boolean;
        };
      } | null;
    } | null> | null;
  };
};
export type useInvoiceDocumentsQuery = {
  response: useInvoiceDocumentsQuery$data;
  variables: useInvoiceDocumentsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "idIn",
            "variableName": "ids"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "InvoiceDocumentConnection",
    "kind": "LinkedField",
    "name": "invoiceDocuments",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceDocumentEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceDocument",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fileName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Invoice",
                "kind": "LinkedField",
                "name": "invoice",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isSent",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "company",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CompanyInvoiceRecipient",
                        "kind": "LinkedField",
                        "name": "invoiceRecipients",
                        "plural": true,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "recipientType",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useInvoiceDocumentsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useInvoiceDocumentsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "cf4d3ebd1636a69f9f6de1a518ee7260",
    "id": null,
    "metadata": {},
    "name": "useInvoiceDocumentsQuery",
    "operationKind": "query",
    "text": "query useInvoiceDocumentsQuery(\n  $ids: [ID!]!\n) {\n  invoiceDocuments(where: {idIn: $ids}) {\n    edges {\n      node {\n        id\n        fileName\n        invoice {\n          id\n          isSent\n          company {\n            id\n            name\n            invoiceRecipients {\n              id\n              email\n              recipientType\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8c820951c9215e9ccd7a2eda70702fb1";

export default node;
