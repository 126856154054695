import { Box, Flex, VStack } from '@chakra-ui/react';
import { PageBack } from 'components/PageBack';
import { PageHeading } from 'components/PageHeading';
import { SubHeading } from 'components/SubHeading';
import { DemandFormDataType } from 'features/demand/form';
import { CompanyInvoiceRecipientRecipientType } from 'gql/graphql.types';
import { useMutationWrapper } from 'hooks/useMutationWrapper';
import { toast } from 'lib/toast';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';
import { DemandForm } from '../components/DemandForm';

export const DemandCreatePage = () => {
  const navigate = useNavigate();

  const { commitMutation, isMutationInFlight } = useMutationWrapper(
    graphql`
      mutation create_DemandCreatePageMutation(
        $input: CreateDemandInput!
      ) {
        createDemand(input: $input) {
          id
        }
      }
    `,
  );

  const onSubmit = (data: DemandFormDataType) => {
    commitMutation({
      variables: {
        input: {
          name: data.name,
          memo: data.memo || undefined,
          invoiceSendRecipients:
            data.recipients?.map((recipient) => ({
              email: recipient.email,
              recipientType: recipient.recipientType as CompanyInvoiceRecipientRecipientType,
            })) || [],
        },
      },
      onCompleted: () => {
        toast({
          title: 'デマンドを作成しました',
          status: 'success',
        });
        navigate(paths.demands.url());
      },
    });
  };

  return (
    <VStack spacing={8} alignItems="flex-start">
      <PageHeading label="デマンド" />
      <Flex justify="space-between" width="768px">
        <RouterLink to={paths.demands.url()}>
          <PageBack />
        </RouterLink>
      </Flex>
      <SubHeading label="デマンドの追加" />
      <Box w="480px">
        <DemandForm type="new" onSubmit={onSubmit} isMutationInFlight={isMutationInFlight} />
      </Box>
    </VStack>
  );
};
