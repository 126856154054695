import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { ErrorMessage } from 'components/ErrorMessage';
import { CompanyComboBox } from 'features/company/components/CompanyComboBox';
import { EstimationFormType } from 'features/estimations/form';
import { SupplierComboBox } from 'features/supplier/components/SupplierComboBox';
import { UserMultipleSelectBox } from 'features/user/components/UserMultipleSelectBox';
import { EstimationFormV2Fragment$key } from 'gql/__generated__/EstimationFormV2Fragment.graphql';
import { Controller, useFormContext } from 'react-hook-form';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { EstimationFormDemandMemo } from './EstimationFormDemandMemo';
import { EstimationFormLineItemsWrapper } from './EstimationFormLineItemsWrapper';
import { EstimationFormPriceSummary } from './EstimationFormPriceSummary';

const estimationFormV2Fragment = graphql`
  fragment EstimationFormV2Fragment on Query {
    ...EstimationFormLineItemsWrapperFragment
  }
`;

type Props = {
  navigateToConfirm: () => void;
  queryRef: EstimationFormV2Fragment$key;
};
export const EstimationFormV2 = ({ navigateToConfirm, queryRef }: Props) => {
  const query = useFragment(estimationFormV2Fragment, queryRef);
  const form = useFormContext<EstimationFormType>();

  const {
    control,
    getValues,
    register,
    formState: { errors, isSubmitting },
    handleSubmit: onSubmit,
    setValue,
  } = form;

  const handleSubmit = () => {
    navigateToConfirm();
  };

  const handleClearDemand = () => {
    setValue('demand', { id: '', name: '' });
  };

  const handleClearSupplier = () => {
    setValue('supplier', { id: '', name: '' });
  };

  return (
    <form onSubmit={onSubmit(handleSubmit)}>
      <VStack spacing={6} alignItems="flex-start">
        <HStack gap={4} align="top" w="3xl">
          <FormControl isInvalid={!!errors.demand} isRequired>
            <VStack align="stretch" spacing={2}>
              <Box>
                <FormLabel>デマンド</FormLabel>
                <Controller
                  name="demand"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <CompanyComboBox
                      onChangeSelected={(value) => {
                        onChange(value);
                      }}
                      defaultSelectedItem={getValues('demand')}
                      onClearInput={handleClearDemand}
                    />
                  )}
                />
                <ErrorMessage name="demand" errors={errors} />
              </Box>
              <EstimationFormDemandMemo />
            </VStack>
          </FormControl>
          <FormControl isInvalid={!!errors.supplier} isRequired>
            <FormLabel>サプライヤー</FormLabel>
            <Controller
              name="supplier"
              control={control}
              render={({ field: { onChange } }) => (
                <SupplierComboBox
                  onChangeSelected={onChange}
                  defaultSelectedItem={getValues('supplier')}
                  onClearInput={handleClearSupplier}
                />
              )}
            />
            <ErrorMessage name="supplier" errors={errors} />
          </FormControl>
        </HStack>
        <Box w="4xl">
          <HStack spacing={5} align="top" w="3xl">
            <FormControl isInvalid={!!errors.title} isRequired>
              <FormLabel>件名</FormLabel>
              <Input type="string" {...register('title')} />
              <ErrorMessage name="title" errors={errors} />
            </FormControl>
            <FormControl isInvalid={!!errors.expirationPeriod} isRequired w={200}>
              <FormLabel>有効期限</FormLabel>
              <Input type="string" {...register('expirationPeriod')} />
              <ErrorMessage name="expirationPeriod" errors={errors} />
            </FormControl>
          </HStack>
        </Box>

        <EstimationFormLineItemsWrapper queryRef={query} />

        <HStack spacing={6} align="flex-start" justifyContent="space-between" w="3xl">
          <VStack w="70%">
            <FormControl isInvalid={!!errors.detailMessage}>
              <FormLabel>備考</FormLabel>
              <Textarea fontSize="sm" {...register('detailMessage')} rows={8} />
              <ErrorMessage name="detailMessage" errors={errors} />
            </FormControl>
            <FormControl isInvalid={!!errors.internalAssignees} isRequired>
              <FormLabel>担当者</FormLabel>
              <Controller
                name="internalAssignees"
                control={control}
                render={({ field: { onChange } }) => (
                  <UserMultipleSelectBox
                    onChange={onChange}
                    defaultValue={getValues('internalAssignees')}
                    menuPlacement="top"
                  />
                )}
              />
              <ErrorMessage name="internalAssignees" errors={errors} />
            </FormControl>
          </VStack>
          <Box w="240px">
            <EstimationFormPriceSummary />
          </Box>
        </HStack>
        <Box w="3xl">
          <Button colorScheme="blue" w="full" type="submit" isLoading={isSubmitting}>
            確認画面へ
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
