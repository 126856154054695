import { ItemCategory as DemandItemCategoryEnum } from 'gql/graphql.types';

export type DemandItemCategory = Exclude<DemandItemCategoryEnum, DemandItemCategoryEnum.Unknown>;

export const DEMAND_ITEM_CATEGORY = {
  Cardboard: DemandItemCategoryEnum.Cardboard,
  FlexiblePackage: DemandItemCategoryEnum.FlexiblePackage,
  GiftBox: DemandItemCategoryEnum.GiftBox,
  PaperBag: DemandItemCategoryEnum.PaperBag,
  Other: DemandItemCategoryEnum.Other,
} as const;

export const DEMAND_ITEM_CATEGORY_UNKNOWN = DemandItemCategoryEnum.Unknown;

export const DEMAND_ITEM_CATEGORY_CODE = {
  [DEMAND_ITEM_CATEGORY.Cardboard]: 'CB',
  [DEMAND_ITEM_CATEGORY.FlexiblePackage]: 'FP',
  [DEMAND_ITEM_CATEGORY.GiftBox]: 'GB',
  [DEMAND_ITEM_CATEGORY.PaperBag]: 'PB',
  [DEMAND_ITEM_CATEGORY.Other]: 'OT',
} as const;

export type DemandItemCategoryCode =
  (typeof DEMAND_ITEM_CATEGORY_CODE)[keyof typeof DEMAND_ITEM_CATEGORY_CODE];

export const DEMAND_ITEM_CATEGORY_LABEL = {
  [DEMAND_ITEM_CATEGORY.Cardboard]: {
    code: DEMAND_ITEM_CATEGORY_CODE[DEMAND_ITEM_CATEGORY.Cardboard],
    categoryName: 'ダンボール',
    tableLabels: ['サイズ', '形状', '構成', '段厚', '印刷', '加工', 'その他'],
  },
  [DEMAND_ITEM_CATEGORY.FlexiblePackage]: {
    code: DEMAND_ITEM_CATEGORY_CODE[DEMAND_ITEM_CATEGORY.FlexiblePackage],
    categoryName: '軟包材',
    tableLabels: ['サイズ', '形状', '構成', '印刷', '加工', 'その他'],
  },
  [DEMAND_ITEM_CATEGORY.GiftBox]: {
    code: DEMAND_ITEM_CATEGORY_CODE[DEMAND_ITEM_CATEGORY.GiftBox],
    categoryName: '化粧箱',
    tableLabels: ['サイズ', '形状', '用紙', '印刷', '加工', 'その他'],
  },
  [DEMAND_ITEM_CATEGORY.PaperBag]: {
    code: DEMAND_ITEM_CATEGORY_CODE[DEMAND_ITEM_CATEGORY.PaperBag],
    categoryName: '紙袋',
    tableLabels: ['サイズ', '用紙', '印刷', '加工', '持ち手', 'その他'],
  },
  [DEMAND_ITEM_CATEGORY.Other]: {
    code: DEMAND_ITEM_CATEGORY_CODE[DEMAND_ITEM_CATEGORY.Other],
    categoryName: 'その他',
    tableLabels: ['その他'],
  },
} as const;

export function getDemandItemCategoryKeyByValue(categoryCode: DemandItemCategoryCode) {
  return (Object.keys(DEMAND_ITEM_CATEGORY_CODE) as Array<DemandItemCategory>).find(
    (key) => DEMAND_ITEM_CATEGORY_CODE[key] === categoryCode,
  );
}
