import { useTransition } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Variables } from 'relay-runtime';
import { dateIsoFormat } from 'utils/date';
import { useQueryParams } from './useInvoiceQueryPrams';

export const getInvoiceWhere = (
  userId?: string,
  demandId?: string,
  isSent?: string,
  startBookedAt?: string, // YYYY-MM-DD形式
  endBookedAt?: string, // YYYY-MM-DD形式
) => {
  const conditions: Array<Record<string, Record<string, string> | string | boolean>> = [];

  if (demandId) {
    conditions.push({ companyID: demandId });
  }

  if (userId) {
    conditions.push({ hasUpdatedByWith: { id: userId } });
  }

  if (isSent) {
    conditions.push({ isSent: isSent === 'true' });
  }

  if (startBookedAt && endBookedAt) {
    // サーバー側で日付範囲で検索するためISO形式に変換
    const isoStartDate = dateIsoFormat(startBookedAt);
    const isoEndDate = dateIsoFormat(endBookedAt);
    conditions.push({
      bookedAtGTE: isoStartDate,
      bookedAtLTE: isoEndDate,
    });
  }

  if (conditions.length === 0) return {};

  return {
    where: {
      and: conditions,
    },
  };
};

export const useInvoiceFilter = (refetch: (vars: Partial<Variables>) => void) => {
  const { updateQueryParams } = useQueryParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isPending, startTransition] = useTransition();

  const onRefetch = ({
    userId,
    demandId,
    isSent,
    startBookedAt,
    endBookedAt,
  }: {
    userId: string;
    demandId: string;
    isSent: string;
    startBookedAt: string;
    endBookedAt: string;
  }) => {
    startTransition(() => {
      refetch(getInvoiceWhere(userId, demandId, isSent, startBookedAt, endBookedAt));
    });
  };

  const onClearDemand = () => {
    const creatorId = searchParams.get('creator');
    const isSent = searchParams.get('isSent');
    const startBookedAt = searchParams.get('startBookedAt');
    const endBookedAt = searchParams.get('endBookedAt');
    searchParams.delete('demand');
    onRefetch({
      demandId: '',
      userId: creatorId || '',
      isSent: isSent || '',
      startBookedAt: startBookedAt || '',
      endBookedAt: endBookedAt || '',
    });
    setSearchParams(searchParams);
    updateQueryParams({ demand: '' });
  };

  const onChangeUser = (value: string) => {
    const params = {} as { [key: string]: string };
    const demandId = searchParams.get('demand');
    const isSent = searchParams.get('isSent');
    const startBookedAt = searchParams.get('startBookedAt');
    const endBookedAt = searchParams.get('endBookedAt');
    if (demandId) params.demand = demandId;
    if (value) params.creator = value;
    if (isSent) params.isSent = isSent;
    if (startBookedAt) params.startBookedAt = startBookedAt;
    if (endBookedAt) params.endBookedAt = endBookedAt;

    onRefetch({
      demandId: params.demand || '',
      userId: value,
      isSent: params.isSent || '',
      startBookedAt: params.startBookedAt || '',
      endBookedAt: params.endBookedAt || '',
    });
    setSearchParams(params);
    updateQueryParams({ creator: value });
  };

  const onChangeCompany = (value: { id: string; name: string } | null | undefined) => {
    const params = {} as { [key: string]: string };
    const creatorId = searchParams.get('creator');
    const demandId = searchParams.get('demand');
    const isSent = searchParams.get('isSent');
    const startBookedAt = searchParams.get('startBookedAt');
    const endBookedAt = searchParams.get('endBookedAt');
    if (creatorId) params.creator = creatorId;
    if (value) params.demand = value.id;
    if (isSent) params.isSent = isSent;
    if (startBookedAt) params.startBookedAt = startBookedAt;
    if (endBookedAt) params.endBookedAt = endBookedAt;

    if (value?.id !== demandId) {
      onRefetch({
        demandId: params.demand || '',
        userId: params.creator || '',
        isSent: params.isSent || '',
        startBookedAt: params.startBookedAt || '',
        endBookedAt: params.endBookedAt || '',
      });
      setSearchParams(params);
      updateQueryParams({ demand: value?.id || '' });
    }
  };

  const onChangeIsSent = (value: string | null) => {
    const params = {} as { [key: string]: string };
    const creatorId = searchParams.get('creator');
    const demandId = searchParams.get('demand');
    const isSent = searchParams.get('isSent');
    const startBookedAt = searchParams.get('startBookedAt');
    const endBookedAt = searchParams.get('endBookedAt');
    if (creatorId) params.creator = creatorId;
    if (demandId) params.demand = demandId;
    if (value) params.isSent = value;
    if (startBookedAt) params.startBookedAt = startBookedAt;
    if (endBookedAt) params.endBookedAt = endBookedAt;

    if (value !== isSent) {
      onRefetch({
        demandId: params.demand || '',
        userId: params.creator || '',
        isSent: params.isSent || '',
        startBookedAt: params.startBookedAt || '',
        endBookedAt: params.endBookedAt || '',
      });
      setSearchParams(params);
      updateQueryParams({ isSent: value || '' });
    }
  };

  const onChangeBookedAt = (startDate: string, endDate?: string) => {
    const params = {} as { [key: string]: string };
    const creatorId = searchParams.get('creator');
    const demandId = searchParams.get('demand');
    const isSent = searchParams.get('isSent');

    if (creatorId) params.creator = creatorId;
    if (demandId) params.demand = demandId;
    if (isSent) params.isSent = isSent;
    if (startDate) params.startBookedAt = startDate;
    if (endDate) params.endBookedAt = endDate;

    onRefetch({
      demandId: params.demand || '',
      userId: params.creator || '',
      isSent: params.isSent || '',
      startBookedAt: startDate,
      endBookedAt: endDate || '',
    });
    setSearchParams(params);
    updateQueryParams({
      startBookedAt: startDate,
      endBookedAt: endDate || '',
    });
  };

  return {
    onClearDemand,
    onChangeUser,
    onChangeCompany,
    onChangeIsSent,
    onChangeBookedAt,
    isPending,
  };
};
