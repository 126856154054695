import { Box, FormControl, Text } from '@chakra-ui/react';
import { DEMAND_ITEM_CATEGORY_UNKNOWN } from 'features/demandItem/models';
import { EstimationFormType } from 'features/estimations/form';
import { useFormContext, useWatch } from 'react-hook-form';
import { EstimationFormSpecs } from './EstimationFormSpecs';

export const EstimationFormLineItemSpecForm = ({ index }: { index: number }) => {
  const { control } = useFormContext<EstimationFormType>();
  const detail = useWatch({ control, name: `details.${index}` });

  return (
    <>
      {detail.category === DEMAND_ITEM_CATEGORY_UNKNOWN ? (
        <Text fontSize="sm" color="gray.500" w="17.75rem" maxW="17.75rem">
          カテゴリーが未選択のため仕様が入力できません。カテゴリーを選択してください。
        </Text>
      ) : (
        <FormControl w="17.75rem">
          <Box borderLeft="2px solid" borderColor="gray.200" pl={3}>
            <EstimationFormSpecs index={index} />
          </Box>
        </FormControl>
      )}
    </>
  );
};
