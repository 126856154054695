import { CloseIcon } from '@chakra-ui/icons';
import { Box, Button, FormControl, HStack, IconButton, VStack } from '@chakra-ui/react';
import { AddOne } from '@icon-park/react';
import { ConfigurableNumberInput } from 'components/ConfigurableNumberInput';
import { CustomFormLabel } from 'components/CustomFormLabel';
import { ErrorMessage } from 'components/ErrorMessage';
import { DemandItemFormType } from 'features/demandItem/form';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { useState } from 'react';
import { GrossProfitMarginText } from './GrossProfitMarginText';

export const DemandItemPriceForm = () => {
  const form = useFormContext<DemandItemFormType>();
  const { register, formState, control } = form;
  const { errors } = formState;

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'prices',
  });

  const [isDisabledDelete, setIsDisabledDelete] = useState(fields.length <= 1);

  const handleAppendPrice = () => {
    append({
      id: '',
      quantity: '',
      unitPrice: '',
      unitSellingPrice: '',
    });
    setIsDisabledDelete(fields.length + 1 <= 1);
  };

  const handleRemovePrice = (index: number) => {
    remove(index);
    setIsDisabledDelete(fields.length - 1 <= 1);
  };

  return (
    <VStack spacing={2} alignItems="flex-start" width="512px">
      <VStack spacing={0} alignItems="flex-start" width="100%">
        <HStack align="flex-start" spacing={2}>
          <CustomFormLabel isRequired w="7.5rem">
            数量
          </CustomFormLabel>
          <CustomFormLabel isRequired w="7.5rem">
            仕入単価
          </CustomFormLabel>
          <CustomFormLabel isRequired w="7.5rem">
            顧客単価
          </CustomFormLabel>
        </HStack>
        <VStack align="flex-start" spacing={2}>
          {fields.map((field, index) => {
            return (
              <HStack align="flex-start" spacing={2} key={field.id}>
                {/* 数量 */}
                <FormControl
                  isRequired
                  flex={1}
                  w="7.5rem"
                  isInvalid={!!(errors.prices || [])[index]?.quantity}
                >
                  <ConfigurableNumberInput
                    {...register(`prices.${index}.quantity`)}
                    size="sm"
                    borderRadius="4px"
                  />
                  <ErrorMessage name={`prices.${index}.quantity`} errors={errors} />
                </FormControl>

                {/* 仕入単価 */}
                <FormControl
                  isRequired
                  flex={1}
                  w="7.5rem"
                  isInvalid={!!(errors.prices || [])[index]?.unitPrice}
                >
                  <ConfigurableNumberInput
                    {...register(`prices.${index}.unitPrice`)}
                    step="0.01"
                    size="sm"
                    borderRadius="4px"
                  />
                  <ErrorMessage name={`prices.${index}.unitPrice`} errors={errors} />
                </FormControl>

                {/* 顧客単価 */}
                <FormControl
                  isRequired
                  flex={1}
                  w="7.5rem"
                  isInvalid={!!(errors.prices || [])[index]?.unitSellingPrice}
                >
                  <ConfigurableNumberInput
                    {...register(`prices.${index}.unitSellingPrice`)}
                    step="0.01"
                    size="sm"
                    borderRadius="4px"
                  />
                  <GrossProfitMarginText index={index} />
                  <ErrorMessage name={`prices.${index}.unitSellingPrice`} errors={errors} />
                </FormControl>

                <Box>
                  <IconButton
                    variant="ghost"
                    isRound
                    aria-label="close"
                    icon={<CloseIcon />}
                    onClick={() => handleRemovePrice(index)}
                    isDisabled={isDisabledDelete}
                    size="sm"
                  />
                </Box>
              </HStack>
            );
          })}
        </VStack>
      </VStack>
      <Button colorScheme="gray" leftIcon={<AddOne />} onClick={handleAppendPrice} size="sm">
        行追加
      </Button>
    </VStack>
  );
};
