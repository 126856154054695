import {
  useSalesOrdersDownloadPdfQuery$data,
  useSalesOrdersDownloadPdfQuery as useSalesOrdersDownloadPdfQueryType,
} from 'gql/__generated__/useSalesOrdersDownloadPdfQuery.graphql';
import { VersionWatcherContext } from 'hooks/useVersionWatcher';
import RelayEnvironment from 'lib/relay/RelayEnvironment';
import { useContext } from 'react';
import { fetchQuery, graphql } from 'relay-runtime';

const useSalesOrdersDownloadPdfQuery = graphql`
  query useSalesOrdersDownloadPdfQuery ($id: ID!) {
    # TODO: V2のエンドポイントに移行する
    downloadSalesOrderPdf(id: $id)
  }
`;

export const useSalesOrdersDownloadPdf = () => {
  const versionWatcher = useContext(VersionWatcherContext);

  const downloadSalesOrderPdf = async (id: string) => {
    const enableSoftDelete = versionWatcher.disableSoftDeleteEffect();
    let data: useSalesOrdersDownloadPdfQuery$data | undefined = undefined;
    try {
      data = await fetchQuery<useSalesOrdersDownloadPdfQueryType>(
        RelayEnvironment,
        useSalesOrdersDownloadPdfQuery,
        {
          id,
        },
      ).toPromise();
    } catch (_error) {
      // nop: useErrorHandlableRelayEnvironmentでエラー処理している
      return;
    } finally {
      enableSoftDelete();
    }

    window.open(data?.downloadSalesOrderPdf, '_blank', 'noopener, noreferrer');
  };

  return {
    downloadSalesOrderPdf,
  };
};
