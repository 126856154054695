/**
 * @generated SignedSource<<f48d8e15d42f6fbb87b345458c918551>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EstimationDetailDocumentDisplayStatus = "hidden" | "shown" | "%future added value";
export type ItemCategory = "cardboard" | "flexiblePackage" | "giftBox" | "other" | "paperBag" | "unknown" | "%future added value";
export type EstimationUpdateInputV2 = {
  companyID: string;
  detailMessage: string;
  details: ReadonlyArray<EstimationDetailInputV2>;
  expirationPeriod: string;
  internalAssignees?: ReadonlyArray<EstimationInternalAssigneeInput> | null;
  showAmountSummary: boolean;
  supplierID: string;
  title: string;
};
export type EstimationDetailInputV2 = {
  cost?: EstimationDetailCostInput | null;
  item?: EstimationDetailItemInput | null;
};
export type EstimationDetailCostInput = {
  documentDisplayStatus?: EstimationDetailDocumentDisplayStatus | null;
  name: string;
  note: string;
  orderNumber: number;
  quantity: number;
  taxCategoryID: string;
  unitPrice: number;
  unitSellingPrice: number;
};
export type EstimationDetailItemInput = {
  category?: ItemCategory | null;
  documentDisplayStatus?: EstimationDetailDocumentDisplayStatus | null;
  name: string;
  orderNumber: number;
  quantity: number;
  spec?: EstimationDetailItemSpecInput | null;
  taxCategoryID: string;
  unitPrice: number;
  unitSellingPrice: number;
};
export type EstimationDetailItemSpecInput = {
  cardboard?: EstimationItemCardboardInput | null;
  flexiblePackage?: EstimationItemFlexiblePackageInput | null;
  giftBox?: EstimationItemGiftBoxInput | null;
  other?: EstimationItemOtherInput | null;
  paperBag?: EstimationItemPaperBagInput | null;
};
export type EstimationItemCardboardInput = {
  material: string;
  other: string;
  printingColor: string;
  processing: string;
  size: string;
  thickness: string;
  type: string;
};
export type EstimationItemFlexiblePackageInput = {
  material: string;
  other: string;
  printingColor: string;
  processing: string;
  size: string;
  type: string;
};
export type EstimationItemGiftBoxInput = {
  other: string;
  paperType: string;
  printingColor: string;
  processing: string;
  size: string;
  type: string;
};
export type EstimationItemOtherInput = {
  specText: string;
};
export type EstimationItemPaperBagInput = {
  handle: string;
  other: string;
  paperType: string;
  printingColor: string;
  processing: string;
  size: string;
};
export type EstimationInternalAssigneeInput = {
  userID: string;
};
export type confirm_EstimationEditConfirmPageMutation$variables = {
  id: string;
  input: EstimationUpdateInputV2;
};
export type confirm_EstimationEditConfirmPageMutation$data = {
  readonly updateEstimationV2: {
    readonly id: string;
  };
};
export type confirm_EstimationEditConfirmPageMutation = {
  response: confirm_EstimationEditConfirmPageMutation$data;
  variables: confirm_EstimationEditConfirmPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Estimation",
    "kind": "LinkedField",
    "name": "updateEstimationV2",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "confirm_EstimationEditConfirmPageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "confirm_EstimationEditConfirmPageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3c07eeebb01c3ad95839e4fb3c94a725",
    "id": null,
    "metadata": {},
    "name": "confirm_EstimationEditConfirmPageMutation",
    "operationKind": "mutation",
    "text": "mutation confirm_EstimationEditConfirmPageMutation(\n  $id: ID!\n  $input: EstimationUpdateInputV2!\n) {\n  updateEstimationV2(id: $id, input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "32047d79a88253cd29006454c9b04a71";

export default node;
