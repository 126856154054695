/**
 * @generated SignedSource<<fbe3cf744ef6ea88501df5f1ef0c79f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type detail_InvoiceDetailPageQuery$variables = {
  id: string;
};
export type detail_InvoiceDetailPageQuery$data = {
  readonly invoices: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"InvoiceDetailBillingInfoFragment" | "InvoiceDetailInfoFragment">;
      } | null;
    } | null> | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"InvoiceDetailHeaderFragment">;
};
export type detail_InvoiceDetailPageQuery = {
  response: detail_InvoiceDetailPageQuery$data;
  variables: detail_InvoiceDetailPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "detail_InvoiceDetailPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InvoiceConnection",
        "kind": "LinkedField",
        "name": "invoices",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Invoice",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "InvoiceDetailInfoFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "InvoiceDetailBillingInfoFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "InvoiceDetailHeaderFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "detail_InvoiceDetailPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InvoiceConnection",
        "kind": "LinkedField",
        "name": "invoices",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Invoice",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bookedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "paymentDueAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isSent",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "company",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "createdBy",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserProfile",
                        "kind": "LinkedField",
                        "name": "profile",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FreeeInvoice",
                    "kind": "LinkedField",
                    "name": "freeeInvoice",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "freeeInvoiceID",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "freeeInvoiceNumber",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SalesOrder",
                    "kind": "LinkedField",
                    "name": "salesOrders",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Estimation",
                        "kind": "LinkedField",
                        "name": "estimations",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EstimationResponse",
                            "kind": "LinkedField",
                            "name": "estimationResponse",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EstimationRequestAssignee",
                                "kind": "LinkedField",
                                "name": "assignee",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "estimationRequestID",
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "detailMessage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InvoiceInternalMemo",
                    "kind": "LinkedField",
                    "name": "internalMemos",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "body",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "orderBy",
                        "value": {
                          "direction": "ASC",
                          "field": "ORDER_NUMBER"
                        }
                      }
                    ],
                    "concreteType": "InvoiceDetailConnection",
                    "kind": "LinkedField",
                    "name": "details",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InvoiceDetailEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InvoiceDetail",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "where",
                                    "value": {
                                      "isValid": true
                                    }
                                  }
                                ],
                                "concreteType": "InvoiceDetailSaleConnection",
                                "kind": "LinkedField",
                                "name": "invoiceDetailsSales",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "InvoiceDetailSaleEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "InvoiceDetailSale",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "salesDate",
                                            "storageKey": null
                                          },
                                          (v2/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "invoiceDetailsSales(where:{\"isValid\":true})"
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "InvoiceItem",
                                "kind": "LinkedField",
                                "name": "item",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v4/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "quantity",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "unitSellingPrice",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": "tax",
                                    "args": null,
                                    "concreteType": "TaxCategory",
                                    "kind": "LinkedField",
                                    "name": "taxCategory",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "rate",
                                        "storageKey": null
                                      },
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SalesOrderDetail",
                                "kind": "LinkedField",
                                "name": "salesOrderDetail",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "branchNumber",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SalesOrder",
                                    "kind": "LinkedField",
                                    "name": "salesOrder",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "transactionID",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "details(orderBy:{\"direction\":\"ASC\",\"field\":\"ORDER_NUMBER\"})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserInfo",
        "kind": "LinkedField",
        "name": "userInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ConnectedServices",
            "kind": "LinkedField",
            "name": "connectedServices",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "freee",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "09d4c22d4c030cd14013ae3766e97aaf",
    "id": null,
    "metadata": {},
    "name": "detail_InvoiceDetailPageQuery",
    "operationKind": "query",
    "text": "query detail_InvoiceDetailPageQuery(\n  $id: ID!\n) {\n  invoices(where: {id: $id}) {\n    edges {\n      node {\n        ...InvoiceDetailInfoFragment\n        ...InvoiceDetailBillingInfoFragment\n        id\n      }\n    }\n  }\n  ...InvoiceDetailHeaderFragment\n}\n\nfragment InvoiceDetailBillingInfoFragment on Invoice {\n  title\n  detailMessage\n  internalMemos {\n    body\n    id\n  }\n  details(orderBy: {direction: ASC, field: ORDER_NUMBER}) {\n    edges {\n      node {\n        id\n        invoiceDetailsSales(where: {isValid: true}) {\n          edges {\n            node {\n              salesDate\n              id\n            }\n          }\n        }\n        item {\n          id\n          name\n          quantity\n          unitSellingPrice\n          tax: taxCategory {\n            rate\n            id\n          }\n        }\n        salesOrderDetail {\n          id\n          branchNumber\n          salesOrder {\n            id\n            transactionID\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment InvoiceDetailHeaderFragment on Query {\n  userInfo {\n    connectedServices {\n      freee\n    }\n  }\n}\n\nfragment InvoiceDetailInfoFragment on Invoice {\n  id\n  title\n  createdAt\n  bookedAt\n  paymentDueAt\n  isSent\n  company {\n    name\n    id\n  }\n  createdBy {\n    profile {\n      lastName\n      firstName\n      id\n    }\n    id\n  }\n  freeeInvoice {\n    freeeInvoiceID\n    freeeInvoiceNumber\n    id\n  }\n  salesOrders {\n    id\n    title\n    estimations {\n      id\n      title\n      estimationResponse {\n        assignee {\n          estimationRequestID\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e05be6f7bb629232b8a2ff52222534b5";

export default node;
