import { CompanyInvoiceRecipientRecipientType } from 'gql/graphql.types';
import { errorMessages } from 'utils/validation';
import { z } from 'zod';

export const demandFormSchema = z.object({
  name: z.string().max(255, { message: errorMessages.MAX_LENGTH(255) }),
  memo: z
    .string()
    .max(1000, { message: errorMessages.MAX_LENGTH(1000) })
    .optional(),
  recipients: z
    .array(
      z.object({
        id: z.string().optional(),
        email: z
          .string()
          .min(1, { message: errorMessages.REQUIRED })
          .email({ message: errorMessages.INVALID_EMAIL_FORMAT }),
        recipientType: z.nativeEnum(CompanyInvoiceRecipientRecipientType),
      }),
    )
    .optional()
    .refine((recipients) => {
      if (!recipients || recipients.length === 0) return true;
      const emails = recipients.map((r) => r.email);
      if (emails.some((e) => e === '')) {
        return true;
      }

      return new Set(emails).size === emails.length;
    }, 'メールアドレスが重複しています')
    .refine((recipients) => {
      if (!recipients || recipients.length === 0) return true;
      return recipients.some((r) => r.recipientType === CompanyInvoiceRecipientRecipientType.To);
    }, 'TOの請求書送付先は最低1件必要です'),
});

export type DemandFormDataType = z.infer<typeof demandFormSchema>;
