/**
 * @generated SignedSource<<7eff8e762a2ea4e34f3cf4c2a7cf959c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InvoiceBulkSendJobDetailRecipientRecipientType = "cc" | "to" | "%future added value";
export type CreateInvoiceBulkSendJobInput = {
  details: ReadonlyArray<InvoiceBulkSendJobDetailInput>;
  objectName: string;
};
export type InvoiceBulkSendJobDetailInput = {
  companyID: string;
  invoiceDocumentIDs: ReadonlyArray<string>;
  invoiceRecipients: ReadonlyArray<InvoiceBulkSendJobDetailRecipientInput>;
};
export type InvoiceBulkSendJobDetailRecipientInput = {
  email: string;
  recipientType: InvoiceBulkSendJobDetailRecipientRecipientType;
};
export type useCreateInvoiceBulkSendJobMutation$variables = {
  input: CreateInvoiceBulkSendJobInput;
};
export type useCreateInvoiceBulkSendJobMutation$data = {
  readonly createInvoiceBulkSendJob: {
    readonly id: string;
  };
};
export type useCreateInvoiceBulkSendJobMutation = {
  response: useCreateInvoiceBulkSendJobMutation$data;
  variables: useCreateInvoiceBulkSendJobMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "InvoiceBulkSendJob",
    "kind": "LinkedField",
    "name": "createInvoiceBulkSendJob",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateInvoiceBulkSendJobMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCreateInvoiceBulkSendJobMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "01858ce69897e5a585b75bac1f1992e5",
    "id": null,
    "metadata": {},
    "name": "useCreateInvoiceBulkSendJobMutation",
    "operationKind": "mutation",
    "text": "mutation useCreateInvoiceBulkSendJobMutation(\n  $input: CreateInvoiceBulkSendJobInput!\n) {\n  createInvoiceBulkSendJob(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b8c3f9c41bde0a51f0a6b11d7ce7b580";

export default node;
