import { Box, Button, VStack } from '@chakra-ui/react';
import { Link } from 'components/Link';
import { EstimationDetailAsideFragment$key } from 'gql/__generated__/EstimationDetailAsideFragment.graphql';
import { useFragment } from 'react-relay';
import { useNavigate } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { dateFormatWithTime } from 'utils/date';
import { concatFullName } from 'utils/label';
import { paths } from 'utils/paths';

const EstimationDetailAsideFragment = graphql`
  fragment EstimationDetailAsideFragment on Estimation
  {
    id
    title
    createdAt
    expirationPeriod
    company {
      id
      name
    }
    supplier {
      id
      name
    }
    createdBy {
      profile {
        lastName
        firstName
      }
    }
    estimationResponse {
      assignee {
        estimationRequest {
          id
          requestTitle
        }
      }
    }
    salesOrders {
      id
      title
    }
    internalAssignees {
      user {
        id
        profile {
          lastName
          firstName
        }
      }
    }
  }
`;

type Props = {
  queryRef: EstimationDetailAsideFragment$key;
};

export const EstimationDetailAside = ({ queryRef }: Props) => {
  const {
    id,
    title,
    createdAt,
    expirationPeriod,
    company,
    estimationResponse,
    createdBy,
    supplier,
    salesOrders,
    internalAssignees: internalAssigneesData,
  } = useFragment(EstimationDetailAsideFragment, queryRef);
  const navigate = useNavigate();

  const estimationRequest = estimationResponse?.assignee.estimationRequest;
  const salesOrder = salesOrders?.[0];

  return (
    <VStack spacing={6} alignItems="flex-start">
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          作成日時
        </Box>
        <Box>{dateFormatWithTime(createdAt)}</Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          有効期限
        </Box>
        <Box>{expirationPeriod}</Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          件名
        </Box>
        <Box>{title}</Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          デマンド
        </Box>
        <Box>
          <Link to={paths.company._id(company.id).url}>{company.name}</Link>
        </Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          サプライヤー
        </Box>
        <Box>
          <Box>
            <Link to={paths.supplier._id(supplier.id).url()}>{supplier.name}</Link>
          </Box>
        </Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          担当者
        </Box>
        <Box>
          {internalAssigneesData?.map((assignee) =>
            assignee.user.profile?.[0]?.lastName && assignee.user.profile?.[0]?.firstName ? (
              <Box key={assignee.user.id}>
                {concatFullName({
                  lastName: assignee.user.profile[0].lastName,
                  firstName: assignee.user.profile[0].firstName,
                })}
              </Box>
            ) : (
              <Box key={assignee.user.id}>-</Box>
            ),
          ) || '-'}
        </Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          作成者
        </Box>
        <Box>
          {createdBy.profile?.[0]?.lastName && createdBy.profile?.[0]?.firstName
            ? concatFullName({
                lastName: createdBy.profile[0].lastName,
                firstName: createdBy.profile[0].firstName,
              })
            : '-'}
        </Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          見積依頼
        </Box>
        <Box>
          {estimationRequest ? (
            <Link to={paths.estimationRequest._id(estimationRequest.id)}>
              {estimationRequest.requestTitle}
            </Link>
          ) : (
            'なし'
          )}
        </Box>
      </VStack>
      <VStack spacing={1} alignItems="flex-start">
        <Box w="120px" color="gray.500">
          発注請書
        </Box>
        {salesOrder ? (
          <Link to={paths.salesOrders.id(salesOrder.id).url()}>{salesOrder.title}</Link>
        ) : (
          <Button
            colorScheme="blue"
            size="sm"
            onClick={() => navigate(paths.estimations._estimationId(id).newSalesOrder.url())}
          >
            内容を引用して作成
          </Button>
        )}
      </VStack>
    </VStack>
  );
};
