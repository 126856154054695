import { HStack, Table, Tbody, Text } from '@chakra-ui/react';
import { DemandItemPriceTableHeader } from 'features/demandItem/components/DemandItemPriceTable/DemandItemPriceTableHeader';
import { DemandItemPriceTableRow } from 'features/demandItem/components/DemandItemPriceTable/DemandItemPriceTableRow';

type Props = {
  values: {
    id: string;
    quantity: number | string;
    unitPrice: number | string;
    unitSellingPrice: number | string;
  }[];
  tax: string;
};

export const DemandItemPriceTable = ({ values, tax }: Props) => {
  const sortedValues = values.sort((a, b) => Number(a.quantity) - Number(b.quantity));

  return (
    <>
      <HStack spacing={4} alignItems="stretch" w="100%">
        <Text
          border="1px solid"
          borderColor="gray.200"
          borderRadius="2px"
          lineHeight={1}
          p={2}
          fontSize="sm"
          fontWeight="bold"
          textAlign="center"
          sx={{ writingMode: 'vertical-rl' }}
        >
          単価表
        </Text>
        <Table w="100%">
          <DemandItemPriceTableHeader />
          <Tbody>
            {sortedValues.map((value) => (
              <DemandItemPriceTableRow key={value.id} value={value} />
            ))}
          </Tbody>
        </Table>
      </HStack>
      <HStack width="100%" justifyContent="flex-end">
        <Text fontSize="xs" color="gray.500">
          税区分：{tax}
        </Text>
      </HStack>
    </>
  );
};
