import { useInvoiceBulkSendJobUploadURLQuery as useInvoiceBulkSendJobUploadURLQueryType } from 'gql/__generated__/useInvoiceBulkSendJobUploadURLQuery.graphql';
import RelayEnvironment from 'lib/relay/RelayEnvironment';
import { fetchQuery, graphql } from 'relay-runtime';

const useInvoiceBulkSendJobUploadURLQuery = graphql`
  query useInvoiceBulkSendJobUploadURLQuery {
    invoiceBulkSendJobUploadURL {
      url
      objectName
    }
  }
`;

export const useInvoiceBulkSendJobUploadURL = () => {
  const fetchUrl = async () => {
    try {
      const res = await fetchQuery<useInvoiceBulkSendJobUploadURLQueryType>(
        RelayEnvironment,
        useInvoiceBulkSendJobUploadURLQuery,
        {},
        {
          fetchPolicy: 'network-only',
        },
      ).toPromise();

      return res?.invoiceBulkSendJobUploadURL;
    } catch (_error) {
      // nop: useErrorHandlableRelayEnvironmentでエラー処理している
      return;
    }
  };

  return { fetchUrl };
};
