import { CompanyDetailPage } from 'pages/company/detail';
import { CompanyEditPage } from 'pages/company/edit';
import { Navigate, Route, useParams } from 'react-router-dom';

const EstimationDetailRedirect = () => {
  const { id } = useParams();
  return <Navigate to={`/estimations/${id}`} replace />;
};

const EstimationEditRedirect = () => {
  const { id } = useParams();
  return <Navigate to={`/estimations/${id}/edit`} replace />;
};

const EstimationDuplicationRedirect = () => {
  const { id } = useParams();
  return <Navigate to={`/estimations/new?originalEstimateId=${id}`} replace />;
};

export const redirectRoutes = (
  <>
    <Route path="/estimation">
      <Route index element={<Navigate to="/estimations" replace />} />
      <Route path=":id">
        <Route index element={<EstimationDetailRedirect />} />
      </Route>
      <Route path="new" element={<Navigate to="/estimations/new" replace />} />
      <Route path="edit">
        <Route path=":id" element={<EstimationEditRedirect />} />
      </Route>
      <Route path="duplication">
        <Route path=":id" element={<EstimationDuplicationRedirect />} />
      </Route>
    </Route>

    <Route path="/company">
      <Route index element={<Navigate to="/demands" replace />} />
      {/* MEMO: 新しいデマンドページは demand.code が必要なため、一旦既存のページに飛ばしてページコンポーネント側でリダイレクトする */}
      <Route path=":id">
        <Route index element={<CompanyDetailPage />} />
        <Route path="edit" element={<CompanyEditPage />} />
      </Route>
      <Route path="new">
        <Route index element={<Navigate to="/demands/new" replace />} />
      </Route>
    </Route>
  </>
);
