/**
 * @generated SignedSource<<819586015daf2855d8a5f58bef9c5fc7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ExtractInvoiceBulkSendJobDocumentsInput = {
  objectName: string;
};
export type useExtractInvoiceBulkSendJobDocumentsMutation$variables = {
  input: ExtractInvoiceBulkSendJobDocumentsInput;
};
export type useExtractInvoiceBulkSendJobDocumentsMutation$data = {
  readonly extractInvoiceBulkSendJobDocuments: ReadonlyArray<{
    readonly id: string;
  }>;
};
export type useExtractInvoiceBulkSendJobDocumentsMutation = {
  response: useExtractInvoiceBulkSendJobDocumentsMutation$data;
  variables: useExtractInvoiceBulkSendJobDocumentsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "InvoiceDocument",
    "kind": "LinkedField",
    "name": "extractInvoiceBulkSendJobDocuments",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useExtractInvoiceBulkSendJobDocumentsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useExtractInvoiceBulkSendJobDocumentsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d7a3ee4311924f9ba76d244c9e87474e",
    "id": null,
    "metadata": {},
    "name": "useExtractInvoiceBulkSendJobDocumentsMutation",
    "operationKind": "mutation",
    "text": "mutation useExtractInvoiceBulkSendJobDocumentsMutation(\n  $input: ExtractInvoiceBulkSendJobDocumentsInput!\n) {\n  extractInvoiceBulkSendJobDocuments(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ce3ca23fdc9134f35e1c1738cf08818a";

export default node;
