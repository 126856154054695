import { DEMAND_ITEM_CATEGORY } from 'features/demandItem/models';
import {
  DEFAULT_CANCEL_MESSAGE,
  DETAIL_MESSAGE_TEMPLATE,
  FARE_MESSAGE,
  MESSAGE_FOR_ERROR_OF_TAX_CALCULATION,
  NewSalesOrderFormType,
  SALES_ORDER_DETAIL_CATEGORY_COST,
} from 'features/salesOrdersV2/form';
import { layout_NewSalesOrderLayoutQuery$data } from 'gql/__generated__/layout_NewSalesOrderLayoutQuery.graphql';
import { dateFormatHyphenDate } from 'utils/date';
import { concatFullName } from 'utils/label';

const buildDetailMessage = (message: string) => {
  if (message) {
    let newMessage = `${message}\n${MESSAGE_FOR_ERROR_OF_TAX_CALCULATION}`;

    if (!message.includes(FARE_MESSAGE)) {
      newMessage = `${newMessage}\n${FARE_MESSAGE}`;
    }

    if (!message.includes(DEFAULT_CANCEL_MESSAGE)) {
      newMessage = `${newMessage}\n${DEFAULT_CANCEL_MESSAGE}`;
    }

    return newMessage;
  }

  return DETAIL_MESSAGE_TEMPLATE;
};

type BaseEstimationType = NonNullable<
  NonNullable<
    NonNullable<NonNullable<layout_NewSalesOrderLayoutQuery$data['estimations']>['edges']>[0]
  >['node']
>;

type BaseEstimationDetailType = NonNullable<
  NonNullable<NonNullable<NonNullable<BaseEstimationType['details']>['edges']>[0]>['node']
>;

export const mapEstimationToNewSalesOrderForm = (
  estimation: BaseEstimationType,
): NewSalesOrderFormType => {
  const assignees =
    estimation.internalAssignees?.map((assignee) => ({
      value: assignee.user.id,
      label: concatFullName({
        lastName: assignee.user?.profile?.[0].lastName || '',
        firstName: assignee.user?.profile?.[0].firstName || '',
      }),
    })) || [];

  const details = [...(estimation.details?.edges || [])]
    .map((edge) => edge?.node)
    .filter((v): v is NonNullable<typeof v> => v != null);

  return {
    demand: {
      id: estimation.company.id,
      name: estimation.company.name,
    },
    supplier: {
      id: estimation.supplier.id,
      name: estimation.supplier.name,
    },
    title: estimation.title,
    detailMessage: buildDetailMessage(estimation.detailMessage || ''),
    estimationID: estimation.id,
    receivedDate: dateFormatHyphenDate(),
    memo: '',
    internalAssignees: assignees,
    details: mapEstimationToEstimationFormDetails(details),
  };
};

const mapEstimationToEstimationFormDetails = (details: BaseEstimationDetailType[]) => {
  return details.map((detail) => {
    if (detail.type === SALES_ORDER_DETAIL_CATEGORY_COST) {
      const cost = detail.cost;
      return {
        estimationDetailID: detail.id,
        name: cost?.name || '',
        unitPrice: cost?.unitPrice || 0,
        unitSellingPrice: cost?.unitSellingPrice || 0,
        quantity: cost?.quantity || 0,
        tax: {
          id: cost?.tax?.id || '',
          rate: cost?.tax?.rate || 0,
          name: cost?.tax?.name || '',
        },
        category: SALES_ORDER_DETAIL_CATEGORY_COST,
        spec: {
          cost: {
            note: cost?.note || '',
          },
        },
      };
    }

    const item = detail.item;
    return {
      estimationDetailID: detail.id,
      name: item?.name || '',
      unitPrice: item?.unitPrice || 0,
      quantity: item?.quantity || 0,
      unitSellingPrice: item?.unitSellingPrice || 0,
      tax: {
        id: item?.tax?.id || '',
        rate: item?.tax?.rate || 0,
        name: item?.tax?.name || '',
      },
      category: item?.category || DEMAND_ITEM_CATEGORY.Cardboard,
      spec: {
        cardboard: item?.cardboard || {
          size: '',
          type: '',
          material: '',
          thickness: '',
          printingColor: '',
          processing: '',
          other: '',
        },
        flexiblePackage: item?.flexiblePackage || {
          size: '',
          type: '',
          material: '',
          printingColor: '',
          processing: '',
          other: '',
        },
        giftBox: item?.giftBox || {
          size: '',
          type: '',
          paperType: '',
          printingColor: '',
          processing: '',
          other: '',
        },
        paperBag: item?.paperBag || {
          size: '',
          paperType: '',
          printingColor: '',
          processing: '',
          handle: '',
          other: '',
        },
        other: {
          specText: item?.other?.specText || '',
        },
      },
    };
  });
};
