/**
 * @generated SignedSource<<413a997bad67faf693c788b12e24b55f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewSalesOrderFormLineItemsFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NewSalesOrderFormDetailsFragment">;
  readonly " $fragmentType": "NewSalesOrderFormLineItemsFragment";
};
export type NewSalesOrderFormLineItemsFragment$key = {
  readonly " $data"?: NewSalesOrderFormLineItemsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewSalesOrderFormLineItemsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewSalesOrderFormLineItemsFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewSalesOrderFormDetailsFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "55010819c18bd1b9ba35dc72a365ae3b";

export default node;
