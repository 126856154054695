import { DEMAND_ITEM_CATEGORY } from 'features/demandItem/models';
import { ESTIMATION_DETAIL_CATEGORY_COST } from 'features/estimations/form/schema';
import { EstimationDetailDocumentDisplayStatus } from 'features/estimations/models';
import { EstimationDetailItemPriceListFragment$data } from 'gql/__generated__/EstimationDetailItemPriceListFragment.graphql';
import { EstimationDetailPreviewFragment$data } from 'gql/__generated__/EstimationDetailPreviewFragment.graphql';

export const mapPreviewDetailsToEstimationDetails = (
  previewDetails: EstimationDetailPreviewFragment$data['previewDetails'],
) => {
  const details = [...(previewDetails.edges || [])]
    .map((edge) => edge?.node)
    .filter((v): v is NonNullable<typeof v> => v != null);

  return details.map((detail) => {
    if (detail.type === ESTIMATION_DETAIL_CATEGORY_COST) {
      const cost = detail.cost;
      return {
        name: cost?.name || '',
        unitPrice: cost?.unitPrice || 0,
        unitSellingPrice: cost?.unitSellingPrice || 0,
        quantity: cost?.quantity || 0,
        tax: {
          id: cost?.taxCategory?.id || '',
          rate: cost?.taxCategory?.rate || 0,
        },
        documentDisplayStatus:
          detail.documentDisplayStatus as EstimationDetailDocumentDisplayStatus,
        category: ESTIMATION_DETAIL_CATEGORY_COST,
        spec: {
          cost: {
            note: cost?.note || '',
          },
        },
      };
    }

    const item = detail.item;
    return {
      name: item?.name || '',
      unitPrice: item?.unitPrice || 0,
      quantity: item?.quantity || 0,
      unitSellingPrice: item?.unitSellingPrice || 0,
      tax: {
        id: item?.taxCategory?.id || '',
        rate: item?.taxCategory?.rate || 0,
      },
      documentDisplayStatus: detail.documentDisplayStatus as EstimationDetailDocumentDisplayStatus,
      category: item?.category || DEMAND_ITEM_CATEGORY.Cardboard,
      spec: {
        cardboard: item?.cardboard || {
          size: '',
          type: '',
          material: '',
          thickness: '',
          printingColor: '',
          processing: '',
          other: '',
        },
        flexiblePackage: item?.flexiblePackage || {
          size: '',
          type: '',
          material: '',
          printingColor: '',
          processing: '',
          other: '',
        },
        giftBox: item?.giftBox || {
          size: '',
          type: '',
          paperType: '',
          printingColor: '',
          processing: '',
          other: '',
        },
        paperBag: item?.paperBag || {
          size: '',
          paperType: '',
          printingColor: '',
          processing: '',
          handle: '',
          other: '',
        },
        other: {
          specText: item?.other?.specText || '',
        },
      },
    };
  });
};

export const mapPriceListDetailsToEstimationDetails = (
  priceListDetails: EstimationDetailItemPriceListFragment$data['priceListDetails'],
) => {
  const details = [...(priceListDetails.edges || [])]
    .map((edge) => edge?.node)
    .filter((v): v is NonNullable<typeof v> => v != null);

  return details.map((detail) => {
    if (detail.type === ESTIMATION_DETAIL_CATEGORY_COST) {
      const cost = detail.cost;
      return {
        name: cost?.name || '',
        unitPrice: cost?.unitPrice || 0,
        unitSellingPrice: cost?.unitSellingPrice || 0,
        quantity: cost?.quantity || 0,
        tax: {
          id: cost?.taxCategory?.id || '',
          rate: cost?.taxCategory?.rate || 0,
        },
        documentDisplayStatus:
          detail.documentDisplayStatus as EstimationDetailDocumentDisplayStatus,
        category: ESTIMATION_DETAIL_CATEGORY_COST,
        spec: {
          cost: {
            note: cost?.note || '',
          },
        },
      };
    }

    const item = detail.item;
    return {
      name: item?.name || '',
      unitPrice: item?.unitPrice || 0,
      quantity: item?.quantity || 0,
      unitSellingPrice: item?.unitSellingPrice || 0,
      tax: {
        id: item?.taxCategory?.id || '',
        rate: item?.taxCategory?.rate || 0,
      },
      documentDisplayStatus: detail.documentDisplayStatus as EstimationDetailDocumentDisplayStatus,
      category: item?.category || DEMAND_ITEM_CATEGORY.Cardboard,
      spec: {
        cardboard: item?.cardboard || {
          size: '',
          type: '',
          material: '',
          thickness: '',
          printingColor: '',
          processing: '',
          other: '',
        },
        flexiblePackage: item?.flexiblePackage || {
          size: '',
          type: '',
          material: '',
          printingColor: '',
          processing: '',
          other: '',
        },
        giftBox: item?.giftBox || {
          size: '',
          type: '',
          paperType: '',
          printingColor: '',
          processing: '',
          other: '',
        },
        paperBag: item?.paperBag || {
          size: '',
          paperType: '',
          printingColor: '',
          processing: '',
          handle: '',
          other: '',
        },
        other: {
          specText: item?.other?.specText || '',
        },
      },
    };
  });
};
