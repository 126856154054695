/**
 * @generated SignedSource<<47d7d2fb632f4c71801fa874f68e22ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SalesOrdersDetailPageSidebarFragment$data = {
  readonly createdAt: any;
  readonly createdBy: {
    readonly profile: ReadonlyArray<{
      readonly firstName: string;
      readonly lastName: string;
    }> | null;
  };
  readonly demand: {
    readonly code: string;
    readonly id: string;
    readonly name: string;
  };
  readonly estimations: ReadonlyArray<{
    readonly estimationResponse: {
      readonly assignee: {
        readonly estimationRequest: {
          readonly id: string;
          readonly requestTitle: string;
        };
      };
    } | null;
    readonly id: string;
    readonly title: string;
  }> | null;
  readonly id: string;
  readonly internalAssignees: ReadonlyArray<{
    readonly user: {
      readonly id: string;
      readonly profile: ReadonlyArray<{
        readonly firstName: string;
        readonly lastName: string;
      }> | null;
    };
  }> | null;
  readonly invoices: ReadonlyArray<{
    readonly id: string;
    readonly title: string;
  }> | null;
  readonly orders: ReadonlyArray<{
    readonly id: string;
    readonly title: string;
  }> | null;
  readonly receivedDate: any;
  readonly supplier: {
    readonly id: string;
    readonly name: string;
  };
  readonly title: string;
  readonly transactionID: any;
  readonly " $fragmentType": "SalesOrdersDetailPageSidebarFragment";
};
export type SalesOrdersDetailPageSidebarFragment$key = {
  readonly " $data"?: SalesOrdersDetailPageSidebarFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SalesOrdersDetailPageSidebarFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "UserProfile",
  "kind": "LinkedField",
  "name": "profile",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SalesOrdersDetailPageSidebarFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "demand",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Supplier",
      "kind": "LinkedField",
      "name": "supplier",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transactionID",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receivedDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Estimation",
      "kind": "LinkedField",
      "name": "estimations",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "EstimationResponse",
          "kind": "LinkedField",
          "name": "estimationResponse",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EstimationRequestAssignee",
              "kind": "LinkedField",
              "name": "assignee",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EstimationRequest",
                  "kind": "LinkedField",
                  "name": "estimationRequest",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "requestTitle",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Order",
      "kind": "LinkedField",
      "name": "orders",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Invoice",
      "kind": "LinkedField",
      "name": "invoices",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SalesOrderInternalAssignee",
      "kind": "LinkedField",
      "name": "internalAssignees",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SalesOrder",
  "abstractKey": null
};
})();

(node as any).hash = "b34e937989ce32918faee0a7e55eeaf2";

export default node;
