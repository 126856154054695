import { VStack } from '@chakra-ui/react';
import { NewSalesOrderFormType } from 'features/salesOrdersV2/form';
import { NewSalesOrderFormDetailsFragment$key } from 'gql/__generated__/NewSalesOrderFormDetailsFragment.graphql';
import { Suspense, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { NewSalesOrderFormDetailsItem } from './NewSalesOrderFormDetailsItem';

const newSalesOrderFormDetailsFragment = graphql`
  fragment NewSalesOrderFormDetailsFragment on Query
  {
    ...TaxSelectBoxFragment
  }
`;

export const NewSalesOrderFormDetails = ({
  queryRef,
}: {
  queryRef: NewSalesOrderFormDetailsFragment$key;
}) => {
  const form = useFormContext<NewSalesOrderFormType>();
  const { control, getValues } = form;

  const query = useFragment(newSalesOrderFormDetailsFragment, queryRef);

  const { fields, remove, insert } = useFieldArray({
    control,
    name: 'details',
  });

  const [isDisabledDelete, setIsDisabledDelete] = useState(fields.length === 1);

  useEffect(() => {
    setIsDisabledDelete(fields.length === 1);
  }, [fields.length]);

  const handleRemove = (index: number) => () => {
    remove(index);
  };

  const handleDuplicationItem = (index: number) => () => {
    const value = getValues(`details.${index}`);
    insert(
      index + 1,
      { ...value, id: undefined },
      { shouldFocus: true, focusName: `details.${index + 1}.quantity` },
    );
  };

  return (
    <VStack align="stretch" spacing={4}>
      {fields.map((field, index) => (
        <Suspense key={field.id}>
          <NewSalesOrderFormDetailsItem
            key={field.id}
            index={index}
            remove={handleRemove(index)}
            duplication={handleDuplicationItem(index)}
            isDisabledDelete={isDisabledDelete}
            isFirstItem={index === 0}
            isLastItem={index + 1 === fields.length}
            queryRef={query}
          />
        </Suspense>
      ))}
    </VStack>
  );
};
