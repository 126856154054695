import { DemandMemo } from 'features/company/components/DemandMemo';
import { EstimationFormType } from 'features/estimations/form';
import { Suspense } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

export const EstimationFormDemandMemo = () => {
  const form = useFormContext<EstimationFormType>();
  const demand = useWatch({ name: 'demand', control: form.control });

  return (
    <Suspense>
      <DemandMemo demandId={demand?.id || ''} />
    </Suspense>
  );
};
