import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { CompanyComboBox } from 'features/company/components/CompanyComboBox';
import { DemandMemo } from 'features/company/components/DemandMemo';
import { SalesOrdersEditFormType } from 'features/salesOrdersV2/form';
import { useSalesOrdersItemModal } from 'features/salesOrdersV2/hooks/useSalesOrdersItemModal';
import { useSalesOrdersRecurringCostModal } from 'features/salesOrdersV2/hooks/useSalesOrdersRecurringCostModal';
import { UserMultipleSelectBox } from 'features/user/components/UserMultipleSelectBox';
import { SalesOrdersEditFormQuery as SalesOrdersEditFormQueryType } from 'gql/__generated__/SalesOrdersEditFormQuery.graphql';
import { SalesOrderDetailType } from 'gql/graphql.types';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';
import { SalesOrdersEditFormDetails } from '../SalesOrdersEditFormDetails';
import { SalesOrdersFormItemModal } from '../SalesOrdersFormItemModal';
import { SalesOrdersFormRecurringCostModal } from '../SalesOrdersFormRecurringCostModal';
import { SalesOrdersFormStepper, SalesOrdersFormStepperStep } from '../SalesOrdersFormStepper';
import { SalesOrdersReorderFormPrices } from '../SalesOrdersReorderFormPrices';

const salesOrdersEditFormQuery = graphql`
  query SalesOrdersEditFormQuery {
    taxCategories {
      edges {
        node {
          id
          rate
        }
      }
    }
  }
`;

export const SalesOrdersEditForm = () => {
  const {
    control,
    register,
    getValues,
    formState: { errors },
  } = useFormContext<SalesOrdersEditFormType>();

  const { taxCategories } = useLazyLoadQuery<SalesOrdersEditFormQueryType>(
    salesOrdersEditFormQuery,
    {},
    { fetchPolicy: 'network-only' },
  );

  const { isOpenItemModal, onOpenItemModal, onCloseItemModal } = useSalesOrdersItemModal();
  const { isOpenRecurringCostModal, onOpenRecurringCostModal, onCloseRecurringCostModal } =
    useSalesOrdersRecurringCostModal();

  const { fields, insert, remove, append } = useFieldArray({
    control,
    name: 'details',
  });

  const handleAddBlankDetail = () => {
    append(
      {
        detailId: undefined,
        type: SalesOrderDetailType.OnetimeCost,
        name: '',
        note: '',
        memo: '',
        fixedDeliveryDate: '',
        quantity: '',
        unitPrice: '',
        unitSellingPrice: '',
        tax: {
          id: taxCategories?.edges?.[0]?.node?.id ?? '',
          rate: taxCategories?.edges?.[0]?.node?.rate ?? 0,
        },
      },
      { shouldFocus: true, focusName: `details.${fields.length}.name` },
    );
  };

  const steps: SalesOrdersFormStepperStep[] = [
    {
      description: '発注を行う商品を一覧から選択しましょう',
      buttonLabel: '商品を追加',
      buttonAction: () => onOpenItemModal(),
    },
    {
      description: '継続的に発生する費用を追加しましょう',
      buttonLabel: '継続費用を追加',
      buttonAction: () => onOpenRecurringCostModal(),
    },
    {
      description: '一時的に発生する費用や割引があれば明細に追加しましょう',
      buttonLabel: '単発費用を追加',
      buttonAction: () => handleAddBlankDetail(),
    },
  ];

  return (
    <>
      {/* 商品選択モーダル */}
      {isOpenItemModal && (
        <SalesOrdersFormItemModal isOpen={isOpenItemModal} onClose={onCloseItemModal} />
      )}

      {/* 継続費用選択モーダル */}
      {isOpenRecurringCostModal && (
        <SalesOrdersFormRecurringCostModal
          isOpen={isOpenRecurringCostModal}
          onClose={onCloseRecurringCostModal}
        />
      )}

      <VStack w="768px" spacing={6} alignItems="flex-start">
        <VStack w="full" gap={2} align="stretch">
          <Box>
            <FormLabel>デマンド</FormLabel>
            <Controller
              name="demand"
              control={control}
              render={({ field: { onChange } }) => (
                <CompanyComboBox
                  defaultSelectedItem={getValues('demand')}
                  onChangeSelected={onChange}
                  onClearInput={() => {
                    return;
                  }}
                  isDisabled={true}
                />
              )}
            />
            <ErrorMessage name="company" errors={errors} />
          </Box>
          <DemandMemo demandId={getValues('demand')?.id} />
        </VStack>

        <HStack spacing={4} align="top" w="100%">
          <FormControl isInvalid={!!errors.title} isRequired>
            <FormLabel>件名</FormLabel>
            <Input type="string" {...register('title')} />
            <ErrorMessage name="title" errors={errors} />
          </FormControl>

          <FormControl isInvalid={!!errors.receivedDate} maxW="160px" isRequired>
            <FormLabel>受注日</FormLabel>
            <Input type="date" {...register('receivedDate')} />
            <ErrorMessage name="receivedDate" errors={errors} />
          </FormControl>
        </HStack>

        <SalesOrdersFormStepper steps={steps} />

        <SalesOrdersEditFormDetails fields={fields} insert={insert} remove={remove} />

        <HStack spacing={6} align="flex-start" justifyContent="space-between" w="100%">
          <VStack w="65%" spacing={4}>
            <FormControl isInvalid={!!errors.detailMessage}>
              <FormLabel m={0}>備考</FormLabel>
              <Textarea mt={2} {...register('detailMessage')} rows={8} />
              <ErrorMessage name="detailMessage" errors={errors} />
            </FormControl>

            <FormControl isInvalid={!!errors.memo}>
              <FormLabel>社内メモ</FormLabel>
              <Textarea {...register('memo')} rows={8} />
              <ErrorMessage name="memo" errors={errors} />
              <Text color="gray.500" fontSize="sm" mt={1}>
                初期費用の減額や値引きを行った場合は必ず理由を記載してください
              </Text>
            </FormControl>

            <FormControl isInvalid={!!errors.internalAssignees} isRequired>
              <FormLabel>担当者</FormLabel>
              <Controller
                name="internalAssignees"
                control={control}
                render={({ field: { onChange } }) => (
                  <UserMultipleSelectBox
                    onChange={onChange}
                    defaultValue={getValues('internalAssignees')}
                    menuPlacement="top"
                  />
                )}
              />

              <ErrorMessage name="internalAssignees" errors={errors} />
            </FormControl>
          </VStack>

          <SalesOrdersReorderFormPrices />
        </HStack>

        <Box w="100%">
          <Button colorScheme="blue" w="full" type="submit">
            確認画面へ進む
          </Button>
        </Box>
      </VStack>
    </>
  );
};
